import { RootState } from '../store';

const updateOrderCartSelectors = {
  getAllUpdateOrderCartInfo: (state: RootState) => state.updateOrderCart,
  getUpdateOrderMenuItem: (item_id: string) => (state: RootState) => {
    const temp = state.updateOrderCart.updateCart.filter(
      (item) => item.item_id === item_id
    )[0];

    return temp;
  },
};

export default updateOrderCartSelectors;
