import { createSlice } from "@reduxjs/toolkit";
export interface InitialState {
  cart: Array<any>;
  itemCount: number;
}

const initialState: InitialState = {
  cart: [],
  itemCount: 0,
};

export const HotelCartSlice = createSlice({
  name: "hotelCartItems",
  initialState: initialState,
  reducers: {
    addItem: (state: any, action: any) => {
      const existingItem = state.cart.filter(
        (item: any) => item.id === action.payload.id
      );
      if (existingItem.length > 0) {
        existingItem[0].count += 1;
      } else {
        state.cart.push(action.payload);
        state.itemCount = state.cart.length;
      }
    },

    subtractItem: (state: any, action: any) => {
      const index = state.cart
        .map((item: any) => item.id)
        .indexOf(action.payload.id);
      if (index >= 0) {
        state.cart[index].count -= 1;
        if (state.cart[index].count === 0) {
          state.cart.splice(index, 1);
          state.itemCount = state.cart.length;
        }
      }
    },
  },
});
