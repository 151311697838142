/** @format */

import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import getCategoryItems from "../../api/menu/getCategoryItems";
import getDailySpecials from "../../api/menu/getDailySpecials";
import getHourlySpecials from "../../api/menu/getHourlySpecials";
import getItemRecommender from "../../api/menu/getItemRecommender";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import CartIcon from "../../asset/icons/CartIcon";
import FoodItemBeforeOrder from "../../component/FoodItemBeforeOrder";
import MostOrdered from "../../component/MostOrdered";
import NoItems from "../../component/NoItems";
import SearchInput from "../../component/SearchInput";
import { Text } from "../../component/Text";
import ToggleChipWithSwitch from "../../component/ToggleChipWithSwitch";
import ViewCartButton from "../../component/ViewCartButton";
import WarningModal from "../../component/WariningModal";
import { QrActions } from "../../store/Qr/reducer";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import menuSelectors from "../../store/menu/selectors";
import showMenuSelector from "../../store/showMenu/selectors";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import { findItems } from "./helper";
import "./style.scss";
import { classNames } from "../../utils/classNames";

interface ItemProps {
   item_id: string;
   item_name: string;
   description: string;
   price: number;
   discount: number;
   price_after_discount: number;
   type: string;
   out_of_stock: string;
   recommended: boolean;
   best_selling: boolean;
   image_url: string;
   merchant_id: string;
   remaining_quantity: string;
   manage_inventory: boolean;
   add_ons: any;
   customizations: any;
}

const MenuPage = () => {
   const dispatch = useAppDispatch();
   const checkMenu = useAppSelector(showMenuSelector.showMenu);
   const { showMenuOnly } = useAppSelector(showMenuSelector.showMenu);
   const [detailedItem, setDetailedItem] = useState<ItemProps>();
   const [selectedType, setselectedType] = useState({
      veg: false,
      nonVeg: false,
      inStock: false,
   });
   const [payloadItems, setpayloadItems] = useState([]);

   const [filteredItems, setFilteredItems] = useState<[]>([]);
   const [allItems, setAllItems] = useState<any>(null);
   const [showSearchResult, setShowSearchResult] = useState(false);
   const [showDetailsModal, setShowDetailsModal] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const { item, mid, qid } = useParams();
   const { cart } = useAppSelector(cartItemsSelector.getAllCartInfo(mid));
   const [loadingItemRecommender, setloadingItemRecommender] = useState(false);
   const [recentlyClickedItem, setrecentlyClickedItem] = useState("");
   const { itemCount, totalValue } = useAppSelector(cartItemsSelector.getTotalInfo(mid));
   const { addMoreItemCount, addMoreItemFlow, totalAmount } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);
   const { menu } = useAppSelector(menuSelectors.getMenuItems);
   const [peopleWhoOrderedItems, setpeopleWhoOrderedItems] = useState([]);
   const { updateCart, isUpdating, updateCartTotal, refundAmount } = useAppSelector(updateOrderCartSelectors.getAllUpdateOrderCartInfo);
   useEffect(() => {
      dispatch(QrActions.addQr({ mid }));
   }, []);
   const [loading, setloading] = useState<boolean>(false);
   const navigate = useNavigate();
   const [toggledId, settoggledId] = useState<any>();

   const handleShowSearchPage = (value: any) => {
      setSearchValue(value);
      const filteredResult: any[] = [];
      for (let i = 0; i < allItems.items.length; i++) {
         const item = allItems.items[i];

         filteredResult.push(item);
      }
      const fuse = new Fuse(filteredResult, {
         keys: ["item_name", "category_name"],
      });
      const res = fuse.search(value);
      let lt = [];
      for (let x of res) {
         lt.push(x.item);
      }
      console.log(lt, filteredResult);
      // setFilteredItems(res)
      // @ts-ignore
      setFilteredItems(lt);
   };

   useEffect(() => {
      if (item === "hourly" || item === "daily") {
         if (item === "hourly") {
            GET_HOURLY_SPECIALS();
         } else {
            GET_DAILY_SPECIALS();
         }
         return;
      }
      const temp: any = findItems(menu, item);

      GET_ITEMS_FROM_CATEGORY();
      settoggledId(temp.items[0]?.item_id);
   }, [menu, item]);
   const handleHideSearchPage = () => {
      setShowSearchResult(false);
      setSearchValue("");
   };

   const GET_HOURLY_SPECIALS = async () => {
      setloading(true);
      const { payload }: any = await getHourlySpecials({ merchantId: mid });
      const payloadItems: any = [];
      for (let special of payload) {
         for (let item of special.items) {
            let flag = false;
            for (let i of payloadItems) {
               if (i.item_id === item.item_id) {
                  flag = true;
               }
            }
            if (!flag) {
               payloadItems.push({
                  ...item,
                  item_source: "from_hourly_specials",
               });
            }
         }
      }
      console.log("PAYLOAD ITEMS", payloadItems);
      setAllItems({ ...payload, items: payloadItems });
      setpayloadItems(payloadItems);
      setloading(false);
   };
   const GET_DAILY_SPECIALS = async () => {
      setloading(true);
      const { payload }: any = await getDailySpecials({ merchantId: mid });
      const payloadItems: any = [];
      for (let special of payload) {
         for (let item of special.items) {
            let flag = false;
            for (let i of payloadItems) {
               if (i.item_id === item.item_id) {
                  flag = true;
               }
            }
            if (!flag) {
               payloadItems.push({
                  ...item,
                  item_source: "from_daily_specials",
               });
            }
         }
      }
      setAllItems({ ...payload, items: payloadItems ?? [] });
      setpayloadItems(payloadItems ?? []);
      setloading(false);
   };
   const GET_ITEMS_FROM_CATEGORY = async () => {
      setloading(true);
      const { payload }: any = await getCategoryItems({ categoryId: item });
      const payloadItems = payload?.items?.map((item: any) => {
         return {
            ...item,
            item_source: "from_menu",
         };
      });
      console.log("GET_ITEMS_FROM_CATEGORY", payloadItems);
      setAllItems({ ...payload, items: payloadItems ?? [] });
      setpayloadItems(payloadItems ?? []);
      setloading(false);
   };

   console.log("ITEM IN CAT", item);

   const CATEGORY_NAME = allItems?.["category_name"];
   const CATEGORY_LENGTH = allItems?.items?.length;

   const CheckItemType = () => {
      const filteredItem = payloadItems.filter((item: any) => {
         if (Object.values(selectedType).every((item) => !item)) {
            return item;
         } else if (selectedType.veg) {
            if (selectedType.inStock) {
               return item.type == "veg" && !item.out_of_stock && item;
            } else {
               return item.type == "veg" && item;
            }
         } else if (selectedType.nonVeg) {
            if (selectedType.inStock) {
               return item.type == "non-veg" && !item.out_of_stock && item;
            } else {
               return item.type == "non-veg" && item;
            }
         } else if (selectedType.inStock) {
            return item.out_of_stock === false && item;
         }
      });
      console.log(filteredItem, "filteredItem");

      setAllItems({ ...allItems, items: filteredItem });
   };

   useEffect(() => {
      CheckItemType();
   }, [selectedType]);

   const onClick = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   const getPeopleAlsoOrdered = async (data: any) => {
      console.log(data, "DATA getPeopleAlsoOrdered");
      setrecentlyClickedItem(data.item_id);
      setloadingItemRecommender(true);
      const request = await getItemRecommender({
         merchantId: data.merchant_id,
         itemId: data.item_id,
         cookieId: data.cookie_id,
         activity: "added_to_cart",
      });
      const payload: any = request.payload;
      setloadingItemRecommender(false);
      if (request.statusCode === 200) {
         if (payload?.response?.length === 0) {
            toast("No items to recommend");
         }

         let PAYLOAD_RESPONSE = payload.response?.map((item: any) => {
            return {
               ...item,
               item_source: "from_item_recommendations",
            };
         });
         setpeopleWhoOrderedItems(PAYLOAD_RESPONSE);
         window.localStorage.setItem("peopleAlsoOrdered", JSON.stringify(PAYLOAD_RESPONSE || []));
         window.localStorage.setItem("recentlyClickedItem", data.item_id);
      }
      console.log(payload, "RESPONSE getItemRecommender");
   };

   useEffect(() => {
      const item_id = window.localStorage.getItem("recentlyClickedItem");
      setrecentlyClickedItem(item_id !== null ? item_id : "");
      settoggledId(item_id !== null ? item_id : "");
      setpeopleWhoOrderedItems(JSON.parse(window.localStorage.getItem("peopleAlsoOrdered") || "[]") ?? []);
   }, []);

   useEffect(() => {
      if (toggledId === "") {
         window.localStorage.removeItem("recentlyClickedItem");
         window.localStorage.removeItem("peopleAlsoOrdered");
      }
   }, [toggledId]);

   const onClickViewCart = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   const handleShowDetails = (item: ItemProps) => {
      setDetailedItem(item);
      setShowDetailsModal(true);
   };

   return (
      <div className="max-h-[100vh]">
         {!loading ? (
            <>
               <WarningModal />
               <Toaster
                  position="bottom-center"
                  toastOptions={{
                     className: "",
                     style: {
                        marginBottom: "4rem",
                     },
                  }}
               />
               <div className="menupage-header bg-[#fff] gap-[20px]">
                  <div className="w-[100%] py-2 gap-1 flex items-center">
                     <div
                        className="mr-[10px] ring-1 p-1 rounded-[50px] ring-[#C3C5C6]"
                        onClick={() => {
                           navigate(-1);
                        }}>
                        <BackArrowIcon />
                     </div>
                     {allItems && (
                        <Text
                           className="menupage-header-text flex flex-col gap-1"
                           size="1.3rem"
                           color="#0F191A"
                           lHeight="1"
                           align="left">
                           {
                              <>
                                 <span
                                    className="font-custom"
                                    style={{
                                       fontWeight: "bold",
                                    }}>
                                    {item === "hourly" ? "Hourly Specials" : item === "daily" ? "Daily Specials" : CATEGORY_NAME}
                                 </span>
                                 <span
                                    className="text-[#858165] font-custom"
                                    style={{
                                       fontSize: "1rem",
                                    }}>
                                    {allItems?.items?.length} items
                                 </span>
                              </>
                           }
                        </Text>
                     )}
                     {!showMenuOnly && checkMenu.showMenuWithOrder && checkMenu.acceptOrders && (
                        <div
                           onClick={onClick}
                           className="ring-1 rounded-[50px] ring-[#C3C5C6] font-custom p-1">
                           <CartIcon count={addMoreItemFlow ? addMoreItemCount : cart.length} />
                        </div>
                     )}
                  </div>
               </div>
               <div className="sticky px-3 py-2 top-0 z-[10] w-[100%]">
                  <SearchInput
                     showCrossEndAdornment={showSearchResult}
                     handleHideSearchPage={handleHideSearchPage}
                     searchValue={searchValue}
                     setSearchValue={handleShowSearchPage}
                     handleShowSearchPage={handleShowSearchPage}
                  />
               </div>
               <div className={classNames(addMoreItemCount > 0 || itemCount > 0 ? "pb-[80px]" : "", "menupage-container  position-relative ")}>
                  {searchValue === "" ? (
                     <>
                        <div className="menupage-filter-btns-subcontainer px-3 w-[100%]">
                           <ToggleChipWithSwitch
                              disabled={selectedType.nonVeg}
                              selected={selectedType.veg}
                              onClickHandler={() =>
                                 setselectedType({
                                    ...selectedType,
                                    veg: !selectedType.veg,
                                 })
                              }
                              label="Veg"
                           />

                           <ToggleChipWithSwitch
                              disabled={selectedType.veg}
                              selected={selectedType.nonVeg}
                              onClickHandler={() =>
                                 setselectedType({
                                    ...selectedType,
                                    nonVeg: !selectedType.nonVeg,
                                 })
                              }
                              label="Non-Veg"
                           />
                           <ToggleChipWithSwitch
                              selected={selectedType.inStock}
                              onClickHandler={() =>
                                 setselectedType({
                                    ...selectedType,
                                    inStock: !selectedType.inStock,
                                 })
                              }
                              label="In-stock"
                           />
                        </div>

                        <div className="menupage-item-container flex flex-col items-center">
                           {allItems?.items.length > 0 ? (
                              allItems?.items
                                 ?.slice()
                                 .sort((a: any, b: any) => (a.out_of_stock === b.out_of_stock ? 0 : a.out_of_stock ? 1 : -1))
                                 .map((item: any, index: any) => (
                                    <>
                                       <FoodItemBeforeOrder
                                          orderedInfo={""}
                                          cardType="details"
                                          index={index}
                                          mid={mid ?? ""}
                                          getPeopleAlsoOrdered={getPeopleAlsoOrdered}
                                          // orderedInfo={'15'}
                                          item={item}
                                          handleShowDetails={handleShowDetails}
                                          key={item.item_id}
                                          toggledId={toggledId}
                                          updateToggle={(id: any) => {
                                             toggledId === id ? settoggledId("") : settoggledId(id);
                                             setrecentlyClickedItem("");
                                          }}
                                       />
                                       {recentlyClickedItem === item?.item_id && loadingItemRecommender ? (
                                          <MostOrderedSkeleton />
                                       ) : (
                                          recentlyClickedItem === item?.item_id &&
                                          toggledId !== "" && (
                                             <MostOrdered
                                                settoggledId={settoggledId}
                                                data={peopleWhoOrderedItems}
                                             />
                                          )
                                       )}
                                    </>
                                 ))
                           ) : (
                              <NoItems />
                           )}
                        </div>
                     </>
                  ) : (
                     <div className="menupage-items-container2">
                        {filteredItems.length > 0 ? (
                           filteredItems?.map((item: any, index: number) => (
                              <>
                                 <FoodItemBeforeOrder
                                    index={index}
                                    orderedInfo={""}
                                    cardType="details"
                                    mid={mid ?? ""}
                                    getPeopleAlsoOrdered={getPeopleAlsoOrdered}
                                    item={item}
                                    key={item.item_id}
                                    toggledId={toggledId}
                                    updateToggle={(id: any) => {
                                       toggledId === id ? settoggledId("") : settoggledId(id);
                                       setrecentlyClickedItem("");
                                    }}
                                    handleShowDetails={handleShowDetails}
                                 />

                                 {recentlyClickedItem === item?.item_id && loadingItemRecommender ? (
                                    <MostOrderedSkeleton />
                                 ) : (
                                    recentlyClickedItem === item?.item_id &&
                                    toggledId !== "" && (
                                       <MostOrdered
                                          settoggledId={settoggledId}
                                          data={peopleWhoOrderedItems}
                                       />
                                    )
                                 )}
                              </>
                           ))
                        ) : (
                           <NoItems />
                        )}
                        {(itemCount || addMoreItemCount) > 0 && (totalValue || totalAmount) > 0 && (
                           <ViewCartButton
                              onClickButton={onClickViewCart}
                              buttonTitle={"View cart"}
                           />
                        )}
                     </div>
                  )}
               </div>
               {checkMenu.acceptOrders && checkMenu.showMenuWithOrder && (itemCount || addMoreItemCount) > 0 && (totalValue || totalAmount) > 0 && (
                  <div>
                     <ViewCartButton
                        buttonTitle={"View cart"}
                        onClickButton={onClickViewCart}
                     />
                  </div>
               )}
            </>
         ) : (
            <MenuPageSkeleton />
         )}
      </div>
   );
};

const MostOrderedSkeleton = () => (
   <div style={{ marginLeft: "0.5rem" }}>
      <div className="menupage-most-ordered-container">
         <div className="menupage-most-ordered-subcontainer">
            <img
               className="menupage-most-ordered-img"
               src={require("../../asset/gifs/newLoader.gif")}
               alt=""
            />
         </div>
         <div className="menupage-most-ordered-text-container">
            <span className="font-custom">Fetching your item recommendations,</span> <span>please wait...</span>
         </div>
      </div>
   </div>
);

const MenuPageSkeleton = () => (
   <>
      <Skeleton
         height={"2.5rem"}
         width={"80%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 35,
            marginTop: 20,
         }}
      />
      <Skeleton
         height={"3rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <div
         style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
         }}>
         <Skeleton
            height={"2rem"}
            width={"5rem"}
            style={{
               alignSelf: "center",
               display: "flex",
               marginLeft: 20,
               marginTop: 10,
            }}
         />
         <Skeleton
            height={"2rem"}
            width={"5rem"}
            style={{
               alignSelf: "center",
               display: "flex",
               marginLeft: 20,
               marginTop: 10,
            }}
         />
         <Skeleton
            height={"2rem"}
            width={"5rem"}
            style={{
               alignSelf: "center",
               display: "flex",
               marginLeft: 20,
               marginTop: 10,
            }}
         />{" "}
      </div>
      <Skeleton
         height={"5rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"5rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />{" "}
      <Skeleton
         height={"5rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />{" "}
      <Skeleton
         height={"5rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />{" "}
      <Skeleton
         height={"5rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />{" "}
   </>
);

export default MenuPage;
