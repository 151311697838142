import React, { useEffect, useState } from 'react';
import { Ippopay } from 'react-ippopay';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import updateOrderAPI from '../../api/order/updateOrder';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { orderActions } from '../../store/order/orderSlice';
import qrSelectors from '../../store/Qr/selectors';
import userSelectors from '../../store/user/selectors';
import orderSelectors from '../../store/order/selectors';
import { useParams } from 'react-router-dom';

function Pay() {
  const { REACT_APP_PRODUCT_KEY } = process.env;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { jwt: accessToken } = useAppSelector( userSelectors.getAccessToken );
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get( "id" );
  const { mid } = useParams();
  const { customer_id } = useAppSelector( userSelectors.getUserDetails );
  const order = useAppSelector( orderSelectors.getOrder );
  const navigateToOrderSummary = () =>
    navigate( `/order_summary/${mid}/${customer_id}/${order.order_id}` );

  const [state, setState] = useState( {
    ippopayOpen: true,
    order_id: id,
    public_key: REACT_APP_PRODUCT_KEY,
  } );
  useEffect( () => {
    window.addEventListener( 'message', ippopayHandler );
  }, [] );

  const ippopayHandler = async ( e ) => {
    if ( e.data.status === 'success' )
    {
      console.log( e.data );
      console.log( 'EXISTING ORDER', order );
      let existing = { ...order.order };
      console.log( 'Ippo pay success event existing order', existing );
      existing = { ...existing, payment_status: 'success' };
      existing = { ...existing, order_updated_by: 'customer' };
      console.log( "before updateorderapi call  updateorderrrr", existing );
      await updateOrderAPI( existing, accessToken );
      navigateToOrderSummary();
      //dispatch(orderActions.clearOrder());
      dispatch( orderActions.setIsOrderSummaryVisible( true ) );
    }
    if ( e.data.status === 'failure' )
    {
      console.log( e.data );
      let existing = { ...order };
      existing = { ...existing, payment_status: 'failure' };
      await updateOrderAPI( existing );
      navigateToOrderSummary();
      dispatch( orderActions.clearOrder() );
    }
  };

  return (
    <div>
      <Ippopay
        ippopayOpen={ state.ippopayOpen }
        ippopayClose={ true }
        order_id={ state.order_id }
        public_key={ state.public_key }
      />
    </div>
  );
}

export default Pay;
