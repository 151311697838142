import react from "react";
import { ArrowRight } from "react-feather";
import type { MenuCard } from "./MenuCards.type";
import "./style.scss";

const MenuItem = ({ background, link, text }: MenuCard) => {
  const styles = {
    background: background,
    fontSize: text.length > 30 ? "1.1rem" : "1.4rem",
  };
  return (
    <div style={styles} className="menu-card-item-container font-custom">
      {text}
      <div className="menu-card-item-arrow-wrapper">
        <ArrowRight />
      </div>
    </div>
  );
};

export default MenuItem;
