import { textAlign } from "@mui/system";
import React from "react";
import FrontArrowIcon from "../../asset/icons/FrontArrowIcon";
import Tick from "../../asset/icons/Tick";
import YellowArrowIcon from "../../asset/icons/YellowArrowIcon";
import "./style.scss";

const ButtonWithArrow = ({ buttonText, background, fontColor, showArrow, border, Icon, CustomIcon, disabled, Type, isSelect, onClickHandler, buttonStyle }: ButtonWithArrowProps) => {
	return (
		<button
			style={{
				textAlign: CustomIcon ? undefined : "center",
				background: background ? background : undefined,
				color: fontColor ? fontColor : undefined,
				border: border ? border : undefined,
				alignItems: "center",
				width: "100%",
			}}
			className="styled-btn"
			onClick={onClickHandler}
			disabled={disabled}>
			{isSelect ? (
				<div className="styled-btn-container font-custom">
					{buttonText}
					<Tick />
				</div>
			) : (
				<div className="styled-btn-container">
					{buttonText}
					<div>{CustomIcon}</div>
					{showArrow &&
						(Icon === "normal" ? (
							<span>
								<FrontArrowIcon />
							</span>
						) : (
							<span className="styled-btn-yellow-arrow-container">
								<YellowArrowIcon />
							</span>
						))}
				</div>
			)}
		</button>
	);
};

interface ButtonWithArrowProps {
	Type?: any;
	buttonText: string;
	background: string;
	fontColor: string;
	border?: any;
	showArrow?: boolean;
	Icon?: string;
	disabled?: boolean;
	onClickHandler?: () => void;
	buttonStyle?: object;
	CustomIcon?: any;
	isSelect?: any;
}

export default ButtonWithArrow;
