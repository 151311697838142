/** @format */

import React from "react";
import styled from "@emotion/styled";
import ConfirmationPending from "../../asset/icons/ConfirmationPending";
import ServedIcon from "../../asset/icons/ServedIcon";
import BeingPreparedIcon from "../../asset/icons/BeingPreparedIcon";
import NewOrderIcon from "../../asset/icons/NewOrderIcon";
import CompletedIcon from "../../asset/icons/CompletedIcon";
import OrderConfirmedIcon from "../../asset/icons/OrderConfirmedIcon";
import CancelOrderIcon from "../../asset/icons/CancelOrderIcon";
import OrderUpdatedIcon from "../../asset/icons/OrderUpdatedIcon";
import "./style.scss";

const Wrapper = styled.div<WrapperProps>`
	font-family: "TWKEverett", sans-serif;
	font-size: 16px;
	margin-top: 4px;
	align-items: center;
	justify-content: space-between;
	display: flex;
	white-space: nowrap;
	font-weight: 600;
	color: ${({ fontColor }) => fontColor ?? ""};
	background: #fff;
	border-style: solid;
`;

const FilledTag = ({ text, background, underline, fontColor, icon, payment_status, order_status, type }: FilledTagProps) => {
	if (order_status || type) {
		if (type === "recommended") {
			return (
				<Wrapper
					fontColor="#4184CC"
					background="#D4E9F6">
					Recommended
				</Wrapper>
			);
		} else if (type === "bestselling") {
			return (
				<Wrapper
					fontColor="#F9CA41"
					background="#F9CA411A">
					Best Selling
				</Wrapper>
			);
		} else if (order_status === "order_confirmed") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#4361EE"
						background="#ECEFFD">
						Confirmed
						<OrderConfirmedIcon />
					</Wrapper>
					{payment_status && (
						<>
							{payment_status === "un-paid" ? (
								<Wrapper
									fontColor="red"
									background="FBE6EA">
									Un-paid
								</Wrapper>
							) : (
								<Wrapper
									fontColor="green"
									background="FBE6EA">
									Paid
								</Wrapper>
							)}
						</>
					)}
				</div>
			);
		} else if (order_status === "start_preparation") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#2FBF71"
						background="#EAF9F1">
						Preparing
						<CompletedIcon />
					</Wrapper>
					{payment_status === "un-paid" ? (
						<Wrapper
							fontColor="red"
							background="FBE6EA">
							Un-paid
						</Wrapper>
					) : (
						<Wrapper
							fontColor="green"
							background="FBE6EA">
							Paid
						</Wrapper>
					)}
				</div>
			);
		} else if (order_status === "order_dispatched") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#2FBF71"
						background="#EAF9F1">
						Dispatched
						<CompletedIcon />
					</Wrapper>
					{payment_status && (
						<>
							{payment_status === "un-paid" ? (
								<Wrapper
									fontColor="red"
									background="FBE6EA">
									Un-paid
								</Wrapper>
							) : (
								<Wrapper
									fontColor="green"
									background="FBE6EA">
									Paid
								</Wrapper>
							)}
						</>
					)}
				</div>
			);
		} else if (order_status === "order_delivered") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#2FBF71"
						background="#EAF9F1">
						Delivered
						<CompletedIcon />
					</Wrapper>
					{payment_status && (
						<>
							{payment_status === "un-paid" ? (
								<Wrapper
									fontColor="red"
									background="FBE6EA">
									Un-paid
								</Wrapper>
							) : (
								<Wrapper
									fontColor="green"
									background="FBE6EA">
									Paid
								</Wrapper>
							)}
						</>
					)}
				</div>
			);
		} else if (order_status === "order_completed" || order_status === "ready_to_serve") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#2FBF71"
						background="#EAF9F1"
						style={{ alignItems: "center", display: "flex" }}>
						{order_status === "ready_to_serve" ? "Ready to serve" : "Completed"}
						<CompletedIcon />
					</Wrapper>
					{payment_status && (
						<>
							{payment_status === "un-paid" ? (
								<Wrapper
									fontColor="red"
									background="FBE6EA">
									Un-paid
								</Wrapper>
							) : (
								<Wrapper
									fontColor="green"
									background="FBE6EA">
									Paid
								</Wrapper>
							)}
						</>
					)}
				</div>
			);
		} else if (order_status === "new_order") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#F9CA41"
						background="#FEFAEC">
						New Order
						<NewOrderIcon />
					</Wrapper>
					{payment_status && (
						<>
							{payment_status === "un-paid" ? (
								<Wrapper
									fontColor="red"
									background="FBE6EA">
									Un-paid
								</Wrapper>
							) : (
								<Wrapper
									fontColor="green"
									background="FBE6EA">
									Paid
								</Wrapper>
							)}
						</>
					)}
				</div>
			);
		} else if (order_status === "order_cancelled" || order_status === "cancelled") {
			return (
				<Wrapper
					fontColor="#D90429"
					background="#FBE6EA">
					Cancelled
					<CancelOrderIcon />
				</Wrapper>
			);
		}
		if (payment_status === "failure" && order_status === "new_order") {
			return (
				<Wrapper
					fontColor="#2FBE51"
					background="FBE6EA">
					Unpaid
				</Wrapper>
			);
		} else if (order_status === "order_updated") {
			return (
				<div className="order-and-payment-status-wrapper">
					<Wrapper
						fontColor="#43D9B9"
						background="#ECFBF8">
						Updated
						<OrderUpdatedIcon />
					</Wrapper>
					{payment_status && (
						<>
							{payment_status === "un-paid" ? (
								<Wrapper
									fontColor="red"
									background="FBE6EA">
									Un-paid
								</Wrapper>
							) : (
								<Wrapper
									fontColor="green"
									background="FBE6EA">
									Paid
								</Wrapper>
							)}
						</>
					)}
				</div>
			);
		} else if (payment_status === "failure" && order_status === "order_updated") {
			return (
				<Wrapper
					fontColor="#D90429"
					background="#FBE6EA">
					Unpaid
				</Wrapper>
			);
		} else if (payment_status === "un-paid" && order_status === "new_order") {
			return (
				<Wrapper
					fontColor="#D90429"
					background="#FBE6EA">
					Unpaid
				</Wrapper>
			);
		}
	}

	return (
		<div className="flex flex-col gap-1 ">
			<Wrapper
				fontColor={fontColor}
				background={background}>
				{text}
				{icon}
			</Wrapper>
			{underline && <div className="w-[100%] h-[2px] bg-[#0F6656]"></div>}
		</div>
	);
};

interface FilledTagProps {
	order_status?: any;
	text?: string;
	background?: string;
	fontColor?: string;
	icon?: React.ReactNode;
	payment_status?: string;
	type?: string;
	underline?: boolean;
}

interface WrapperProps {
	background?: string;
	fontColor?: string;
}

export default FilledTag;
