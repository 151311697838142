import React from "react";
import styled from "@emotion/styled";
import NonVegIcon from "../../asset/icons/NonVegIcon";
import VegIcon from "../../asset/icons/VegIcon";
import "./style.scss";

const NonVegTag = ({ veg }: NonVegTagProps) => {
	return (
		<div className="non-veg-tag-wrapper">
			{veg ? (
				<>
					<VegIcon />
					{/* <div className="non-veg-text">Veg</div> */}
				</>
			) : (
				<>
					<NonVegIcon />
					{/* <div className="non-veg-non-veg-text">Non-veg</div> */}
				</>
			)}
		</div>
	);
};

interface NonVegTagProps {
	veg: boolean;
}

export default NonVegTag;
