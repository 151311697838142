/** @format */

import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import SingleOrderDetailsSkeleton from "./SingleOrderDetailsSkeleton";
import "./style.scss";
import { useAppSelector } from "../../../app/hooks";
import addMoreItemsOnCartSelector from "../../../store/addMoreItemOnOrder/selectors";
import showMenuSelector from "../../../store/showMenu/selectors";

const OrderDetailsSection = ({
   totalItems,
   totalAmount,
   totalDiscount,
   grandTotal,
   refundAmount,
   paymentStatus,
   orderType,
   onPressChange,
   showChange,
   extraPaymentAmount,
   orderStatus,
   cgst,
   sgst,
   rounded_off,
}: OrderDetailsSectionsProps) => {
   if (totalItems) {
      console.log("heree");
   }

   const { currency } = useAppSelector(showMenuSelector.showMenu);
   const { addMoreItemFlow } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);
   const showOrderStatus = (value: string) => {
      if (value === "new_order") {
         return "Order placed";
      } else if (value === "order_confirmed") {
         return "Confirmed";
      } else if (value === "order_dispatched") {
         return "Disptached";
      } else if (value === "order_delivered") {
         return "Delivered";
      } else if (value === "start_preparation") {
         return "Preparing";
      } else if (value === "order_completed") {
         return "Completed";
      } else if (value === "order_updated") {
         return "Order updated";
      }
   };

   return (
      <div className="order-details-container">
         {/* <Skeleton wrapper={SingleOrderDetailsSkeleton} /> */}

         <div className="order-details-container-text">
            <div className="order-details-text">Total items</div>

            <div className="order-details-content">{totalItems}</div>
         </div>

         <div className="order-details-container-text">
            <div className="order-details-text">Order type</div>
            <div
               style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
               }}>
               <div
                  style={{ color: "#2FBF71" }}
                  className="order-details-content">
                  {orderType}
               </div>
               {addMoreItemFlow ||
                  (showChange && (
                     <div
                        className="change-order-type"
                        onClick={onPressChange}>
                        change
                     </div>
                  ))}
            </div>
         </div>
         <div className="order-details-container-text">
            <div className="order-details-text">Total amount</div>
            <div className="order-details-content">{`${currency}${totalAmount}`}</div>
         </div>
         {orderStatus !== "" && (
            <div className="order-details-container-text">
               <div className="order-details-text">Order status</div>
               <div
                  style={{
                     flexDirection: "row",
                     display: "flex",
                     alignItems: "center",
                  }}>
                  <div
                     style={{ color: "#4361EE" }}
                     className="order-details-content">
                     {showOrderStatus(orderStatus)}
                  </div>
               </div>
            </div>
         )}

         {paymentStatus !== "" && (
            <div className="order-details-container-text">
               <div className="order-details-text">Payment status</div>
               <div
                  style={{
                     flexDirection: "row",
                     display: "flex",
                     alignItems: "center",
                  }}>
                  <div
                     style={{ color: paymentStatus === "paid" ? "#2D6B4D" : "red" }}
                     className="order-details-content">
                     {paymentStatus === "paid" ? "Paid" : "Unpaid"}
                  </div>
               </div>
            </div>
         )}

         {cgst > 0 && (
            <div className="order-details-container-text">
               <div className="order-details-text">CGST</div>
               <div
                  style={{
                     flexDirection: "row",
                     display: "flex",
                     alignItems: "center",
                  }}>
                  <div
                     style={{ color: paymentStatus === "paid" ? "#2D6B4D" : "red" }}
                     className="order-details-content">
                     <div className="order-details-content">{cgst}%</div>
                  </div>
               </div>
            </div>
         )}
         {sgst > 0 && (
            <div className="order-details-container-text">
               <div className="order-details-text">SGST</div>
               <div
                  style={{
                     flexDirection: "row",
                     display: "flex",
                     alignItems: "center",
                  }}>
                  <div
                     style={{ color: paymentStatus === "paid" ? "#2D6B4D" : "red" }}
                     className="order-details-content">
                     <div className="order-details-content">{sgst}%</div>
                  </div>
               </div>
            </div>
         )}
         <div className="order-details-container-text">
            <div className="order-details-text">Rounded off (+/-)</div>
            <div
               style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
               }}>
               <div
                  style={{ color: paymentStatus === "paid" ? "#2D6B4D" : "red" }}
                  className="order-details-content">
                  <div className="order-details-content">{rounded_off}</div>
               </div>
            </div>
         </div>

         {totalDiscount > 0 && (
            <div className="order-details-container-text">
               <div className="order-details-text">Discount</div>
               <div>{`-${currency}${totalDiscount}`}</div>
            </div>
         )}
         {refundAmount > 0 && (
            <div className="order-details-container-text">
               <div className="order-details-text">Refund</div>

               <div className="order-details-content">
                  {currency}
                  {refundAmount}
               </div>
            </div>
         )}
         {extraPaymentAmount > 0 && (
            <div className="order-details-container-text">
               <div className="order-details-text">Extra payment amount</div>

               <div className="order-details-content">
                  {currency}
                  {extraPaymentAmount}
               </div>
            </div>
         )}
         <div className="order-details-dotted-divider"></div>
         <div className="order-details-container-text">
            <div className="order-details-text-grand-total">{addMoreItemFlow ? "NEW GRAND TOTAL" : "GRAND TOTAL"}</div>
            <div className="order-details-content-grand-total">{`${currency}${grandTotal}`}</div>
         </div>
      </div>
   );
};

interface OrderDetailsSectionsProps {
   totalItems: string;
   totalAmount: number;
   totalDiscount: number;
   grandTotal: number;
   refundAmount: number;
   paymentStatus: string;
   orderType: string;
   onPressChange: any;
   showChange: Boolean;
   extraPaymentAmount: number;
   orderStatus: string;
   cgst: any;
   sgst: any;
   rounded_off: string;
}

export default OrderDetailsSection;
