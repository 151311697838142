import React from "react";

const FacebookIcon = () => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 28 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.4102 14.0002C28.4102 6.26816 22.1421 0.000156403 14.4102 0.000156403C6.67816 0.000156403 0.410156 6.26816 0.410156 14.0002C0.410156 20.988 5.52975 26.7798 12.2227 27.8301V18.047H8.66797V14.0002H12.2227V10.9158C12.2227 7.40703 14.3128 5.46891 17.5107 5.46891C19.0424 5.46891 20.6445 5.74234 20.6445 5.74234V9.18766H18.8792C17.14 9.18766 16.5977 10.2668 16.5977 11.374V14.0002H20.4805L19.8598 18.047H16.5977V27.8301C23.2906 26.7798 28.4102 20.988 28.4102 14.0002Z"
				fill="#1877F2"
			/>
			<path
				d="M19.8598 18.0469L20.4805 14H16.5977V11.3738C16.5977 10.2667 17.14 9.1875 18.8792 9.1875H20.6445V5.74219C20.6445 5.74219 19.0424 5.46875 17.5107 5.46875C14.3128 5.46875 12.2227 7.40688 12.2227 10.9156V14H8.66797V18.0469H12.2227V27.8299C12.9354 27.9418 13.666 28 14.4102 28C15.1543 28 15.8849 27.9418 16.5977 27.8299V18.0469H19.8598Z"
				fill="white"
			/>
		</svg>
	);
};

export default FacebookIcon;
