import React from "react";
import { Modal } from "@material-ui/core";
import { ReactComponent as ClubIcon } from "../../asset/svg/ClubIcon.svg";
import { ReactComponent as HeartIcon } from "../../asset/svg/HeartIcon.svg";

export default function ExclusiveClubModal({ open, onClose }) {
  const clubDetails = [
    "Get personalized offers & rewards",
    "Earn coins on each order and use it to redeem food",
    "Get personalized offers & rewards",
  ];
  return (
    <Modal open={open} onClose={onClose}>
      <div className="h-screen flex flex-column items-center bg-[#FF4700] text-[#ffffff] px-3">
        <ClubIcon className="mt-10 mb-3" />
        <h2 className="text-4xl font-bold text-center  mb-5 font-custom">
          Welcome to the Exclusive Club
        </h2>
        <div className="flex flex-column px-2">
          {clubDetails.map((items) => (
            <div className="flex mb-4 items-center">
              <HeartIcon className="flex-shrink-0" />
              <p className="ms-2 font-custom tracking-tight">{items}</p>
            </div>
          ))}
        </div>

        <button
          onClick={onClose}
          className="fw-bold border-2 w-[100%] rounded-xl py-3 px-6 mt-5 font-custom"
        >
          Yay! Go to menu
        </button>
      </div>
    </Modal>
  );
}
