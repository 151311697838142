/** @format */

import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import NonVegIcon from "../../../asset/icons/NonVegIcon";
import VegIcon from "../../../asset/icons/VegIcon";
import { addMoreItemOnCartActions } from "../../../store/addMoreItemOnOrder/reducer";
import addMoreItemsOnCartSelector from "../../../store/addMoreItemOnOrder/selectors";
import { cartActions } from "../../../store/cart/reducer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import showMenuSelector from "../../../store/showMenu/selectors";
import "../style.scss";

type Props = {
   item: any;
};

const CONTAINER_WIDTH = window.innerWidth / 1.4;

export default function MostOrderedCard({ item }: Props) {
   const [count, setCount] = useState(0);
   const [showAdded, setshowAdded] = useState(false);
   const { addMoreItemFlow } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);
   function handleAdd() {
      setCount(() => count + 1);
   }

   const { currency } = useAppSelector(showMenuSelector.showMenu);

   const dispatch = useAppDispatch();

   const addToCart = () => {
      setshowAdded(true);
      !addMoreItemFlow ? dispatch(cartActions.addFoodItem(item)) : dispatch(addMoreItemOnCartActions.updateAddMoreItemCart({ item, type: "add" }));
   };

   return (
      <div
         style={{ width: CONTAINER_WIDTH, minWidth: CONTAINER_WIDTH }}
         className="MostOrderedCard-Container">
         <Snackbar
            style={{ marginBottom: "4rem" }}
            anchorOrigin={{
               horizontal: "center",
               vertical: "bottom",
            }}
            open={showAdded}
            autoHideDuration={1000}
            onClose={() => setshowAdded(false)}>
            <Alert
               className="font-custom"
               onClose={() => setshowAdded(false)}
               severity="success"
               variant="filled"
               sx={{ width: "100%" }}>
               {item?.item_name} added to cart
            </Alert>
         </Snackbar>
         <div className="MostOrderedCard-ImageWrapper">
            {item?.image_url === null || item?.image_url === "" ? (
               <>
                  <div className="MostOrderedCard-IconWrapper">{item.type === "veg" ? <VegIcon /> : <NonVegIcon />}</div>
                  <div
                     style={{ width: CONTAINER_WIDTH }}
                     className="notImage font-custom">
                     {item?.item_name?.charAt(0).toUpperCase()}
                  </div>
               </>
            ) : (
               <>
                  <div className="MostOrderedCard-IconWrapper">{item.type === "veg" ? <VegIcon /> : <NonVegIcon />}</div>
                  <img
                     style={{ width: CONTAINER_WIDTH }}
                     src={item?.image_url + "?" + new Date().getMilliseconds()}
                     alt=""
                     className="MostOrderedCard-Image"
                  />
               </>
            )}
         </div>
         <div className="MostOrderedCard-Content rounded-[8px] ">
            <div className="MostOrderedCard-TopWrapper">
               <div className="MostOrderedCard-TilteWrapper">
                  <div className="MostOrderedCard-Title font-custom">{item?.item_name}</div>
               </div>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center", padding: "0 0.5rem" }}>
               <div className="MostOrderedCard-PriceTag font-custom">
                  {currency}
                  {item?.price_after_discount}
               </div>
               <div
                  onClick={addToCart}
                  className="add-to-cart font-custom">
                  Add
               </div>
               {/* <div className='MostOrderedCard-CounterWrapper'>
          <Counter count={count} onAdd={handleAdd} onMinus={handleDecrease} />
        </div> */}
            </div>
         </div>
      </div>
   );
}
