/** @format */

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

const FileUploadComponent = () => {
   const onDrop = useCallback((acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles);
   }, []);

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
         "image/jpeg": [],
         "image/png": [],
         "application/pdf": [],
      },
   });

   return (
      <div className="flex justify-center items-center mt-4">
         <div
            {...getRootProps()}
            className="flex flex-col justify-center h-[13rem] bg-[grey] bg-opacity-[0.07] items-center w-full border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer">
            <input {...getInputProps()} />
            <button
               type="button"
               className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm">
               <CloudUploadIcon style={{ color: "white", marginRight: 8 }} />
               Choose files
            </button>
            <p className="text-gray-500 mt-2">Or drop files here</p>
            {isDragActive && <p className="text-gray-500">Drop the files here ...</p>}
         </div>
      </div>
   );
};

export default FileUploadComponent;
