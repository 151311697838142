/** @format */

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import DragAndDrop from "../../component/DragAndDrop";
import Logo from "../../component/Logo";
import MobilePreview from "../../component/MobilePreview";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const points = ["Only PDF and Images should be uploaded for the conversion", "Upload images in .JPEG or .JPG format only", "File size should not exceed more than 20 MB"];

export default function MenuConversion() {
   const [file, setFile] = useState(null);
   const handleChange = (file) => {
      setFile(file);
   };

   const innerWidth = window.innerWidth;

   return (
      <div>
         <div className="h-[10vh]  border-solid lg:justify-start flex items-center justify-center">
            <Logo />
         </div>
         <div className="h-[90vh] flex lg:flex-row flex-col">
            <div className={`p-${innerWidth >= 1024 ? "4" : "2"} lg:w-[60vw]`}>
               <h3 className="lg:text-[1.5rem] text-[1.2rem] font-bold">Go Digital, Instantly: Transform Your Traditional Menu into a Modern Masterpiece!</h3>
               <p
                  className="lg:text-[1rem] text-[0.8rem] mt-2"
                  style={{ color: "rgba(15, 25, 26, 0.5)" }}>
                  Upload your menu card image, convert it to a digital menu, and share it instantly with your customers.
               </p>{" "}
               <DragAndDrop />
               <ul className="flex flex-col mt-4 lg:mb-0 mb-3 gap-4">
                  {points.map((item) => {
                     return (
                        <li className="flex items-start  lg:items-center gap-2">
                           <div>
                              <CheckCircleIcon sx={{ color: "#2FBF71", fontSize: 18 }} />
                           </div>
                           <div>{item}</div>
                        </li>
                     );
                  })}
               </ul>
            </div>
            <div className="lg:w-[40vw] position-relative flex items-center h-full justify-center w-full">
               <MobilePreview />
               <div className={"position-absolute gap-2 bottom-10 lg:bottom-4 w-full flex flex-col items-center"}>
                  <div className={"flex gap-4"}>
                     <div className={"border-[#4361EE] text-[#4361EE] rounded-[0.5rem] cursor-pointer font-medium border-[1px] border-solid text-center py-2 w-[8rem] bg-[#fff]"}>
                        <BorderColorOutlinedIcon
                           className="mb-[2px] mr-[2px]"
                           style={{ fontSize: "0.9rem" }}
                        />{" "}
                        Edit menu
                     </div>
                     <div className={"text-[#fff] rounded-[0.5rem] cursor-pointer font-medium border-[1px] border-solid text-center py-2 w-[8rem] bg-[#000]"}>
                        <ContentCopyOutlinedIcon
                           className="mb-[2px] mr-[2px]"
                           style={{ fontSize: "0.9rem" }}
                        />{" "}
                        Copy link
                     </div>
                  </div>
                  <div className={"text-[0.8rem]"}>
                     Link will expire in <span className="font-bold">7 days</span>, sign up to continue
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
