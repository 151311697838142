/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonWithArrow from "../../component/Button";
import Footer from "../../component/Footer";
import playstore from "../../asset/icons/playstore.svg";
import BarsIcon from "../../asset/icons/BarsIcon";
import SideBar from "../../component/SideBar";
import logo from "../../asset/icons/logo.svg";
// import Header from '../PreHomePage/Header/Header';

function RefundPolicy() {
   const [click, setClick] = useState(false);
   const navigate = useNavigate();
   return (
      <div className="screenContainer">
         {/* <img src={bg} className="preHomeBgImage" /> */}
         {click && <SideBar setClick={setClick} />}

         <div className="screenMainContainer">
            {/* <Header setClick={setClick} /> */}

            <div className="preHomeContainer">
               <div
                  className="column"
                  style={{ textAlign: "center" }}>
                  <h3>Refund policy</h3>
                  <div className="tandCContainer">
                     <div>
                        1. Customer can place an order through the platform and can pay directly through the platform. On placing the order and if one or more items are not available as per the order
                        placed the proportionate amount shall be refunded back to the customer.
                     </div>
                     <div>2. If the restaurant merchant post placing of order by customer cancels or deletes the order, then the sum paid by customer shall be refunded back.</div>
                     <div>3. The process of refund shall take 5 to 8 business days depending on the mode of payment opted by the customer.</div>
                     <div>4. We do not provide any delivery for the orders placed.</div>
                  </div>
               </div>
               {/* <div>
        <img className='Image' src={vector1} />
      </div> */}
            </div>
         </div>
         <Footer />
      </div>
   );
}

export default RefundPolicy;
