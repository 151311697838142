/** @format */

import { Divider, Slide } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import FoodItemBeforeOrder from "../FoodItemBeforeOrder";
import MostOrderedCard from "../MostOrdered/MostOrderedCard";
import { CloseOutlined } from "@mui/icons-material";

function DetailsModal({ item, visible, onClose, updateToggle, getPeopleAlsoOrdered, mid }) {
	return (
		<Modal
			slotProps={{
				backdrop: "static",
			}}
			open={visible}>
			<Slide
				direction="up"
				in={visible}>
				<div className="position-absolute bottom-0 w-full">
					<div className="flex flex-col gap-3 items-center pb-3 relative bg-[#fff] w-[100%] rounded-tr-[15px] rounded-tl-[15px]">
						<div
							onClick={onClose}
							className="absolute right-3 top-3 bg-[#F5F6F6] rounded-[50px] w-[30px] h-[30px] flex items-center justify-center ">
							<CloseOutlined sx={{ fontSize: "1.4rem" }} />
						</div>{" "}
						<Divider sx={{ height: "1px", backgroundColor: "#E7E8E8", width: "100%" }} />
						<div className="w-[100%] mt-4">
							<FoodItemBeforeOrder
								index={0}
								item={item}
								mid={mid}
								cardType=""
								updateToggle={updateToggle}
								getPeopleAlsoOrdered={getPeopleAlsoOrdered}
							/>
						</div>
						{item?.description?.length > 0 && (
							<RenderContent
								value={item?.description}
								label="Item description"
							/>
						)}
						{item?.ingredients?.length > 0 && (
							<RenderContent
								value={item?.ingredients}
								label="Ingredients"
							/>
						)}
					</div>
				</div>
			</Slide>
		</Modal>
	);
}

const RenderContent = ({ value, label }) => {
	const [expand, setexpand] = useState(false);

	const toggleExpand = () => setexpand(!expand);

	return (
		<>
			<Divider sx={{ height: "1px", backgroundColor: "#E7E8E8", width: "100%" }} />
			<div className="flex w-[100%] flex-col   px-3">
				<div className="text-[#0F191A] text-[20px] font-bold">{label}</div>
				<div className="text-[#878C8C]">{expand ? value : value.substring(0, 100) + "..."} </div>
				<div
					onClick={toggleExpand}
					className="text-[#0F6656]">
					See {expand ? "less" : "more"}
				</div>
			</div>
		</>
	);
};

export default DetailsModal;
