/** @format */

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { rootReducer } from "./rootReducer";
import storage from "redux-persist/lib/storage";
import { getAllOrdersApi } from "../api/order/getAllOrdersRTK";

// bump this number whenever there is a change in store structure.
const CURRENT_STORE_VERSION = 1;

const persistConfig = {
   key: "root",
   storage,
   whitelist: ["cartItems", "user", "menuItems", "filter", "qr", "order", "updateOrderCart", "hotelCartItems", "surpriseMe", "showMenu", "addMoreItemOnCart"],
   // blacklist: [getAllOrdersApi.reducerPath],
   version: CURRENT_STORE_VERSION,
};

const persistReducerConfig = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
   reducer: persistReducerConfig,
   middleware: getDefaultMiddleware({
      serializableCheck: true,
   }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
