/** @format */

import ToggleSwitch from "../ToggleSwitch";
import "./style.scss";

function ToggleChipWithSwitch({ selected, disabled, onClickHandler, label }: any) {
	return (
		<div className="toggle-chips-with-switch">
			<div className="toggle-label-text"> {label}</div>
			<ToggleSwitch
				checked={selected}
				disabled={disabled}
				onClick={onClickHandler}
				fillcolor="#2EBE72"
			/>
		</div>
	);
}

export default ToggleChipWithSwitch;
