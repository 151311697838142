/** @format */

import React from "react";
import { alpha, styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

interface ToggleSwitchProps extends SwitchProps {
   fillcolor: string;
}

const ToggleSwitch = styled(Switch)<ToggleSwitchProps>(({ theme, fillcolor }) => ({
   "& .MuiSwitch-switchBase": {
      color: "#ffffff",
      "&:hover": {
         backgroundColor: alpha(fillcolor, theme.palette.action.hoverOpacity),
      },
   },
   "& .MuiSwitch-switchBase.Mui-checked": {
      color: fillcolor,
      "&:hover": {
         backgroundColor: alpha(fillcolor, theme.palette.action.hoverOpacity),
      },
   },
   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: fillcolor,
   },
   "& .MuiSwitch-thumb": {
      width: 18, // Reduced width
      height: 18, // Reduced height
   },

   // Reducing the size of the track
   "& .MuiSwitch-track": {
      borderRadius: 12 / 2, // Adjusted to match thumb's reduced size
      width: 34, // Reduced width
      height: 14, // Reduced height
   },
}));

export default ToggleSwitch;
