/** @format */

import { createSlice } from "@reduxjs/toolkit";

export interface CARTProps {
   CART: Array<FavouriteState>;
}

export interface FavouriteState {
   discountPrice: any;
   cart: Array<any>;
   itemCount: number;
   totalValue: number;
   merchant_id: string;
}

const initialState: CARTProps = {
   CART: [
      {
         itemCount: 0,
         totalValue: 0.0,
         discountPrice: 0.0,
         merchant_id: "",
         cart: [],
      },
   ],
};

export const cartSlice = createSlice({
   name: "cartItems",
   initialState: initialState,
   reducers: {
      addFoodItem: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            console.log("cartInfo 00000", cartInfo);
            cartInfo.totalValue += action.payload.price_after_discount;
            const discount = parseInt(action.payload.discount);
            const calcdiscountPrice = (discount / 100) * action.payload.price_after_discount;
            cartInfo.discountPrice += calcdiscountPrice;
            console.log("cartInfo.totalValue", cartInfo, action.payload.price_after_discount);

            const temp = cartInfo.cart.filter((item: any) => item.item_id === action.payload.item_id);
            if (temp.length === 0) {
               cartInfo.cart.push({ ...action.payload, count: 1, addons: [], customizations: [] });
            } else {
               const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);
               cartInfo.cart[index].count += 1;
            }
            cartInfo.itemCount = cartInfo.cart.length;
            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         } else {
            console.log("New merchant ID");

            let cartInfo: any = {
               itemCount: 0,
               totalValue: 0,
               discountPrice: 0,
               merchant_id: "",
               cart: [],
            };
            cartInfo.merchant_id = action.payload.merchant_id;
            cartInfo.totalValue += action.payload.price_after_discount;
            const discount = parseInt(action.payload.discount);
            const calcdiscountPrice = (discount / 100) * action.payload.price_after_discount;
            cartInfo.discountPrice += calcdiscountPrice;

            const temp = cartInfo.cart.filter((item: any) => item.item_id === action.payload.item_id);

            if (temp.length === 0) {
               cartInfo.cart.push({ ...action.payload, count: 1, addons: [], customizations: action.payload.customizations });
            } else {
               const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);
               cartInfo.cart[index].count += 1;
            }
            cartInfo.itemCount = cartInfo.cart.length;
            console.log(cartInfo, "cartInfo NEWW");
            state.CART.push(cartInfo);
         }
      },
      removeFoodItem: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };

            const filteredItem = cartInfo.cart.filter((item: any) => item.item_id === action.payload.item_id);

            if (filteredItem.length > 0) {
               const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);

               if (cartInfo.cart[index].count > 1) {
                  cartInfo.cart[index].count -= 1;
               } else if (cartInfo.cart[index].count === 1) {
                  let addonsAmount = 0;
                  cartInfo.cart[index].addons.map((item: any) => {
                     addonsAmount += parseInt(item.count) * parseInt(item.price);
                  });
                  cartInfo.cart.splice(index, 1);
                  cartInfo.totalValue -= addonsAmount;
               }
               const discount = parseInt(action.payload.discount);
               const calcdiscountPrice = (discount / 100) * action.payload.price_after_discount;
               cartInfo.itemCount = cartInfo.cart.length;
               cartInfo.totalValue -= action.payload.price_after_discount;
               cartInfo.discountPrice -= calcdiscountPrice;
               state.CART.splice(merchant_index, 1);
               state.CART.splice(merchant_index, 0, cartInfo);
            }
         }
      },
      updateOutOfStock: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         console.log("updateOutOfStock => ", action);

         const UpdatedOutOfStockItems = action.payload.unavailable_items;

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            let reducerCart = cartInfo.cart;
            for (const key in reducerCart) {
               for (const index in UpdatedOutOfStockItems) {
                  if (reducerCart[key].item_id === UpdatedOutOfStockItems[index].item_id) {
                     reducerCart[key].out_of_stock = UpdatedOutOfStockItems[index].out_of_stock;
                     reducerCart[key].available_quantity = UpdatedOutOfStockItems[index].available_quantity;
                     reducerCart[key].count = UpdatedOutOfStockItems[index].available_quantity;
                  }
               }
            }
            cartInfo = { ...cartInfo, cart: reducerCart };
            console.log("updateOutOfStock", cartInfo);

            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
      clearCart: (state: any, action: any) => {
         const getIndex = state.CART.findIndex((item: any) => item.merchant_id === action.payload);
         state.CART.splice(getIndex, 1);
         // state.cart = [];
         // state.itemCount = 0;
         // state.totalValue = 0;
         // state.merchant_id = '';
      },
      addAddon: (state: any, action: any) => {
         // action = item_id, add_on_id
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);

            const addonIndex = cartInfo.cart[index]?.addons?.findIndex((item: any) => item.add_on_id === action.payload.add_on_id);

            if (addonIndex === -1) {
               cartInfo.cart[index]?.addons?.push({
                  ...action.payload,
                  count: 1,
               });
            } else {
               cartInfo.cart[index].addons[addonIndex].count += 1;
            }
            cartInfo.totalValue += parseInt(action.payload.price);

            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
      removeAddon: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);

            const addonIndex = cartInfo.cart[index].addons.findIndex((item: any) => item.add_on_id === action.payload.add_on_id);

            if (addonIndex !== -1) {
               if (cartInfo.cart[index].addons[addonIndex].count > 1) {
                  cartInfo.cart[index].addons[addonIndex].count -= 1;
               } else if (cartInfo.cart[index].addons[addonIndex].count === 1) {
                  cartInfo.cart[index].addons.splice(addonIndex, 1);
               }
            }
            cartInfo.totalValue -= parseInt(action.payload.price);

            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
      clearAddonsAndCustomizations: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);
            cartInfo.cart[index].addons = [];
            cartInfo.cart[index].customizations = [];
            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
      otherCustomization: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);

            if (cartInfo.cart[index]) {
               cartInfo.cart[index].other_customization = action.payload.other_customization;
            }

            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
      toggleCustomization: (state: any, action: any) => {
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);
         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);
            const customizationIndex = cartInfo.cart[index].customizations.findIndex((item: any) => item.customisation_id === action.payload.customisation_id);
            if (customizationIndex !== -1) {
               if (action.payload.selection_type === "checkbox") {
                  if (cartInfo.cart[index].customizations[customizationIndex].selected_options?.includes(action.payload.option)) {
                     cartInfo.cart[index].customizations[customizationIndex].selected_options?.splice(
                        cartInfo.cart[index].customizations[customizationIndex].selected_options?.indexOf(action.payload.option),
                        1,
                     );
                  } else {
                     cartInfo.cart[index].customizations[customizationIndex].selected_options?.push(action.payload.option);
                  }
               } else {
                  cartInfo.cart[index].customizations[customizationIndex] = {
                     selected_options: [action.payload.option],
                     ...action.payload,
                  };
               }
            } else {
               cartInfo.cart[index].customizations.push({
                  selected_options: [action.payload.option],
                  ...action.payload,
               });
            }

            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
      clearCustomization: (state: any, action: any) => {
         console.log("clearCustomization => ", action);
         const merchant_index = state.CART.findIndex((item: any) => item.merchant_id === action.payload.merchant_id);

         if (merchant_index !== -1) {
            let cartInfo = { ...state.CART[merchant_index] };
            const index = cartInfo.cart.map((item: any) => item.item_id).indexOf(action.payload.item_id);

            const customizationIndex = cartInfo?.cart[index]?.customizations.findIndex((item: any) => item.customisation_id === action.payload.customisation_id);

            if (customizationIndex !== -1) {
               if (cartInfo.cart[index] && cartInfo.cart[index].customizations) {
                  if (cartInfo.cart[index].customizations[customizationIndex]) {
                     cartInfo.cart[index].customizations[customizationIndex].selected_options = [];
                  }
               }
            }

            state.CART.splice(merchant_index, 1);
            state.CART.splice(merchant_index, 0, cartInfo);
         }
      },
   },
});

export const cartActions = cartSlice.actions;
