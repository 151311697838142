import { request } from '../axios';
import { SuccessResponse, ErrorResponse, ResponsePayload } from '../index.type';
import { PayloadProps, Props } from './models/getAllOrders.type';
import { ErrorResponseHandler } from '../index';

const getAllOrders = async (
  mid: string,
  accessToken: string | null
): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  try {
    const response = await request<ResponsePayload<PayloadProps>>({
      api: '/customer/order/all/' + mid,
      method: 'GET',
      accessToken,
    });

    const result = response.data;
    console.log(response, ' get orders response => ');

    if (response.status !== 200) {
      console.log('Get all orders API ', response);
      throw new Error(result.error.message);
    }
    return {
      statusCode: 200,
      message: 'Success',
      payload: result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getAllOrders;
