import React from 'react';

function OrderConfirmedIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginLeft: '4px' }}
    >
      <path
        d='M10.1599 1.33325H5.83986C3.3332 1.33325 3.13986 3.58658 4.4932 4.81325L11.5065 11.1866C12.8599 12.4133 12.6665 14.6666 10.1599 14.6666H5.83986C3.3332 14.6666 3.13986 12.4133 4.4932 11.1866L11.5065 4.81325C12.8599 3.58658 12.6665 1.33325 10.1599 1.33325Z'
        stroke='#4361EE'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default OrderConfirmedIcon;
