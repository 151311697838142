import React from "react";
import NoItemsFoundIcon from "../../asset/icons/NoItemsFoundIcon";
import './style.scss';

function NoItems() {
  return (
    <div className='no-items-container'>
      <div className='no-items-wrapper'>
        <NoItemsFoundIcon />
        <div className='no-items'>No items found</div>
      </div>
    </div>
  );
}

export default NoItems;
