/** @format */

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ForwardArrowIcon from "../../asset/icons/ForwardArrow";
import LogoutIcon from "../../asset/icons/LogoutIcon";
import FacebookIcon from "../../asset/icons/FacebookIcon";
import InstagramIcon from "../../asset/icons/InstragramIcon";
import YoutubeIcon from "../../asset/icons/YoutubeIcon";
import GoogleIcon from "../../asset/icons/GoogleIcon";
import TwitterIcon from "../../asset/icons/TwitterIcon";
import { useNavigate } from "react-router-dom";
import { ImageWrapper } from "../OrderStatusCard/styles";
import BecomeOurMember from "../BecomeOurMember";
import userSelectors from "../../store/user/selectors";
import { useAppSelector } from "../../store/hooks";
import { useParams } from "react-router-dom";
import { getLoyaltyPointsAPI } from "../../api/loyalty/loyaltyAPIs";
import { Avatar } from "@mui/material";
import DividerWithText from "../DividerWithText";

export default function CustomDrawer({ isLoggedIn, merchantDetails, open, toggleDrawer, data, isLoyaltyEnabled }: any) {
   const navigate = useNavigate();
   const { mid, qid } = useParams();
   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);

   const [isSubscribedToLoyalty, setisSubscribedToLoyalty] = useState(false);

   const SOCIAL_LINKS = [
      {
         icon: <InstagramIcon />,
         href: merchantDetails?.instagram_url,
      },
      {
         icon: <TwitterIcon />,
         href: merchantDetails?.twitter_url,
      },
      {
         icon: <FacebookIcon />,
         href: merchantDetails?.facebook_page_url,
      },
      {
         icon: <GoogleIcon />,
         href: merchantDetails?.google_reviews_link,
      },
      {
         icon: <YoutubeIcon />,
         href: merchantDetails?.youtube_url,
      },
   ];
   console.log(merchantDetails?.instagram_url, "insta url");

   const hideLoyaltyProfileTile = (item: any) => {
      if (item?.name === "Exclusive Club") {
         if (accessToken === null) {
            return true;
         } else if (merchantDetails?.loyalty_enabled) {
            if (isSubscribedToLoyalty) {
               return false;
            } else {
               return true;
            }
         } else {
            return true;
         }
      }
   };

   const list = () => (
      <div className="h-[100vh] flex flex-col justify-between">
         <Box
            sx={{
               width: "85vw",
               overflow: "scroll",
               borderRadius: "10px",
            }}
            role="presentation"
            // onClick={toggleDrawer}
            onKeyDown={toggleDrawer}>
            <Box
               className="flex flex-col items-center justify-evenly gap-2"
               width={"100%"}
               height={"20vh"}>
               <div className="flex items-center font-custom flex-col justify-center gap-2">
                  <span>
                     {merchantDetails?.image_url && merchantDetails?.image_url !== null ? (
                        <Avatar
                           sx={{
                              width: "75px",
                              height: "75px",
                           }}
                           src={merchantDetails?.image_url}
                        />
                     ) : (
                        <Avatar
                           sx={{
                              width: "60px",
                              height: "60px",
                              fontSize: "28px",
                              fontFamily: "TWKEverett,san-serif",
                           }}>
                           {merchantDetails?.restaurant_name[0]?.toUpperCase()}
                        </Avatar>
                     )}
                  </span>
                  <span className="font-bold font-custom text-[20px]">{merchantDetails?.restaurant_name}</span>
               </div>
               {accessToken !== null && (
                  <span className="font-custom flex justify-center gap-[1.8rem] w-[70%]">
                     {SOCIAL_LINKS?.map((item: any) => {
                        if (item?.href !== null) {
                           return (
                              <div
                                 onClick={() => {
                                    if (item?.href !== null) {
                                       window.open(item?.href, "_blank");
                                    }
                                 }}
                                 className="font-custom"
                                 style={{
                                    display: item?.href === "" ? "none" : "flex",
                                    width: "20px",
                                    height: "20px",
                                 }}>
                                 {item?.icon}
                              </div>
                           );
                        }
                     })}
                  </span>
               )}
            </Box>
            <div className="w-[100%] flex mt-2 items-center justify-center">
               <Divider className="h-[1px] w-[80%] bg-[#E7E8E8]" />
            </div>
            <List>
               {data?.map((item: any) => {
                  if (hideLoyaltyProfileTile(item)) return null;
                  return (
                     <ListItem
                        disabled={item?.name === "Loyalty profile" && !isLoyaltyEnabled}
                        onClick={(e) => {
                           e.stopPropagation();
                           if (item.name === "About us") {
                              window.open("https://www.thebeautifulmenu.com/about-us", "_blank");
                           } else if (item?.handleClick) {
                              item?.handleClick();
                           } else {
                              navigate(item?.navigate);
                           }
                        }}>
                        <ListItemButton
                           disabled={item?.name === "Loyalty profile" && !isLoyaltyEnabled}
                           href={item?.href}>
                           <ListItemIcon>{item.icon}</ListItemIcon>
                           <ListItemText>
                              <span className="font-custom font-bold text-[18px] text-[#0F191A]">{item?.name}</span>
                           </ListItemText>
                           {item?.showArrow && <ForwardArrowIcon />}
                        </ListItemButton>
                     </ListItem>
                  );
               })}
            </List>
            <Divider />
         </Box>
         <div className="flex flex-col gap-1">
            {!isSubscribedToLoyalty && merchantDetails?.loyalty_enabled && <BecomeOurMember />}
            {isLoggedIn && (
               <ListItem
                  sx={{
                     bottom: "10px",
                  }}
                  onClick={async (e) => {
                     e.stopPropagation();
                     window.location.reload();
                     await localStorage.clear();
                  }}>
                  <ListItemButton>
                     <ListItemIcon>
                        <span className="drawer-menu-icon-wrapper ">
                           <LogoutIcon />
                        </span>
                     </ListItemIcon>
                     <span className="font-[Everett] font-bold text-[18px] text-[#0F191A]">Sign out</span>
                  </ListItemButton>
               </ListItem>
            )}
         </div>
      </div>
   );

   const fetchLoyaltyPoints = async () => {
      try {
         const response = await getLoyaltyPointsAPI(accessToken, mid);
         if (response?.status === 200) {
            setisSubscribedToLoyalty(true);
         }
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      fetchLoyaltyPoints();
   }, []);

   return (
      <div>
         <React.Fragment>
            <Drawer
               sx={{
                  borderTopRightRadius: "10px",
               }}
               PaperProps={{
                  sx: {
                     borderTopRightRadius: "10px",
                     borderBottomRightRadius: "10px",
                  },
               }}
               anchor={"left"}
               open={open}
               onClose={toggleDrawer}>
               {list()}
            </Drawer>
         </React.Fragment>
      </div>
   );
}
