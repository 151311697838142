import React from "react";
import Container from "./styles/Container";

const BottomModal = ({
  children,
  height,
  isModalVisible,
}: BottomModalProps) => {
  return (
    <Container height={height} isModalVisible={isModalVisible}>
      {children}
    </Container>
  );
};

interface BottomModalProps {
  children: React.ReactNode;
  height?: string;
  isModalVisible?: boolean;
}

export default BottomModal;
