import React from 'react';
import { Modal } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';
import { Text } from '../Text';
import './style.scss';

function Loader({ Load }: any) {
  return (
    <Modal open={Load}>
      <div className='loader-container1'>
        <div className='loader-container2'>
          <TailSpin
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='tail-spin-loading'
            radius='1'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
          />
          <div className='loader-please-wait-text'>Please Wait</div>
        </div>
      </div>
    </Modal>
  );
}

export default Loader;
