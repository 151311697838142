import { request } from '../axios';
import { SuccessResponse, ErrorResponse, ResponsePayload } from '../index.type';
import { PayloadProps, Props } from './getItemRecommender.type';
import { ErrorResponseHandler } from '../index';

const getItemRecommender = async ({
  merchantId,
  itemId,
  cookieId,
  activity,
}: Props): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  try {
    const response = await request<ResponsePayload<PayloadProps>>({
      api: `/customer/item_recommender/${merchantId}`,
      method: 'POST',
      data: {
        item_id: itemId,
      },
    });

    const result = response.data;

    if (response.status !== 200) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: 'Success',
      payload: result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getItemRecommender;
