/** @format */

import React from "react";
import MenuImage from "../../../asset/images/menu.png";
import DeviceMenuImage from "../../../asset/images/deviceMenu.png";
import FloatingArrow from "../../../asset/icons/FloatingArrow";
import { IS_SMALLER_DEVICE } from "../../../utils/styleDefinitions";
import EastIcon from "@mui/icons-material/East";

export default function ConvertToMenuCard() {
   return (
      <div
         data-aos="fade-up"
         style={{ marginTop: IS_SMALLER_DEVICE ? "5rem" : "20rem", marginBottom: IS_SMALLER_DEVICE ? "5rem" : "20rem" }}
         className="flex items-center justify-center lg:h-[70vh] h-[50vh] my-[20rem]">
         <div className="bg-[#2D6B4D] w-[90%] lg:w-[85%] h-[100%] justify-around flex lg:flex-row flex-col lg:rounded-[2.5rem] rounded-[1rem]">
            <div
               style={{ padding: IS_SMALLER_DEVICE ? "1rem" : "1rem", marginTop: IS_SMALLER_DEVICE ? "2rem" : 0 }}
               className="flex items-center lg:px-[5rem] lg:w-[50%] lg:h-full  gap-4">
               <div
                  data-aos="fade-in"
                  className="lg:h-[65%] lg:w-[35%] lg:ml-4">
                  <img
                     src={MenuImage}
                     className="w-full h-full"
                  />
               </div>
               <div data-aos="fade-in">
                  <FloatingArrow />
               </div>
               <div
                  data-aos="fade-up"
                  className="lg:h-[80%] lg:w-[35%]">
                  <img
                     src={DeviceMenuImage}
                     className="w-full h-full"
                  />
               </div>
            </div>
            <div
               data-aos="fade-right"
               className="flex items-center  lg:h-full h-[50%]  lg:w-[60%] justify-center w-full">
               <div className="lg:w-[90%] flex flex-col lg:items-start items-center gap-4">
                  <h3
                     style={{ textAlign: IS_SMALLER_DEVICE ? "center" : "start" }}
                     className="text-[#fff] text-[1.2rem] lg:text-[2.5rem]  font-bold">
                     Convert your Traditional menu into Digital menu in “One click”!
                  </h3>
                  <button className="bg-[#fff] lg:w-[10rem] w-[80%] text-[#2D6B4D] border-[1px] border-solid border-[#2D6B4D] hover:border-[#fff] px-3 py-2 rounded-[0.3rem] hover:bg-[#2D6B4D] hover:text-[#fff] transition duration-300 ease-in-out">
                     Convert now <EastIcon style={{ fontSize: "1rem" }} />
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
