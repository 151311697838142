import React from 'react';

function DiningInIcon() {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_636_435)'>
        <path
          d='M41.3633 9.48267H38.1148C37.767 9.48267 37.4783 9.77142 37.4783 10.1192V22.0564H32.6811C29.3867 22.0564 26.7026 24.7339 26.7026 28.0349V34.5908C26.7026 34.9386 26.9914 35.2274 27.3392 35.2274H30.1545C30.5089 35.2274 30.7911 34.9386 30.7911 34.5908V28.6714C30.7911 27.4836 31.7558 26.5189 32.9436 26.5189H37.4783V34.2102C37.4783 34.558 37.767 34.8467 38.1148 34.8467H41.3633C41.7111 34.8467 41.9998 34.558 41.9998 34.2102V10.1192C41.9998 9.77142 41.7111 9.48267 41.3633 9.48267ZM40.7267 33.5802H38.7514V25.902C38.7514 25.8955 38.758 25.8955 38.758 25.8889C38.758 25.7117 38.6792 25.5542 38.5676 25.4361C38.4466 25.3178 38.284 25.2519 38.1148 25.2524H32.9436C32.0356 25.2541 31.1653 25.6156 30.5232 26.2576C29.8812 26.8997 29.5197 27.77 29.5179 28.678V33.9608H27.9758V28.0414C27.9758 25.4492 30.0889 23.3361 32.6811 23.3361H38.1214C38.4692 23.3361 38.758 23.0539 38.758 22.6995L38.7514 22.693V10.7558H40.7267V33.5802Z'
          fill='black'
        />
        <path
          d='M40.7205 10.7756V33.5606H38.7518V25.8891C38.7518 25.8825 38.7583 25.8825 38.7583 25.8759C38.7583 25.6988 38.6796 25.5413 38.568 25.4231C38.447 25.3049 38.2844 25.2389 38.1152 25.2394H32.9439C31.0605 25.2394 29.5249 26.7684 29.5249 28.6584V33.9347H27.9893V28.0284C27.9893 25.4428 30.0958 23.3297 32.688 23.3297H38.1218C38.4696 23.3297 38.7583 23.0475 38.7583 22.6931L38.7518 22.6866V10.7756H40.7205Z'
          fill='#FBC551'
        />
        <path
          d='M9.31875 22.0564H4.515V10.1192C4.515 9.77142 4.22625 9.48267 3.87844 9.48267H0.636563C0.28875 9.48267 0 9.77142 0 10.1192V34.2102C0 34.558 0.28875 34.8467 0.636563 34.8467H3.885C4.23281 34.8467 4.52156 34.558 4.52156 34.2102V26.5189H9.05625C10.2375 26.5189 11.2087 27.477 11.2087 28.6714V34.5908C11.2087 34.9386 11.4909 35.2274 11.8453 35.2274H14.6606C15.0084 35.2274 15.2972 34.9386 15.2972 34.5908V28.0349C15.2906 24.7405 12.6131 22.0564 9.31875 22.0564ZM14.0175 33.9542H12.4753V28.6714C12.4736 27.7634 12.1121 26.8931 11.4701 26.251C10.828 25.609 9.95769 25.2475 9.04969 25.2458H3.885C3.70781 25.2458 3.55031 25.318 3.43219 25.4295C3.32063 25.5411 3.24187 25.7052 3.24187 25.8824L3.24844 25.8889V33.5736H1.27313V10.7558H3.24844V22.6864C3.24844 22.693 3.24187 22.693 3.24187 22.693C3.24187 23.0474 3.53062 23.3295 3.87844 23.3295H9.31875C11.9109 23.3295 14.0241 25.4427 14.0241 28.0349V33.9542H14.0175Z'
          fill='black'
        />
        <path
          d='M14.0173 28.0349V33.9543H12.4751V28.6715C12.4734 27.7635 12.1119 26.8932 11.4699 26.2511C10.8278 25.6091 9.95751 25.2476 9.04951 25.2459H3.88482C3.70764 25.2459 3.55014 25.318 3.43201 25.4296C3.32045 25.5412 3.2417 25.7052 3.2417 25.8824L3.24826 25.889V33.5737H1.27295V10.7559H3.24826V22.6865C3.24826 22.693 3.2417 22.693 3.2417 22.693C3.2417 23.0474 3.53045 23.3296 3.87826 23.3296H9.31857C11.9042 23.3296 14.0173 25.4427 14.0173 28.0349Z'
          fill='#FBC551'
        />
        <path
          d='M32.563 14.6869H29.728V12.0356C30.3568 11.8922 30.9181 11.5393 31.3199 11.0349C31.7218 10.5305 31.9403 9.90457 31.9396 9.25968V7.47468C31.9396 7.1203 31.6574 6.83812 31.303 6.83812H26.8668C26.698 6.83812 26.5361 6.90518 26.4167 7.02456C26.2973 7.14394 26.2302 7.30585 26.2302 7.47468V9.25968C26.2302 10.6181 27.1752 11.7403 28.4484 12.0356V14.6869H13.5449V11.9831C14.1736 11.8373 14.7344 11.4828 15.136 10.9776C15.5376 10.4723 15.7563 9.84602 15.7565 9.20062V7.41562C15.7565 7.0678 15.4677 6.77905 15.1199 6.77905H10.6837C10.3359 6.77905 10.0471 7.0678 10.0471 7.41562V9.20062C10.0471 10.5591 10.9921 11.6878 12.2652 11.9831V14.6869H9.42367C9.07586 14.6869 8.78711 14.9756 8.78711 15.3234V18.4537C8.78711 18.8016 9.07586 19.0903 9.42367 19.0903H18.0927L18.2109 19.2084C18.8868 23.4872 19.2937 31.0144 16.6752 33.0816C16.4587 33.2522 16.3734 33.5344 16.4652 33.7903C16.5571 34.0528 16.7934 34.2234 17.069 34.2234H24.4387C24.7143 34.2234 24.9571 34.0528 25.0424 33.7903C25.1343 33.5344 25.049 33.2522 24.8324 33.0816C22.4699 31.2178 22.5027 24.675 23.2574 19.5431L23.7168 19.0837H32.563C32.9174 19.0837 33.1996 18.795 33.1996 18.4472V15.3169C33.1996 14.9691 32.9174 14.6869 32.563 14.6869ZM27.5099 9.25312V8.09812H30.673V9.25312C30.673 9.67083 30.5071 10.0714 30.2117 10.3668C29.9164 10.6622 29.5158 10.8281 29.098 10.8281H29.0849C28.2187 10.8347 27.5099 10.1259 27.5099 9.25312ZM11.3202 9.20062V8.04562H14.4834V9.20062C14.4834 10.08 13.7746 10.7822 12.9084 10.7822C12.0355 10.7822 11.3202 10.08 11.3202 9.20062ZM10.0602 17.8172V15.96H14.9624L16.8196 17.8172H10.0602ZM23.0474 32.9503H18.4668C20.429 29.8856 20.0746 23.94 19.7005 20.6981L20.4487 21.4462C20.5734 21.5709 20.7374 21.63 20.9015 21.63C21.059 21.63 21.223 21.5709 21.3477 21.4462L21.7809 21.0131C21.4199 24.36 21.164 29.9972 23.0474 32.9503ZM23.0015 18.0009L20.9015 20.1009L16.7605 15.96H25.0424L23.0015 18.0009ZM31.9265 17.8172H24.9899L26.8405 15.96H31.9265V17.8172Z'
          fill='black'
        />
        <path
          d='M30.043 8.64283V9.33846C30.043 9.86346 29.6164 10.29 29.0914 10.29H29.0783C28.5533 10.29 28.1268 9.86346 28.1268 9.33846V8.64283H30.043ZM13.8599 8.59033V9.28596C13.8599 9.81752 13.4333 10.2441 12.9083 10.2441C12.3833 10.2441 11.9502 9.81752 11.9502 9.28596V8.59033H13.8599Z'
          fill='#FBC551'
        />
        <path
          d='M19.4841 31.5789C20.5735 29.8726 20.3766 26.5717 20.1731 24.7736L20.5931 25.1936C20.6653 25.2658 20.7506 25.2986 20.8425 25.2986C20.9278 25.2986 21.0197 25.2658 21.0919 25.1936L21.3347 24.9508C21.1313 26.808 20.9935 29.9383 22.0369 31.5789H19.4841ZM30.6863 16.2883V17.4826H26.2303L27.4181 16.2883H30.6863ZM23.7628 16.5967L22.3519 18.0076H22.3453L20.895 19.458L18.0272 16.5901H23.7628V16.5967ZM15.6647 17.4958H11.2153V16.2751H14.4441L15.6647 17.4958Z'
          fill='#71BFEA'
        />
      </g>
      <defs>
        <clipPath id='clip0_636_435'>
          <rect width='42' height='42' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DiningInIcon;
