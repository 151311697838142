/** @format */

import styled from "@emotion/styled";

const Container = styled.div`
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   margin: auto;
   width: 90%;
`;

const ImageContainer = styled.div`
   position: relative;

   border-top-left-radius: 12px;
   border-top-right-radius: 12px;

   border-bottom-right-radius: 0;
   border-bottom-right-radius: 0;

   background-color: rgba(67, 97, 238, 0.5);
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;

   margin-top: 1rem;

   padding: 0 1rem;
`;

const SubContainer = styled.div`
   background-color: white;
   border-radius: 12px;
`;

const ButtonContainer = styled.div`
   width: 80%;
   margin-bottom: 1.5rem;
   padding-bottom: 1rem;

   margin: 0 auto;
`;

const InputContainer = styled.input`
   width: 90%;
   border: 1px solid #0f6656;
   margin: 0 auto 2rem auto;
   padding: 1rem;
   border-radius: 8px;
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.4rem;
   line-height: 1rem;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
   background: #ffffff;
   &::placeholder {
      font-family: DM-Sans;
      font-size: 1.4rem;
      letter-spacing: -0.3px;
      font-weigt: 500;
      line-height: 2rem;
      color: rgba(15, 25, 26, 0.5);
   }
`;

const Form = styled.form``;

export { Container, ButtonContainer, ImageContainer, TextContainer, SubContainer, InputContainer, Form };
