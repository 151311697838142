/** @format */
/** @format */

import feature1 from "../../../asset/feature1.png";
import HotelTables from "../../../asset/images/landinpage/HotelTables.jpg";
import feature3 from "../../../asset/feature3.png";
import BillingImage from "../../../asset/images/landinpage/BillingImage.jpg";
import PeopleInTable from "../../../asset/images/landinpage/PeopleInTable.jpg";
import CraftedMeal from "../../../asset/images/landinpage/CraftedMeal.jpg";
import RestaurantTables from "../../../asset/images/landinpage/RestaurantTables.jpg";
import Culinary from "../../../asset/images/landinpage/Culinary.jpg";
import Loyalty from "../../../asset/images/landinpage/Loyalty.jpg";
import Content from "../Content";
import Description from "../Description";
import "./index.scss";

export default function Features() {
   return (
      <div className="mt-[3rem]">
         <Content
            title={"Features"}
            reverse={true}
            content={
               <Description
                  title={
                     <span>
                        Highlight Your <span className="test">Signature Dishes</span>
                     </span>
                  }
                  description={"Easily showcase your hourly and daily specials, inviting customers to experience the novelty and freshness of your kitchen's creations"}
               />
            }
            image={Culinary}
         />

         <Content
            title={""}
            reverse={false}
            content={
               <Description
                  title={
                     <span>
                        <span className="test">Increase Your Revenue</span> With Enticing Offers
                     </span>
                  }
                  description={"Implement irresistible offers to attract more customers and craft deals that are hard to ignore, boosting both customer satisfaction and your bottom line"}
               />
            }
            image={PeopleInTable}
         />
         <Content
            reverse={true}
            content={
               <Description
                  title={
                     <span>
                        Reward Your Customers Through <span className="test">Loyalty </span> <span className="test"> Programs</span>
                     </span>
                  }
                  description={"Integrate our sophisticated loyalty management system to transform occasional visitors into loyal patrons, increasing repeat business"}
               />
            }
            image={Loyalty}
         />
         <Content
            reverse={false}
            content={
               <Description
                  title={
                     <span>
                        Unlock Personalized Suggestions to <span className="test">Boost Cart Value</span>
                     </span>
                  }
                  description={"Leverage your menu to subtly suggest items, providing a customized and enhanced customer experience that goes beyond the ordinary"}
               />
            }
            image={feature3}
         />
         <Content
            reverse={true}
            content={
               <Description
                  title={
                     <span>
                        Fuel <span className="test">Insightful Analytics </span> For Strategic Growth
                     </span>
                  }
                  description={"Gain a deeper understanding of your business operations and customer preferences, enabling data-driven decisions for sustained growth"}
               />
            }
            image={RestaurantTables}
         />
      </div>
   );
}
