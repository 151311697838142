/** @format */

import React from "react";
import ContentTitle from "../ContentTitle";
import PricingHardPreHome from "../../../component/PricingCardPreHome";
import { IS_SMALLER_DEVICE } from "../../../utils/styleDefinitions";

export default function Pricing() {
   return (
      <div
         id="pricing"
         className="flex flex-col my-4 items-center w-full py-2 justify-center">
         <ContentTitle title={"Our pricing"} />
         <div
            style={{ marginTop: IS_SMALLER_DEVICE ? "2rem" : "9rem" }}
            className="flex lg:flex-row flex-col gap-4">
            <PricingHardPreHome
               title={"Monthly plan"}
               price={49}
               duration={"per month"}
               saved={""}
               recommended={false}
            />
            <PricingHardPreHome
               title={"Yearly plan"}
               price={499}
               duration={"per year"}
               saved={"29"}
               recommended={true}
            />
         </div>
      </div>
   );
}
