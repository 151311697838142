import React from "react";
import PendingAnimation from "../../asset/svg/OrderSummary/Gifs/PendingAnimation.gif";
import PreparingAnimation from "../../asset/svg/OrderSummary/Gifs/Preparing.gif";
import ServedAnimation from "../../asset/svg/OrderSummary/Gifs/ServingAnimation.gif";
import { ReactComponent as OrderCancelled } from "../../asset/svg/OrderSummary/OrderCancelled.svg";
import { ReactComponent as OrderCompleted } from "../../asset/svg/OrderSummary/OrderCompleted.svg";

export default function OrderStatusIndicator({
  completeStatus,
  indicator,
  type,
}) {
  console.log(type, "type from orderindicator");
  return (
    <div className="p-2 px-3">
      <div className="mb-3">
        {type?.suborder_status === "new_order" && (
          <span>
            <img src={PendingAnimation} alt="pendingGif" />
          </span>
        )}

        {type?.suborder_status === "order_confirmed" && (
          <span>
            <img src={PreparingAnimation} alt="preparingGif" />
          </span>
        )}
        {type?.suborder_status === "start_preparation" && (
          <span>
            <img src={PreparingAnimation} alt="preparingGif" />
          </span>
        )}
        {completeStatus?.order_status === "order_completed"
          ? ""
          : type?.suborder_status === "ready_to_serve" && (
              <img src={ServedAnimation} alt="ServingGif" />
            )}

        {type?.suborder_status === "cancelled" && <OrderCancelled />}
        {completeStatus?.order_status === "order_completed" && (
          <OrderCompleted />
        )}
      </div>
      <p className="text-2xl mb-1 font-custom">
        {type?.suborder_status === "new_order" && (
          <span>Confirmation pending...</span>
        )}
        {type?.suborder_status === "order_confirmed" && (
          <span>Order being prepared...</span>
        )}
        {type?.suborder_status === "start_preparation" && (
          <span>Order being prepared...</span>
        )}
        {completeStatus?.order_status === "order_completed"
          ? ""
          : type?.suborder_status === "ready_to_serve" && (
              <span>Deliciously served!</span>
            )}
        {type?.suborder_status === "cancelled" && (
          <span className="text-[#DA0A2E]">Order cancelled!</span>
        )}
        {completeStatus?.order_status === "order_completed" && (
          <span>Order completed!</span>
        )}
      </p>
      <p className="text-sm text-[#b1b1b1] mb-2 font-custom">
        {type?.suborder_status === "new_order" && (
          <span>Your order's almost in the chef's queue!</span>
        )}
        {type?.suborder_status === "order_confirmed" && (
          <span>
            Our chefs are skillfully crafting your meal to perfection!
          </span>
        )}
        {type?.suborder_status === "start_preparation" && (
          <span>
            Our chefs are skillfully crafting your meal to perfection!
          </span>
        )}
        {completeStatus?.order_status === "order_completed"
          ? ""
          : type?.suborder_status === "ready_to_serve" && (
              <span>Your order is table-ready! Enjoy your delicious meal.</span>
            )}
        {type?.suborder_status === "cancelled" && (
          <span>Your order has been cancelled by the restaurant.</span>
        )}
        {completeStatus?.order_status === "order_completed" && (
          <span>Enjoyed your meal? We await your return</span>
        )}
      </p>
      {completeStatus?.order_status === "order_completed" && (
        <p className="text-sm mt-2.5 tracking-tight font-custom">
          Your order was completed at {completeStatus?.order_time},{" "}
          {completeStatus?.order_date}
        </p>
      )}
      {indicator &&
        completeStatus?.order_status !== "order_completed" &&
        type?.suborder_status !== "cancelled" && (
          <div className="flex gap-2 mb-2 mt-1">
            <span className="bg-[#2FBF71] w-[33%] h-1.5 rounded-lg"></span>
            <span
              className={`${
                type?.suborder_status === "new_order"
                  ? "bg-[#E7E8E9]"
                  : "bg-[#2FBF71]"
              } 
             w-[33%] h-1.5 rounded-lg`}
            ></span>
            <span
              className={`${
                type?.suborder_status === "ready_to_serve"
                  ? "bg-[#2FBF71]"
                  : "bg-[#E7E8E9]"
              }  w-[33%] h-1.5 rounded-lg`}
            ></span>
          </div>
        )}
    </div>
  );
}
