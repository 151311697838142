import React from "react";
import { Navigate } from "react-router-dom";

import history from "../../lib/history";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return children;
};

interface PrivateRouteProps {
  children: React.ReactElement;
}

export default PrivateRoute;
