/** @format */

import React from "react";

function ORSvg() {
   return (
      <div>
         <svg
            width="242"
            height="25"
            viewBox="0 0 242 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
               d="M0 13H60.5H90.75H105.875M242 13H181.5H151.25H136.125"
               stroke="#0F191A"
               stroke-opacity="0.25"
            />
            <path
               d="M114.748 19.2328C113.403 19.2328 112.226 18.9353 111.217 18.3404C110.208 17.7455 109.419 16.9242 108.85 15.8766C108.281 14.8161 107.997 13.5939 107.997 12.21C107.997 10.8261 108.281 9.6104 108.85 8.5628C109.419 7.50227 110.208 6.67453 111.217 6.0796C112.226 5.48467 113.403 5.1872 114.748 5.1872C116.093 5.1872 117.27 5.48467 118.279 6.0796C119.287 6.67453 120.07 7.50227 120.626 8.5628C121.195 9.6104 121.48 10.8261 121.48 12.21C121.48 13.5939 121.195 14.8161 120.626 15.8766C120.07 16.9242 119.287 17.7455 118.279 18.3404C117.27 18.9353 116.093 19.2328 114.748 19.2328ZM114.748 17.3898C115.679 17.3898 116.487 17.1829 117.173 16.769C117.871 16.3551 118.414 15.7602 118.802 14.9842C119.19 14.2082 119.384 13.2835 119.384 12.21C119.384 11.1365 119.19 10.2118 118.802 9.4358C118.414 8.6598 117.871 8.06487 117.173 7.651C116.487 7.23713 115.679 7.0302 114.748 7.0302C113.817 7.0302 113.002 7.23713 112.303 7.651C111.605 8.06487 111.062 8.6598 110.674 9.4358C110.299 10.2118 110.111 11.1365 110.111 12.21C110.111 13.2835 110.299 14.2082 110.674 14.9842C111.062 15.7602 111.605 16.3551 112.303 16.769C113.002 17.1829 113.817 17.3898 114.748 17.3898ZM123.47 19V5.42H128.146C129.219 5.42 130.099 5.60107 130.784 5.9632C131.483 6.32533 132 6.8168 132.336 7.4376C132.672 8.04547 132.841 8.72447 132.841 9.4746C132.841 10.3153 132.614 11.0783 132.162 11.7638C131.722 12.4493 131.03 12.9407 130.086 13.2382L132.976 19H130.59L127.952 13.5292H125.527V19H123.47ZM125.527 11.9772H128.029C128.961 11.9772 129.64 11.7509 130.066 11.2982C130.506 10.8455 130.726 10.2571 130.726 9.5328C130.726 8.80853 130.513 8.233 130.086 7.8062C129.659 7.36647 128.967 7.1466 128.01 7.1466H125.527V11.9772Z"
               fill="#0F6656"
            />
         </svg>
      </div>
   );
}

export default ORSvg;
