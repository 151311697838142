/** @format */

import React from "react";
import { Link } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const footerData = [
   {
      title: "Quick Links",
      links: [
         {
            title: "About us",
            url: "/about-us",
         },
         {
            title: "Privacy policy",
            url: "/privacy-policy",
         },
         {
            title: "Terms and Conditions",
            url: "/terms-and-conditions",
         },
      ],
   },
];

export default function Footer() {
   return (
      <footer
         id="contact-us"
         className="bg-[#515B60] flex gap-[2rem] lg:flex-row flex-col lg:items-center justify-start p-[2rem] justify-around">
         <div>
            {footerData.map((item) => {
               return (
                  <div className="flex flex-col gap-4">
                     <h4 className="text-[#fff] font-bold">{item.title}</h4>
                     <div className="mt-2 flex flex-col gap-3">
                        {item.links.map((link) => {
                           return (
                              <Link
                                 target="_blank"
                                 to={link.url}
                                 className="text-[#fff] hover:text-[#fff]">
                                 {link.title}
                              </Link>
                           );
                        })}
                     </div>
                  </div>
               );
            })}
         </div>
         <div className="flex flex-col gap-4">
            <h4 className="text-[#fff] font-bold">{"Contact us"}</h4>
            <div className="mt-1 flex flex-col gap-3">
               <Link
                  className="text-[#fff] hover:text-[#fff]"
                  target="_blank"
                  to={"mailto:someone@example.com"}>
                  <EmailOutlinedIcon /> support@greyfeathers.in
               </Link>
               <Link
                  className="text-[#fff] hover:text-[#fff]"
                  target="_blank"
                  to={"https://wa.me/+917418590412"}>
                  <WhatsAppIcon /> +91 7418590412
               </Link>
               <Link
                  className="text-[#fff] hover:text-[#fff] flex items-center"
                  to={""}>
                  <FmdGoodOutlinedIcon /> <div>3/236-A, Nilla street, Krishna Nagar, Thirupillai, Madurai - 625014</div>
               </Link>
            </div>
         </div>
      </footer>
   );
}
