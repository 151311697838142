/** @format */

import React from "react";
import Footer from "../../component/Footer";
import Navbar from "../PreHomePage/Navbar";
import ContentTitle from "../PreHomePage/ContentTitle";

export default function AboutUS() {
   return (
      <div>
         {/* <Navbar /> */}
         <div className="w-full text-center mt-4">
            <ContentTitle title={"Terms and conditions"} />
         </div>
         <div
            className="my-[5rem]"
            style={{ textAlign: "center", marginBottom: "5rem" }}>
            <div className="w-full flex justify-center items-center">
               <p className="w-[90%] text-justify leading-9">
                  GREYFEATHERS(OPC) PRIVATE LIMITED, A COMPANY REGISTERED UNDER THE COMPANIES ACT, 2013 HAVING ITS REGISTERED OFFICE AT BUILDING No:3/236A NILLA STREET, KRISHNA NAGAR, THIRUPPALAI,
                  MADURAI 625014, INDIA. (HEREINAFTER REFERRED TO AS “GREYFEATHERS”, “WE”, “US” OR “OUR”) ON BEHALF OF ITSELF AND ITS AFFILIATES/GROUP COMPANIES UNDER THE BRAND ‘THE BEAUTIFUL MENU’
                  OPERATES THE WEBSITE ‘thebeautifulmenu.com’ AND THE APPLICATION ‘THE BEAUTIFUL MENU’ (TOGETHER REFERRED TO AS “PLATFORM”). THIS DOCUMENT IS A LEGALLY BINDING DOCUMENT BETWEEN
                  REGISTERED RESTAURANTS/ MERCHANTS OF THE PLATFORM (HEREINAFTER REFERRED TO AS “YOU”/ “YOUR”/ “RESTAURANT MERCHANTS”), AND US IN RELATION TO THE USE OF THE PLATFORM AND SET FORTH THE
                  TERMS AND CONDITIONS BY WHICH YOU MAY ACCESS AND USE THE PLATFORM AND OUR RELATED WEBSITES, SERVICES, APPLICATIONS, PRODUCTS AND CONTENTS. THE TERMS OF THIS DOCUMENT WILL BE
                  EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR INDIRECTLY IN ELECTRONIC FORM, BY CLICKING ON THE "I AGREE" TAB OR BY USE OF THE PLATFORM AND/OR BY CREATING A REGISTERED
                  ACCOUNT ON THE PLATFORM (HEREINAFTER REFERRED TO AS “ACCOUNT”) AND WILL GOVERN THE RELATIONSHIP BETWEEN YOU AND US FOR YOUR USE OF THE PLATFORM AND THE SERVICES RENDERED THEREIN.
                  THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000 AND RULES FRAMED THEREUNDER, AS APPLICABLE AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC
                  RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL
                  SIGNATURES. THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES AND DIGITAL MEDIA ETHICS CODE) RULES,
                  2021 THAT REQUIRE PUBLISHING INTER ALIA THE RESTAURANT MERCHANT TERMS AND CONDITIONS AND THE PRIVACY POLICY FOR ACCESS OR USAGE OF THE PLATFORM AND THE SERVICES RENDERED THEREUNDER.
                  PLEASE READ THIS DOCUMENT CAREFULLY. BY USING THE PLATFORM, YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS DOCUMENT. IF YOU DO NOT AGREE WITH THE TERMS OF THIS DOCUMENT,
                  PLEASE DO NOT USE THIS PLATFORM. YOU HEREBY PROVIDE YOUR UNCONDITIONAL CONSENT OR AGREEMENTS TO US AS PROVIDED UNDER SECTION 43A AND SECTION 72A OF THE INFORMATION TECHNOLOGY ACT,
                  2000 TO ACCESS AND USE YOUR PERSONAL INFORMATION THAT YOU HAVE VOLUNTARILY PROVIDED TO US IN CONNECTION WITH THE USE OF THE PLATOFRM AND THE SERVICES. Greyfeathers and Restaurant
                  Merchants, wherever the context so requires shall, hereinafter collectively be referred to as “Parties” and individually as “Party”. “Personal Information” shall mean and include,
                  but not limited to, any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available
                  to a body corporate, is capable of identifying such person, which may include, but is not limited to, Your contact number, address, gender, and other such details as defined under
                  the Information Technology Act, 2000 and rules made thereunder, that We may require in order for You to use Our Platform. By providing Us Your information or by making use of the
                  Platform and Services provided therein, You hereby consent to the collection, storage, processing, and transfer of any or all Personal Information and Non-Personal Information by Us
                  as specified under this document and further agree that such collection, use, storage, and transfer of information shall not cause any loss or wrongful gain to You or any other
                  person. You access to Platform and the use of Services is also subject to our Privacy Policy, the terms of which can be found directly on the Platform and are incorporated herein by
                  reference. You agree that all agreements, notices, disclosures, and other communications we provide to You electronically satisfy any legal requirement that such communications be in
                  writing. Before You use or subscribe to, and/or begin participating in or using the Platform, it is represented and warranted that You as the Restaurant Merchant of Greyfeathers has
                  or have fully read, understood, and accept the terms and conditions of the Restaurant Merchant (hereinafter “Terms & Conditions”) as updated from time to time without any notice to
                  You. You are advised to review this Terms & Conditions periodically for any updates. We shall take reasonable efforts to notify You regarding the amendments to the Terms & Conditions
                  through a notice on the Platform or otherwise as may be communicated by Us to You from time to time, if any. Your continued access to the Platform and/or use of Services from the
                  date of updated Terms & Conditions shall be deemed to Your acceptance of the updated Terms & Conditions. If You do not agree to or wish to be bound by the updated Terms & Conditions,
                  You may not access to or otherwise access the Platform or use Services. We may, from to time, release new versions of the Platform, or release/introduce new and additional scope of
                  Services which will be subject to these Terms & Conditions, and any additional terms of Services as may apply to such additional new version or Services. SERVICES: Restaurant
                  Merchant Service You agree to perform the following Services (“Restaurant Service” / “Your Services”) to Us, including, without limitation: (a) Creating the menu of foods, adding the
                  images of the product/hotel, adding the logo, and the like, on Your own (known as “Menu Bar”) for displaying on Our platform to make it accessible to the customers on our Platform
                  pursuant to this Terms & Conditions. (b) To oblige and respond to the orders and make it deliverable, if the order is not processable You shall update the cancellation of the order
                  on the platform for updating the same to the customers. (c) Such other services as mutually agreed between You and Us in writing from time to time. The Beautiful Menu Services We
                  agree to provide the following Services (“The Beautiful Menu Services”/ “Our Services”) to You: a) We shall provide a platform to list your menu to the customers and place an order
                  through our platform b) We shall undertake the payment from customers and process Yours share. c) Such other services as mutually agreed between You and Us in writing from time to
                  time Restaurant Merchant Services and The Beautiful Menu Services together referred as “Services” to the extent necessary and applicable. All Our Content and Your Content, shall be
                  subject to approval by You or sale by You or Sale by Us. If We request You, then You shall remove or revise any of Your Content as soon as possible related to the product listed
                  hereunder after they are made available to the public. The terms of Services may change from time to time, at the sole discretion of Us, and the Terms & Conditions will apply to Your
                  visit to and Your use of the Platform to avail/provide the Services, as well as to all information provided by You on the Platform at any given point in time. REGISTRATION AND
                  MAINTENANCE OF ACCOUNT: Registration To avail The Beautiful Menu Services from Us and/or to provide the Restaurant Merchant Services to Us, You will need to create an Account on the
                  Platform and become a registered Account holder of the Platform by registering as prompted in the Platform’s relevant registration form (such information being the "Registration
                  Data") whereby You will receive a password and username (Login Credentials) upon completing the registration process. You shall also use the Login Credentials of the account
                  maintained by You with Our integrated third-party (eg. Google or Facebook), in which case You shall also be subject to relevant third-party terms and conditions applicable to such
                  account. You will be asked to provide certain information about Yourself including the name, contact details, address, and related information as may be required by Us from time to
                  time. In addition to it, as a Restaurant Merchant, You warrant that the information provided by You is correct, complete, and not misleading. You shall inform Us promptly of any
                  changes to the information provided by You, by updating the details, for effective communication. You acknowledge that if any Registration Data provided by You is untrue, inaccurate,
                  not current or incomplete, We reserve the right to indefinitely suspend or terminate or block access of Your Account on the Platform. While creating the Account, You agree: a. to
                  restrict access by any other person or entity to Your password or other login information; b. to not knowingly use the name or email of any other person without authorization; c. to
                  not use an email or profile name that is profane, offensive, or otherwise inappropriate; d. to not allow any third party to use Your login information, Registration Data or Account;
                  e. to notify Us of any activities in your Account even if such activities are not committed by You. We may modify or add to the Registration Data information fields required to
                  create an Account from time to time. You agree to promptly complete any such additional or modified information fields when and as requested by Us. In some cases, You will also need
                  to update Your Account and/or operating system settings to allow Us to access Your other details including but limited to the address book/contacts and enable the geolocation. Your
                  registration with Us is subject to Our confirmation and will become valid when We either confirm Your registration or activate Your Account. We will have the right to use Your
                  Registration Data in connection with servicing and operating the Platform and in other manners as set forth in more detail in this Terms & Conditions and our Privacy Policy. Password
                  & Security You must keep Your Login Credentials confidential and must not disclose it or share it with anyone. You are responsible for preventing unauthorised use of Your Login
                  Credentials. If in any circumstance, You believe that there has been a breach of security such as disclosure, theft, or unauthorised use of Your ID, You must notify Us immediately.
                  If We reasonably believe that Your ID is being used in any way which is not permitted by this Terms & Conditions, We reserve the right to suspend access rights immediately on giving
                  notice to You and to block access from Your ID until the issues has been resolved. Third Party Communications While registering the Account in Our Platform, You provide Us with Your
                  contact details including Your email address. You hereby affirmatively consent to the use of Your email address for notifications from Us regarding important service announcements
                  and other administrative communications related to Your use of the Platform, as well as certain marketing and other advertising communications from Us and from Our third-party
                  advertising partners. You may opt out of receipt of certain notifications; however, there are certain services and administrative notification which You cannot opt out the receipt of
                  it. You do not wish to receive such notifications related to the Platform, Your only way to opt out of such messages is to stop using and delete the Platform. We disclaim liability
                  for any communications directed to You from any third party directly or indirectly in connection with the Platform that You may receive and any actions You may take or refrain from
                  taking as a result of any such third-party communications and assumes no responsibility for verifying, and makes no representations or warranties regarding, the identity or
                  trustworthiness of the source or content of any such third-party Communications. Termination to Platform Access & Deletion of Account The agreement is valid from the date of creation
                  of account subsequent to acceptance of terms and conditions and valid until deleted or withdrawn from being listed. We reserve the right to disable Your Account at any time a) if You
                  have failed to comply with any of the provisions of these Terms & Conditions; b) if activities occur on your Account which, in our sole discretion, would or might cause damage to Us
                  and/or the Platform; c) impair Your Services and/or Our Services or infringe or violate any third-party rights, or violate any applicable laws or regulations; d) if You have engaged
                  in conduct that We determine to be inappropriate or unacceptable in Our sole discretion; e) or for any other reason whatsoever. We may also disable Your Account or suspend or
                  terminate Your access to the Platform if You file any claim against Us or file any claim that involves the Platform. We also reserve the right, in Our sole discretion, to seek and
                  obtain any other remedies available to Us pursuant to any applicable laws and regulations or at equity as a result of Your breach of this Terms & Conditions or any other act or
                  omission by You that gives rise to a claim by Us, and Our disabling of Your Account or suspension or termination of Your use of, or access to, the Platform shall be without prejudice
                  to, and shall not be deemed a waiver of, the foregoing. If We terminate, limit, or suspend Your right to use the Platform, You are prohibited, without Our prior written consent, from
                  registering and creating a new Account under Your name, a fake or borrowed name, or the name of any third party, even if You may be acting on behalf of the third party. In the event
                  Your right to use the Platform terminated, limited, or suspended, this Terms & Conditions will remain in effect and enforceable against You. You may delete Your Account at any time,
                  for any reason, through the “Delete” option in the Platform and terminate the relationship with Us. However, You must note that We reserve the right to retain Your Registration Data
                  in an anonymous form, and any other information provided on an “as-is” basis as part of statutory requirement. Notwithstanding anything contained in this Terms & Conditions, all
                  clauses of this Terms & Conditions which by their nature should survive the expiration or termination shall continue in full force and effect subsequent to, and notwithstanding the
                  expiration or termination of this Terms & Conditions. Your Consent You acknowledge and give Your full and unconditional consent to: a. Your Account Information being permanently
                  retained on a computer database that shall be maintained, owned, and controlled by Us. b. Your Account Information being shared with any other third-party entity that enters into a
                  commercial relationship with Us. c. Your Account Information being shared in its original form, or as part of or aggregated form, with other third-party entities. d. Your Account
                  Information being shared with Our partners and affiliates. e. Receiving links to third-party websites and services, and/or display advertisements for third party service providers,
                  which have entered into a commercial relationship with Us (collectively, “Third-Party Links & Advertisements”). CONSIDERATION AND PAYMENT TERMS: Consideration for Restaurant Merchant
                  Services We shall define the pricing as listed by you on the platform and the payment shall be processed through the Platform to our account. In consideration of Your Menu listed, We
                  shall pay You the consideration in the manner set forth below: a) Price listed on the platform minus 6% of the Bill Value of the order value that are generated out of menu listed and
                  undertaking such other services as agreed between us from time to time. The specifics of such percentage amount shall be mutually agreed between the Parties from time to time in
                  writing (e-mails are permitted). b) If any order is cancelled or deleted by the Restaurant Merchant the same shall be deducted from the amount payable shall be deducted by such
                  amount refunded to the Customers. c) As a provider of e-commerce the GST TCS shall be deducted and credited to your GST account and shall be reduced from consideration payable.
                  Consideration for The Beautiful Menu Services You shall determine the price of Your Menu sold to the customers through Our Platform, and in consideration of Our Services to You, We
                  shall collect and retain from customer such payments i.e., the service charges for facilitating the sales which shall be mutually discussed and agreed in writing between us from time
                  to time. We shall remit to You any amount in excess of the service charges (“Your Profits”). Any refunds, returns, and/or charge backs related to these sales are subject to being
                  deducted from Your current and future Profits. This also gives Us express permission to include Your Menu in featured categories and any other marketing efforts, which is subject to
                  the charges and fees as may be communicated by Us from time to time. We reserve the right to show or remove any Restaurant Merchant on Our Platform at any time, for any reason. Other
                  Payment Terms All the fees and charges shall be inclusive of GST, which shall be deducted by Us against a valid tax invoice complied under the relevant GST laws in force. We reserve
                  our rights to dispute against any invoice and shall be settled only after the mutual discussion and agreement between the Parties. The consideration for Your Services shall be
                  payable by Us every quarter from the date of receipt of proper and valid tax invoice from You under the relevant quarter during the financial year. All the payments are subject to
                  withhold taxes as applicable under the relevant laws in force. No expenses shall be reimbursed or deemed to be an obligation of Us unless You obtain a prior written consent from Us.
                  Refund Policy: 1. Customer can place an order through the platform and can pay directly through the platform. On placing the order and if one or more items are not available as per
                  the order placed the proportionate amount shall be refunded back to the customer. 2. If the restaurant merchant post placing of order by customer cancels or deletes the order, then
                  the sum paid by customer shall be refunded back. 3. The process of refund shall take 5 to 8 business days depending on the mode of payment opted by the customer. PLATFORM ACCESS AND
                  USE OF SERVICES: You shall not use the Platform or the Services therein to host, display, upload, modify, publish, transmit, store, update or share any information that: a. belongs
                  to another person and to which You do not have any right. b. infringes any patent, trademark, copyright, or other proprietary/intellectual property rights. c. violates any law for
                  the time being in force. d. deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or
                  misleading in nature but may reasonably be perceived as a fact. e. impersonates another person. f. is patently false and untrue, and is written or published in any form, with the
                  intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any person. g. any unlawful information, which is prohibited under any law for
                  the time being in force in relation to the interest of the sovereignty and integrity of India; security of the State; friendly relations with foreign States; public order; decency or
                  morality; in relation to contempt of court; defamation; incitement to an offence relating to the above, or any information which is prohibited under any law for the time being in
                  force. h. any private information of any third party, including addresses, phone numbers, email addresses or any personal identifiable information. You agree to not use the Platform
                  to: a. upload, post, email or otherwise make available or transmit any material that contains software viruses, or any other computer code, files or programs designed to interrupt,
                  destroy or limit the functionality of any computer software or hardware or telecommunications equipment; b. disrupt the normal flow of dialogue, cause a screen to "scroll" faster
                  than other users of the Platform are able to type, or otherwise act in a manner that negatively affects other users' ability to engage in real time exchanges or to utilize the
                  service on an as is basis as provided by Us; c. interfere with or disrupt the Platform or servers or networks connected to the Platform, or disobey any requirements, procedures,
                  policies or regulations of networks connected to the Platform; d. intentionally or unintentionally violate any applicable local, state, national or international law, including, but
                  not limited to, regulations promulgated by the Indian securities, exchange control and other regulatory authorities, any rules of any Indian securities exchange and any regulations
                  having the force of law; e. commit any fraudulent or unlawful act, whether in relation to any third party provider of products and services on the Service or otherwise; f. make
                  unauthorised copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any derivative works of the Services or any content included therein, including any
                  files, tables or documentation (or any portion thereof) or determine or attempt to determine any source code, algorithms, methods or techniques embodied by the Services or any
                  derivative works thereof g. distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works thereof h. market, rent or lease the Services for
                  a fee or charge, or use the Services to advertise, or perform any commercial solicitation. i. use the Services, without our express written consent, for any commercial or
                  unauthorized purpose, including communicating or facilitating any commercial advertisement or solicitation or spamming. j. incorporate the Services or any portion thereof into any
                  other program or product. In such case, we shall have the right to terminate the Services and Your accounts or limit access to the Services, as the case may be, in our sole
                  discretion. k. impersonate, or falsely state or otherwise misrepresent Your affiliation with any person, l. infringes any patent, trademark, trade secret, copyright or other
                  proprietary rights ("Rights") of any party; m. use or attempt to another user’s/patient’s/practitioner’s account, service, or system, or create a false identity on the Services. You
                  acknowledge that the Platform may contain third-party content or hyperlinks to third party websites or services including but not limited to for any advertisements displayed in the
                  Platform that are not owned or controlled by Us. They are provided as an information service, for reference and convenience only. We do not control any such third-party content and
                  is not responsible for their availability or accuracy content, advertising, or products or services and the inclusion of such third-party content does not constitute or indicate any
                  kind endorsement of endorsement by Us. We shall not be liable or responsible for any such third-party content transmitted through the Platform. Such third-party content shall not be
                  monitored, censored, or edited by Us and we assume no liability or responsibility for any of your action in relation to such third-party content. You shall read and understand the
                  privacy policy and terms of use associated with such third-party content before using the same. By using the Platform, You expressly relieve and hold Us harmless from any and all
                  liability arising from Your use of any third-party communications and third-party content, including any loss or damage incurred as a result of any dealings between You and any third
                  parties, or as the result of the presence of such third-party content on the Platform or the failure of such third-party content to function as intended. It is Your responsibility to
                  evaluate the content and usefulness of the information obtained from third-party content. In addition to this Terms & Conditions, You shall also agree and be by bound by such other
                  terms and conditions including but not limited to the Terms of Use(Our Platform/Website), Shipping Policy, Intellectual Property Policy, Return Policy, Cancellation Policy and such
                  other Greyfeathers’s policies and/or procedures as may be posted by Us on our Platform or communicated by Us to You by way of e-mails or otherwise, if any. INTELLECTUAL PROPERTY
                  RIGHTS AND PLATFORM LICENSE: The copyright, database right and other intellectual property rights in the Platform, software, products, and Our Services including without limitation
                  all content included on the Platform including but not limited to Our Content, Your Content, if any, for the purpose of Our photographs, text and/or all works of similar nature
                  produced, developed, or created or posted by You for this Terms & Conditions, Registration Data, texts, graphics, logos, button icons, images, audios, clips, digital downloads,
                  software, data compilations and technology used or appearing or transmitted through the Platform and all rights associated or inherent thereunder (“Intellectual Property”), belongs
                  to Us or Our affiliates or licensors and are protected by the laws of India and/or foreign countries. We and Our licensors reserve all rights not expressly granted in these Terms &
                  Conditions. You shall have no claims over Our Intellectual Property at any point of time. Further, We, shall, where needed, call upon the Restaurant Merchants to delete or edit Our
                  Content, Your Content, or the disclosure label to adhere to the Advertisement Standards Council of India Code and Guidelines made thereunder. You hereby grant to Us and Our
                  subsidiaries, the worldwide, exclusive, irrevocable, fully paid-up, royalty-free, fully sublicensable and transferable right and license to reproduce, modify, edit, adapt, publish,
                  translate, distribute, transmit, display, perform, post, re-post, share or otherwise use our Content and/or Social Media Post (including any posts, photos, videos, blogs, articles or
                  any other content), or any portion of our Content or Social Media Post, on the social media pages of Greyfeathers (including but not limited to Instagram, Facebook, Twitter, YouTube,
                  Snap, Pinterest) and to create any merchandise, developing any kinds of products and undertaking all kinds of commercial exploitations out of Your Content and/or our Content, at any
                  time during term of this engagement and these rights shall survive perpetually. You shall not re-utilise Our Platform and/or any of our Services in the Platform or systematically
                  extract any part of the content, use any robots, data mining or extraction tools for reutilization of Our Platform and/or Our Services. Further, You shall not create publish anything
                  that features parts of Our Platform (for example Our Services and its prices) without our prior written permission. “The Beautiful Menu”, Our logo, and local language variants of the
                  foregoing trademarks, and Our Services that appear on this Platform (collectively, the “The Beautiful Menu”), are trademarks or registered trademarks of Greyfeathers. Except as
                  expressly provided in these Terms & Conditions or as expressly authorized in writing by the Us, You shall not use any The Beautiful Menu Marks or Greyfeathers’s Intellectual Property
                  either alone or in combination with other words or design elements, including, in any press release, advertisement, or other promotional or marketing material or media, whether in
                  written, oral, electronic, visual, or any other form. References to other parties’ trademarks and/or any other intellectual property rights on this Platform including but not limited
                  to Our payment service providers and other service providers are for identification purposes only and do not indicate that such parties have approved this Platform or any of its
                  services. The Terms and Conditions do not grant You any right to use the trademarks and/or any other intellectual property rights of such other third parties. INTELLECTUAL PROPERTY
                  COMPLAINT POLICY- YOUR MERCHANDISE: Complaint Policy We shall prohibit You from availing The Beautiful Menu Services and/or usage and access of the Platform, if any Your Merchandise
                  and/or Our Merchandise that are created solely out of Your own creation/content or such contents that are jointly developed by both of us, infringes upon any third party intellectual
                  property rights (such as copyright, trademark, trade dress, and right of publicity and the like). We shall consider complaints of possible infringement only from any third party or
                  other influencer partners or Our Platform’s users that own, or have the exclusive right to exploit, said intellectual property rights. We are not in a position to adjudicate
                  disputes. If any third party or other influencer partners or Our users believes that Your Merchandise that are solely created out of Your Content and/or Our Merchandise that are
                  created solely out of Your own content/creation or such contents jointly developed by both of us, contains a material or any such things that are not authorized or not belonging to
                  You, upon receipt of the information enumerated below, We shall provide Your information to the third party or other influencer partners or Our users claiming as the owner of the
                  content/creation underlying in Your/Our Merchandise, with Your correspondence and contact information and direct that third party or other influencer partner or Our users to contact
                  You within seven (7) days to resolve this dispute. If the issue is not resolved, or in case of an alleged infringement, in our sole discretion, We may remove Your Account,
                  Merchandise created out of Your content, Our Merchandise that are created solely out of Your own content/creation or such contents jointly developed by both of us from Our Platform,
                  provided that the conditions, below, are satisfied. If You believe that Your intellectual property rights have been infringed upon by any of our other influencer partners registered
                  with the Platform or Our users, please notify to us @ anandaraj@greyfeathers.in. You must include within your notification the following information: 1. Identification of the
                  copyright, trademark, or other rights that allegedly have been infringed, including proof of ownership (such as copies of existing trademark or copyright registrations). 2. Your full
                  name, address, telephone number(s), and email address(es). 3. A statement that you have a good-faith belief that use of the material in the URL submitted is unauthorized by the
                  rights owner, or its licensee, and such use amounts to infringement under the applicable laws in force. 4. A statement, under penalty of perjury, that the information in the
                  notification is complete and accurate and that you are authorized to act on behalf of the owner of the intellectual property or other right that is allegedly infringed. 5. Such other
                  information or documents as may be sought by Us at that point in time. Counter-Notice Policy If You believe that a claim of intellectual property infringement was filed by mistake or
                  misidentification You may file a counter-notice. If You materially misrepresent in Your counter-notice that Your Content/creation/Your Services in any manner whatsoever is not
                  infringing upon the intellectual property rights of any third parties, You may be liable for damages to the intellectual property owner (including costs and attorney’s fees).
                  Therefore, if You are unsure whether or not the material infringes on the intellectual property, please contact an attorney before filing the counter-notice. If you submit a
                  counter-notice, a copy of the counter-notice may be sent to the complaining party informing the complaining party that We may replace the removed material or cease disabling it in
                  (10) business days. Unless the intellectual property owner files an action seeking a court order against you, the removed material may be replaced or access to it restored in 10 to
                  14 business days after receipt of the counter-notice. Repeat Intellectual Property Complaint Policy If We receive repeated notices that You have posted others’ intellectual
                  property/rights thereunder without permission, We may terminate Your Account. We have a system for keeping track of repeat violators of intellectual property rights of others and
                  determining when to suspend or terminate Your Account. If You submit a counter-notice in response to the intellectual property owner’s notice and that counter-notice is not answered
                  by the intellectual property owner, or if the dispute leads to legal proceedings that result in a court finding that you have not infringed the owner’s intellectual property, We will
                  allow You to continue for posting the relevant contents/Merchandise in our Platform. We reserve the right to terminate accounts that act against the spirit of the Influencer Partner
                  Program and these Terms & Conditions, regardless of how many disputes/alleged notices issued against You, are involved. We will accept complaints and notification of possible
                  infringement from customers that claim ownership of said contents/merchandise created out of the contents. . Once notified, We, in its discretion, may remedy any alleged infringement
                  by removing unauthorized content. Alternatively, We may notify the allegedly infringing party and provide a warning. Instances of further infractions can lead to possible suspension
                  or termination of the allegedly infringing party’s account. If You see any other contents or merchandise present in our Platform containing Your original image or design/contents and
                  would like to report it to Us, You shall do the same by contacting Us at anandaraj@greyfeathers.in with the following information: (a) The URL(s) used in connection with the sale of
                  the allegedly infringing merchandise/contents; and (b) The URL(s) of Your Merchandise and date of first use and the details of Your Contents etc. PERSONAL DATA PROTECTION As a
                  general principle, Our Services shall be provided and structured without the requirement to process Personal Data (e.g. by using fictitious test data, rendering data anonymous,
                  etc.). Our obligation for the use of Personal Data will be restricted within the scope of the purpose of collecting such Personal Data. Personal Data. To the extent that We receive
                  Your Personal Data as a result of Our Services, We agree that We will (a) not disclose or use any of Your Personal Data except to the extent necessary to carry out Our obligations
                  hereinunder and for no other purpose, (b) not disclose Your Personal Data to any third party, including Our third party service providers without the Your prior written consent and
                  subject to the further requirements of this Clause, (c) employ administrative, technical and physical safeguards to prevent unauthorized use or disclosure of Your Personal Data, (d)
                  promptly provide such information regarding its privacy and information security systems, policies and procedures as You may request relating to its due diligence and oversight
                  obligations under applicable laws and regulations, (e) in the event of any actual or apparent theft, unauthorized use or disclosure of any of Your Personal Data immediately commence
                  all reasonable efforts to investigate and correct the causes and remediate the results thereof, and (f) as soon as practicable following discovery of any event described in
                  sub-clause (e) hereof, provide You a notice thereof, and such further information and assistance as may be reasonably requested. REPRESENTATIONS, WARRANTIES, COVENANTS You represent,
                  warrant and covenant that You: a) have full power to enter into this Terms & Conditions, and Your performance does not violate any other agreement, past or current, with any other
                  party; b) shall perform Your Services hereunder in a professional and workman like manner to the best of Your abilities; c) shall perform Your Services and Your obligations hereunder
                  in compliance with all applicable laws in all respects, Advertising Standards Council of India (ASCI) Code, Advertisement Guidelines for Influencer Advertising on Digital Media and
                  make all required disclosures, disclosures labels in the prescribed form under such guidelines and regulations; d) owns all rights in and to the social media account(s) associated
                  with the Social Media Post; e) have not infringed nor will it infringe any right (whether it is privacy, publicity or intellectual property rights) of any third party in the
                  performance of Your Services or Your obligations hereunder; f) shall be eighteen (18) years of age or older and are not a member of any union; and g) shall comply with all of the
                  obligations as set forth under this Terms & Conditions and such other guidelines, policies and procedures as may be communicated by Us from time to time. h) You shall not avail any
                  of Our Services beyond the contemplated functionality of Our Services. i) You shall not attempt to gain unauthorized access to any of Our Services or any networks, servers or
                  computer systems connected to Our Services. j) You shall not avail any of Our Services in a way that may damage or impair (i) Our Services, or (ii) Our underlying systems and
                  security. k) You shall not avail any of Our Services in any unlawful manner, or in a manner which promotes or encourages illegal activity, including (without limitation) copyright
                  infringement or data theft. l) You shall not modify, adapt, translate, or reverse engineer any part of Our Services (e.g., by creating a shadow site or Website that mirrors the said
                  services, or substantially mirrors said services). m) You understand and acknowledge the any calls, text messages that You receive from Us on Your Mobile Contact Number are not
                  unsolicited calls or messages. n) You will not carry out any action or omit to do any action which will create liability for Us or cause Us to lose (in whole or in part) the services
                  of Our Internet service providers (ISPs) or other partners, suppliers, contractors, vendors. CONFIDENTIALITY All non-public, confidential or proprietary information of Us, including,
                  but not limited to, research or development projects, plans for future product development, information pertaining to business operations and strategies, and information pertaining
                  to customers, suppliers, pricing, advertising, promotion, marketing, and technology, inventions and trade secrets and the terms of this Terms & Conditions(collectively, "Confidential
                  Information"), disclosed or otherwise made available by or on behalf of Us (or its agents) to You in connection with the provision of the Services or this Terms & Conditions, whether
                  orally or in written, electronic or other form or media, and whether or not marked, designated or otherwise identified as "confidential," is confidential and shall be used only for
                  purposes of this Terms & Conditions and shall not be disclosed by You without the prior written consent of Us. Confidential Information does not include information that You can
                  demonstrate either (i) is or becomes generally available to the public other than through Your breach of this Terms & Conditions; (ii) is disclosed to You prior to the date of this
                  Terms & Conditions by a third party having a legal right to make such disclosure and (iii) is known to You or was in Your possession prior to disclosure by Us. INDEMNIFICATION You
                  agree to defend, indemnify and hold harmless Us and its directors, officers, employees, agents, successors and assigns against any suits, claims, causes of action, judgments,
                  liabilities, damages and expenses (including without limitation reasonable attorneys’ fees and court costs) in connection with, relating to or arising from (i) the breach of any
                  provision, representation, warranties and covenants of this Terms & Conditions by You (ii) Your willful misconduct or negligence or omissions; (iii) any claim (whether it relates to
                  infringement or otherwise) that any Social Media Post, Your Content, Our Content or other materials created or provided by You hereunder infringes or misappropriates any right of a
                  third party and (iv) breach of applicable laws in force including but not limited to GST laws, ASCI Code, ASCI Guidelines for Influencer and the like. DISCLAIMER OF WARRANTIES YOUR
                  USE OF THE PLATFORM IS ENTIRELY AT YOUR OWN RISK. TO THE EXTENT NOT OTHERWISE SET IN THIS TERMS OF USE AND TO THE FULLEST EXTENT PERMITTED UNDER LAW, THE PLATFORM IS PROVIDED ON AN
                  “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF (A) MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                  NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, CORRECTNESS, COMPLETENESS OR RELIABILITY OF ANY THE BEAUTIFUL MENU-SERVICES PROVIDED BY US EITHER
                  THROUGH THE PLATFORM OR OTHERWISE OR THIRD PARTY SERVICES LINKED TO THE PLATFORM AND ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF THE
                  BEAUTIFUL MENU SERVICES, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (C) ANY UNAUTHORIZED ACCESS TO OR
                  USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN (D) YOUR USE OF THE BEAUTIFUL MENU SERVICES WILL MEET YOUR REQUIREMENTS (E) ANY INFORMATION OBTAINED
                  BY YOU AS A RESULT OF YOUR USE OF THE BEAUTIFUL MENU SERVICES WILL BE ACCURATE OR RELIABLE; AND DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF
                  THE SERVICES WILL BE CORRECTED. WE DO NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY SERVICES ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR ANY
                  HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
                  THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES, OTHER THAN AS PROVIDED HEREIN. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
                  YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. WITHOUT LIMITING THE FOREGOING, NEITHER US NOR OUR AFFILIATES OR LICENSORS WARRANT THAT ACCESS TO THE PLATFORM WILL BE
                  UNINTERRUPTED OR THAT THE PLATFORM WILL BE ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED OR THAT IT WILL ALWAYS BE ACCESSIBLE; NOR WE MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
                  OBTAINED FROM THE USE OF THE PLATFORM, OR AS TO THE TIMELINESS, ACCURACY, RELIABILITY, COMPLETENESS OR, INFORMATION OR MATERIALS OR THE BEAUTIFUL MENU SERVICES PROVIDED THROUGH OR IN
                  CONNECTION WITH THE USE OF THE PLATFORM. NEITHER US NOR OUR AFFILIATES OR LICENSORS IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USERS. WE, OUR AFFILIATES AND
                  LICENSORS DO NOT WARRANT THAT THE PLATFORM IS FREE FROM VIRUSES, WORMS, TROJAN HORSES, OR OTHER HARMFUL COMPONENTSAND DO NOT GUARANTEE THAT ANY PERSONAL INFORMATION SUPPLIED BY YOU
                  WILL NOT BE MISAPPROPRIATED, INTERCEPTED, DELETED, DESTROYED OR USED BY OTHERS.WE MAY CHANGE, SUSPEND, WITHDRAW OR RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR PLATFORM FOR
                  BUSINESS AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE. LIMITATION OF LIABILITY UNDER NO CIRCUMSTANCES WILL WE, OUR AFFILIATES, LICENSORS, OR ANY OF SUCH PARTIES’ AGENTS,
                  EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE PARTNERS, OR PARTICIPANTS BE LIABLE FOR ANY DIRECT (UNLESS NECESSARY UNDER APPLICABLE LAWS IN FORCE), INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                  SPECIAL OR EXEMPLARY DAMAGES ARISING IN CONNECTION WITH OUR SERVICES, YOUR USE OF OR INABILITY TO USE THE PLATFORM, EVEN IF ADVISED OF THE POSSIBILITY OF THE SAME INCLUSING BUT NOT
                  LIMITED TO ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL(C)ANY LOSS OF OPPORTUNITY, ANY LOSS OF DATA, LOSS OF BUSINESS OR BUSINESS REPUTATION,
                  BUSINESS INTERRUPTION, SUFFERED BY YOU. WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF: ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS,
                  ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICE,ANY
                  CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES) C) THE DELETION OF,
                  CORRUPTION OF, OR FAILURE TO STORE, ANY COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES; YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT
                  INFORMATION; OR YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL. WE WILL NOT BE LIABLE FOR DAMAGE THAT YOU COULD HAVE AVOIDED BY FOLLOWING OUR ADVICE TO
                  APPLY AN UPDATE OFFERED TO YOU FREE OF CHARGE OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY FOLLOW INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM SYSTEM
                  REQUIREMENTS ADVISED BY US. YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR USE OF OUR SERVICE, INCLUDING TEXT-MESSAGING AND DATA CHARGES. TO THE FULLEST EXTENT
                  PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY ARISING OUT OF YOUR USE OF OUR SERVICES, INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER OR
                  OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF
                  EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. GOVERNING LAWS AND JURISDICTION This Terms & Conditions shall be subject to and
                  interpreted in accordance with the Indian Laws and competent Court/s in Bengaluru, India shall have exclusive jurisdiction to try any dispute arising out of this Terms & Conditions.
                  In the event one party hereto shall commence any proceeding, at law, or in equity, against the other party or institute any counterclaim against the other party in any such
                  proceeding, which proceeding or counterclaim relates to this Terms & Conditions, the non-prevailing party in such proceeding or counterclaim shall pay to the prevailing party the
                  prevailing party’s reasonable attorneys’ fees and other legal fees, expert or consultant fees and other costs and expenses incurred in connection with such proceeding or counterclaim
                  (including post-judgment collection). NON-DISPARAGEMENT During the term of our engagement and thereafter, You represent, warrant and covenant that You will not make any statements
                  that disparage or reflect unfavorably on the Merchandise or services of Greyfeathers.   MISCELLANEOUS This Terms & Conditions is on Principal to Principal basis and nothing contained
                  in this Terms & Conditions or otherwise shall be deemed to create any partnership, joint venture or employee/employer relationship between the Parties. Any notice required under this
                  Terms & Conditions will be effective and sufficient if given in writing and delivered by registered mail or by overnight courier of general commercial use and addressed to and as
                  given in the recital. Neither Party shall be liable for any failure or delay in its performance or for non-performance due to circumstances beyond its reasonable control which would
                  include but not limited by circumstances such as natural calamities, act of god, lockdown, pandemic, epidemics, war, riots, war like situations, state embargos. If one or more
                  provisions of this Terms & Conditions are held to be void, voidable, illegal or otherwise unenforceable under applicable law, such provision shall be excluded from this Terms &
                  Conditions and the remainder of this Terms & Conditions shall be interpreted as if such provisions were so excluded and shall be enforceable in accordance with its terms. No waiver
                  of any provisions of this Terms & Conditions shall be effective unless made in writing and signed by an authorised representative of the Party against which enforcement of the waiver
                  is sought. Except where otherwise explicitly provided or agreed in writing all remedies arising under or in connection with this Terms & Conditions are cumulative and not exclusive
                  of any other remedy or right in this Terms & Conditions or available at law. Neither Party shall be entitled to assign, sub-let or otherwise transfer this Terms & Conditions whether
                  in whole or in part to any third party without the prior written consent of the other Party. This Terms & Conditions constitutes the entire document between the Parties and
                  supersedes all previous documents, agreements and correspondence, oral or written, between the Us and You. It is expressly agreed that, in addition to any other remedy to which We
                  may be entitled under this Terms & Conditions, at law or in equity, We shall be entitled to injunctive relief to prevent breaches of the provisions of this Terms & Conditions and to
                  specifically enforce the terms and provisions hereof in any action instituted in any court of any state or country having subject matter jurisdiction thereof.
               </p>
            </div>
         </div>
         <Footer />
      </div>
   );
}
