import React from "react";

export default function Tag({ type, orderHistoryPage, orderStatus }) {
  return (
    <div>
      {type === "ready_to_serve" && (
        <span className="text-[#009243] font-custom font-semibold bg-[#E1FFEF] px-2 py-0.5 rounded-md border-custom border-lime-600">
          Served
        </span>
      )}
      {type === "new_order" && (
        <span className="text-[#C68D00] font-custom font-semibold bg-[#FFF9C4] px-2 py-0.5 rounded-md border-custom border-[#c68d00]">
          Pending
        </span>
      )}
      {type === "order_confirmed" && (
        <span className="text-[#9000A9] font-custom font-semibold bg-[#FBE1FF] px-2 py-0.5 rounded-md border-custom border-[#9000A9]">
          Preparing
        </span>
      )}
      {type === "start_preparation" && (
        <span className="text-[#9000A9] font-custom font-semibold bg-[#FBE1FF] px-2 py-0.5 rounded-md border-custom border-[#9000A9]">
          Preparing
        </span>
      )}
      {type === "cancelled" && (
        <span className="text-[#D90429] font-custom font-semibold bg-[#FBE6E9] px-2 py-0.5 rounded-md border-custom border-[#D90429]">
          Cancelled
        </span>
      )}
      {orderHistoryPage && (
        <>
          {orderStatus?.order_status === "new_order" && (
            <span className="text-[#4361EE] font-custom font-semibold bg-[#E5EAFF] px-2 py-0.5 rounded-md border-custom border-[#4361EE]">
              Ongoing
            </span>
          )}
          {orderStatus?.order_status === "order_updated" && (
            <span className="text-[#4361EE] font-custom font-semibold bg-[#E5EAFF] px-2 py-0.5 rounded-md border-custom border-[#4361EE]">
              Ongoing
            </span>
          )}
          {orderStatus?.order_status === "order_completed" && (
            <span className="text-[#0F6656] font-custom font-semibold bg-[#D1F2E0] px-2 py-0.5 rounded-md border-custom border-[#0F6656]">
              Completed
            </span>
          )}
          {orderStatus?.order_status === "cancelled" && (
            <span className="text-[#D90429] font-custom font-semibold bg-[#FBE6E9] px-2 py-0.5 rounded-md border-custom border-[#D90429]">
              Cancelled
            </span>
          )}
        </>
      )}
    </div>
  );
}
