import { relative } from "path";
import React, { useState } from "react";
import CartIcon from "../../../asset/icons/CartIcon";
import ContactIcon from "../../../asset/icons/ContactIcon";
import { useAppSelector } from "../../../store/hooks";
import HotelCartSelectors from "../../../store/HotelCart/selectors";
import SearchInput from "../../SearchInput";
import { Text } from "../../Text";
import ViewCartButton from "../../ViewCartButton";
import AddToCartFooter from "../AddToCartFooter";
import { Quantity } from "../CartScreen/styles";
import AddBtnSvg from "./AddBtnSvg";
import HotelItem from "./HotelItem";
import { useNavigate } from "react-router-dom";
import MinusBtnSvg from "./MinusBtnSvg";
import PlusBtnSvg from "./PlusBtnSvg";
import { HeadingText, ItemContainer, ItemName } from "./styles";
import "./style.scss";

function Amenities() {
  const { cart, itemCount } = useAppSelector(HotelCartSelectors.getCartInfo);
  const navigate = useNavigate();
  let item1 = {
    id: "1",
    name: "Turkey Towel",
    count: 0,
  };

  let item2 = {
    id: "2",
    name: "Bathing Soap",
    count: 0,
  };

  const PlusMinusBtns = (
    <div>
      <MinusBtnSvg />
      <div className="amenities-plus-minus-btns-text">5</div>
      <PlusBtnSvg />
    </div>
  );

  return (
    <div className="amenities-container">
      <div className="amenities-subcontainer">
        <div>
          <a
            className="amenities-contact-us-container"
            href={`tel:+9199999999`}
          >
            <ContactIcon />
          </a>
        </div>
        <div>
          <div className="amenities-restaurant-name">
            {/* KHKJ */}
            My Awesome Restaurant
          </div>
        </div>
        <div onClick={() => navigate("/hotel/cartscreen")}>
          <CartIcon count={itemCount} />
        </div>
      </div>
      <SearchInput
        searchValue={""}
        setSearchValue={function (value: React.SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        }}
      />
      <div className="amenities-heading-text">Services</div>

      {[item1, item2].map((item: any) => (
        <HotelItem item={item} />
      ))}
      <AddToCartFooter />
    </div>
  );
}

export default Amenities;
