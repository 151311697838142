/** @format */

import React from "react";
import showMenuSelector from "../../store/showMenu/selectors";
import { useAppSelector } from "../../store/hooks";

export default function TotalAmountToPay({ amount }) {
   const { currency } = useAppSelector(showMenuSelector.showMenu);
   return (
      <div className="px-3 pt-4">
         <div
            className="text-[#ffffff] rounded-3xl text-center py-4"
            style={{
               background: "linear-gradient(96deg, #1D8B7C 3.42%, #085A4E 98.56%)",
            }}>
            <p className="text-base mb-3 font-custom">Total amount to pay</p>
            <p className="text-4xl font-bold font-custom">
               {currency}
               {amount}
            </p>
         </div>
      </div>
   );
}
