import React from "react";

export default function OrderDividerTitle({ title }) {
  return (
    <div className="py-3">
      <p className="text-base text-neutral-400  bg-[#F6F6F6] p-2 font-custom font-semibold">
        {title}
      </p>
    </div>
  );
}
