import { request } from '../axios';
import { SuccessResponse, ErrorResponse, ResponsePayload } from '../index.type';
import { PayloadProps, Props } from './getBestSellingItems.type';
import { ErrorResponseHandler } from '../index';

const getNewlyAddedItems = async ({
  merchantId,
}: Props): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  try {
    const response = await request<ResponsePayload<PayloadProps>>({
      api: `/customer/newly_added_items/${merchantId}`,
      method: 'GET',
    });

    const result = response.data;

    if (response.status !== 200) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: 'Success',
      payload: result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getNewlyAddedItems;
