/** @format */

import { request } from "../axios";
import { SuccessResponse, ErrorResponse, ResponsePayload } from "../index.type";
import { OtpPayloadProps, Props } from "./verifyOtp.type";
import { ErrorResponseHandler } from "../index";

const verifyOtp = async ({ phone_number, otp, merchant_id, email }: Props): Promise<SuccessResponse<OtpPayloadProps> | ErrorResponse> => {
   try {
      const response = await request<ResponsePayload<OtpPayloadProps>>({
         api: `/customer_signup/verify_otp`,
         method: "POST",
         data: {
            phone_number,
            otp,
            merchant_id,
            email,
         },
      });
      console.log("sent otp");
      const result = response.data;

      if (response.request.status > 300) {
         return {
            statusCode: response.request.status,
            message: "Failed",
            payload: JSON.parse(response.request.response),
            error: null,
         };
      } else {
         return {
            statusCode: response.request.status,
            message: "Success",
            payload: result,
            error: null,
         };
      }
   } catch (error) {
      console.log("Error");

      return ErrorResponseHandler(error);
   }
};

export default verifyOtp;
