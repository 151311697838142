import { RootState } from "../store";

const userSelectors = {
  getAccessToken: (state: RootState) => state.user.accessToken,
  getUserDetails: (state: RootState) => {
    return {
      user_name: state.user.user_name,
      phone_number: state.user.phone_number,
      otp_id: state.user.otp_id,
      customer_id: state.user.customer_id,
      fcm_token: state.user.fcm_token,
      longitude: state.user.longitude,
      latitude: state.user.latitude,
      // pincode: state.user.pincode,
    };
  },
  getFormState: (state: RootState) => state.user.formState,
};

export default userSelectors;
