/** @format */

import React, { useState } from "react";
import Logo from "../../../component/Logo";
import { Link, useNavigate } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material";

const navbarItems = [
   {
      title: "Home",
      url: "/",
   },
   {
      title: "About Us",
      url: "/about-us",
      newTab: true,
   },
   {
      title: "Contact us",
      url: "contact-us",
   },
   {
      title: "Blog",
   },
   {
      title: "Pricing",
      url: "pricing",
   },
];

const innerWidth = window.innerWidth;

export default function Navbar() {
   const [showDrawer, setshowDrawer] = useState(false);
   const navigate = useNavigate();
   const toggleDrawer = () => setshowDrawer(!showDrawer);

   const openInNewTab = (url) => {
      window.open(url, "_blank");
   };

   return (
      <div className="flex items-center justify-between pr-3 lg:pr-5">
         <div
            className="cursor-pointer"
            onClick={() => navigate("/")}>
            <Logo />
         </div>
         <Drawer
            open={showDrawer}
            onClose={toggleDrawer}
            anchor="right">
            <div className="px-[2rem] flex  items-center flex-col pt-5  gap-4">
               <Logo />
               {navbarItems.map((item, index) => {
                  return (
                     <a
                        onClick={toggleDrawer}
                        target={item?.newTab && "_blank"}
                        href={item?.newTab ? item.url : `/#${item.url}`}
                        className="hover:no-underline hover:text-[#000] hover:font-bold transition duration-300 ease-in-out">
                        {item.title}
                     </a>
                  );
               })}
               <div
                  onClick={() => openInNewTab("/free-demo-form")}
                  className="bg-[#2D6B4D] cursor-pointer text-[#fff]  border-[1px] border-solid border-[#000]  px-3 py-2 rounded-[0.3rem] transition duration-300 ease-in-out">
                  Free demo
               </div>
            </div>
         </Drawer>
         <div className="lg:hidden">
            <MenuIcon onClick={toggleDrawer} />
         </div>
         <div className="gap-[3.5rem] hidden lg:flex items-center">
            <div className="gap-[3.5rem] hidden lg:flex">
               {navbarItems.map((item, index) => {
                  return (
                     <a
                        target={item?.newTab && "_blank"}
                        href={item?.newTab ? item.url : `/#${item.url}`}
                        className="z-10 hover:no-underline hover:text-[#000] hover:font-bold transition duration-300 ease-in-out">
                        {item.title}
                     </a>
                  );
               })}
            </div>
            <button
               onClick={() => openInNewTab("/free-demo-form")}
               className="bg-[#2D6B4D] z-10 cursor-pointer text-[#fff]  border-[1px] border-solid border-[#000]  px-3 py-2 rounded-[0.3rem] transition duration-300 ease-in-out">
               Free demo
            </button>
         </div>
      </div>
   );
}
