/** @format */

import React, { Component } from "react";
import "./style.scss";
import DoubleArrow from "../../asset/icons/DoubleArrow";

const slider = React.createRef();
const container = React.createRef();
const isTouchDevice = "ontouchstart" in document.documentElement;

export default class SwipeButton extends Component {
   state = {};

   componentDidMount() {
      if (isTouchDevice) {
         document.addEventListener("touchmove", this.onDrag);
         document.addEventListener("touchend", this.stopDrag);
      } else {
         document.addEventListener("mousemove", this.onDrag);
         document.addEventListener("mouseup", this.stopDrag);
      }
      this.containerWidth = container.current.clientWidth - 50;
   }

   onDrag = (e) => {
      if (this.unmounted || this.state.unlocked) return;
      if (this.isDragging) {
         if (isTouchDevice) {
            this.sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - this.startX), this.containerWidth);
         } else {
            this.sliderLeft = Math.min(Math.max(0, e.clientX - this.startX), this.containerWidth);
         }
         this.updateSliderStyle();
      }
   };

   updateSliderStyle = () => {
      if (this.unmounted || this.state.unlocked) return;
      if (this.sliderLeft < 30) {
         slider.current.style.left = this.sliderLeft + 50 + "px";
      } else {
         slider.current.style.left = this.sliderLeft + 25 + "px";
      }
   };

   stopDrag = () => {
      if (this.unmounted || this.state.unlocked) return;
      if (this.isDragging) {
         this.isDragging = false;
         if (this.sliderLeft > this.containerWidth * 0.8) {
            this.sliderLeft = this.containerWidth;
            if (this.props.onSuccess) {
               this.props.onSuccess();
            }
            this.sliderLeft = 0;
         } else {
            this.sliderLeft = 0;
            if (this.props.onFailure) {
               this.props.onFailure();
            }
         }
         this.updateSliderStyle();
      }
   };

   startDrag = (e) => {
      if (this.unmounted || this.state.unlocked) return;
      this.isDragging = true;
      if (isTouchDevice) {
         this.startX = e.touches[0]?.clientX;
      } else {
         this.startX = e.clientX;
      }
   };

   onSuccess = () => {
      container.current.style.width = container.current.clientWidth + "px";
      this.setState({
         unlocked: true,
      });
   };

   getText = () => {
      return this.state.unlocked ? this.props.text_unlocked || " UNLOCKED" : this.props.text || "SLIDE";
   };

   reset = () => {
      if (this.unmounted) return;
      this.setState({ unlocked: false }, () => {
         this.sliderLeft = 0;
         this.updateSliderStyle();
      });
   };

   componentWillUnmount() {
      this.unmounted = true;
   }

   render() {
      return (
         <div className="ReactSwipeButton">
            <div
               className={"rsbContainer px-2" + (this.state.unlocked ? "rsbContainerUnlocked" : "")}
               ref={container}>
               {this.props.isLoading ? (
                  <div className="w-[100%] h-[100%] flex items-center justify-center">
                     <div className="loader"></div>
                  </div>
               ) : (
                  <>
                     <div
                        className="rsbcSlider"
                        ref={slider}
                        onMouseDown={this.startDrag}
                        style={{ background: this.props.color }}
                        onTouchStart={this.startDrag}>
                        <span className="rsbcSliderArrow ">
                           <svg
                              width="20"
                              height="14"
                              viewBox="0 0 20 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                 d="M13.3632 13.6374C12.9759 14.0247 12.3467 14.0202 11.965 13.6274C11.5911 13.2425 11.5955 12.6286 11.975 12.2492L16.2495 7.97462L0.973741 7.97462C0.435959 7.97462 0 7.53866 0 7.00088C0 6.46309 0.435959 6.02714 0.973741 6.02714L16.2495 6.02714L11.975 1.75256C11.5955 1.3731 11.5911 0.759265 11.965 0.374391C12.3467 -0.0183963 12.9759 -0.0229239 13.3632 0.364331L19.2128 6.21395C19.6474 6.64856 19.6474 7.3532 19.2128 7.7878L13.3632 13.6374Z"
                                 fill="#0F6656"
                              />
                           </svg>
                        </span>

                        <span
                           className="rsbcSliderCircle"
                           style={{ background: this.props.color }}></span>
                     </div>
                     <div className="rsbcText flex items-center justify-center gap-2 font-custom">
                        {this.getText()} <DoubleArrow />
                     </div>
                  </>
               )}
            </div>
         </div>
      );
   }
}
