import React from 'react';

function InfoIcon() {
  return (
    <svg width='20' height='24' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0007 1.66683C5.41732 1.66683 1.66732 5.41683 1.66732 10.0002C1.66732 14.5835 5.41732 18.3335 10.0007 18.3335C14.584 18.3335 18.334 14.5835 18.334 10.0002C18.334 5.41683 14.584 1.66683 10.0007 1.66683Z'
        fill='#0F191A'
        stroke='#0F191A'
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10 13.3335L10 9.16683'
        stroke='white'
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.0049 6.6665L9.9974 6.6665'
        stroke='white'
        stroke-width='1.66667'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default InfoIcon;
