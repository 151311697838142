import React from "react";

function EmptySvg() {
  return (
    <div>
      <svg
        width="176"
        height="176"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M88 66V102.667"
          stroke="#0F191A"
          stroke-opacity="0.5"
          stroke-width="8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M87.996 157.001H43.556C18.1093 157.001 7.47601 138.815 19.796 116.595L42.676 75.3813L64.236 36.6613C77.2893 13.1213 98.7027 13.1213 111.756 36.6613L133.316 75.4547L156.196 116.668C168.516 138.888 157.809 157.075 132.436 157.075H87.996V157.001Z"
          stroke="#0F191A"
          stroke-opacity="0.5"
          stroke-width="8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M87.957 124.667H88.0229"
          stroke="#0F191A"
          stroke-opacity="0.5"
          stroke-width="12"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default EmptySvg;
