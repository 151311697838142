import { Modal, useMediaQuery } from "@mui/material";
import React from "react";
import { Text } from "../Text";
import { Container, ModalContainer } from "./style";
import './style.scss';

function WarningModal() {
  const isMobile = useMediaQuery("(min-width:700px)");

  return (
    <>
      <Modal open={isMobile} sx={{ backdropFilter: "blur(10px)" }}>
        <div className="warning-container">
          <div className="warning-modal-container">
            <div className="warning-modal-text">
              This menu is best viewed on a mobile view
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WarningModal;
