/** @format */

import React from "react";

export default function PricingHardPreHome({ title, price, duration, saved, recommended }) {
   return (
      <div
         data-aos={"zoom-in"}
         data-aos-duration={500}
         style={{ boxShadow: "0px 6.16708px 15px 0px rgba(0, 0, 0, 0.25)" }}
         className="lg:w-[23rem] lg:h-[24rem] position-relative w-[19rem] h-[20rem] rounded-[1.5rem] p-3 flex flex-col justify-between items-center bg-[#fff]">
         <div></div>
         {recommended && <div className="bg-[#F9CA41] text-[#000] text-[0.8rem] px-2 py-1 position-absolute right-0 rounded-l-[2rem]">Recommended</div>}
         <div className="flex flex-col items-center gap-2">
            <h6 className="font-bold">{title}</h6>
            <h1 className="font-bold text-[5rem] font-[Montserrat]">{price}$</h1>
            <h6 className="font-bold">{duration}</h6>
            {saved !== "" && <h6 className="font-bold text-[#4361EE]">Save {saved}$</h6>}
         </div>
         <button className="bg-[#000] text-[#fff] w-[70%] h-[2.8rem] rounded-[0.4rem]">Choose plan</button>
      </div>
   );
}
