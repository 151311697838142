/** @format */

import React from "react";
import ContentTitle from "../ContentTitle";
import { IS_SMALLER_DEVICE } from "../../../utils/styleDefinitions";

export default function Content({ content, image, title, reverse }) {
   const borderTopRightRadius = reverse ? "1rem" : 0;
   const borderBottomRightRadius = reverse ? "1rem" : 0;
   const borderTopLeftRadius = !reverse ? "1rem" : 0;
   const borderBottomLeftRadius = !reverse ? "1rem" : 0;

   return (
      <div
         data-aos="fade-up"
         className="flex flex-col items-center">
         <ContentTitle title={title} />
         <div
            style={{ gap: IS_SMALLER_DEVICE ? "2rem" : 0, flexDirection: IS_SMALLER_DEVICE ? "column" : reverse ? "row-reverse" : "row" }}
            className={`flex  flex-col   w-full mt-5 mb-5 lg:h-[80vh]`}>
            <div className="w-full flex items-center justify-center lg:w-[60%]">{content}</div>
            <div
               style={{ justifyContent: reverse ? "flex-start" : "flex-end" }}
               className={`lg:w-[40%] flex`}>
               <div
                  style={{ borderTopRightRadius, borderBottomRightRadius, borderBottomLeftRadius, borderTopLeftRadius }}
                  className={`lg:h-[100%] h-full  lg:w-full flex  overflow-hidden items-center   w-[80%]`}>
                  <img
                     src={image}
                     style={{ borderTopRightRadius, borderBottomRightRadius, borderBottomLeftRadius, borderTopLeftRadius }}
                     className="object-cover h-full lg:h-[80%] w-full"
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
