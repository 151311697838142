import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Text } from '../Text';
import type { MenuCardsProps } from './MenuCards.type';
import MenuItem from './MenuItem';
import { useAppSelector } from '../../store/hooks';
import qrSelectors from '../../store/Qr/selectors';
import Skeleton from 'react-loading-skeleton';
import ShuffleIcon from '../../asset/icons/ShuffleIcon';
import { useParams } from 'react-router-dom';

const MenuCards = ({ cards, menu }: MenuCardsProps) => {
  const { mid, qid } = useParams();
  const navigate = useNavigate();

  const onClick = (link: any) => {
    if (qid !== undefined) {
      navigate(`/${mid}/${qid}/menu/${link}`);
    } else {
      navigate(`/${mid}/menu/${link}`);
    }
  };


  return (
    <div className='menu-card-container'>
      <div className='menu-card-subcontainer'>
        {
          //@ts-ignore
          menu ? (
            cards?.map((item) => (
              <div
                className='menu-card-menu-item'
                onClick={() => onClick(item.link)}>
                <MenuItem
                  background={item.background}
                  text={item.text}
                  link={item.link}
                  key={item.text}
                />
              </div>
            ))
          ) : (
            <Skeleton
              className='menu-card-skeleton'
              height={'5rem'}
              width='100%'
              count={5}
            />
          )
        }
      </div>
    </div>
  );
};

export default MenuCards;
