import React from "react";
import Button from "@mui/material/Button";
import { XCircle } from "react-feather";
import CorrectCircle from "../../asset/icons/CorrectCircle";
import { Text } from "../Text";
import {
  Container,
  ImageContainer,
  TextContainer,
  SubContainer,
} from "./styles";
import { Box } from "@mui/material";

const SubscrinePopup = ({ onCloseHandler }: SubscribePopupProps) => {
  return (
    <div className="subscribe-done-pop-up-container">
      <div className="subscribe-done-pop-up-subcontainer">
        <div className="subscribe-done-pop-up-image-container">
          <CorrectCircle />
          <XCircle
            onClick={onCloseHandler}
            style={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
            }}
          />
        </div>
        <div className="subscribe-done-pop-up-text-container">
          <div className="subscribe-done-pop-up-btn-text1">
            Subscribed to our newsletter
          </div>
          <div className="subscribe-done-pop-up-btn-text2">
            You’ll be notified about our amazing foods through your mail.
          </div>
          <Box mb={2} ml="auto">
            <Button
              onClick={onCloseHandler}
              variant="contained"
              style={{
                marginLeft: "auto",
                background: "rgba(67, 97, 238, 0.2)",
                color: "rgb(67, 97, 238)",
              }}
            >
              Done
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

interface SubscribePopupProps {
  onCloseHandler: () => void;
}

export default SubscrinePopup;
