/** @format */

import React, { useState, useEffect } from "react";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import "./index.scss";
import Input from "../../component/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";
import { useNavigate, useLocation, useParams } from "react-router";
import { getAddress } from "../../api/address/addessAPI";
import qrSelectors from "../../store/Qr/selectors";

export default function Index() {
   const [inputValues, setinputValues] = useState({
      flat_no: "",
      street_name: "",
      landmark: "",
      town_or_city: "",
      pincode: "",
      appartment: "",
   });

   const navigate = useNavigate();

   const location = useLocation();

   const orderDetails = location?.state?.orderDetails;
   const { mid, qid } = useParams();

   console.log(orderDetails);

   const createUserValidationSchema = yup.object({
      flat_no: yup.string().required("This field is required"),
      street_name: yup.string().required("This field is required"),
      landmark: yup.string().required("This field is required"),
      town_or_city: yup.string().required("This field is required"),
      pincode: yup.number("Must be only digits").required("This field is required"),
   });

   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);

   useEffect(() => {
      GetDetails();
   }, []);

   const formik = useFormik({
      initialValues: {
         flat_no: inputValues?.flat_no,
         street_name: inputValues?.street_name,
         landmark: inputValues?.landmark,
         town_or_city: inputValues?.town_or_city,
         pincode: inputValues?.pincode,
         appartment: inputValues?.appartment,
      },
      onSubmit: (values) => SubmitForm(values),
      validationSchema: createUserValidationSchema,
   });

   const SubmitForm = async (body) => {
      const response = await fetch(`${process.env.REACT_APP_DEV_URL}/customer/customer_details/address`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
         },
         body: JSON.stringify(body),
      });

      if (response.status === 200 || (await response).status === 201) {
         const resData = await response.json();
         if (qid !== undefined) {
            navigate(`/${mid}/${qid}/ordersummary`, { state: { orderDetails: { ...orderDetails, address: resData } } });
         } else {
            navigate(`/${mid}/ordersummary`, { state: { orderDetails: { ...orderDetails, address: resData } } });
         }
      }
   };

   const GetDetails = async () => {
      const resData = await getAddress(accessToken, mid, qid);
      formik.setValues({
         flat_no: resData.flat_no,
         landmark: resData.landmark,
         pincode: resData.pincode,
         street_name: resData.street_name,
         town_or_city: resData.town_or_city,
         appartment: resData?.appartment,
      });
   };

   console.log(inputValues);

   useEffect(() => {}, [inputValues]);

   return (
      <div className="address-screen-container">
         <div className="address-header-container">
            <div onClick={() => navigate(-1)}>
               <BackArrowIcon />
            </div>
            <div className="address-text"> Address</div>
            <div></div>
         </div>
         <div className="address-inputs-container">
            <form onSubmit={formik.handleSubmit}>
               <Input
                  id="flat_no"
                  label="Flat, House no."
                  onChange={formik.handleChange}
                  value={formik.values.flat_no}
                  error={formik.touched.flat_no && Boolean(formik.errors.flat_no)}
                  helperText={formik.touched.flat_no && formik.errors.flat_no}
               />
               <Input
                  id="street_name"
                  label="Street name"
                  onChange={formik.handleChange}
                  value={formik.values.street_name}
                  error={formik.touched.street_name && Boolean(formik.errors.street_name)}
                  helperText={formik.touched.street_name && formik.errors.street_name}
               />
               <Input
                  id="appartment"
                  label="Apartment"
                  onChange={formik.handleChange}
                  value={formik.values.appartment}
               />
               <Input
                  id="landmark"
                  label="Landmark"
                  onChange={formik.handleChange}
                  value={formik.values.landmark}
                  error={formik.touched.landmark && Boolean(formik.errors.landmark)}
                  helperText={formik.touched.landmark && formik.errors.landmark}
               />
               <Input
                  id="town_or_city"
                  label="Town/City"
                  onChange={formik.handleChange}
                  value={formik.values.town_or_city}
                  error={formik.touched.town_or_city && Boolean(formik.errors.town_or_city)}
                  helperText={formik.touched.town_or_city && formik.errors.town_or_city}
               />
               <Input
                  id="pincode"
                  label="Pincode"
                  onChange={formik.handleChange}
                  value={formik.values.pincode}
                  inputType="number"
                  error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                  helperText={formik.touched.pincode && formik.errors.pincode}
               />
               <button
                  type="submit"
                  className="add-location-button">
                  Add address
               </button>
            </form>
         </div>
      </div>
   );
}
