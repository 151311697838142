import React from "react";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import FrontArrowIcon from "../../asset/icons/FrontArrowIcon";
import FrontBtnSvg from "./FrontBtnSvg";
import { ContentHeading, ContentText, Heading } from "./styles";

function Promotions() {
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          margin: "1rem",
        }}
      >
        <BackArrowIcon />
        <Heading>Promotions</Heading>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            margin: "0.5rem 1rem",
            background:
              "linear-gradient(94.07deg, #F7B723 -2.69%, #FFCE5D 38.44%, rgba(255, 206, 93, 0.99) 125.37%)",
            borderRadius: "12px",
            padding: "1rem",
          }}
        >
          <div>
            <ContentHeading>Diwali Month Offer</ContentHeading>
            <ContentText>20% OFF on all veg items</ContentText>
          </div>

          <FrontBtnSvg />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            margin: "0.5rem 1rem",
            background:
              "linear-gradient(94.51deg, #24C5A2 -9.07%, #3AF0C9 32.78%, rgba(67, 217, 185, 0.6) 126.69%)",
            borderRadius: "12px",
            padding: "1rem",
          }}
        >
          <div>
            <ContentHeading>Non-Veg Bonanza</ContentHeading>
            <ContentText>
              10% OFF on all Non-veg items & all biriyani Items
            </ContentText>
          </div>

          <FrontBtnSvg />
        </div>
      </div>
    </div>
  );
}

export default Promotions;
