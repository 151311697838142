/** @format */

import "./style.scss";
import Chic from "./Chic.jpeg";
import NonVegIcon from "../../asset/icons/NonVegIcon";
import MinusBtnSvg from "../Hotel/Amenities/MinusBtnSvg";
import PlusBtnSvg from "../Hotel/Amenities/PlusBtnSvg";
import { useAppSelector } from "../../store/hooks";
import showMenuSelector from "../../store/showMenu/selectors";

function AlsoOrdered() {
   const { currency } = useAppSelector(showMenuSelector.showMenu);

   return (
      <div className="also-ordered-container">
         <img
            className="also-ordered-img"
            height="40%"
            width="96%"
            src={Chic}
         />
         <div className="also-ordered-middle-container">
            <div className="also-ordered-icon-container">
               <NonVegIcon />
               <div className="also-ordered-item-name">Chicken biryani</div>
            </div>
            <div className="also-ordered-price-container">
               <div className="also-ordered-price-text-container">{currency} 270</div>
            </div>
         </div>
         <div className="also-ordered-btns-container">
            <MinusBtnSvg />
            <div className="also-ordered-btns-container-count-content">0</div>
            <PlusBtnSvg />
         </div>
      </div>
   );
}

export default AlsoOrdered;
