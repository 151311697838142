import React from 'react';
import CounterDown from '../../asset/icons/CounterDown';
import CounterUp from '../../asset/icons/CounterUp';
import plusIcon from '../../asset/svg/plusIcon.svg';
import minusIcon from '../../asset/svg/minusIcon.svg';
import './index.scss';

interface Props {
  filter: any;
  setFilter: any;
}

function CountInput({ filter, setFilter }: Props) {
  const handleIncrement = () => {
    setFilter({
      ...filter,
      number_of_items: filter?.number_of_items + 1,
    });
  };

  const handleDecrement = () => {
    if (filter?.number_of_items > 0) {
      setFilter({
        ...filter,
        number_of_items: filter?.number_of_items - 1,
      });
    }
  };

  return (
    <>
      <div className='completely-random-icons-container'>
        <div className='completely-random-icons'>
          <img src={minusIcon} onClick={handleDecrement} />
          <div className='completely-random-no-of-items'>
            {filter.number_of_items}
          </div>
          <img src={plusIcon} onClick={handleIncrement} />
        </div>
      </div>
    </>
  );
}

export default CountInput;
