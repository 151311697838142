import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorResponseHandler } from '..';
import { request } from '../axios';
import { CompletelyRandomPayloadProps, NotSoRandomPayloadProps,FilterProps } from './models/index.type';

export const notSoRandom = async (data: NotSoRandomPayloadProps) => {
  try {
    const response = await request<any>({
      api: '/customer/surprise_me/not_so_random',
      method: 'POST',
      data: data,
    });
    const result = response.data;
    console.log('RESULT', response);

    if (response.status === 200 || response.status === 201) {
      return {
        statusCode: response.status,
        message: 'Success',
        payload: result,
        error: null,
      };
    } else {
      throw new Error('Cant get Items');
    }
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export const filterItemsBasedOnType = async (data: FilterProps) => {
  try {
    const response = await request<any>({
      api: '/customer/surprise_me/filter_items',
      method: 'POST',
      data: data,
    });
    const result = response.data;
    console.log('RESULT', response);

    if (response.status === 200 || response.status === 201) {
      return { statusCode: response.status, message: 'Success', payload: result, error: null };
    } else {
      throw new Error('Cant get Items');
    }
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};


export const completelyRandom = async (data: CompletelyRandomPayloadProps) => {
  try {
    const response = await request<any>({
      api: '/customer/surprise_me/completely_random',
      method: 'POST',
      data: data,
    });
    const result = response.data;
    console.log('RESULT', response);

    if (response.status === 200 || response.status === 201) {
      return {
        statusCode: response.status,
        message: 'Success',
        payload: result,
        error: null,
      };
    } else {
      throw new Error('Cant get Items');
    }
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};
