import React from 'react';

type Props = {};

function UpArrowIcon({}: Props) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.07992 15.0498L10.5999 8.5298C11.3699 7.7598 12.6299 7.7598 13.3999 8.5298L19.9199 15.0498'
        stroke='#292D32'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default UpArrowIcon;
