import styled from '@emotion/styled';

const Container = styled.div`
  position: fixed;
  background-color: #fff;
  height: 100%;
  width: 70%;
  transition: all 1s ease;
`;
const SubContainer = styled.ul`
  padding: 0;
`;
const NavItem = styled.li`
  color: #000;
  padding: 0.5rem;
  list-style: none;
  margin: 0;
  width: 70%;
`;

export { Container, SubContainer, NavItem };
