/** @format */

import "./index.scss";
import coupon from "./../../asset/svg/coupon.svg";
import showMenuSelector from "../../store/showMenu/selectors";
import { useAppSelector } from "../../store/hooks";

const OffersBanner = ({ offers }: any) => {
   console.log("OFFERS", offers);

   const { currency } = useAppSelector(showMenuSelector.showMenu);
   return (
      <div
         className="offers-banner-container  hide-scroll-bar bg-[#fff] px-2"
         style={{
            gridTemplateColumns: `repeat(${offers?.length}, 100%)`,
         }}>
         {offers?.map((offer: any, index: any) => (
            <div
               className="offer-banner"
               key={index}>
               <div className="offer-img-container">
                  <img
                     src={coupon}
                     alt="coupon"
                  />
               </div>
               <div className="offer-content">
                  <div className="offer-code font-custom">{offer?.discount_type === "absolute" ? `${currency}${offer?.discount_val}` : `${offer.discount_val}%`} Off</div>
                  <div className="offer-description font-custom">{`on all orders above Rs. ${offer?.offer_value}`}</div>
               </div>
               <div className="offer-index">
                  {/* <div>
							<span>{index + 1}</span>
							<span style={{ opacity: "0.5" }}>/{offers.length}</span>
						</div> */}
                  <div className="index-dots-container">
                     {offers.map((offer: any, index2: any) => (
                        <div
                           className="index-dots"
                           style={{
                              width: index === index2 ? "1rem" : "0.3rem",
                              opacity: index === index2 ? "1" : "0.25",
                           }}
                        />
                     ))}
                  </div>
               </div>
            </div>
         ))}
      </div>
   );
};

export default OffersBanner;
