import React from 'react';

type Props = {
  title: string;
  isSelect: boolean;
  onClickHandler?: any;
};

export default function ToggleFoodType({ title, isSelect, onClickHandler }: Props) {
  return (
    <div
      onClick={onClickHandler}
      className={
        isSelect
          ? 'surprise-me-food-type-toggler type-active col-4'
          : 'surprise-me-food-type-toggler col-4'
      }
    >
      {title}
    </div>
  );
}
