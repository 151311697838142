/** @format */

import EastIcon from "@mui/icons-material/East";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Image1 from "../../../asset/images/landinpage/customerWebappImages/1_HomePage-portrait.png";
import Image2 from "../../../asset/images/landinpage/customerWebappImages/2_Offers-portrait.png";
import Image3 from "../../../asset/images/landinpage/customerWebappImages/3_-portrait.png";
import Image4 from "../../../asset/images/landinpage/customerWebappImages/4_Side bar-portrait.png";
import Image5 from "../../../asset/images/landinpage/customerWebappImages/5_Food Items = Add 5-portrait.png";
import Image6 from "../../../asset/images/landinpage/customerWebappImages/6_Cart Screen-portrait.png";
import Image7 from "../../../asset/images/landinpage/customerWebappImages/8_Mobile notification-portrait.png";
import Image8 from "../../../asset/images/landinpage/customerWebappImages/9_Side bar-2-portrait.png";
import OrderDetails from "../../../asset/images/landinpage/merchantWebapp/OrderDetails.png";
import OrderScreens from "../../../asset/images/landinpage/merchantWebapp/OrderScreens.png";
import { IS_SMALLER_DEVICE } from "../../../utils/styleDefinitions";
import "./index.scss";
import AnimatedCard from "../../../component/AnimatedCard";
import { TypeAnimation } from "react-type-animation";

export default function HomeSection() {
   const slides = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, OrderScreens, OrderDetails];

   const [imageIndex, setimageIndex] = useState(0);

   const [activeCard, setActiveCard] = useState(0);

   useEffect(() => {
      const interval = setInterval(() => {
         setActiveCard((prevActiveCard) => (prevActiveCard + 1) % slides.length);
      }, 3000); // Change card every 1 second

      return () => clearInterval(interval);
   }, [slides.length]);

   const openInNewTab = (url) => {
      window.open(url, "_blank");
   };

   console.log(activeCard, "activeCard");

   return (
      <div className="flex lg:justify-center flex-col lg:pl-[5rem] lg:flex-row lg h-[100vh] lg:h-[80vh] items-center mt-4">
         <div data-aos="zoom-in-right">
            <h2
               style={{ textAlign: IS_SMALLER_DEVICE ? "center" : "left" }}
               className={`lg:text-[3.3rem] lg:w-[40rem] md:text-[2rem] md:w-[28rem]   text-[1.7rem]  font-bold`}>
               Enhance your restaurant's charm with a <span className="test">beautifully</span> <span className="test">designed menu</span>
            </h2>
            <div className="w-full flex items-center justify-center">
               <h3
                  style={{ textAlign: IS_SMALLER_DEVICE ? "center" : "left" }}
                  className="my-3 lg:w-full w-[80%] text-[#0F191A80] text-[1rem] font-bold">
                  Let your menu reflect the love and passion you have{" "}
                  <span>
                     <TypeAnimation
                        sequence={[
                           // Same substring at the start will only be typed out once, initially
                           "for novel diverse food",
                           1000, // wait 1s before replacing "Mice" with "Hamsters"
                           "for your valued customers",
                           1000,
                           "infused in every dish",
                           1000,
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ fontSize: "1rem", display: "inline-block", color: "green" }}
                        repeat={Infinity}
                     />
                  </span>
               </h3>
            </div>
            <div className="flex  items-center justify-center lg:justify-start lg:mt-5 mt-1 gap-2">
               <button
                  onClick={() => openInNewTab("/free-demo-form")}
                  className="border-[1px] border-solid border-[#000]  border-[#fff] px-3 py-2 rounded-[0.3rem]  bg-[#2D6B4D] text-[#fff] transition duration-300 ease-in-out">
                  Free demo <EastIcon style={{ fontSize: "1rem" }} />
               </button>
            </div>
         </div>

         <div className="lg:h-[100vh] lg:w-[60%]  w-full flex position-relative">
            {slides.map((item, index) => {
               return (
                  <AnimatedCard
                     key={index}
                     id={index}
                     activeCard={activeCard}
                     totalCards={slides.length}>
                     <div className={`w-full mt-[6rem] flex items-center justify-center overflow-hidden`}>
                        <div className={index === 8 || index === 9 ? "lg:h-[100%] lg:w-[80%] w-full h-full" : `h-[80%] lg:w-[35%] w-[60%]`}>
                           <img
                              src={item}
                              className="w-full h-full object-fit-contain"
                           />
                        </div>
                     </div>
                  </AnimatedCard>
               );
            })}
         </div>
      </div>
   );
}
