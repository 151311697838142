/** @format */

import { RootState } from "../store";

const orderSelectors = {
   getOrder: (state: RootState) => state.order,
   getOffers: (state: RootState) => state.order.offers,
   getOrderId: (state: RootState) => state.order.order_id,
   getIsOrderSummaryVisible: (state: RootState) => state.order.isOrderSummaryVisible,
   getloyaltyDetails: (state: RootState) => state.order.loyaltyDetails,
   getloyaltyPoints: (state: RootState) => state.order.loyaltyPoints,
   getAppliedCoupon: (state: RootState) => state.order.appliedCoupon,
   getLoyaltyUsage: (state: RootState) => state.order.loyaltyUsage,
};

export default orderSelectors;
