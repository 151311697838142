import React from "react";

const GoogleIcon = () => {
	return (
		<svg
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M26.7302 14.293C26.7302 13.383 26.6485 12.508 26.4968 11.668H14.4102V16.6321H21.3168C21.0193 18.2363 20.1152 19.5955 18.756 20.5055V23.7255H22.9035C25.3302 21.4913 26.7302 18.2013 26.7302 14.293Z"
				fill="#4285F4"
			/>
			<path
				d="M14.4097 26.8345C17.8747 26.8345 20.7797 25.6853 22.9031 23.7253L18.7556 20.5053C17.6064 21.2753 16.1364 21.7303 14.4097 21.7303C11.0672 21.7303 8.23807 19.4728 7.22891 16.4395H2.94141V19.7645C5.05307 23.9586 9.39307 26.8345 14.4097 26.8345Z"
				fill="#34A853"
			/>
			<path
				d="M7.23063 16.4399C6.97396 15.6699 6.82813 14.8474 6.82813 14.0016C6.82813 13.1558 6.97396 12.3333 7.23063 11.5633V8.23828H2.94313C2.04479 10.0266 1.57733 12.0003 1.57813 14.0016C1.57813 16.0724 2.07396 18.0324 2.94313 19.7649L7.23063 16.4399Z"
				fill="#FBBC05"
			/>
			<path
				d="M14.4097 6.27018C16.2939 6.27018 17.9856 6.91768 19.3156 8.18935L22.9964 4.50852C20.7739 2.43768 17.8689 1.16602 14.4097 1.16602C9.39307 1.16602 5.05307 4.04185 2.94141 8.23602L7.22891 11.561C8.23807 8.52768 11.0672 6.27018 14.4097 6.27018Z"
				fill="#EA4335"
			/>
		</svg>
	);
};

export default GoogleIcon;
