/** @format */

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

type Props = {
	header: any;
	details: any;
	type?: "normal";
};

export default function Component({ header, details, type }: Props) {
	return (
		<>
			{type === "normal" ? (
				<div className="w-[100%] ">
					<div className="w-[100%]">
						<div className="flex justify-between w-full items-center gap-1">
							<div className="add-on-items-text whitespace-nowrap">{header}</div>
							<div className="h-[1px] bg-[#E7E8E8] w-[100%]"></div>
						</div>
					</div>
					<div>{details}</div>
				</div>
			) : (
				<Accordion style={{ boxShadow: "none", border: "0px", padding: 0, margin: 0 }}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={{ boxShadow: "none", border: "0px", padding: 0, margin: 0 }}>
						{header}
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0, border: "0px", margin: 0, marginTop: "-1rem" }}>{details}</AccordionDetails>
				</Accordion>
			)}
		</>
	);
}
