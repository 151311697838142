/** @format */

import React from "react";
import Avatar from "@mui/material/Avatar";

export default function TestimonialCard() {
   return (
      <div
         style={{ border: "1px solid var(--text-25, rgba(15, 25, 26, 0.25))" }}
         className="rounded-[0.5rem] lg:w-[23rem] flex flex-col justify-around lg:h-[15rem] p-3 h-[16rem] w-[90%]">
         <div className="flex gap-3 items-center">
            <Avatar
               alt="Remy Sharp"
               src="/static/images/avatar/1.jpg"
            />
            <div>
               <h6 className="text-[1.2rem]">Rooban</h6>
               <h6 className="opacity-[0.5] text-[0.7rem]">Greyfeathers cafe</h6>
            </div>
         </div>
         <p>
            Is a natural part of life and one that marketers have been able to utilize far more since the internet and smartphones. art of life and one that marketers have been able to utilize far
            more since the internet and smartphones
         </p>
      </div>
   );
}
