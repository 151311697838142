import React from 'react';

function AnalyticsIcon() {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.999 1L6.33236 6.04L4.55458 3.16L0.999023 7'
        stroke='#2D6B4D'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.99902 1H10.999V3'
        stroke='#2D6B4D'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default AnalyticsIcon;
