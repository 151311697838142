import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { cartActions } from '../../store/cart/reducer';
import cartItemsSelector from '../../store/cart/selectors';
import './index.scss';

type Props = {
  item: any;
  filter: any;
  handleCategory?: any;
  setFilter: any;
  isSelected: boolean;
};

function CounterCard({ item, filter, setFilter, isSelected, handleCategory }: Props) {
  const [count, setCount] = useState(1);

  const handleAddToCart = (id: any) => {
    filter.categories.forEach((e: any, i: number) => {
      if (e.category_id === id) {
        setCount((p) => p + 1);
        e.count = count + 1;
      }
    });

    console.log('FILTER', filter);
  };

  const handleRemoveFromCart = (id: any) => {
    filter.categories.forEach((e: any, i: number) => {
      if (e.category_id === id) {
        if (count !== 1) {
          setCount((p) => p - 1);
          e.count = count;
        }
      }
    });
  };

  return (
    <div className='counter-card-main-container'>
      <div className='counter-card-name'>
        <input
          className='counter-card-checkbox'
          type='checkbox'
          checked={isSelected}
          onClick={() => {
            handleCategory(item);
          }}
        />
        {item?.category_name}
      </div>
      <div className='counter-card-counter'>
        <div
          className='counter-card-btn'
          onClick={() => {
            handleRemoveFromCart(item?.id);
          }}
        >
          -
        </div>
        <div className='counter-card-counts'>{count}</div>
        <div
          className='counter-card-btn'
          onClick={() => {
            handleAddToCart(item?.id);
          }}
        >
          +
        </div>
      </div>
    </div>
  );
}

export default CounterCard;
