/** @format */

import React from "react";
import ContentTitle from "../ContentTitle";
import TestimonialCard from "../../../component/TestimonialCard";

export default function Testimonials() {
   return (
      <div
         data-aos="fade-up"
         className="flex mb-[15rem] flex-col w-full  justify-center items-center">
         <ContentTitle title={"What our customers says?"} />
         <div className="w-full lg:flex-row flex-col flex gap-3 my-4 items-center justify-center">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
         </div>
      </div>
   );
}
