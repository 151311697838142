/** @format */

import { request } from "../axios";
import { SuccessResponse, ErrorResponse, ResponsePayload } from "../index.type";
import { Props } from "./models/getAllOffers.type";
import { ErrorResponseHandler } from "../index";

export const getAllOffers = async ({ merchantId, accessToken }: Props): Promise<SuccessResponse<any> | ErrorResponse> => {
   try {
      const response = await request<ResponsePayload<any>>({
         accessToken,
         api: `/offers/${merchantId}`,
         method: "GET",
      });

      const result = response.data;
      if (response.status != 200) {
         console.log("MID", merchantId);

         throw new Error(result.error.message);
      }
      return {
         statusCode: 200,
         message: "Success",
         payload: result,
         error: null,
      };
   } catch (error) {
      return ErrorResponseHandler(error);
   }
};
