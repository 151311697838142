import styled from "@emotion/styled";

interface TextProps {
  size?: string;
  color?: string;
  weight?: string;
  lHeight?: string;
  align?: string;
  mTop?: string;
  mBottom?: string;
  family?: string;
  letterSpacing?: string;
}

const Text = styled.div<TextProps>`
  ${(props) => `
    font-size: ${props.size ?? ""};
    font-weight: ${props.weight ?? ""};
    color: ${props.color ?? ""};
    text-align: ${props.align ?? ""};
    line-height: ${props.lHeight ?? ""};
    margin-top: ${props.mTop ?? ""};
    margin-bottom: ${props.mBottom ?? ""};
    letter-spacing : ${props.letterSpacing ?? ""};
    font-family : ${props.family ?? ""};
  `}
`;

export { Text };
