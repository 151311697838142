import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

interface ContainerProps {
  height?: string;
  isModalVisible?: boolean;
}

const Pop = keyframes`
from {bottom: -100%;}
  to {bottom: 0%;}
`;

const Container = styled.div<ContainerProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => (height ? height : "40%")};
  animation: ${Pop} 1s;
  box-shadow: 0px -8px 26px rgba(0, 0, 0, 0.3);
  border-radius: 24px 24px 0 0;

  background: white;

  z-index: 2;

  display: ${({ isModalVisible }) => (isModalVisible ? "flex" : null)};
  flex-direction: ${({ isModalVisible }) => (isModalVisible ? "column" : null)};
  justify-content: ${({ isModalVisible }) =>
    isModalVisible ? "center" : null};
  align-items: ${({ isModalVisible }) => (isModalVisible ? "center" : null)};
  padding: ${({ isModalVisible }) => (isModalVisible ? "1rem 0 1rem 0" : null)};
  gap: ${({ isModalVisible }) => (isModalVisible ? "0.7rem" : null)};
`;

export default Container;
