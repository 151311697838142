const findItems = (data: any[], id: string | undefined) => {
  let result: unknown = [];

  if (id) {
    data?.forEach((item) => {
      if (item.id === id) {
        result = item;
      }
    });
  }

  return result;
};

export { findItems };
