import React from "react";

const CorrectCircle = () => {
  return (
    <svg
      width="144"
      height="144"
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.0002 0.332031C32.5118 0.332031 0.333496 32.5104 0.333496 71.9987C0.333496 111.487 32.5118 143.665 72.0002 143.665C111.488 143.665 143.667 111.487 143.667 71.9987C143.667 32.5104 111.488 0.332031 72.0002 0.332031ZM106.257 55.5154L65.6218 96.1504C64.6185 97.1537 63.2568 97.727 61.8235 97.727C60.3902 97.727 59.0285 97.1537 58.0252 96.1504L37.7435 75.8687C35.6652 73.7904 35.6652 70.3504 37.7435 68.272C39.8218 66.1937 43.2618 66.1937 45.3402 68.272L61.8235 84.7554L98.6602 47.9187C100.738 45.8404 104.178 45.8404 106.257 47.9187C108.335 49.997 108.335 53.3654 106.257 55.5154Z"
        fill="#2FBF71"
      />
    </svg>
  );
};

export default CorrectCircle;
