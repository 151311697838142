import { request } from '../axios';
import {
  SuccessResponse,
  ErrorResponse,
  OrderResponsePayload,
} from '../index.type';
import { PayloadProps, Props } from './models/createOrder.type';
import { ErrorResponseHandler } from '../index';

const createOrder = async (
  order: Props,
  accessToken: string
): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  console.log('CREATE ORDER API CALL', order);

  try {
    const response = await request<OrderResponsePayload<PayloadProps>>({
      accessToken,
      api: `/customer/order/`,
      method: 'POST',
      data: order,
    });

    const result = response.data;

    if (response.request.status > 300) {
      console.log('CREATE ORDER FAILED', result);

      return {
        statusCode: response.request.status,
        message: 'Failed',
        payload: JSON.parse(response.request.response),
        error: null,
      };
    } else {
      return {
        statusCode: 200,
        message: 'Success',
        payload: result,
        error: null,
      };
    }
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default createOrder;
