import React from "react";

function LogoutIcon() {
	return (
		<svg
			width="19"
			height="20"
			viewBox="0 0 19 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H9C9.79565 20 10.5587 19.6839 11.1213 19.1213C11.6839 18.5587 12 17.7956 12 17V3C12 2.20435 11.6839 1.44129 11.1213 0.87868C10.5587 0.316071 9.79565 0 9 0H3ZM13.293 5.293C13.4805 5.10553 13.7348 5.00021 14 5.00021C14.2652 5.00021 14.5195 5.10553 14.707 5.293L18.707 9.293C18.8945 9.48053 18.9998 9.73483 18.9998 10C18.9998 10.2652 18.8945 10.5195 18.707 10.707L14.707 14.707C14.5184 14.8892 14.2658 14.99 14.0036 14.9877C13.7414 14.9854 13.4906 14.8802 13.3052 14.6948C13.1198 14.5094 13.0146 14.2586 13.0123 13.9964C13.01 13.7342 13.1108 13.4816 13.293 13.293L15.586 11H7C6.73478 11 6.48043 10.8946 6.29289 10.7071C6.10536 10.5196 6 10.2652 6 10C6 9.73478 6.10536 9.48043 6.29289 9.29289C6.48043 9.10536 6.73478 9 7 9H15.586L13.293 6.707C13.1055 6.51947 13.0002 6.26516 13.0002 6C13.0002 5.73484 13.1055 5.48053 13.293 5.293Z"
				fill="#D90429"
			/>
		</svg>
	);
}

export default LogoutIcon;
