/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosInstance,
  CancelToken,
} from "axios";
import { GET, OtherThanGetRequest, POST } from "../index.type";
import APIURL from "../../lib/ApiUrl";

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create();

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );

  instance.interceptors.request.use((requestInstance: any) => {
    requestInstance.headers["device-type"] = "WEB";
    requestInstance.headers["device-env"] =
      process.env.REACT_APP_NODE_ENV === "development" ? "test" : "production";

    return requestInstance;
  });

  return instance;
};

interface GetRequestProps {
  method: GET;
  api: string;
  accessToken?: string | null;
  cancelToken?: CancelToken;
}

interface OtherThanGetRequestProps {
  method: OtherThanGetRequest;
  api: string;
  data?: AxiosRequestConfig["data"];
  accessToken?: string;
  headers?: AxiosRequestConfig["headers"];
  cancelToken?: CancelToken;
}

type Props = GetRequestProps | OtherThanGetRequestProps;

export function request<P>(props: Props): AxiosPromise<P> {
  const axiosRequest = axiosInstance();

  const isAxiosTokenRequired = props.accessToken === undefined;

  let getHeaders = {};

  if (props.accessToken) {
    getHeaders = {
      Authorization: `Bearer ${props.accessToken}`,
    };
  }
  if (props.method === "GET") {
    return axiosRequest(`${APIURL}${props.api}`, {
      method: 'get',
      headers: {
        ...getHeaders,
        'ngrok-skip-browser-warning': 'any',
      },
    });
  }

  const headers: any = {
    Accept: '*',
    headers: {
      ...getHeaders,
      'ngrok-skip-browser-warning': 'any',
    },
  };

  if (!isAxiosTokenRequired) {
    headers["Authorization"] = `Bearer ${props.accessToken}`;
  }

  return axiosRequest(`${APIURL}${props.api}`, {
    data: props.data,
    method: props.method,
    headers: {
      ...headers,
    },
  });
}

export default AxiosInstance;
