import EmptyGif from '../../asset/emptyCart.gif';
import './index.css';
const EmptyCart = () => {
  return (
    <div className='EmptyCartContainer'>
      <div className='EmptyCartWrapper'>
        <img className='EmptyCartGif' src={EmptyGif} alt='' />
      </div>
      <div className='EmptyCartText'>Your plate is empty</div>
      <div className='EmptyCartText'>Add items to fill it up</div>
    </div>
  );
};

export default EmptyCart;
