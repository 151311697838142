import styled from "@emotion/styled";

const Content = styled.div`
  font-family: system-ui;
  font-style: normal;
  font-weight: 400;
  font-size: 24.4px;
  line-height: 32px;
  letter-spacing: -0.3px;
  color: #0f191a;
  width: 75%;
  text-align: center;
`;

const SubContainer = styled.div``;

const HeaderText = styled.div``;

const ContentText = styled.div``;

export { Content, SubContainer, HeaderText, ContentText };
