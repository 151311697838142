import React, { useState, useEffect } from 'react';
import './index.scss';
import DownArrowIcon from '../../asset/icons/DownArrowIcon';
import UpArrowIcon from '../../asset/icons/UpArrowIcon';
import SelectedCategoryChip from '../SelectedCategoryChip';

type Props = {
  data: Array<any>;
  selectedCat: any;
  handleCategory: any;
  selectedCategories: any;
  categoriesBasedItemType: any;
};

const SelectCategory = ({
  data,
  selectedCat,
  handleCategory,
  selectedCategories,
  categoriesBasedItemType,
}: Props) => {
  const [dropdown, setdropdown] = useState<boolean>(false);
  const [renderDropDownData, setrenderDropDownData] = useState<any>([]);
  const isMaxCardSelected = () => selectedCategories.length === 5;

  useEffect(() => {
    setrenderDropDownData([]);
    if (categoriesBasedItemType.length > 0) {
      let result = [];
      for (const key in data) {
        //@ts-ignore
        if (categoriesBasedItemType.includes(data[key].id)) {
          //@ts-ignore
          result.push(data[key]);
        }
      }
      //@ts-ignore
      setrenderDropDownData(result);
    } else {
      //@ts-ignore
      setrenderDropDownData(data);
    }
  }, [categoriesBasedItemType]);

  console.log(categoriesBasedItemType, 'SelectCategory', data);

  console.log(renderDropDownData, 'SelectCategory renderDropDownData');

  return (
    <>
      <div
        className='select-category-main-container'
        onClick={() => {
          setdropdown((p) => !p);
        }}>
        <div className='select-category-placeholder'>
          {selectedCat?.length === 0
            ? 'Choose'
            : selectedCat?.map((item: any) => (
                <SelectedCategoryChip
                  handleCategory={handleCategory}
                  item={item}
                />
              ))}
        </div>
        <div
          className='select-category-arrow-btn'
          onClick={() => {
            setdropdown((p) => !p);
          }}>
          {dropdown ? <UpArrowIcon /> : <DownArrowIcon />}
        </div>
      </div>
      {dropdown ? (
        <div className='select-category-dropdown-container'>
          {renderDropDownData?.map((item: any, index: any) => (
            <div
              onClick={() => {
                if (isMaxCardSelected()) {
                  if (selectedCat?.includes(item)) {
                    handleCategory(item);
                  }
                } else {
                  handleCategory(item);
                }
              }}
              style={{
                opacity: selectedCat?.includes(item)
                  ? 1
                  : isMaxCardSelected()
                  ? 0.5
                  : 1,
              }}
              className='select-category-checkbox-wrapper'
              key={index}>
              <input
                type='checkbox'
                className='select-category-checkbox'
                value={item?.category_name}
                onChange={() => {}}
                checked={selectedCat?.includes(item)}
              />
              <div className='select-category-checkbox-label'>
                {item?.category_name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SelectCategory;
