/** @format */

import BackArrowIcon from "../../../asset/icons/BackArrowIcon";
import { Header } from "../../../pages/CheckoutPage/styles";
import ButtonWithArrow from "../../Button";
import { Text } from "../../Text";
import EmptySvg from "./EmptySvg";
import { Content } from "./styles";
function EmptyCart() {
   return (
      <div
         style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
         }}>
         <Header style={{ width: "90%" }}>
            <span>
               <BackArrowIcon />
            </span>
            <Text
               style={{
                  width: "calc(100% - 6rem)",
               }}
               size="1.25rem"
               color="#0F191A"
               lHeight="1.33"
               align="center"
               weight="700">
               Item Details
            </Text>
         </Header>
         <div>
            <div
               style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
               }}>
               <EmptySvg />
               <Content>You do not have any orders in your cart.</Content>
            </div>
         </div>
         <div
            style={{
               width: "90%",
               marginBottom: "1rem",
            }}>
            <ButtonWithArrow
               disabled={false}
               background="#0F6656"
               fontColor="white"
               buttonText={"Browse Items"}
               showArrow={true}
            />
         </div>
      </div>
   );
}

export default EmptyCart;
