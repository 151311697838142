import React from 'react';

function FrontArrowIcon() {
  return (
    <svg
      width='19'
      height='16'
      style={{ marginRight: '10px' }}
      viewBox='0 0 19 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.1836 4.3916L15.6781 8.04099L11.1836 11.6904'
        stroke='white'
        stroke-width='1.6'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.08594 8.04102H15.5476'
        stroke='white'
        stroke-width='1.6'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default FrontArrowIcon;
