/** @format */

import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonWithArrow from "../../component/Button";
import PageHeader from "../../component/PageHeader";
import { Container, SubContainer } from "./styles";
import { useAppSelector } from "../../store/hooks";
import qrSelectors from "../../store/Qr/selectors";
import { useParams } from "react-router-dom";

const ConfirmationPage = () => {
   const { mid, qid } = useParams();
   const navigate = useNavigate();
   return (
      <Container>
         <PageHeader
            backLink={`/${mid}`}
            background="white"
            headerText="Confirmation"
            textColor="black"
         />

         <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
               height: "calc(100vh - 5rem)",
               width: "90%",
               margin: "0 auto",
            }}>
            <SubContainer>We have accepted your order and notified the restaurant. Someone from the restaurant will come to confirm your order</SubContainer>

            <ButtonWithArrow
               background="#0F6656"
               buttonText="Okay! Go home"
               fontColor="white"
               onClickHandler={() => navigate(`/${mid}`)}
            />
         </Box>
      </Container>
   );
};

export default ConfirmationPage;
