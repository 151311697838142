import React from "react";

function ForwardArrow() {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.50016 12.834L7.3335 7.00065L1.50016 1.16732"
        stroke="#0F191A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ForwardArrow;
