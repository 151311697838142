/** @format */

import React from "react";

function loginIcon() {
   return (
      <svg
         width="40"
         height="40"
         viewBox="0 0 40 40"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
         <circle
            cx="20"
            cy="20"
            r="20"
            fill="#0F6656"
            fill-opacity="0.1"
         />
         <path
            d="M17.417 16.2992C17.6753 13.2992 19.217 12.0742 22.592 12.0742H22.7003C26.4253 12.0742 27.917 13.5659 27.917 17.2909V22.7242C27.917 26.4492 26.4253 27.9409 22.7003 27.9409H22.592C19.242 27.9409 17.7003 26.7326 17.4253 23.7826"
            stroke="#0F6656"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M11.6665 20H22.3998"
            stroke="#0F6656"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M20.542 17.209L23.3337 20.0007L20.542 22.7923"
            stroke="#0F6656"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   );
}

export default loginIcon;
