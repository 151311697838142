/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";

export interface Qr {
   qid: string;
   mid: string;
   merchantDetails: any;
}

const initialState: Qr = {
   qid: "",
   mid: "",
   merchantDetails: {},
};

export const QrSlice = createSlice({
   name: "qr",
   initialState: initialState,
   reducers: {
      addQr: (state: any, action: any) => {
         if (action.payload.qid || action.payload.mid) {
            state.qid = action.payload.qid;
            state.mid = action.payload.mid;
         }
      },
      setMerchantDetails: (state: any, action: any) => {
         state.merchantDetails = action.payload;
      },
   },
});

export const QrActions = QrSlice.actions;
