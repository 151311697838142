/** @format */

import styled from "@emotion/styled";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   background-color: #0f6656;

   min-height: calc(100vh - 106px);

   padding-right: 0.75rem;
   padding-left: 0.75rem;
   padding-bottom: 1.25rem;
`;

const TextContainer = styled.div`
   display: flex;

   align-items: center;
   gap: 0.5rem;
`;

const Line = styled.div`
   height: 1px;
   background: white;
   width: 100%;
`;

const AddNewAddressContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`;

const AddNewDetailButton = styled.button`
   display: flex;
   align-items: center;

   background: #0f6656;
   border: none;
   font-size: 1.5rem;

   line-height: 2rem;
   font-weight: 400;

   padding: 0;
   margin-top: 2rem;

   color: white;
`;

export { Line, Container, AddNewDetailButton, TextContainer, AddNewAddressContainer };
