import React from "react";

type Props = {};

function DrawerIcon({}: Props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3 6H12"
				stroke="#0F191A"
				stroke-width="2.5"
				stroke-linecap="round"
			/>
			<path
				d="M3 12H21"
				stroke="#0F191A"
				stroke-width="2.5"
				stroke-linecap="round"
			/>
			<path
				d="M12 18L21 18"
				stroke="#0F191A"
				stroke-width="2.5"
				stroke-linecap="round"
			/>
		</svg>
	);
}

export default DrawerIcon;
