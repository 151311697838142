/** @format */

import React from "react";

const DiscountRect = () => {
   return (
      <svg
         width="65"
         height="28"
         viewBox="0 0 65 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
         <path
            d="M0 0H52.7647L65 14L52.7647 28H0V0Z"
            fill="#0F6656"
         />
      </svg>
   );
};

export default DiscountRect;
