/** @format */

import React, { useState } from "react";
import OrderItemsRow from "./OrderItemsRow";
import { ReactComponent as ArrowForward } from "../../asset/svg/OrderSummary/ArrowForward.svg";
import { useNavigate, useParams } from "react-router-dom";
import OrderStatusTag from "../../component/OrderSummary/OrderStatusTag";
import OrderDividerTitle from "./OrderDividerTitle";
import showMenuSelector from "../../store/showMenu/selectors";
import { useAppSelector } from "../../store/hooks";

export default function SubOrdercCard({ orderDetails }) {
   const [showAllItems, setShowAllItems] = useState(false);
   const navigate = useNavigate();
   const { customer_id, mid, qid, orderId } = useParams();
   const { currency } = useAppSelector(showMenuSelector.showMenu);

   const CGST = orderDetails?.cgst_percentage;
   const SGST = orderDetails?.sgst_percentage;

   const formatDate = (dateString) => {
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.toLocaleDateString("en-US", {
         month: "short",
         day: "numeric",
         year: "numeric",
      });
      const formattedTime = dateObject.toLocaleTimeString("en-US", {
         hour: "numeric",
         minute: "numeric",
         hour12: true,
      });
      const finalFormattedDateTime = `${formattedDate} - ${formattedTime}`;
      return finalFormattedDateTime;
   };

   const handleViewFullDetails = (subOrderItems) => {
      console.log("suborder ID:", subOrderItems);
      navigate(`/${mid}/${qid}/orderdetails/${customer_id}/${orderId}/${subOrderItems}`);
   };

   return (
      <div>
         {Array.from(orderDetails?.items_ordered)
            ?.reverse()
            .map((subOrder, index) => (
               <>
                  <OrderDividerTitle title={"Sub order"} />
                  <div
                     className="px-2 pb-3"
                     key={index}>
                     <div className="border-2 rounded-xl flex flex-col pt-2">
                        <div className="flex justify-between items-center border-b px-2 pb-2">
                           <div>
                              <p className="text-base font-extrabold font-custom">Sub order ID #{subOrder?.suborder_id}</p>
                              <p className="text-xs tracking-tight text-[#ACAFB0] font-custom">{`${subOrder?.suborder_items_ordered?.length} items, ${formatDate(subOrder?.created_on)}`}</p>
                           </div>

                           <OrderStatusTag
                              key={subOrder?.suborder_id}
                              type={subOrder?.suborder_status}
                           />
                        </div>
                        <div className="p-2">
                           {subOrder?.suborder_items_ordered?.slice(0, showAllItems ? undefined : 3).map((order, orderIndex) => (
                              <React.Fragment key={orderIndex}>
                                 <OrderItemsRow
                                    key={orderIndex}
                                    itemDetails={order}
                                 />
                              </React.Fragment>
                           ))}
                        </div>
                        {subOrder?.suborder_items_ordered?.length > 3 && (
                           <p
                              className="text-[#ACAFB0] text-xs px-2 mb-2 cursor-pointer font-custom"
                              onClick={() => setShowAllItems(!showAllItems)}>
                              {showAllItems ? "Show less" : `+${subOrder?.suborder_items_ordered?.length - 3} more items`}
                           </p>
                        )}
                        <div className="border-b-2 border-dashed"></div>
                        <div className="px-2 ">
                           <div className="flex justify-between items-center mt-2 mb-1">
                              <span className="text-sm font-custom">Sub total</span>
                              <span className="text-sm font-custom">
                                 {currency}
                                 {subOrder?.total_suborder_amount}
                              </span>
                           </div>
                           {subOrder?.offers_discount_amount ? (
                              <div className="flex justify-between items-center mb-1">
                                 <span className="text-sm font-custom">Offer price</span>
                                 <span className="text-sm font-custom text-[#2FBF71]">
                                    -{currency}
                                    {subOrder?.offers_discount_amount}
                                 </span>
                              </div>
                           ) : (
                              ""
                           )}
                           {subOrder?.loyalty_discount_amount ? (
                              <div className="flex justify-between items-center mb-1">
                                 <span className="text-sm font-custom">Loyalty points</span>
                                 <span className="text-sm font-custom text-[#2FBF71]">
                                    -{currency}
                                    {subOrder?.loyalty_discount_amount}
                                 </span>
                              </div>
                           ) : (
                              ""
                           )}
                           <div className="flex justify-between items-center mb-1">
                              <span className="text-sm font-custom">CGST</span>
                              <span className="text-sm font-custom text-[#2FBF71]">{CGST}%</span>
                           </div>
                           <div className="flex justify-between items-center mb-1">
                              <span className="text-sm font-custom">SGST</span>
                              <span className="text-sm font-custom text-[#2FBF71]">{SGST}%</span>
                           </div>
                           <div className="flex justify-between items-center pb-2 border-b">
                              <span className="text-base font-semibold font-custom">Your order's total</span>
                              <span className="text-base font-semibold font-custom">
                                 {currency}
                                 {subOrder?.total_suborder_amount_offers_applied}
                              </span>
                           </div>
                        </div>
                        <button
                           className="flex items-center justify-center font-custom cursor-pointer gap-2 py-3 text-[#0F6656] text-base font-semibold"
                           onClick={() => handleViewFullDetails(subOrder?.suborder_id)}>
                           <p>View full details</p> <ArrowForward />
                        </button>
                     </div>
                  </div>
               </>
            ))}
      </div>
   );
}
