/** @format */

import HomeSection from "./HomeSection";
import Navbar from "./Navbar";
import Features from "./Features";
import AOS from "aos";
import { useEffect } from "react";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import Footer from "../../component/Footer";
import EastIcon from "@mui/icons-material/East";
import ConvertToMenuCard from "./ConvertToMenuCard";
import { IS_SMALLER_DEVICE } from "../../utils/styleDefinitions";
import DemoVideo from "./DemoVideo";

export default function PreHomepage() {
   useEffect(() => {
      AOS.init({
         duration: 2000, // values from 0 to 3000, with step 50ms
         once: true, // whether animation should happen only once - while scrolling down
      });
   }, []);

   const showConversionCard = JSON.parse(process.env.REACT_APP_SHOW_MENU_CONVERSION_CARD_ON_LANDING_PAGE);
   const showUserTestimonial = JSON.parse(process.env.REACT_APP_SHOW_USER_TESTIMONIALS_ON_LANDING_PAGE);
   const showDemoVideo = JSON.parse(process.env.REACT_APP_SHOW_DEMO_VIDEO_ON_LANDING_PAGE);

   const openInNewTab = (url) => {
      window.open(url, "_blank");
   };

   return (
      <div>
         <Navbar />
         <div
            id="home"
            data-aos="fade-up">
            <HomeSection />
         </div>
         {showDemoVideo && <DemoVideo />}
         <Features />
         <Pricing />
         {showConversionCard && <ConvertToMenuCard />}
         {showUserTestimonial && <Testimonials />}
         <div className="flex w-full mt-[5rem] mb-[5rem] flex-col gap-3 items-center justify-center">
            <h2 className="lg:text-[3.2rem] font-bold lg:w-full w-[60%] text-center text-[1.5rem]">
               Ready to <span className="test">transform</span> <span className="test">your restaurant ?</span>
            </h2>
            <button
               style={{ paddingBlock: IS_SMALLER_DEVICE ? "0.7rem" : "0.8rem", paddingInline: IS_SMALLER_DEVICE ? "1rem" : "3rem" }}
               onClick={() => openInNewTab("/free-demo-form")}
               className="border-[1px] border-solid border-[#000]  border-[#fff] rounded-[0.7rem] text-[1.2rem]  bg-[#2D6B4D] text-[#fff] transition duration-300 ease-in-out">
               Contact us for free demo <EastIcon style={{ fontSize: "1rem" }} />
            </button>
         </div>
         <Footer />
      </div>
   );
}
