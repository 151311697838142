import React from "react";

const DoubleArrow = () => {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.2127 6.42071L8.83104 0.920708C8.75539 0.826542 8.66188 0.748254 8.55588 0.690345C8.44988 0.632435 8.33348 0.596046 8.21337 0.583269C8.09326 0.570491 7.97181 0.581577 7.856 0.615889C7.74019 0.650202 7.6323 0.707064 7.53854 0.783208C7.44437 0.858853 7.36609 0.952364 7.30818 1.05836C7.25027 1.16436 7.21388 1.28076 7.2011 1.40087C7.18832 1.52098 7.19941 1.64243 7.23372 1.75824C7.26803 1.87406 7.3249 1.98194 7.40104 2.07571L11.3152 6.99821L7.20854 11.9207C7.13135 12.0133 7.0732 12.1203 7.03741 12.2354C7.00162 12.3506 6.9889 12.4716 6.99997 12.5917C7.01103 12.7117 7.04568 12.8284 7.10192 12.9351C7.15816 13.0417 7.23489 13.1363 7.32771 13.2132C7.49388 13.3466 7.70132 13.4179 7.91437 13.4149C8.04904 13.4151 8.1821 13.3856 8.3041 13.3286C8.42609 13.2716 8.53402 13.1884 8.62021 13.0849L13.2035 7.58487C13.3397 7.42192 13.415 7.21676 13.4167 7.00443C13.4183 6.79211 13.3462 6.58579 13.2127 6.42071Z"
				fill="white"
			/>
			<path
				d="M2.41307 0.922034C2.25991 0.726327 2.03528 0.599478 1.78859 0.569395C1.5419 0.539311 1.29336 0.608455 1.09766 0.761618C0.901948 0.91478 0.775099 1.13941 0.745015 1.3861C0.714931 1.63279 0.784076 1.88133 0.937239 2.07703L4.89724 6.99953L0.790572 11.9129C0.713387 12.0055 0.655234 12.1124 0.619443 12.2276C0.583652 12.3427 0.570927 12.4638 0.581996 12.5838C0.593066 12.7039 0.627713 12.8206 0.683952 12.9273C0.740191 13.0339 0.816919 13.1284 0.909739 13.2054C1.07466 13.342 1.28222 13.4166 1.49641 13.4162C1.63107 13.4164 1.76414 13.387 1.88613 13.3299C2.00812 13.2729 2.11605 13.1897 2.20224 13.0862L6.78557 7.5862C6.9204 7.42218 6.9941 7.21644 6.9941 7.00412C6.9941 6.7918 6.9204 6.58606 6.78557 6.42203L2.41307 0.922034Z"
				fill="white"
			/>
		</svg>
	);
};

export default DoubleArrow;
