/** @format */

import { RootState } from "../store";

const addMoreItemsOnCartSelector = {
   getAddMoreItemsOnCart: (state: RootState) => state.addMoreItemOnCart,
   getAddMoreItem: (item_id: string) => (state: RootState) => {
      const addMoreItemOnCart = state.addMoreItemOnCart;
      const temp = addMoreItemOnCart.addedItems.filter((item: any) => item.item_id === item_id)[0];
      return temp;
   },
   isItemConfirmedAldready: (item_id: string) => (state: RootState) => {
      const addMoreItemOnCart = state.addMoreItemOnCart;
      const temp = addMoreItemOnCart.confirmedItems.findIndex((item: any) => item.item_id === item_id);
      return temp === -1 ? false : true;
   },
   canReduceItem: (item_id: string) => (state: RootState) => {
      const addMoreItemOnCart = state.addMoreItemOnCart;
      const itemIndex = addMoreItemOnCart.confirmedItems.findIndex((item: any) => item.item_id === item_id);
      let confirmedCount = 0;
      if (itemIndex !== -1) {
         confirmedCount = addMoreItemOnCart.confirmedItems[itemIndex].count;
      }
      const addedItemIndex = addMoreItemOnCart.addedItems.findIndex((item: any) => item.item_id === item_id);
      let addedItemCount = 0;
      if (addedItemIndex !== -1) {
         addedItemCount = addMoreItemOnCart.addedItems[addedItemIndex].count;
      } else {
         return true;
      }
      if (addedItemCount > confirmedCount) {
         return true;
      } else {
         return false;
      }
   },
};

export default addMoreItemsOnCartSelector;
