import styled from "@emotion/styled";

const Container = styled.div``;

const SubContainer = styled.div`
  width: 90%;

  text-align: center;
  margin: 50% auto;
  font-weight: 500;

  display: flex;
  align-items: center;
`;

export { Container, SubContainer };
