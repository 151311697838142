import React from "react";

const YoutubeIcon = () => {
	return (
		<svg
			width="34"
			height="25"
			viewBox="0 0 35 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M33.8776 3.74572C33.4846 2.2705 32.3265 1.10882 30.8557 0.714563C28.1901 -0.00195312 17.5011 -0.00195312 17.5011 -0.00195312C17.5011 -0.00195312 6.81212 -0.00195312 4.14644 0.714563C2.67579 1.10882 1.51748 2.2705 1.12443 3.74572C0.410156 6.41946 0.410156 11.998 0.410156 11.998C0.410156 11.998 0.410156 17.5766 1.12443 20.2505C1.51748 21.7256 2.67579 22.8873 4.14644 23.2817C6.81212 23.998 17.5011 23.998 17.5011 23.998C17.5011 23.998 28.1901 23.998 30.8557 23.2817C32.3265 22.8873 33.4846 21.7256 33.8776 20.2505C34.592 17.5766 34.592 11.998 34.592 11.998C34.592 11.998 34.592 6.41946 33.8776 3.74572Z"
				fill="#ED1D24"
			/>
			<path
				d="M14.0039 17.0625L22.9378 11.9978L14.0039 6.93266V17.0625Z"
				fill="white"
			/>
		</svg>
	);
};

export default YoutubeIcon;
