/** @format */

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate, useParams } from "react-router";
import createOrder from "../../api/order/createOrder";
import updateOrderAPI from "../../api/order/updateOrder";
import OrderPlaced from "../../component/OrderPlaced";
import { addMoreItemOnCartActions } from "../../store/addMoreItemOnOrder/reducer";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import { cartActions } from "../../store/cart/reducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import showMenuSelector from "../../store/showMenu/selectors";
import { userActions } from "../../store/user/reducer";
import userSelectors from "../../store/user/selectors";
import "./index.scss";

const items = [1, 2, 3, 4, 5, 6, 7, 8, 83, 3, 3];

const HEIGHT = window.innerHeight;

export default function Index() {
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useAppDispatch();
   const [errorMessage, setErrorMessage] = useState("");
   const [orderConfirmationModal, setorderConfirmationModal] = useState(false);
   const [warningModal, setWarningModal] = useState(false);
   const [warningBanner, setWarningBanner] = useState(false);
   const [showOrderExceedingModal, setshowOrderExceedingModal] = useState(false);
   const { orderDetails } = location.state;
   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
   const { latitude, longitude } = useAppSelector(userSelectors.getUserDetails);
   const [loading, setloading] = useState(false);
   const Razorpay = useRazorpay();
   const { mid, qid } = useParams();
   const [showDineInPaymentModal, setshowDineInPaymentModal] = useState(false);
   const [orderType, setorderType] = useState(orderDetails?.order_type);
   console.log("ORDER TYPE ", orderDetails);
   const [NotificationPermission, setNotificationPermission] = useState(false);
   const BASE_URL = qid !== undefined ? `/${mid}/${qid}` : `/${mid}`;
   const { addMoreItemFlow, orderId } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);
   const checkMenu = useAppSelector(showMenuSelector.showMenu);

   const getLocationAccess = () => {
      if (navigator.geolocation) {
         navigator.permissions.query({ name: "geolocation" }).then(function (result) {
            if (result.state === "granted") {
               console.log(result.state);
               navigator.geolocation.getCurrentPosition((position) => {
                  dispatch(
                     userActions.getLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                     }),
                  );
               });
            } else if (result.state === "prompt") {
               console.log(result.state);
               navigator.geolocation.getCurrentPosition((position) => {
                  dispatch(
                     userActions.getLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                     }),
                  );
               });
            } else if (result.state === "denied") {
               setWarningBanner(true);
            }
         });
      } else {
         setWarningModal(true);
         setWarningBanner(true);
      }
   };

   useEffect(async () => {
      setorderConfirmationModal(false);
      if (NotificationPermission === true) {
         getLocationAccess();
      }
   }, [NotificationPermission]);

   useEffect(() => {
      setTimeout(async () => {
         await creatingOrder();
      }, 3000);
   }, []);

   console.log("ORDER TYPE ", orderDetails);
   const creatingOrder = async (dineInPayment) => {
      let createOrderPayload = { ...orderDetails, order_type: orderType };
      console.log(" creatingOrder ", createOrderPayload);
      createOrderPayload["qr_code_id"] = "";
      if (orderType === "Take away") {
         delete createOrderPayload["address"];
      } else if (orderType === "Dine-in") {
         delete createOrderPayload["address"];
         createOrderPayload["qr_code_id"] = qid !== undefined ? qid : "standalone";
      }

      setloading(true);
      console.log(createOrderPayload, "createOrderPayload");
      try {
         let result = null;
         if (addMoreItemFlow) {
            let payload = { ...createOrderPayload, order_id: orderId, payment_order_id: "" };
            result = await updateOrderAPI(payload, accessToken);
         } else {
            result = await createOrder(
               {
                  ...createOrderPayload,
                  latitude: latitude,
                  longitude: longitude,
               },
               accessToken,
            );
         }
         console.log(result, "result create order");
         setloading(false);
         if (result.statusCode === 200 || result.statusCode === 201) {
            addMoreItemFlow ? dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow({})) : dispatch(cartActions.clearCart(orderDetails?.merchant_id));
            if (orderType === "Dine-in") {
               if (qid !== undefined) {
                  navigate(`/${mid}/${qid}/order_summary/${result.payload.customer_id}/${result.payload.order_id}`, {
                     state: {
                        fromDinein: true,
                        fromOrderFlow: true,
                     },
                  });
               } else {
                  navigate(`/${mid}/order_summary/${result.payload.customer_id}/${result.payload.order_id}`, {
                     state: {
                        fromOrderFlow: true,
                     },
                  });
               }
            } else {
               if (qid !== undefined) {
                  navigate(`/${orderDetails?.merchant_id}/${qid}`);
               } else {
                  navigate(`/${orderDetails?.merchant_id}`);
               }
               openPaymentGateway(result);
            }
            addMoreItemFlow ? dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow) : dispatch(cartActions.clearCart(orderDetails?.merchant_id));
         } else {
            if (result.payload.detail.reason === "unavailable_items") {
               setshowOrderExceedingModal(true);
               // dispatch( cartActions.updateOutOfStock( { merchant_id: mid, unavailable_items: result.payload.detail.unavailable_items } ) );
            } else if (result.payload.detail.type === "lat_long_missing_error") {
               toast.error(result.payload.detail.message);
               setNotificationPermission(true);
            } else if (result.payload.detail.type === "unservicable_error") {
               setErrorMessage(result.payload.detail?.message);
               setWarningModal(true);
            }
            console.log("Something went wrong", result);
         }
      } catch (error) {
         setloading(false);
         toast.error("We're sorry, we were unable to update your order, please try again");
      }
   };

   const openPaymentGateway = async (createApiResponse) => {
      console.log("openPaymentGateway calling ..", createApiResponse.payload);
      const amount = createApiResponse.payload.amount_after_taxes;
      const amount_paid = createApiResponse.payload.amount_after_taxes;
      const options = {
         key: process.env.REACT_APP_RAZORPAY_KEY_ID,
         amount: (amount * 100).toString(),

         ...createApiResponse.payload.payment_gateway_details,
         handler: async (response) => {
            let updateOrderPayload = {
               ...createApiResponse.payload,
               payment_order_id: response.razorpay_payment_id,
               payment_status: "paid",
               extra_payment: false,
               extra_payment_amount: 0,
               amount_paid: amount_paid,
               payment_made_through_payment_gateway: true,
            };
            const updateOrderResponse = await updateOrderAPI(updateOrderPayload, accessToken);
            console.log(" updating payment status updateOrderPayload", updateOrderPayload);
            if (updateOrderResponse.statusCode === 200) {
               if (qid !== undefined) {
                  navigate(`/${mid}/${qid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`, {
                     state: {
                        fromPayment: true,
                        fromOrderFlow: true,
                     },
                  });
               } else {
                  navigate(`/${mid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`, {
                     state: {
                        fromPayment: true,
                        fromOrderFlow: true,
                     },
                  });
               }
               addMoreItemFlow ? dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow({})) : dispatch(cartActions.clearCart(orderDetails?.merchant_id));
            }
         },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
         dispatch(cartActions.clearCart(orderDetails?.merchant_id));
      });

      rzp1.open();
   };

   const updateOrderType = (value) => {
      setorderType(value);
      if (value === "Delivery") {
         if (CheckIfAddressExist()) {
            navigate(`${BASE_URL}/address`, { state: { orderDetails: orderDetails } });
         }
      }
      setorderConfirmationModal(false);
   };

   const CheckIfAddressExist = () => {
      if (orderDetails?.address.flat_no !== undefined) {
         return false;
      }
      return true;
   };

   const totalItemsCount = (order) => {
      console.log(order, "order");
      let Count = 0;
      for (let i = 0; i < order?.items_ordered?.length; i++) {
         if (order.items_ordered[i]?.suborder_status !== "cancelled") {
            Count += order.items_ordered[i].suborder_items_ordered?.length;
         }
      }
      console.log(Count, "Counttt");
      return Count;
   };

   const showChangeOrderType = () => {
      const allOrderTypes = {
         dineIn: checkMenu.dineIn,
         delivery: checkMenu.delivery,
         takeAway: checkMenu.takeAway,
      };
      let totalEnabledOrders = [];
      Object.keys(allOrderTypes).forEach((key) => allOrderTypes[key] === true && totalEnabledOrders.push(key));
      if (totalEnabledOrders.length > 1) {
         return true;
      }
      return false;
   };

   return <OrderPlaced />;
}
