import { createSlice } from "@reduxjs/toolkit";
export interface UserState {
  accessToken: { jwt: string | null };
  user_name: string | null;
  phone_number: string | null;
  otp_id: string;
  customer_id: string | null;
  fcm_token: string | null;
  longitude: number;
  latitude: number;
  formState: {
    // user_name: string;
    phone_number: string;
    email: string;
    save: boolean;
    pincode: string;
  };
}

const initialState: UserState = {
  accessToken: { jwt: null },
  user_name: null,
  phone_number: null,
  otp_id: "",
  customer_id: null,
  fcm_token: "",
  latitude: 0,
  longitude: 0,
  formState: {
    // user_name: '',
    phone_number: "",
    email: "",
    save: false,
    pincode: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    updateUserDetails: (state: any, action: any) => {
      // state.user_name = action.payload.user_name;
      state.phone_number = action.payload.phone_number;
      state.otp_id = action.payload.otp_id;
      state.customer_id = action.payload.customer_id;
      state.pincode = action.payload.pincode;
    },
    addAccessToken: (state: any, action: any) => {
      state.accessToken = action.payload.accessToken;
    },
    removeAccessToken: (state: any, action: any) => {
      console.log("remove Access token");
      state.accessToken = { jwt: null };
    },
    updateFCMTOken: (state: any, action: any) => {
      state.fcm_token = action.payload.fcm_token;
    },
    addFormState: (state: any, action: any) => {
      state.formState = { ...action.payload };
    },
    getLocation: (state: any, action: any) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
  },
});

export const userActions = userSlice.actions;
