import React from 'react';
import { Input, InputArea } from './styles';
import { Field as FormikField } from 'formik';

const FormikInputField = ({
  FieldArea,
  field,
  form: { touched, errors },
  ...props
}: any) => (
  <div>
    {FieldArea ? (
      <InputArea {...field} {...props} />
    ) : (
      <Input {...field} {...props} />
    )}
  </div>
);

const InputField = (props: any) => {
  return <FormikField {...props} component={FormikInputField} />;
};

export default InputField;
