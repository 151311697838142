/** @format */
/** @format */

import React from "react";
import Footer from "../../component/Footer";
import Navbar from "../PreHomePage/Navbar";
import ContentTitle from "../PreHomePage/ContentTitle";

export default function Privacypolicy() {
   return (
      <div>
         {/* <Navbar /> */}
         <div className="w-full text-center mt-4">
            <ContentTitle title={"Privacy policy"} />
         </div>
         <div className="flex items-center justify-center my-[4rem]">
            <p className="w-[90%] text-justify leading-7">
               Greyfeathers (opc) private limited Privacy Policy Welcome to Greyfeathers! Greyfeathers (opc) -is an Indian company offering an internet-based service (The Beautiful Menu Service”) that
               allows Restaurant Merchants (“sellers”) to upload their menu of certain products and promote the sale of those products to consumers (“buyers”). This Privacy Policy applies to The
               Beautiful Menu Service, operated by Greyfeathers and located at thebeautifulmenu.com This Privacy Policy describes how Greyfeathers collects and uses the information we obtain when you
               use The Beautiful Menu Service. It also describes the choices available to you regarding our use of your Personal Information and how you can access and manage that information. This
               Privacy Policy is part of and is governed by our Terms of Service, which you accept in order to transact with us. Your acceptance of this Privacy Policy includes your consent to our use
               of cookies and other similar technologies as described in this document. To note, our site may include links to other web sites whose privacy practices may differ from those of
               Greyfeathers, including sellers’ websites that Greyfeathers operates as a service provider on their behalf. Because the use of your information is governed by their privacy policies, we
               encourage you to read each policy carefully. We are not responsible for the policies or practices of any third-party website or service. Information We Collect Information You Provide
               to Us. “Personal Information” means information that alone or in combination with other information may be used to readily identify, contact, or locate you. The Personal Information we
               collect will be different depending on your activities on The Beautiful Menu Service. For example, to register as a seller on our service, we will collect your name and email address.
               If you register using a sign-in service or a social media platform such as Facebook, we will collect the information needed to authenticate your account and any other information the
               platform provides in that process. If you launch a successful campaign, we may collect additional Personal Information as needed to send you and the applicable tax authorities an income
               tax reporting statement. If you make a purchase through The Beautiful Menu Service, we will collect your name, email address, shipping address and payment information such as credit
               card number and billing address. We may also collect Personal Information you provide about other people, such as their name and physical address (for example, when we ship to another
               person at your request). This Personal Information is used for the sole purpose of completing your request. Automated Technologies. “Site Information” means information received and
               recorded on our server logs from your browser, including your IP address, browser type, the date and time of the use, the pages you visit, the page you came from and go to when leaving
               The Beautiful Menu Service your operating system and information about your mobile device. We may combine this automatically collected log information with other information we collect
               about you. In addition, we also automatically collect Site Information when you access or use The Beautiful Menu Service. We (and our vendors) collect that information using cookies,
               pixel tags, web beacons, embedded web links, and similar technologies. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our
               site, but your ability to use some features or areas of our service may be limited. We Greyfeathers and our partners use technologies such as cookies or similar technologies to analyze
               trends, administer the website, track users’ movements around the website, and to gather demographic information about our user base as a whole. We also use cookies for our shopping
               cart, to remember users’ settings (e.g. language preference), for authentication and other similar services. Users can control the use of cookies at the individual browser level. If you
               reject cookies, you may still use our site, but your ability to use some features or areas of our service may be limited. Beautiful Menu Tools. “Performance Information” means
               information we collect through certain tools that we provide to our sellers. Where a seller requests the use of a particular tool (e.g. an advertising analytics tool for use with ad
               platforms), then Beautiful Menu -will collect additional data based on the operation of that tool (e.g. advertising conversion information from the applicable ad platform). Blog. The
               Beautiful Menu Service may offer publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by
               others who access them. To request removal of your personal information from our blog or community forum, contact us at anandaraj@greyfeathers.in. In some cases, we may not be able to
               remove your Personal Information, in which case we will let you know if we are unable to do so and why. Social Media Widgets. The Beautiful Menu Service may include social media
               features, such as the Facebook like button and share buttons. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the
               features to function properly. Certain social media features are hosted by the applicable provider (e.g. Facebook). Your interactions with these features are governed by the privacy
               policy of the company providing the features. Single Sign-On. You can log into The Beautiful Menu Service using sign-in services such as Facebook Connect or an Open ID provider. These
               services will authenticate your identity and provide you the option to share certain Personal Information with us such as your name and email address to pre-populate our sign up form.
               Services like Facebook Connect give you the option to post information about your activities on The Beautiful Menu Service to your profile page to share with others within your network.
               How We Use Your Information In general, we use Personal Information to provide The Beautiful Menu Service to you, including, to establish and administer your account, to fulfill your
               order and send you an order confirmation, to pay you and to respond to customer service requests. If you wish to subscribe to our newsletter(s), we will use your name and email address
               to send the newsletter to you. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails, accessing the
               email preferences in your account settings page or you can contact us at anandaraj@greyfeathers.in. You may not opt-out of the transactional emails, Greyfeathers sends in connection
               with your use of The Beautiful Menu Service (e.g. email notification of individual account activity, such as shipment of an order, or broader account notifications such as changes to
               Greyfeathers’ policies). We partner with a third party to either display advertising on our website or to manage our advertising on other sites. Our third party partner may use
               technologies such as cookies to gather information about your activities on this website and other sites in order to provide you advertising based upon your browsing activities and
               interests. If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by clicking here. We use Performance Information to analyse
               and better understand the characteristics of successful offerings and campaigns and to improve the performance of our sellers and the overall Beautiful Menu Service. Greyfeathers does
               not collect Personal Information about your online activities over time, across different web sites. Accordingly, Greyfeathers does not currently respond to “do not track” browser
               requests. Greyfeathers processes Personal Information in India. Please note that you are agreeing to the transfer of your Personal Information to India and other locations throughout
               the world. Your Personal Information will be processed in accordance with this Privacy Policy. Information Sharing We will share information we collect, including your Personal
               Information, as needed to provide The Beautiful Menu Service to you. We will also share your Personal Information with your permission. Otherwise, we do not rent, sell or share your
               Personal Information with third parties and specifically will not share your Personal Information for their direct marketing purposes without your consent. Sellers. We may share
               aggregate, anonymized Site Information, Performance Information or other information with our sellers to help them improve their performance through The Beautiful Menu Services. If you
               purchase a product from a seller through The Beautiful Menu Service, we will share your contact information and information about your purchase with the seller. If you visit a seller’s
               website that Greyfeathers operates as a service provider on the seller’s behalf, we may collect and provide other information to the seller as described in the seller’s privacy policy.
               We will not otherwise share your Personal Information with our sellers unless we have your consent. Strategic Partners. We may share aggregate, anonymized data with certain marketing
               and content licensing partners. Where a partner or agency compensates sellers directly, we will also share performance data with the partner to enable those payments. Vendors and
               Service Providers. To provide you with The Beautiful Menu Service, we share information, including Personal Information, with third party vendors and service providers. For example, we
               provide Personal Information to companies that help us with our business activities, such as marketing, payment processing and fulfillment. We will use reasonable efforts to ensure
               those companies use your Personal Information only as necessary. As Required by Law and Similar Disclosures. In certain situations, Greyfeathers may be required to disclose personal
               data in response to lawful requests by public authorities, including meeting law enforcement requirements. We may access, preserve, and disclose your Personal Information and content if
               we believe doing so is required or appropriate to comply with law enforcement requests, such as a court order or bankruptcy proceedings, or similar legal process or when we believe in
               good faith that disclosure is necessary to protect our rights or the rights of others. Specifically, we may disclose your information to a complainant who alleges that your activities
               have violated their rights or policies (e.g. where your campaign or advertisement is the subject of an investigation). We may also disclose your information where you have alleged
               violation of your rights by another party. Merger, Sale or Other Asset Transfers. If Greyfeathers is involved in a merger, acquisition, or sale of all or a portion of its assets, you
               will be notified via email and/or a prominent notice on our website, of any change in ownership, uses of your personal information, and choices you may have regarding your personal
               information. We may also disclose your personal information to any other third party with your prior consent. Manage Your Information Upon request Greyfeathers will provide you with
               information about whether we hold any of your personal information. If you would like to update or modify any Personal Information you have provided to us, you can do so in the account
               settings tab of your dashboard or by contacting us. For your protection, we will require some proof of your identity to obtain any Personal Information hosted on our site. Additionally,
               you may deactivate your account by contacting us. At your request, we will remove your Personal Information, provided we have no outstanding business or other obligation requiring such
               information. We will respond to your request within a reasonable timeframe. Children’s Privacy We do not knowingly collect, maintain, or use Personal Information from children under 13
               years of age without parental consent, and no part of The Beautiful Menu-Service is directed to children under the age of 13. If you learn that your child has provided us with Personal
               Information without your consent, you may alert us at anandaraj@greyfeathers.in. Data Retention We will retain your information for as long as your account is active or as needed to
               provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. Security The security of
               your Personal Information is important to us and we take reasonable steps to ensure that it is treated accordingly. However, we cannot ensure the security of any information provided to
               us. We do not accept liability for unintentional disclosure. When you enter sensitive information (such as a credit card number) on our order forms, we encrypt the transmission of that
               information using secure socket layer technology (SSL). We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once it
               is received. If you have any questions about the security of your personal information, you can contact us at anandaraj@greyfeathers.in. This Privacy Policy shall be subject to and
               interpreted in accordance with the Indian Laws and competent Court/s in Bengaluru, India shall have exclusive jurisdiction to try any dispute arising out of this Policy. In the event
               one party hereto shall commence any proceeding, at law, or in equity, against the other party or institute any counterclaim against the other party in any such proceeding, which
               proceeding or counterclaim relates to this Policy, the non-prevailing party in such proceeding or counterclaim shall pay to the prevailing party the prevailing party’s reasonable
               attorneys’ fees and other legal fees, expert or consultant fees and other costs and expenses incurred in connection with such proceeding or counterclaim (including post-judgment
               collection). Please be aware that we do not accept or process requests through other means (e.g., via fax, social media, email addresses, etc.). When you make a request, we may ask you
               to provide verifying information, such as your name, email, or phone number. We will review the information provided and may request additional information via email or other means to
               ensure we are interacting with the correct individual. Please also be aware that making any such request does not ensure complete or comprehensive removal or deletion of Personal
               Information or content you may have posted, and there may be circumstances in which the law does not require or allow us to fulfill your request. Children. Our Services are not directed
               to children, and we do not knowingly collect Personal Information from children under the age of 16. If you learn that a child has provided us with Personal Information, then you may
               contact us as indicated above. Notification of Privacy Policy Changes We may periodically make changes to this Privacy Policy that will be posted on our website (thebeautifulmenu.com)
               or communicated to you through other reasonable means (e.g. email or mobile alerts). We encourage you to periodically review this page for the latest information on our privacy
               practices. Contact Information: ANANDARAJ CHINNAPERUMAL 7418590412
            </p>
         </div>
         <Footer />
      </div>
   );
}
