/** @format */

import React, { useEffect, useRef } from "react";
import ButtonWithArrow from "../../Button";
import lottie from "lottie-web";
import animation from "./animation.json";
import { Header, ParaContent } from "./styles";
import "./style.scss";

function Confirmed() {
   const jsonGif = useRef(null);
   useEffect(() => {
      lottie.loadAnimation({
         //@ts-ignore
         container: jsonGif.current,
         renderer: "svg",
         loop: true,
         autoplay: true,
         animationData: animation,
      });
      return () => lottie.stop();
   }, []);
   return (
      <div className="confirmation-container">
         <div className="confirmation-subcontainer">
            <div ref={jsonGif}></div>
            <div className="confirmation-header">Request Confirmed</div>
            <div className="confirmation-para-content">You will receive your order within 5 - 10 minutes from the time of request</div>
         </div>
         <div className="confirmation-btn-container">
            <ButtonWithArrow
               disabled={false}
               background="#0F6656"
               fontColor="white"
               buttonText={"Confirm"}
               showArrow={true}
            />
         </div>
      </div>
   );
}

export default Confirmed;
