/** @format */

import { RootState } from "../store";

const qrSelectors = {
   getqr: (state: RootState) => state.qr,
   getMerchantDetails: (state: RootState) => state.qr.merchantDetails,
};

export default qrSelectors;
