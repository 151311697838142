/** @format */

import { createSlice } from "@reduxjs/toolkit";

export interface addMoreItemOnOrder {
   confirmedItems: Array<any>;
   totalAmount: number;
   addMoreItemFlow: boolean;
   addedItems: Array<any>;
   addMoreItemCount: number;
   orderId: string;
}

const initialState: addMoreItemOnOrder = {
   confirmedItems: [],
   totalAmount: 0,
   addMoreItemFlow: false,
   addedItems: [],
   addMoreItemCount: 0,
   orderId: "",
};

export const addMoreItemOnCartSlice = createSlice({
   name: "addMoreItemOnCart",
   initialState: initialState,
   reducers: {
      initialise: (state: any, action: any) => {
         console.log("INITIALISING ADD MORE ITEMS FLOW", action.payload);
         state.confirmedItems = action.payload.confirmedItems; //confItm = [1,2,3]
         state.addMoreItemFlow = true;
         state.orderId = action.payload.orderId;
      },
      updateAddMoreItemCart: (state: any, action: any) => {
         console.log("ADD ITEM", action.payload);
         if (action.payload.type === "add") {
            let item = { ...action.payload.item }; //itm = 3
            const itemIndex = state.addedItems.findIndex((item: any) => item.item_id === action.payload.item.item_id);
            if (itemIndex === -1) {
               // item = { ...item, count: 1, is_newly_added: true };
               item = { ...item, count: 1, addons: [], customizations: [], other_customization: "" };
               state.addedItems?.push(item);
               state.addMoreItemCount = state.addMoreItemCount + 1;
               state.totalAmount += item?.price_after_discount;
            } else {
               //3
               const updatedCount = state.addedItems[itemIndex].count + 1;
               state.addedItems[itemIndex].count = updatedCount;
               state.addedItems[itemIndex].total_price = updatedCount * item.price_after_discount;
               state.totalAmount += item?.price_after_discount;
               console.log("NEWLY ADDED QUANTITY", state.addedItems[itemIndex]);
            }
         } else if (action.payload.type === "remove") {
            const item = action.payload.item;
            const itemIndex = state.addedItems.findIndex((item: any) => item.item_id === action.payload.item.item_id);
            const confirmedItemIndex = state.confirmedItems.findIndex((item: any) => item.item_id === action.payload.item.item_id);
            const updatedCount = state.addedItems[itemIndex].count - 1;
            const confirmedItemCount = state.confirmedItems[confirmedItemIndex]?.count;
            if (updatedCount === 0) {
               state.addedItems.splice(itemIndex, 1);
               state.addMoreItemCount = state.addMoreItemCount - 1;
               state.totalAmount = state.totalAmount - item.price_after_discount;
            } else {
               state.addedItems[itemIndex].count = updatedCount;
               state.addedItems[itemIndex].total_price = updatedCount * item.price_after_discount;
               state.totalAmount = state.totalAmount - item.price_after_discount;
               if (confirmedItemCount === updatedCount) {
                  let temp = {
                     ...item,
                     count: updatedCount,
                     newly_added_quantity: 0,
                  };
                  state.addedItems[itemIndex] = temp;
               }
            }
         }
      },
      addAddon: (state: any, action: any) => {
         const index = state.addedItems.map((item: any) => item.item_id).indexOf(action.payload.item_id);
         const addonIndex = state.addedItems[index]?.addons?.findIndex((item: any) => item.add_on_id === action.payload.add_on_id);
         if (addonIndex === -1) {
            state.addedItems[index]?.addons?.push({
               ...action.payload,
               count: 1,
            });
         } else {
            state.addedItems[index].addons[addonIndex].count += 1;
         }
         state.totalAmount += parseInt(action.payload.price);
      },
      removeAddon: (state: any, action: any) => {
         const index = state.addedItems.map((item: any) => item.item_id).indexOf(action.payload.item_id);
         const addonIndex = state.addedItems[index].addons.findIndex((item: any) => item.add_on_id === action.payload.add_on_id);
         if (addonIndex !== -1) {
            if (state.addedItems[index].addons[addonIndex].count > 1) {
               state.addedItems[index].addons[addonIndex].count -= 1;
               state.totalAmount -= parseInt(action.payload.price);
            } else if (state.addedItems[index].addons[addonIndex].count === 1) {
               state.addedItems[index].addons.splice(addonIndex, 1);
               state.totalAmount -= parseInt(action.payload.price);
            }
         }
      },
      otherCustomization: (state: any, action: any) => {
         const index = state.addedItems.map((item: any) => item.item_id).indexOf(action.payload.item_id);
         if (state.addedItems[index]) {
            state.addedItems[index].other_customization = action.payload.other_customization;
         }
      },
      toggleCustomization: (state: any, action: any) => {
         const index = state.addedItems.map((item: any) => item.item_id).indexOf(action.payload.item_id);

         const customizationIndex = state.addedItems[index].customizations.findIndex((item: any) => item.customisation_id === action.payload.customisation_id);

         if (customizationIndex !== -1) {
            if (action.payload.selection_type === "checkbox") {
               if (state.addedItems[index].customizations[customizationIndex].selected_options?.includes(action.payload.option)) {
                  state.addedItems[index].customizations[customizationIndex].selected_options?.splice(
                     state.addedItems[index].customizations[customizationIndex].selected_options?.indexOf(action.payload.option),
                     1,
                  );
               } else {
                  state.addedItems[index].customizations[customizationIndex].selected_options.push(action.payload.option);
               }
            } else {
               state.addedItems[index].customizations[customizationIndex].selected_options = [action.payload.option];
               state.addedItems[index].customizations[customizationIndex].title = action.payload.title;
            }
         } else {
            state.addedItems[index].customizations.push({
               selected_options: [action.payload.option],
               ...action.payload,
            });
         }
      },
      clearCustomization: (state: any, action: any) => {
         const index = state.addedItems.map((item: any) => item.item_id).indexOf(action.payload.item_id);
         const customizationIndex = state.addedItems[index].customizations.findIndex((item: any) => item.customisation_id === action.payload.customisation_id);
         if (customizationIndex !== -1) {
            state.addedItems[index].customizations.splice(customizationIndex, 1);
         }
      },

      clearAddonsAndCustomizations: (state: any, action: any) => {
         const index = state.addedItems.map((item: any) => item.item_id).indexOf(action.payload.item_id);
         state.addedItems[index].addons = [];
         state.addedItems[index].customizations = [];
      },
      resetAddMoreItemFlow: (state: any, action: any) => {
         state.addMoreItemFlow = false;
         state.addMoreItemCount = 0;
         state.addedItems = [];
         state.confirmedItems = [];
         state.totalAmount = 0;
      },
   },
});

export const addMoreItemOnCartActions = addMoreItemOnCartSlice.actions;
