import { useEffect, useRef } from "react";
import "./styles.scss";

const HourlyDailyCard = ({ title, cardTypeText, extraText, images, colorScheme, onClickAction }: any) => {
	// colorScheme = {b

	const img1 = useRef<HTMLImageElement>(document.createElement("img"));
	const img2 = useRef<HTMLImageElement>(document.createElement("img"));

	useEffect(() => {
		let idx1 = images.length > 0 ? 0 : 0;
		let idx2 = images.length > 1 ? 1 : images.length > 0 ? 0 : 0;

		let i: any;
		if (images.length > 0) {
			i = setInterval(() => {
				img1.current.src = images[(idx1 + 1) % images.length];
				idx1 = (idx1 + 1) % images.length;
				img2.current.src = images[(idx2 + 1) % images.length];
				idx2 = (idx2 + 1) % images.length;
			}, 10000);
		}

		return () => {
			// cleanup
			clearInterval(i);
		};
	}, []);

	return (
		<div
			className="HourlyDailyCard-Outer"
			style={{
				backgroundColor: colorScheme === "purple" ? "#6F2DBD1A" : "#F9CA411A",
			}}
			onClick={onClickAction}>
			<div className="">
				<span
					className="HourlyDailyCard-Type font-custom"
					style={{
						backgroundColor: colorScheme === "purple" ? "#6F2DBD" : "#F9CA41",
						color: colorScheme === "purple" ? "#fff" : "#000",
					}}>
					{cardTypeText}
				</span>
				<div className="HourlyDailyCard-Title font-custom">{title}</div>
				<div
					className="HourlyDailyCard-ExtraText font-custom"
					style={{
						color: colorScheme === "purple" ? "#6F2DBD" : "#000",
						backgroundColor: colorScheme === "purple" ? "#E4D7F3" : "#fff",
					}}>
					{extraText}
				</div>
			</div>
			<div className="HourlyDailyCard-Images">
				<div className="HourlyDailyCard-Image-1">
					<img
						src={images.length > 0 && images[0]}
						alt="preview"
						ref={img1}
					/>
				</div>
				<div className="HourlyDailyCard-Image-2">
					<img
						src={images.length > 1 ? images[1] : images.length > 0 && images[0]}
						alt="preview"
						ref={img2}
					/>
				</div>
				<div style={{ height: "100%", width: "5rem", backgroundColor: "transparent" }}></div>
			</div>
		</div>
	);
};

export default HourlyDailyCard;
