import * as React from "react";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 3% 0 3%",
      }}>
      <Box marginBottom={0.5} sx={{ width: "92%" }} textAlign="start">
        <Typography variant="body2" color="text.secondary">
          <span
            style={{
              fontWeight: 600,
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "12px",
              marginLeft: "5px",
            }}>
            Refreshing in :
          </span>
          <span
            style={{
              fontWeight: 600,
              color: "#4361EE",
              fontFamily: "Poppins",
            }}>
            {" "}
            {`0:${props.value >= 10 ? Math.round(props.value) : `0${Math.round(props.value)}`}`}
          </span>
        </Typography>
      </Box>
      <Box sx={{ width: "90%" }}>
        <LinearProgress
          sx={{
            color: "#4361EE",
            //   width:120px;
            //   height:20px;
            //   background:
            //    linear-gradient(#000 0 0) 0/0% no-repeat
            //    #ddd;
            //   animation:p1 2s infinite linear;
            // }
            // @keyframes p1 {
            //     100% {background-size:100%}
            // }
          }}
          variant="determinate"
          value={10 + props.value * 3}
        />
      </Box>
    </Box>
  );
}

export default function OrderStatusProgressbar({ progress, setProgress }: any) {
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress: any) => (prevProgress === 1 ? 30 : prevProgress - 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
