import React, { useEffect } from 'react';
import BackArrowIcon from '../../asset/icons/BackArrowIcon';
import ORSvg from './ORSvg';
import './style.scss';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useRazorpay from 'react-razorpay';
import updateOrderAPI from '../../api/order/updateOrder';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import userSelectors from '../../store/user/selectors';
import { cartActions } from '../../store/cart/reducer';
import addMoreItemsOnCartSelector from '../../store/addMoreItemOnOrder/selectors';
import { addMoreItemOnCartActions } from '../../store/addMoreItemOnOrder/reducer';

function DiningInConfirmation() {
  const Razorpay = useRazorpay();
  const { qid, mid } = useParams();
  const navigate = useNavigate();
  const { jwt: accessToken }: any = useAppSelector(
    userSelectors.getAccessToken
  );
  const dispatch = useAppDispatch();

  const { createApiResponse }: any = useLocation().state;
  console.log('createApiResponse DINING IN CONFIRMATION', createApiResponse);

  const { addMoreItemFlow } = useAppSelector(
    addMoreItemsOnCartSelector.getAddMoreItemsOnCart
  );
  const goBack = () => {
    if (qid !== undefined) {
      navigate(`/${mid}/${qid}`);
    } else {
      navigate(`/${mid}`);
    }
  };

  const openPaymentGateway = async () => {
    console.log('openPaymentGateway calling ..', createApiResponse.payload);
    const amount = createApiResponse.payload.total_amount_after_discount;
    const amount_paid = createApiResponse.payload.amount_after_taxes;
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: (amount * 100).toString(),
      ...createApiResponse.payload.payment_gateway_details,
      handler: async (response: any) => {
        let updateOrderPayload = {
          ...createApiResponse.payload,
          payment_order_id: response.razorpay_payment_id,
          payment_status: 'paid',
          extra_payment: false,
          extra_payment_amount: 0,
          amount_paid: amount_paid,
          order_status: addMoreItemFlow ? 'order_updated' : 'new_order',
          payment_made_through_payment_gateway: true,
        };
        const updateOrderResponse = await updateOrderAPI(
          updateOrderPayload,
          accessToken
        );

        if (updateOrderResponse.statusCode === 200) {
          if (qid !== undefined) {
            navigate(
              `/${mid}/${qid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`,
              { state: { fromDinein: true } }
            );
          } else {
            navigate(
              `/${mid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`,
              { state: { fromDinein: true } }
            );
          }
          addMoreItemFlow
            ? dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow({}))
            : dispatch(cartActions.clearCart(mid));
        }
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on('payment.failed', function (response: any) {
      dispatch(cartActions.clearCart(mid));
    });

    rzp1.open();
  };

  const onPressPayLater = () => {
    if (qid !== undefined) {
      navigate(
        `/${mid}/${qid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`,
        { state: { fromDinein: true } }
      );
    } else {
      navigate(
        `/${mid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`,
        { state: { fromDinein: true } }
      );
    }
    // navigate(
    //   `/${mid}/order_summary/${createApiResponse.payload.customer_id}/${createApiResponse.payload.order_id}`
    // );
    addMoreItemFlow
      ? dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow({}))
      : dispatch(cartActions.clearCart(mid));
  };

  return (
    <div className='dining-in-confirmation-container'>
      <div className='dining-in-confirmation-subcontainer'>
        <div onClick={goBack}>
          <BackArrowIcon />
        </div>
        <div className='dining-in-confirmation-heading'>Confirmation</div>
        <div></div>
      </div>
      <div className='dining-in-confirmation-text-content-container'>
        <div className='dining-in-confirmation-text-content-subcontainer'>
          <div
            style={{ width: '80%' }}
            className='dining-in-confirmation-text-content'>
            Someone from the restaurant will confirm your order
          </div>
          <div
            onClick={onPressPayLater}
            className='dining-in-confirmation-btn-container'>
            Okay, I'll wait
          </div>
        </div>

        <ORSvg />
        <div className='dining-in-confirmation-text-content-subcontainer'>
          <div
            style={{ width: '70%' }}
            className='dining-in-confirmation-text-content'>
            Pay now to confirm your order faster
          </div>
          <div onClick={openPaymentGateway} className='pay-now-btn'>
            Pay now
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiningInConfirmation;
