/** @format */

import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CoinIcon from "../../asset/icons/CoinIcon";
import bg from "../../asset/images/bg.png";
import ClubIcon from "../../asset/icons/ClubIcon";

type Props = {
	appliedCoins: any;
	previewDesc: "";
	onClick: any;
	custom?: boolean;
	isMember: boolean;
};

function LoyaltyBanner({ appliedCoins, previewDesc, onClick, isMember, custom }: Props) {
	return (
		<>
			{custom ? (
				<>
					<div
						style={{
							backgroundColor: "#FDF0C2",
							border: "none",
							color: "#F98200",
							height: "55px",
							width: "100%",
						}}
						className="apply-coupon-container mt-2"
						onClick={onClick}>
						<div className="apply-coupon-wrapper d-flex align-items-center justify-content-center w-100">
							{isMember && appliedCoins ? (
								<>
									<CoinIcon />
									<div className="ms-2 ">
										<div
											className="fw-bold font-custom"
											style={{ fontSize: "0.8rem", color: "#2FBF71" }}>
											{previewDesc}
										</div>
										<div className="fw-bold font-custom ">Loyalty points: {appliedCoins}</div>
									</div>
								</>
							) : (
								<div
									className="w-100 fw-bold"
									style={{
										width: "290px",
										fontSize: "15px",
										display: "flex",
										gap: "8px",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<CoinIcon />
									<span>Subscribe to loyalty program</span>
								</div>
							)}
						</div>
						{!isMember && <ArrowForwardIosIcon sx={{ color: "#FF4700" }} />}
					</div>
					{appliedCoins < 1000 && (
						<div
							style={{
								fontSize: "12px",
								fontWeight: "normal",
								textAlign: "center",
								width: "100%",
								color: "#F98200",
							}}>
							“You will be eligible for a discount once you have accumulated 1000 loyalty points”
						</div>
					)}
				</>
			) : (
				<div
					style={{
						backgroundImage: `url(${bg})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						border: "none",
						height: "60px",
					}}
					className="apply-coupon-container mt-2"
					onClick={onClick}>
					<div className="apply-coupon-wrapper w-100">
						{appliedCoins ? (
							<>
								<CoinIcon />
								<div className="ms-2 ">
									<div className="fw-bold">{appliedCoins}</div>
									<div
										className="mt-1 fw-bold"
										style={{ fontSize: "0.8rem", color: "#2FBF71" }}>
										{previewDesc}
									</div>
								</div>
							</>
						) : isMember ? (
							<div className="flex text-[#FF4700] items-center gap-2 apply-coupon-text fw-bold text-[15px]">
								<span>
									<ClubIcon />
								</span>
								<span>Apply discount using your loyalty coins</span>
							</div>
						) : (
							<div
								className="w-100 text-[#FF4700]"
								style={{
									width: "290px",
									fontSize: "17px",
									display: "flex",
									gap: "5px",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<span>
									<ClubIcon />
								</span>
								<span className="text-[#FF4700]">
									Join <b>Exclusive Club</b> & Earn benefits
								</span>
							</div>
						)}
					</div>
					{isMember && <ArrowForwardIosIcon sx={{ color: "#FF4700" }} />}
				</div>
			)}
		</>
	);
}

export default LoyaltyBanner;
