/** @format */

import React from "react";

export default function Description({ title, description }) {
   return (
      <div
         data-aos="zoom-in"
         data-aos-duration="500"
         className="flex items-center flex-col">
         <div className="lg:w-[70%] w-[90%]">
            <h3 className="font-bold text-[1.5rem] lg:text-[2.5rem]">{title}</h3>
            <p className="lg:text-[1.8rem] text-[#0F191A80] text-[1.2rem] mt-3">{description}</p>
         </div>
      </div>
   );
}
