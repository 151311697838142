import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { useDispatch } from 'react-redux';
import { tokenToString } from 'typescript';
import background from '../../constants/backgroundColors';
import firebase from '../../firebase';
import { userActions } from '../../store/user/reducer';

const menuDataTransformer = (data: any[]) => {
  const result: any[] = [];
  data?.forEach((item, index) => {
    result.push({
      text: `${item.category_name} (${item.items.length})`,
      background: background[index % background.length],
      link: item.id,
      category: item.category_name,
    });
  });

  return result;
};

const messaging = getMessaging(firebase);
const messagingSupported = isSupported();

const firebaseTokenCreator = async (data: any, dispatch: any) => {
  messagingSupported.then(async (supported) => {
    if (supported) {
      console.log('SUPPORTED HEHE');
      if (!('Notification' in window)) {
        console.log("Browser doesn't support Notification");
      } else if (Notification.permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        const body = {
          firebase_token: token,
          merchant_id: data.firebasePayload.mid,
          cookie_id: data.firebasePayload.cookie_id,
        };
        console.log('firebaseTokenCreator', data);
        dispatch(userActions.updateFCMTOken({ fcm_token: token }));

        const HEADERS: any =
          data.accessToken !== null
            ? {
                'Content-type': 'application/json',
                Authorization: `Bearer ${data.accessToken} `,
              }
            : {
                'Content-type': 'application/json; charset=UTF-8',
              };

        fetch(
          `${
            process.env.REACT_APP_DEV_URL
          }/customer/notifications/${'firebase_token'}`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: HEADERS,
          }
        )
          .then((response) => response.json())
          .then((json) => console.log(json));

        if (data.accessToken) {
          fetch(
            `${
              process.env.REACT_APP_DEV_URL
            }/customer/notifications/${'auth_firebase_token'}`,
            {
              method: 'POST',
              body: JSON.stringify(body),
              headers: HEADERS,
            }
          )
            .then((response) => response.json())
            .then((json) => console.log(json));
        }

        onMessage(messaging, (payload) => {
          new Notification('message', { body: payload?.notification?.body });
        });
        return token;
      } else if (Notification.permission !== 'denied') {
        try {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            // next steps for notifications.
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  });
};

const getLocationAccess = () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos: any) {
    var crd = pos.coords;

    console.log('Your current position is:');
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    return crd;
  }

  function errors(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  if (navigator.geolocation) {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then(function (result) {
        if (result.state === 'granted') {
          console.log(result.state);
          navigator.geolocation.getCurrentPosition(success);
          return success;
        } else if (result.state === 'prompt') {
          console.log(result.state);
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === 'denied') {
          console.log('user denied location access');
        }
      });
  } else {
    alert('Geolocation Not available!');
  }
};

export { menuDataTransformer, firebaseTokenCreator, getLocationAccess };
