import React from "react";
import { X } from "react-feather";
import ButtonWithArrow from "../Button";
import "./style.scss";

import { Text } from "../Text";
import { Container, HeaderContainer, RadioButtonsContainer } from "./styles";

const FilterModal = ({ handleClose }: FilterModalProps) => {
  return (
    <div className="filter-modal-container">
      <div className="filter-modal-container-header">
        <div className="filter-modal-filter-by-text">Filter by</div>
        <X style={{ marginLeft: "auto" }} onClick={handleClose} />
      </div>
      <div>
        <div className="filter-modal-radio-btn-wrapper ">
          <div className="filter-modal-radio-input" id="filter-1" />
          <div className="filter-modal-radio-input-label">Veg</div>
        </div>

        <div className="filter-modal-radio-btn-wrapper ">
          <div className="filter-modal-radio-input" id="filter-2" />
          <div className="filter-modal-radio-input-label">Non-Veg</div>
        </div>

        <div className="filter-modal-radio-btn-wrapper ">
          <div className="filter-modal-radio-input" id="filter-3" />
          <div className="filter-modal-radio-input-label">In-stock</div>
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          <ButtonWithArrow
            buttonText="Apply filter"
            background="#2D6B4D"
            fontColor="white"
            showArrow={false}
          />
        </div>
      </div>
    </div>
  );
};

interface FilterModalProps {
  handleClose: () => void;
}

export default FilterModal;
