import React from "react";

const YellowArrowIcon = () => (
	<svg
		width="14"
		height="18"
		viewBox="0 0 6 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.19993 6.48999L5.22993 4.51999L2.01993 1.30999C1.33993 0.639988 0.179932 1.11999 0.179932 2.07999V8.30999V13.92C0.179932 14.88 1.33993 15.36 2.01993 14.68L7.19993 9.49999C8.02993 8.67999 8.02993 7.31999 7.19993 6.48999Z"
			fill="#fff"
		/>
	</svg>
);

export default YellowArrowIcon;
