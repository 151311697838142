/** @format */

import React from "react";
import { Checkbox } from "@mui/material";
import { Text } from "../Text";
import { Container, Redline, PropertiesContainer, DetailsContainer } from "./styles";
import "./style.scss";

const SelectAddressCard = () => {
   return (
      <div className="select-address-card-container">
         <div className="select-address-card-red-line"></div>
         <div className="select-address-card-properties-container">
            <div className="select-address-card-text">Name</div>
            <div className="select-address-card-text">Contact</div>
            <div className="select-address-card-text">Email</div>
         </div>
         <div>
            <div className="select-address-card-text2">Rangabashiyam</div>
            <div className="select-address-card-text2">62135 45682</div>
            <div className="select-address-card-text2">foodie@gmail.com</div>
         </div>
         <Checkbox
            style={{ marginLeft: "auto" }}
            sx={{
               "&.Mui-checked": {
                  color: "#0F6656",
               },
            }}
         />
      </div>
   );
};

export default SelectAddressCard;
