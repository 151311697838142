import React from 'react';
import SmallCrossIcon from '../../asset/icons/SmallCrossIcon';
import CrossSvg from '../Hotel/CartScreen/CrossSvg';
import './index.scss';

type Props = {
  item: any;
  handleCategory: any;
};

const SelectedCategoryChip = ({ item, handleCategory }: Props) => {
  return (
    <div className='selected-category-chip'>
      {item?.category_name}
      <div
        onClick={() => {
          handleCategory(item);
        }}
      >
        <SmallCrossIcon />
      </div>
    </div>
  );
};

export default SelectedCategoryChip;
