/** @format */

import { RootState } from "../store";

const showMenuSelector = {
   showMenu: (state: RootState) => state.showMenu,
   getLoyaltyStatus: (state: RootState) => state.showMenu.loyalty_enabled,
};

export default showMenuSelector;
