/** @format */

export const reverseArray = (array) => {
   let reversedArray = [];
   for (let i = array.length - 1; i >= 0; i--) {
      reversedArray.push(array[i]);
   }
   console.log("Reverse array funcc ", reversedArray);
   return reversedArray;
};
