/** @format */

import { useState, useEffect } from "react";
import { Add, Remove } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, Radio } from "@mui/material";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { addMoreItemOnCartActions } from "../../store/addMoreItemOnOrder/reducer";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import { cartActions } from "../../store/cart/reducer";
import cartItemsSelector from "../../store/cart/selectors";
import menuSelectors from "../../store/menu/selectors";
import showMenuSelector from "../../store/showMenu/selectors";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";
import ViewCartButton from "../ViewCartButton";
import { toast } from "react-hot-toast";

const AddonsModal = ({ mid, onClose, item, updateToggle, visible, clearOnClose, toggleId }) => {
   const [count, setCount] = useState(1);
   const dispatch = useDispatch();

   const { showMenu, showMenuWithOrder, acceptOrders, show_available_item_count, dineIn, currency } = useAppSelector(showMenuSelector.showMenu);
   const { menu } = useAppSelector(menuSelectors.getMenuItems);

   const { addMoreItemFlow, addedItems } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);

   const addedMoreItem = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItem(item?.item_id));

   const canReduceItem = useAppSelector(addMoreItemsOnCartSelector.canReduceItem(item?.item_id));

   const cartItem = useAppSelector(cartItemsSelector.getMenuItem(item?.item_id, mid));
   console.log(cartItem, "cartItem");

   const getAddons = () => {
      const allItems = menu?.filter((category) => category.id === item.category_id)[0]?.items;
      const currentItem = allItems?.filter((Item) => Item.item_id == item.item_id)[0];
      console.log(currentItem, "getAddons");
      return currentItem?.add_ons;
   };

   const getCustomizations = () => {
      const allItems = menu?.filter((category) => category.id === item.category_id)[0]?.items;
      const currentItem = allItems?.filter((Item) => Item.item_id == item.item_id)[0];
      console.log(currentItem, "getAddons");
      return currentItem?.customizations;
   };

   const addons = getAddons();

   console.log("ITEMM", item);

   const customizations = getCustomizations();

   const toggleShowButton = () => {
      // if (!showButton) {
      //   handleAddToCart();
      // }
      if (showMenuWithOrder && acceptOrders) {
         updateToggle(item?.item_id);
      }
      // setShowButton((val) => !val);
   };

   const handleAddToCart = () => {
      console.log("ADD TO CART CALLED");

      const cookies = Object.fromEntries(document.cookie.split("; ").map((v) => v.split(/=(.*)/s).map(decodeURIComponent)));
      const isQuantityReached = item?.manage_inventory ? (addMoreItemFlow ? addedMoreItem?.count === item.remaining_quantity : cartItem?.count === item.remaining_quantity) : false;
      if (isQuantityReached) {
         toast.error("No more quantity available");
      } else if (cartItem?.count > 0) {
         addMoreItemFlow
            ? dispatch(
                 addMoreItemOnCartActions.updateAddMoreItemCart({
                    item: item,
                    type: "add",
                 }),
              )
            : dispatch(cartActions.addFoodItem(item));
      } else {
         addMoreItemFlow
            ? dispatch(
                 addMoreItemOnCartActions.updateAddMoreItemCart({
                    item: item,
                    type: "add",
                 }),
              )
            : dispatch(cartActions.addFoodItem(item));
         const data = {
            item_id: item?.item_id,
            merchant_id: mid,
            cookie_id: cookies["cookie_id"],
         };
         // getPeopleAlsoOrdered(data);
      }
      // setShowAddons(true)
   };

   const handleRemoveFromCart = (count) => {
      if (count === 1) {
         toggleShowButton();
         onClose();
      }
      addMoreItemFlow
         ? dispatch(
              addMoreItemOnCartActions.updateAddMoreItemCart({
                 item,
                 type: "remove",
              }),
           )
         : dispatch(cartActions.removeFoodItem(item));
   };

   const addAddonToItem = (addon) => {
      if (addon.in_stock) {
         const payload = {
            merchant_id: mid,
            item_id: item?.item_id,
            ...addon,
         };
         addMoreItemFlow ? dispatch(addMoreItemOnCartActions.addAddon(payload)) : dispatch(cartActions.addAddon(payload));
      }
   };

   const removeAddonFromItem = (addon) => {
      if (addon?.in_stock) {
         const payload = {
            merchant_id: mid,
            item_id: item?.item_id,
            ...addon,
         };
         addMoreItemFlow ? dispatch(addMoreItemOnCartActions.removeAddon(payload)) : dispatch(cartActions.removeAddon(payload));
      }
   };
   const toggleCustomizationToItem = (customization, option) => {
      const payload = {
         merchant_id: mid,
         item_id: item?.item_id,
         option,
         ...customization,
      };
      addMoreItemFlow ? dispatch(addMoreItemOnCartActions.toggleCustomization(payload)) : dispatch(cartActions.toggleCustomization(payload));
   };
   const clearSelection = (customisation_id) => {
      const payload = {
         merchant_id: mid,
         item_id: item?.item_id,
         customisation_id,
      };
      addMoreItemFlow ? dispatch(addMoreItemOnCartActions.clearCustomization(payload)) : dispatch(cartActions.clearCustomization(payload));
   };

   const updateOtherCustomization = (text) => {
      const payload = {
         merchant_id: mid,
         item_id: item?.item_id,
         other_customization: text,
      };
      addMoreItemFlow ? dispatch(addMoreItemOnCartActions.otherCustomization(payload)) : dispatch(cartActions.otherCustomization(payload));
   };

   const getCurrentCart = () => {
      const currentCart = addMoreItemFlow ? addedMoreItem : cartItem;
      return currentCart;
   };

   const getAddonCount = (add_on_id) => {
      return getCurrentCart()?.addons?.find((item) => item?.add_on_id === add_on_id)?.count || 0;
   };

   const closeModal = () => {
      if (clearOnClose) {
         const payload = {
            merchant_id: mid,
            item_id: item?.item_id,
         };
         addMoreItemFlow ? dispatch(addMoreItemOnCartActions.clearAddonsAndCustomizations(payload)) : dispatch(cartActions.clearAddonsAndCustomizations(payload));
      }
      onClose();
   };

   useEffect(() => {
      !isAnyCustomizationSelected() && clearCustomizationOnItem();
   }, []);

   console.log("CUSTOMIZATIONS", item.item_name, getCustomizations());

   const clearCustomizationOnItem = () => {
      getCustomizations()?.map((item) => {
         clearSelection(item?.customisation_id);
      });
   };

   const isAnyCustomizationSelected = () => {
      return getCurrentCart()?.customizations?.length > 0;
   };

   const getCustomizationChecked = (customization, option) => {
      return (
         getCurrentCart()
            ?.customizations?.find((item) => item.customisation_id === customization.customisation_id)
            ?.selected_options?.includes(option) || false
      );
   };

   return (
      <Modal
         open={visible}
         onClose={closeModal}>
         <>
            <Slide
               direction="up"
               in={visible}>
               <div className="addons-modal">
                  <CloseIcon
                     style={{ fontSize: "2rem" }}
                     className="bg-[#fff] rounded-[1rem] p-2"
                     onClick={closeModal}
                  />
                  <div className="addons-modal-content">
                     <div className="addons-modal-item position-static">
                        {item.image_url ? (
                           <img
                              src={item.image_url}
                              alt={item.name}
                              className="addons-img"
                           />
                        ) : (
                           <div className="addons-item-icon font-custom">{item.item_name.slice(0, 1)}</div>
                        )}
                        <div className="addons-item">
                           <div className="addons-item-name font-custom">{capitalizeFirstLetter(item?.item_name)}</div>
                           <div className="font-custom	">
                              {currency}
                              {item.price}
                           </div>
                        </div>
                        <div className="food-item-before-order-count-content">
                           {addMoreItemFlow ? (
                              addedMoreItem?.count ? (
                                 <div className="food-item-before-order-count-container">
                                    <Remove
                                       fontSize="small"
                                       onClick={() => {
                                          canReduceItem && handleRemoveFromCart(addedMoreItem?.count);
                                       }}
                                    />
                                    <div> {addedMoreItem.count} </div>
                                    <Add
                                       fontSize="small"
                                       onClick={handleAddToCart}
                                    />
                                 </div>
                              ) : (
                                 <div
                                    className="food-item-before-order-add-button"
                                    onClick={() => {
                                       handleAddToCart();
                                       toggleShowButton();
                                    }}>
                                    Add
                                 </div>
                              )
                           ) : cartItem && showMenuWithOrder && acceptOrders && cartItem.count ? (
                              <div className="food-item-before-order-count-container">
                                 <Remove
                                    fontSize="small"
                                    onClick={() => {
                                       canReduceItem && handleRemoveFromCart(cartItem?.count);
                                    }}
                                 />
                                 {cartItem.count}
                                 <Add
                                    fontSize="small"
                                    onClick={handleAddToCart}
                                 />
                              </div>
                           ) : (
                              acceptOrders &&
                              !item?.out_of_stock &&
                              showMenuWithOrder && (
                                 <div
                                    className="font-custom food-item-before-order-add-button"
                                    onClick={handleAddToCart}>
                                    Add
                                 </div>
                              )
                           )}
                        </div>
                     </div>
                     <div className="h-[70%] overflow-y-scroll pb-[3rem] scrollbar-hide">
                        {addons?.length > 0 && (
                           <div className="addons-addons">
                              <div className="addons-head font-custom">Choose add on items</div>
                              {addons.map((addon, index) => (
                                 <div
                                    className={`addons-addon ${addon.in_stock ? "opacity-1" : "opacity-50"}`}
                                    key={index}>
                                    <div>
                                       <div>{capitalizeFirstLetter(addon?.item_name)}</div>
                                       {!addon.in_stock && <span className="text-[red] text-[0.8rem] font-bold">Out of stock</span>}
                                    </div>
                                    <div>
                                       {currency}
                                       {addon.price}
                                    </div>
                                    {getAddonCount(addon.add_on_id) > 0 ? (
                                       <div className="food-item-before-order-count-container">
                                          <Remove
                                             fontSize="small"
                                             onClick={() => removeAddonFromItem(addon)}
                                          />
                                          <div> {getAddonCount(addon.add_on_id)} </div>
                                          <Add
                                             fontSize="small"
                                             onClick={() => addAddonToItem(addon)}
                                          />
                                       </div>
                                    ) : (
                                       <div
                                          className="food-item-before-order-add-button"
                                          onClick={() => addAddonToItem(addon)}>
                                          Add
                                       </div>
                                    )}
                                 </div>
                              ))}
                           </div>
                        )}
                        {customizations?.length > 0 && (
                           <div className="addons-customizations">
                              <div className="addons-head font-custom">Choose customizations</div>
                              {customizations.map((customization, index) => (
                                 <div
                                    className="addons-customization"
                                    key={index}>
                                    <div className="addons-customization-top">
                                       <div className="addons-customization-title font-custom">{capitalizeFirstLetter(customization?.customisation_title)}</div>
                                       <div
                                          className="clear-selection font-custom"
                                          onClick={() => clearSelection(customization.customisation_id)}>
                                          clear selection
                                       </div>
                                    </div>
                                    <div>
                                       {customization?.options?.map((option, index) => (
                                          <div
                                             className="addons-customization-top"
                                             key={index}>
                                             {option}
                                             {customization.selection_type === "radio" ? (
                                                <Radio
                                                   checked={getCustomizationChecked(customization, option)}
                                                   onChange={() => toggleCustomizationToItem(customization, option)}
                                                   name={customization.customisation_title}
                                                   value={option}
                                                />
                                             ) : (
                                                <Checkbox
                                                   checked={getCustomizationChecked(customization, option)}
                                                   onChange={() => toggleCustomizationToItem(customization, option)}
                                                   name={customization.customisation_title}
                                                   value={option}
                                                />
                                             )}
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              ))}
                           </div>
                        )}
                        <div className="addons-customization">
                           <div className="addons-customization-title font-custom">Add other customizations:</div>
                           <textarea
                              placeholder="Type here ..."
                              className="other-customization-input font-custom"
                              value={getCurrentCart()?.other_customization}
                              onChange={(e) => updateOtherCustomization(e.target.value)}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Slide>
            <div className="bg-[grey]">
               <ViewCartButton
                  onClickButton={onClose}
                  buttonTitle={"Confirm"}
               />
            </div>
         </>
      </Modal>
   );
};

export default AddonsModal;
