/** @format */

import { request } from "../axios";
import { SuccessResponse, ErrorResponse, ResponsePayload } from "../index.type";
import { PayloadProps, Props } from "./sendOtp.type";
import { ErrorResponseHandler } from "../index";

const sendOtp = async ({
   // user_name,
   phone_number,
   email,
   save,
   merchant_id,
}: // pincode,
Props): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
   try {
      const response = await request<ResponsePayload<PayloadProps>>({
         api: `/customer_signup/send_otp`,
         method: "POST",
         data: {
            // user_name,
            phone_number,
            email,
            save,
            merchant_id,
            // pincode,
         },
      });

      const result = response.data;

      if (response.status > 300) {
         throw new Error(result.error.message);
      }

      return {
         statusCode: 200,
         message: "Success",
         // @ts-ignore
         payload: result,
         error: null,
      };
   } catch (error) {
      return ErrorResponseHandler(error);
   }
};

export default sendOtp;
