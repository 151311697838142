import { RootState } from '../store';

const cartItemsSelector = {
  getAllCartInfo: (mid: any) => (state: RootState) => {
    const allCarts = [...state.cartItems.CART];
    const findIndex = allCarts.findIndex((item) => item.merchant_id === mid);
    if (findIndex !== -1) {
      return {
        cart: allCarts[findIndex].cart,
        totalValue: allCarts[findIndex].totalValue,
        itemCount: allCarts[findIndex].itemCount,
      };
    } else {
      return {
        cart: [],
        totalValue: 0,
        itemCount: 0,
      };
    }
  },
  getAllCartItems: (mid: string) => (state: RootState) => {
    const allCarts = [...state.cartItems.CART];
    const findIndex = allCarts.findIndex((item) => item.merchant_id === mid);
    if (findIndex !== -1) {
      return allCarts[findIndex].cart;
    } else {
      return [];
    }
  },
  getMenuItem: (item_id: string, merchant_id: string) => (state: RootState) => {
    const allCarts = [...state.cartItems.CART];
    const findIndex = allCarts.findIndex(
      (item) => item.merchant_id === merchant_id
    );
    if (findIndex !== -1) {
      const cart = allCarts[findIndex].cart;
      const temp = cart.filter((item: any) => item.item_id === item_id)[0];

      return temp;
    }
  },
  getTotalInfo: (mid: any) => (state: RootState) => {
    console.log('getTotalInfo');
    
    const allCarts = [...state.cartItems.CART];
    console.log(allCarts);

    const findIndex = allCarts.findIndex((item) => item.merchant_id === mid);

    if (findIndex !== -1) {
      return {
        itemCount: state.cartItems.CART[findIndex].itemCount,
        discountPrice: state.cartItems.CART[findIndex].discountPrice,
        totalValue: state.cartItems.CART[findIndex].totalValue,
      };
    } else {
      return {
        itemCount: 0,
        dicountPrice: 0,
        totalValue: 0,
      };
    }
  },
};

export default cartItemsSelector;
