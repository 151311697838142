/** @format */

import { useEffect } from "react";
import { Provider } from "react-redux";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import AllRoutes from "./routes";
import { persistor, store } from "./store/store";
import "aos/dist/aos.css";

const App = () => {
   const removeConsole = () => {
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
         console.log = () => {};
      }
   };

   useEffect(() => {
      removeConsole();
   }, []);

   let theme = createTheme({
      typography: {
         allVariants: {
            fontFamily: "DM Sans",
         },
         h4: {
            fontWeight: 600,
            color: "#000",
            marginBottom: "2%",
         },
         h2: {
            fontWeight: 700,
            color: "#000",
         },
         body1: {
            fontWeight: 500,
            color: "#878C8C",
            fontSize: "1.2rem",
         },
      },
   });

   theme = responsiveFontSizes(theme);

   return (
      <Provider store={store}>
         <ThemeProvider theme={theme}>
            <PersistGate
               persistor={persistor}
               loading={null}>
               <AllRoutes />
            </PersistGate>
         </ThemeProvider>
      </Provider>
   );
};

export default App;
