import React from "react";

type Props = {
	open?: boolean;
	transform?: string;
	Totransform?: string;
	type?: number;
};

function RightArrow({ open, type, transform, Totransform }: Props) {
	if (type === 1) {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<mask
					id="mask0_3549_8242"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="20"
					height="20">
					<rect
						width="20"
						height="20"
						fill="#D9D9D9"
					/>
				</mask>
				<g mask="url(#mask0_3549_8242)">
					<path
						d="M12.159 14.264C11.9104 14.5126 11.5065 14.5097 11.2616 14.2576C11.0216 14.0106 11.0244 13.6166 11.268 13.373L14.0115 10.6295H4.20701C3.86184 10.6295 3.58203 10.3497 3.58203 10.0045C3.58203 9.65932 3.86184 9.37951 4.20701 9.37951H14.0115L11.268 6.63594C11.0244 6.3924 11.0216 5.99841 11.2616 5.75139C11.5065 5.49928 11.9104 5.49638 12.159 5.74493L15.9135 9.49941C16.1924 9.77836 16.1924 10.2306 15.9135 10.5096L12.159 14.264Z"
						fill="#FF4700"
					/>
				</g>
			</svg>
		);
	} else if (type === 2) {
		return (
			<svg
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.47266 2.04094L7.73266 5.30094C8.11766 5.68594 8.11766 6.31594 7.73266 6.70094L4.47266 9.96094"
					stroke="#0F191A"
					strokeOpacity="0.5"
					strokeWidth="1.125"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	} else {
		return (
			<svg
				style={{
					transform: !open ? (transform ? transform : "rotate(0deg)") : Totransform ? Totransform : "rotate(90deg)",
					transition: "all 0.3s ease-in-out",
				}}
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.07992 15.0498L10.5999 8.52981C11.3699 7.7598 12.6299 7.7598 13.3999 8.5298L19.9199 15.0498"
					stroke="#292D32"
					stroke-width="1.5"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		);
	}
}

export default RightArrow;
