/** @format */

import React from "react";
import Footer from "../../component/Footer";
import Navbar from "../PreHomePage/Navbar";
import ContentTitle from "../PreHomePage/ContentTitle";

export default function AboutUS() {
   return (
      <div>
         <Navbar />
         <div className="w-full text-center mt-4">
            <ContentTitle title={"About Us"} />
         </div>
         <div className="flex flex-col items-center w-full  justify-center ">
            <div className="w-[99vw] flex items-center justify-center text-[1.5rem]">
               <p className="w-[90%] leading-10">
                  The beautiful menu came into existence to minimize the frustration experienced by users while waiting for a waiter, and, to provide an exceptional customer experience with digital
                  menus. While there are many apps that provide an option to build a digital menu, we observed that there were only a few (or none) focused on providing a good experience to the
                  restaurant’s customers. Also, restaurants were having a hard time creating their menu digitally since they had to type everything themselves (even with auto completes it’s still a
                  hassle) Our aim is to solve both of these problems with AI and we’re pretty much there. If you think we could be an interesting option to try out, we’d love to talk to you.
               </p>
            </div>
         </div>
         <Footer />
      </div>
   );
}
