/** @format */

import React, { useState, useMemo, useEffect } from "react";
import bg from "./assets/bg.png";
import logo from "../../asset/icons/logo.svg";
import playstore from "../../asset/icons/playstore.svg";
import vector1 from "../../asset/icons/vector1.svg";
import BarsIcon from "../../asset/icons/BarsIcon";
import ButtonWithArrow from "../../component/Button";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../../component/SideBar";
import { Form, Input, InputArea } from "./style";
import { useForm } from "react-hook-form";
import Footer from "../../component/Footer";
import InputField from "../../component/InputField";
import * as yup from "yup";
import { Formik } from "formik";
import contactApi from "../../api/contact/contact";
// import Header from '../PreHomePage/Header/Header';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FrontArrowIcon from "../../asset/icons/FrontArrowIcon";
import "./style.scss";
// import countryList from 'react-select-country-list';

const ContactUs = () => {
   const navigate = useNavigate();
   const [click, setClick] = useState(false);
   const initialValues = {
      name: "",
      email: "",
      pincode: "",
      phone: "",
      state: "",
      country: "",
      query: "I would like to know more about your product",
   };

   const validationSchema = yup.object({
      name: yup.string().required(),
      email: yup.string().required(),
   });
   const onSubmit = async (values: any) => {
      const { payload, error, statusCode } = await contactApi({
         name: values.name,
         email: values.email,
         pincode: values?.pincode,
         phone: values?.phone,
         state: values?.state,
         country: values?.country,
         query: values?.query,
      });

      if (statusCode === 200) {
         console.log("Contact Data", payload);
         toast("Thankyou for contacting us");
      }
      console.log(error);
   };

   // const Countries = countryList().getValues();

   // useEffect(() => {
   //   console.log(Countries);
   // }, []);

   return (
      <>
         <div className="screenContainer">
            {/* <img src={bg} className="preHomeBgImage" /> */}
            {click && <SideBar setClick={setClick} />}
            {/* <Header setClick={setClick} /> */}
            <ToastContainer />
            <div className="screenMainContainer">
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}>
                  {({ handleSubmit, isValid, isSubmitting }) => {
                     return (
                        <div className="form">
                           <Form className="form-container">
                              <div className="form-sub-container">
                                 <div className="form-group">
                                    <label htmlFor="name">How should we call you?*</label>
                                    <InputField
                                       type="text"
                                       name={"name"}
                                       id={"name"}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor="email">Email address*</label>
                                    <InputField
                                       type="text"
                                       name={"email"}
                                       id={"email"}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor="phone">Phone number (optional)</label>
                                    <InputField
                                       type="tel"
                                       name={"phone"}
                                       id={"phone"}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor="country">Your country (optional, but super useful to us)</label>
                                    <InputField
                                       type="text"
                                       name={"country"}
                                       id={"country"}
                                    />
                                 </div>
                              </div>
                              <div className="form-sub-container">
                                 <div className="form-group">
                                    <label htmlFor="state">State (optional)</label>
                                    <InputField
                                       type="text"
                                       name={"state"}
                                       id={"state"}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor="pincode">Pincode (optional)</label>
                                    <InputField
                                       type="text"
                                       name={"pincode"}
                                       id={"pincode"}
                                    />
                                 </div>

                                 <div className="form-group">
                                    <label htmlFor="query">Please let us know how we can help you</label>
                                    <InputField
                                       type="text"
                                       FieldArea
                                       name="query"
                                       id="query"
                                       placeholder="I would like to know more about your product"
                                       style={{ height: "70px" }}
                                    />
                                 </div>
                                 <div className="contact-us-btn-submit-container">
                                    <div className="contact-us-btn-submit">
                                       Submit
                                       <FrontArrowIcon />
                                    </div>
                                 </div>
                              </div>
                           </Form>

                           {/* <div>
            <img className='Image' src={vector1} />
          </div> */}
                        </div>
                     );
                  }}
               </Formik>
            </div>
         </div>
         <Footer />
      </>
   );
};

{
   /* <div className="title">PRIVACY POLICY</div> */
}

export default ContactUs;
