import styled from "@emotion/styled";

const MainContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 14.3rem;
  height: 60%;
♣`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  padding: 0.5rem 1.5rem;
  background: linear-gradient(90deg, #ffb356 -25%, #fff95b 106.97%);
  right: 0;
  left: 0;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 1.5rem;
`;
const Image = styled.img`
  width: 50%;
  height: 50%;
`;
const EmptyGifWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Container,
  EmptyGifWrapper,
  Header,
  Image,
  ItemsContainer,
  MainContainer,
};
