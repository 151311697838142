import { request } from '../axios';
import {
  SuccessResponse,
  ErrorResponse
} from '../index.type';
import { ErrorResponseHandler } from '../index';
import { PayloadProps, ResponsePayload, ResponsePayloadProps } from './models/index.type';

const url_count = async (data: PayloadProps): Promise<SuccessResponse<ResponsePayloadProps> | ErrorResponse> => {
  console.log("URL COUNT Visits:", data)
  try {
    const response = await request<ResponsePayload<ResponsePayloadProps>>({
      api: `/customer/menu/count_visits`,
      method: 'POST',
      data: data,
    });

    const result = response.data
    console.log("URL COUNT response", response)
    console.log("URL COUNT result", result)
    
    if (response.status > 300) {
      throw new Error(result.error.message);
    } else {
      return {
        statusCode: 200,
        message: 'Success',
        payload: result,
        error: null,
      };
    }
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default url_count;
