import React from "react";

function Razorpay() {
  async function handlePayment() {
    const res = await fetch(
      "https://the-beautiful-menu-backend-staging-m4aiobf47q-el.a.run.app/api/payments/order",
      { method: "POST" }
    );
    console.log("razorpay", res);
  }
  return <button onClick={handlePayment}>Pay</button>;
}

export default Razorpay;
