import React, { useState } from "react";
import AddBtnSvg from "./AddBtnSvg";
import MinusBtnSvg from "./MinusBtnSvg";
import PlusBtnSvg from "./PlusBtnSvg";
import pic1 from "./Chic.jpeg";
import { ItemContainer, ItemName, Quantity } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { HotelCartSlice } from "../../../store/HotelCart";
import HotelCartSelectors from "../../../store/HotelCart/selectors";

function HotelItem({ item }: any) {
  const dispatch = useAppDispatch();
  const { cart, itemCount } = useAppSelector(HotelCartSelectors.getCartInfo);
  const [showPlusMinusBtns, setShowPlusMinusBtns] = useState(false);
  const cartItem = cart?.find((it: any) => it.id === item.id);
  const PlusMinusBtns = (
    <div style={{ display: "flex" }}>
      <MinusBtnSvg handleSubtractItem={handleSubtractItem} />
      <div style={{ fontSize: "1.5rem", margin: "0 0.5rem" }}>
        {cartItem?.count}
      </div>
      <PlusBtnSvg handleAddItem={handleAddItem} />
    </div>
  );

  function handleAddItem() {
    if (!showPlusMinusBtns) {
      setShowPlusMinusBtns(true);
    }
    dispatch(HotelCartSlice.actions.addItem(item));
  }

  function handleSubtractItem() {
    if (cartItem.count === 1) {
      setShowPlusMinusBtns(false);
    }
    dispatch(HotelCartSlice.actions.subtractItem(item));
  }

  return (
    <ItemContainer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "100px",
            height: "90px",
            margin: "0.5rem",
            backgroundImage: `url(${pic1})`,
            borderRadius: "11px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0.3rem",
          }}
        >
          <ItemName>{item.name}</ItemName>
          <Quantity>
            {" "}
            {cartItem?.count > 0 ? `Pack of ${cartItem?.count}` : null}
          </Quantity>
        </div>
        <div
          style={{
            display: "flex",
            bottom: "1rem",
            margin: "3rem 0.5rem 0 1rem",
          }}
        >
          {showPlusMinusBtns ? (
            PlusMinusBtns
          ) : (
            <AddBtnSvg handleAddItem={handleAddItem} />
          )}
        </div>
      </div>

      <div style={{ animation: "move 0.1s" }}></div>
    </ItemContainer>
  );
}

export default HotelItem;
