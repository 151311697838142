import React from "react";

const ContactIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.625"
      y="0.625"
      width="48.75"
      height="48.75"
      rx="11.375"
      fill="white"
      stroke="#E8EBED"
      strokeWidth="1.25"
    />
    <path
      d="M34.97 31.33C34.97 31.69 34.89 32.06 34.72 32.42C34.55 32.78 34.33 33.12 34.04 33.44C33.55 33.98 33.01 34.37 32.4 34.62C31.8 34.87 31.15 35 30.45 35C29.43 35 28.34 34.76 27.19 34.27C26.04 33.78 24.89 33.12 23.75 32.29C22.6 31.45 21.51 30.52 20.47 29.49C19.44 28.45 18.51 27.36 17.68 26.22C16.86 25.08 16.2 23.94 15.72 22.81C15.24 21.67 15 20.58 15 19.54C15 18.86 15.12 18.21 15.36 17.61C15.6 17 15.98 16.44 16.51 15.94C17.15 15.31 17.85 15 18.59 15C18.87 15 19.15 15.06 19.4 15.18C19.66 15.3 19.89 15.48 20.07 15.74L22.39 19.01C22.57 19.26 22.7 19.49 22.79 19.71C22.88 19.92 22.93 20.13 22.93 20.32C22.93 20.56 22.86 20.8 22.72 21.03C22.59 21.26 22.4 21.5 22.16 21.74L21.4 22.53C21.29 22.64 21.24 22.77 21.24 22.93C21.24 23.01 21.25 23.08 21.27 23.16C21.3 23.24 21.33 23.3 21.35 23.36C21.53 23.69 21.84 24.12 22.28 24.64C22.73 25.16 23.21 25.69 23.73 26.22C24.27 26.75 24.79 27.24 25.32 27.69C25.84 28.13 26.27 28.43 26.61 28.61C26.66 28.63 26.72 28.66 26.79 28.69C26.87 28.72 26.95 28.73 27.04 28.73C27.21 28.73 27.34 28.67 27.45 28.56L28.21 27.81C28.46 27.56 28.7 27.37 28.93 27.25C29.16 27.11 29.39 27.04 29.64 27.04C29.83 27.04 30.03 27.08 30.25 27.17C30.47 27.26 30.7 27.39 30.95 27.56L34.26 29.91C34.52 30.09 34.7 30.3 34.81 30.55C34.91 30.8 34.97 31.05 34.97 31.33Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ContactIcon;
