import React from 'react';

function DeliveryIcon() {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_2943_23966)'>
        <path
          d='M32.1971 31.1828L31.8633 30.5151L33.5433 29.3935L38.0251 26.9238L38.5602 27.9563L37.6359 31.29L33.8657 32.1657L32.1971 31.1828V31.1828Z'
          fill='#4A4F60'
        />
        <path
          d='M17.7999 8.125H9.79492C9.34188 8.125 8.97461 8.49227 8.97461 8.94531V16.2773C8.97461 16.7304 9.34188 17.0977 9.79492 17.0977H17.7999C18.253 17.0977 18.6203 16.7304 18.6203 16.2773V8.94531C18.6203 8.49227 18.253 8.125 17.7999 8.125Z'
          fill='#FFE966'
        />
        <path
          d='M17.7988 8.125H15.3379C15.5555 8.125 15.7641 8.21143 15.9179 8.36526C16.0718 8.5191 16.1582 8.72775 16.1582 8.94531V16.2774C16.1582 16.495 16.0718 16.7036 15.9179 16.8575C15.7641 17.0113 15.5555 17.0977 15.3379 17.0977H17.7988C18.0164 17.0977 18.225 17.0113 18.3789 16.8575C18.5327 16.7036 18.6191 16.495 18.6191 16.2774V8.94531C18.6191 8.72775 18.5327 8.5191 18.3789 8.36526C18.225 8.21143 18.0164 8.125 17.7988 8.125V8.125Z'
          fill='#FFE236'
        />
        <path
          d='M17.698 29.8706C17.698 30.9326 17.2761 31.9511 16.5252 32.7021C15.7742 33.4531 14.7557 33.8749 13.6937 33.8749C12.6316 33.8749 11.6131 33.4531 10.8622 32.7021C10.1112 31.9511 9.68931 30.9326 9.68931 29.8706C9.68931 29.8706 9.63705 29.1338 9.92219 29.1516C10.2073 29.1694 17.6979 29.0625 17.6979 29.0625L17.698 29.8706Z'
          fill='#4A4F60'
        />
        <path
          d='M16.4672 29.0796V29.8705C16.4672 30.8259 16.1256 31.7498 15.5041 32.4755C14.8826 33.2011 14.0222 33.6807 13.0781 33.8277C13.6491 33.916 14.2324 33.8798 14.7881 33.7216C15.3438 33.5634 15.8587 33.2868 16.2975 32.9109C16.7363 32.5351 17.0886 32.0687 17.3303 31.5439C17.572 31.0191 17.6973 30.4483 17.6977 29.8705V29.0625C17.6977 29.0625 17.2045 29.0695 16.4672 29.0796Z'
          fill='#3B3F4D'
        />
        <path
          d='M15.2488 30.0428C15.1415 30.704 14.544 31.4208 13.6931 31.4137C12.8317 31.4066 12.1611 30.6613 12.1504 29.871C12.1506 29.5914 12.2269 29.3171 12.3709 29.0775C12.515 28.8379 12.7214 28.6419 12.9682 28.5106C13.739 28.1056 14.7363 28.4782 15.1062 29.152C15.2507 29.4251 15.3008 29.7383 15.2488 30.0428V30.0428Z'
          fill='white'
        />
        <path
          d='M31.6066 29.208L6.88958 29.5135C5.5149 27.1522 7.52967 22.3382 10.9422 20.366C12.7351 19.3299 14.5656 19.2794 15.5951 19.3404H21.8823C20.5698 21.3386 20.7067 23.8884 22.1368 25.5258C23.2907 26.847 25.1498 27.419 26.8113 27.0531C30.7942 26.176 30.5455 21.5704 29.8867 18.4592C29.8371 18.2248 29.1333 15.7048 28.7799 14.6709L31.4187 15.2709C32.3805 17.9467 34.6092 24.9429 34.6092 24.9429L31.6066 29.208Z'
          fill='#FF6666'
        />
        <path
          d='M15.5959 19.3404C15.2173 19.3178 14.8376 19.3178 14.459 19.3404H15.5959ZM20.9072 25.5258C21.9699 26.7425 23.6307 27.3232 25.1843 27.1218C24.0085 26.9779 22.9254 26.4106 22.1377 25.5258C20.7076 23.8884 20.5707 21.3386 21.8831 19.3404H20.6527C19.3403 21.3386 19.4771 23.8884 20.9072 25.5258ZM31.4195 15.2709L28.7807 14.6709C28.8115 14.7611 28.8451 14.8631 28.8807 14.9733L30.1891 15.2709C31.1509 17.9467 33.3797 24.9429 33.3797 24.9429H34.6101C34.6101 24.9429 32.3813 17.9467 31.4196 15.2709H31.4195Z'
          fill='#FF4E4E'
        />
        <path
          d='M38.8867 27.1309C39.3004 27.5644 39.6115 28.0853 39.7971 28.655C39.9827 29.2248 40.038 29.8289 39.9591 30.4229C39.8801 31.0169 39.6689 31.5856 39.3409 32.0871C39.013 32.5886 38.5767 33.0101 38.0641 33.3206C37.5516 33.6311 36.9759 33.8225 36.3796 33.8809C35.7832 33.9393 35.1813 33.8632 34.6183 33.658C34.0553 33.4529 33.5455 33.124 33.1265 32.6957C32.7075 32.2673 32.3899 31.7504 32.1973 31.183'
          fill='#4A4F60'
        />
        <path
          d='M38.8878 27.1309L38.0617 27.6313C38.4447 28.1891 38.68 28.8349 38.7457 29.5083C38.8114 30.1817 38.7053 30.8608 38.4373 31.4821C38.1694 32.1034 37.7483 32.6466 37.2134 33.0611C36.6786 33.4755 36.0474 33.7476 35.3789 33.8519C36.2054 33.9799 37.0513 33.8458 37.7977 33.4685C38.5441 33.0912 39.1536 32.4896 39.5407 31.7482C39.9278 31.0068 40.0729 30.1628 39.9558 29.3347C39.8387 28.5065 39.4652 27.7359 38.8877 27.1309H38.8878Z'
          fill='#3B3F4D'
        />
        <path
          d='M36.7301 28.5414C36.9683 28.6716 37.1679 28.8622 37.3088 29.0942C37.4497 29.3261 37.527 29.5912 37.5328 29.8625C37.5385 30.1339 37.4726 30.4019 37.3417 30.6397C37.2108 30.8774 37.0195 31.0764 36.7871 31.2166C36.5547 31.3568 36.2894 31.4332 36.0181 31.4381C35.7467 31.443 35.4788 31.3763 35.2415 31.2446C35.0042 31.113 34.8058 30.921 34.6663 30.6882C34.5269 30.4553 34.4513 30.1898 34.4473 29.9184C34.4898 29.669 34.5857 29.4317 34.7282 29.2227C34.8708 29.0137 35.0567 28.8378 35.2734 28.7071C35.49 28.5764 35.7323 28.494 35.9837 28.4654C36.2351 28.4368 36.4897 28.4627 36.7302 28.5414H36.7301Z'
          fill='white'
        />
        <path
          d='M6.89062 29.5134C7.83234 25.1522 11.9031 22.692 15.2139 23.5402C17.6128 24.1548 19.5058 26.479 19.9474 29.3452L6.89062 29.5134Z'
          fill='#FF4E4E'
        />
        <path
          d='M15.2139 23.5404C14.3637 23.3298 13.4763 23.319 12.6212 23.5087C12.6652 23.5188 12.7092 23.5291 12.753 23.5404C15.1518 24.1549 17.0449 26.4791 17.4864 29.3453L6.89784 29.4817C6.89555 29.4924 6.89292 29.5029 6.89062 29.5136L19.9474 29.3454C19.5058 26.4792 17.6128 24.1549 15.2139 23.5404V23.5404Z'
          fill='#FF3636'
        />
        <path
          d='M32.8632 25.0022L33.1072 24.857C33.6625 24.5266 34.2774 24.3088 34.9169 24.216C35.5563 24.1232 36.2078 24.1573 36.8341 24.3163C37.4604 24.4753 38.0492 24.7561 38.5669 25.1427C39.0847 25.5293 39.5212 26.0141 39.8517 26.5694L31.151 31.7467C30.8206 31.1914 30.6028 30.5765 30.51 29.937C30.4172 29.2976 30.4513 28.6461 30.6103 28.0198C30.7693 27.3935 31.0501 26.8047 31.4366 26.287C31.8232 25.7692 32.308 25.3326 32.8632 25.0022V25.0022Z'
          fill='#FF4E4E'
        />
        <path
          d='M39.8504 26.569C39.3059 25.6544 38.4795 24.9409 37.4952 24.5356C36.5109 24.1304 35.4217 24.0552 34.391 24.3214C35.1705 24.522 35.8887 24.9108 36.4828 25.4537C36.6094 25.5711 36.7068 25.7165 36.7673 25.8782C36.8278 26.0399 36.8497 26.2134 36.8313 26.3851C36.8129 26.5567 36.7546 26.7217 36.6612 26.8669C36.5678 27.0121 36.4418 27.1334 36.2932 27.2213L30.6465 30.5815C30.7633 30.9899 30.9325 31.3814 31.1498 31.7463L39.8504 26.569Z'
          fill='#FF3636'
        />
        <path
          d='M29.5482 14.0781H25.4277C25.2646 14.0781 25.1081 14.0133 24.9927 13.8979C24.8773 13.7825 24.8125 13.6261 24.8125 13.4629C24.8125 13.2997 24.8773 13.1432 24.9927 13.0279C25.1081 12.9125 25.2646 12.8477 25.4277 12.8477H29.5482C29.7113 12.8477 29.8678 12.9125 29.9832 13.0279C30.0986 13.1432 30.1634 13.2997 30.1634 13.4629C30.1634 13.6261 30.0986 13.7825 29.9832 13.8979C29.8678 14.0133 29.7113 14.0781 29.5482 14.0781Z'
          fill='#4A4F60'
        />
        <path
          d='M30.1249 11.6533H32.3697C32.4349 11.6533 32.4975 11.6792 32.5437 11.7254C32.5898 11.7716 32.6157 11.8341 32.6157 11.8994V15.0241C32.6157 15.0894 32.5898 15.152 32.5437 15.1982C32.4975 15.2443 32.4349 15.2702 32.3697 15.2702H30.1249C29.6452 15.2702 29.1852 15.0797 28.8461 14.7406C28.5069 14.4014 28.3164 13.9414 28.3164 13.4618C28.3164 12.9821 28.5069 12.5222 28.8461 12.183C29.1852 11.8439 29.6452 11.6533 30.1249 11.6533V11.6533Z'
          fill='#FF4E4E'
        />
        <path
          d='M32.3711 11.6533H31.1406C31.2059 11.6533 31.2685 11.6792 31.3146 11.7254C31.3608 11.7716 31.3867 11.8341 31.3867 11.8994V15.0241C31.3867 15.0894 31.3608 15.152 31.3146 15.1982C31.2685 15.2443 31.2059 15.2702 31.1406 15.2702H32.3711C32.4364 15.2702 32.499 15.2443 32.5451 15.1982C32.5913 15.152 32.6172 15.0894 32.6172 15.0241V11.8994C32.6172 11.8341 32.5913 11.7716 32.5451 11.7254C32.499 11.6792 32.4364 11.6533 32.3711 11.6533V11.6533Z'
          fill='#FF3636'
        />
        <path
          d='M6.51328 10.7656H3.07617C2.913 10.7656 2.75651 10.7008 2.64114 10.5854C2.52576 10.47 2.46094 10.3136 2.46094 10.1504C2.46094 9.98722 2.52576 9.83073 2.64114 9.71535C2.75651 9.59998 2.913 9.53516 3.07617 9.53516H6.51328C6.67645 9.53516 6.83294 9.59998 6.94832 9.71535C7.0637 9.83073 7.12852 9.98722 7.12852 10.1504C7.12852 10.3136 7.0637 10.47 6.94832 10.5854C6.83294 10.7008 6.67645 10.7656 6.51328 10.7656ZM6.51328 13.2266H1.8457C1.68253 13.2266 1.52605 13.1617 1.41067 13.0464C1.29529 12.931 1.23047 12.7745 1.23047 12.6113C1.23047 12.4482 1.29529 12.2917 1.41067 12.1763C1.52605 12.0609 1.68253 11.9961 1.8457 11.9961H6.51328C6.67645 11.9961 6.83294 12.0609 6.94832 12.1763C7.0637 12.2917 7.12852 12.4482 7.12852 12.6113C7.12852 12.7745 7.0637 12.931 6.94832 13.0464C6.83294 13.1617 6.67645 13.2266 6.51328 13.2266ZM6.51328 15.6875H0.615234C0.452064 15.6875 0.295577 15.6227 0.180198 15.5073C0.0648192 15.3919 0 15.2354 0 15.0723C0 14.9091 0.0648192 14.7526 0.180198 14.6372C0.295577 14.5218 0.452064 14.457 0.615234 14.457H6.51328C6.67645 14.457 6.83294 14.5218 6.94832 14.6372C7.0637 14.7526 7.12852 14.9091 7.12852 15.0723C7.12852 15.2354 7.0637 15.3919 6.94832 15.5073C6.83294 15.6227 6.67645 15.6875 6.51328 15.6875Z'
          fill='#4A4F60'
        />
        <path
          d='M21.7259 17.0977H12.4146C11.7951 17.0977 11.293 17.5998 11.293 18.2192C11.293 18.8387 11.7951 19.3408 12.4146 19.3408H21.7259C22.3453 19.3408 22.8475 18.8387 22.8475 18.2192C22.8475 17.5998 22.3453 17.0977 21.7259 17.0977Z'
          fill='#4A4F60'
        />
        <path
          d='M21.7246 17.0977H19.2637C19.4118 17.0964 19.5586 17.1245 19.6958 17.1803C19.833 17.2361 19.9578 17.3185 20.063 17.4228C20.1681 17.5271 20.2516 17.6511 20.3086 17.7878C20.3655 17.9245 20.3949 18.0712 20.3949 18.2193C20.3949 18.3674 20.3655 18.514 20.3086 18.6507C20.2516 18.7874 20.1681 18.9115 20.063 19.0158C19.9578 19.12 19.833 19.2024 19.6958 19.2582C19.5586 19.314 19.4118 19.3421 19.2637 19.3408H21.7246C21.8727 19.3421 22.0196 19.314 22.1568 19.2582C22.294 19.2024 22.4187 19.12 22.5239 19.0158C22.6291 18.9115 22.7125 18.7874 22.7695 18.6507C22.8265 18.514 22.8558 18.3674 22.8558 18.2193C22.8558 18.0712 22.8265 17.9245 22.7695 17.7878C22.7125 17.6511 22.6291 17.5271 22.5239 17.4228C22.4187 17.3185 22.294 17.2361 22.1568 17.1803C22.0196 17.1245 21.8727 17.0964 21.7246 17.0977V17.0977Z'
          fill='#3B3F4D'
        />
        <path
          d='M41.3848 34.5762H0.615234C0.452064 34.5762 0.295577 34.5114 0.180198 34.396C0.0648191 34.2806 0 34.1241 0 33.9609C0 33.7978 0.0648191 33.6413 0.180198 33.5259C0.295577 33.4105 0.452064 33.3457 0.615234 33.3457H41.3848C41.5479 33.3457 41.7044 33.4105 41.8198 33.5259C41.9352 33.6413 42 33.7978 42 33.9609C42 34.1241 41.9352 34.2806 41.8198 34.396C41.7044 34.5114 41.5479 34.5762 41.3848 34.5762V34.5762Z'
          fill='#4A4F60'
        />
      </g>
      <defs>
        <clipPath id='clip0_2943_23966'>
          <rect width='42' height='42' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DeliveryIcon;
