import React from 'react';

function OrderUpdatedIcon() {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginLeft: '4px' }}
    >
      <path
        d='M14.3332 8.83333C14.3332 12.0533 11.7198 14.6667 8.49984 14.6667C5.27984 14.6667 2.6665 12.0533 2.6665 8.83333C2.6665 5.61333 5.27984 3 8.49984 3C11.7198 3 14.3332 5.61333 14.3332 8.83333Z'
        stroke='#43D9B9'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.5 5.3335V8.66683'
        stroke='#43D9B9'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.5 1.3335H10.5'
        stroke='#43D9B9'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default OrderUpdatedIcon;
