/** @format */

import React from "react";
import BackArrowIcon from "../../../asset/icons/BackArrowIcon";
import {
   ConfirmationText,
   Container,
   ContentText,
   ContentText2,
   ContentText3,
   ImageContainer,
   ModalContainer,
   ModalHeaderText,
   Name,
   PaymentTypeModalContent,
   PaymentTypeModalHeader,
   Quantity,
   TextContainer,
} from "./styles";
import { Text } from "../../Text";
import { Header } from "../../../pages/CheckoutPage/styles";
import { ItemContainer } from "./styles";
import { ButtonsContainer, QuantityButton, QuantityText } from "../../FoodItemCheckoutPage/styles";
import MinusIcon from "../../../asset/icons/MinusIcon";
import PlusIcon from "../../../asset/icons/PlusIcon";
import BottomModal from "../../BottomModal";
import ButtonWithArrow from "../../Button";
import InfoSvg from "./InfoSvg";
import RedSvg from "./RedSvg";
import CrossSvg from "./CrossSvg";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import HotelCartSelectors from "../../../store/HotelCart/selectors";
import { HotelCartSlice } from "../../../store/HotelCart";
import { useNavigate } from "react-router-dom";
import "./style.scss";

function CartScreen() {
   const { cart, itemCount } = useAppSelector(HotelCartSelectors.getCartInfo);
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const PayLaterBottomModal = (
      <BottomModal height="25%">
         <ModalContainer>
            <ModalHeaderText>Payment Type</ModalHeaderText>
            <div
               style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
               }}>
               <ContentText>Pay Now</ContentText>

               <ContentText2>Change</ContentText2>
            </div>
            <div
               style={{
                  display: "flex",
                  alignItems: "flex-start",
               }}>
               {/*
<ContentText3>
            <InfoSvg />
            <span style={{ marginLeft: "0.4rem" }}>
              The hotel staff will contact you to confirm your
            </span>
          </ContentText3>
            */}
            </div>

            <div style={{ marginBottom: "1rem" }}>
               <ButtonWithArrow
                  disabled={false}
                  background="#0F6656"
                  fontColor="white"
                  buttonText={"Proceed"}
                  showArrow={true}
               />
            </div>
         </ModalContainer>
      </BottomModal>
   );

   const PaymentTypeModal = (
      <BottomModal height="33%">
         <ModalContainer>
            <PaymentTypeModalHeader>Payment Type</PaymentTypeModalHeader>
            <div
               style={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  top: "1.5rem",
                  right: "3rem",
               }}>
               <CrossSvg />
            </div>
            <div
               style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "2rem 2rem 1rem 2rem",
               }}>
               <RedSvg />
               <PaymentTypeModalContent>Pay now</PaymentTypeModalContent>
            </div>
            <div
               style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 2rem 2rem 2rem",
               }}>
               <RedSvg />
               <PaymentTypeModalContent>Pay later</PaymentTypeModalContent>
            </div>
            <div
               style={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
               }}>
               <ButtonWithArrow
                  disabled={false}
                  background="#0F6656"
                  fontColor="white"
                  buttonText={"Confirm Payment Type"}
                  showArrow={true}
               />
            </div>
         </ModalContainer>
      </BottomModal>
   );

   const PaymentInfoModal = (
      <BottomModal>
         <ModalContainer>
            <PaymentTypeModalHeader>Payment Type</PaymentTypeModalHeader>
            <ConfirmationText>Do you want to place the request to the hotel?</ConfirmationText>

            <div
               style={{
                  margin: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
               }}>
               <ButtonWithArrow
                  disabled={false}
                  background="#0F6656"
                  fontColor="white"
                  buttonText={"Yes, Request"}
                  showArrow={false}
               />
               <ButtonWithArrow
                  disabled={false}
                  background="#ffffff"
                  fontColor="#0F191A"
                  buttonText={"Cancel"}
                  showArrow={false}
                  border="1px solid #0F191A;"
               />
            </div>
         </ModalContainer>
      </BottomModal>
   );

   function handleAddItem(cartItem: any) {
      dispatch(HotelCartSlice.actions.addItem(cartItem));
   }

   function handleSubtractItem(cartItem: any) {
      dispatch(HotelCartSlice.actions.subtractItem(cartItem));
   }

   return (
      <div>
         <Header style={{ width: "90%" }}>
            <span onClick={() => navigate("/hotel/amenities")}>
               <BackArrowIcon />
            </span>
            <div className="cart-screen-item-details">Item Details</div>
         </Header>
         {cart.map((cartItem: any) => {
            return (
               <div className="cart-screen-item-container">
                  <div className="cart-screen-image-container"> </div>
                  <div className="cart-screen-text-container">
                     <Name>{cartItem.name}</Name>
                     <Quantity>Pack of {cartItem.count}</Quantity>
                  </div>

                  <div className="cart-screen-btns-container">
                     <div
                        className="art-screen-qty-btns"
                        onClick={() => handleSubtractItem(cartItem)}>
                        <MinusIcon />
                     </div>
                     <div className="cart-screen-qty-text">{cartItem.count}</div>
                     <div
                        className="cart-screen-qty-btns"
                        onClick={() => handleAddItem(cartItem)}>
                        <PlusIcon />
                     </div>
                  </div>
               </div>
            );
         })}

         {PayLaterBottomModal}
      </div>
   );
}

export default CartScreen;
