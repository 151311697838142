/** @format */

import React from "react";
import logo from "../../asset/icons/logo.svg";
import "./style.scss";

function Logo() {
   return (
      <div className="logo-container">
         <img
            className="logo"
            src={logo}
         />
         <div className="title font-bold">The Beautiful menu</div>
      </div>
   );
}

export default Logo;
