/** @format */

import React from "react";
import "./index.scss";
import { useAppSelector } from "../../store/hooks";
import showMenuSelector from "../../store/showMenu/selectors";

export default function Index({ offerValue, discount, maximum_allowed_discount, total_suborder_amount }) {
   const { currency } = useAppSelector(showMenuSelector.showMenu);

   console.log("DISCOUNTT", discount);

   return (
      <div className="offers-message-wrapper">
         <div className="offers-message-container">
            <img
               className="offer-message-hurray-icon"
               src={require("../../asset/images/HurrayIcon.png")}
            />
            <div>
               {/* <div className="offers-message-row">
                  Add items worth {currency}{offerValue} and get{" "}
                  <div className="offer-bold">
                     {discount} off {maximum_allowed_discount > 0 && `upto {currency}${maximum_allowed_discount}`}
                  </div>
               </div> */}
               <div className="offers-message-row">
                  Add {currency}
                  {offerValue - total_suborder_amount} more and get
                  <div className="offer-bold">
                     {discount} off
                     {/* {maximum_allowed_discount > 0 && `upto {currency}${maximum_allowed_discount}`} */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
