import React from "react";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import { useNavigate, useParams } from "react-router-dom";

export default function LoyaltyInfo() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-3 ">
      <BackArrow onClick={goBack} />
      <h2 className="font-bold text-3xl font-custom mt-4">
        Why we need your info?
      </h2>
      <p className="text-lg mt-4 tracking-tighter font-custom">
        At The Beautiful Menu, we deeply value your trust and privacy. The
        personal information you share with us plays a vital role in enhancing
        your experience with our services.
      </p>
      <p className="text-lg my-4 font-semibold font-custom">
        Here's how your data helps us serve you better:
      </p>
      <ul className="list-outside list-decimal text-lg tracking-tighter px-3">
        <li className="mb-3 font-custom">
          <span className="font-semibold tracking-tight font-custom">
            Enhanced Personalization:
          </span>{" "}
          Your date of birth lets us surprise you with special offers on your
          special days.
        </li>
        <li className="mb-3 font-custom font-normal">
          <span className="font-semibold tracking-tight font-custom">
            Swift and Accurate Delivery:
          </span>{" "}
          Your address and pin code ensure that your orders reach you quickly
          and without any hassle.
        </li>
        <li className="mb-3 font-custom">
          <span className="font-semibold tracking-tight font-custom">
            Seamless Communication:
          </span>{" "}
          Providing your mobile number helps us keep you updated about your
          order status and exclusive deals.
        </li>
        <li className="mb-3 font-custom">
          <span className="font-semibold tracking-tight font-custom">
            Tailored Experience:
          </span>{" "}
          Understanding your preferences and order history enables us to
          recommend dishes that you're likely to love.
        </li>
      </ul>
      <p className="text-lg mt-4 tracking-tighter font-custom">
        We adhere to strict data protection and privacy policies, ensuring that
        your personal information is always secure and used solely for improving
        your experience with us. Your data is yours - you have complete control
        over it. You can view, edit, or delete it at any time. Thank you for
        your trust in The Beautiful Menu. We're committed to keeping your data
        safe and making your experience with us enjoyable and personalized."
      </p>
    </div>
  );
}
