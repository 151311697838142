/** @format */

import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/styles";

export default function Index(props) {
  const {
    label,
    onChange,
    error,
    helperText,
    id,
    value,
    disabled,
    placeholder,
    style,
  } = props;

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderRadius: 10,
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "#0F6656",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0F6656",
      },
      "& .MuiInputLabel-outlined": {
        fontWeight: "500",
        fontFamily: "TWK Everett",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#0F191A",
        fontWeight: "600",
      },
    },
  });
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id={id}
      placeholder={placeholder}
      style={{
        marginTop: 20,
        marginBottom: 10,
      }}
      fullWidth
      error={error}
      label={label}
      onChange={onChange}
      variant="outlined"
      value={value}
      helperText={helperText}
      disabled={disabled}
    />
  );
}
