const colors: string[] = [
  // "linear-gradient(90deg, #FFB356 -25%, #FFF95B 106.97%)",
  // "linear-gradient(90deg, #D4ACFB -72.4%, #B84FCE 160.93%)",
  // "linear-gradient(90deg, #FF0F7B -72.4%, #E776FF 160.93%)",
  // "linear-gradient(90deg, #8DE9D5 -72.4%, #32C4C0 160.93%)",
  // "linear-gradient(90deg, #4184CC -72.4%, #D4E9F6 160.93%)",
  // "linear-gradient(90deg, #43D9B9 -72.4%, #C4FFCA 160.93%)",
  // "linear-gradient(90deg, #FFCE5D -72.4%, #FFEACC 160.93%)",
  // "linear-gradient(90deg, #8DE9D5 -72.4%, rgba(45, 107, 77, 0.5) 160.93%)",
  // "linear-gradient(90deg, rgba(45, 107, 77, 0.5) -72.4%, rgba(45, 107, 77, 0.04) 160.93%)",
  // "linear-gradient(90deg, rgba(230, 57, 70, 0.5) -72.4%, rgba(230, 57, 70, 0.25) 160.93%)",
  // "linear-gradient(90deg, rgba(45, 107, 77, 0.25) -72.4%, rgba(45, 107, 77, 0.5) 160.93%)",
  // "linear-gradient(90deg, #C4FFCA -72.4%, rgba(45, 107, 77, 0.5) 160.93%)",
  // "linear-gradient(90deg, #FFEACC -72.4%, rgba(249, 202, 65, 0.5) 160.93%)",
  '#FFC6FF',
  '#BDB2FF',
  '#9BF6FF',
  '#DFE7FD',
  '#CAFFBF',
  '#FDFFB6',
  '#FFD7A6'
];

export default colors;
