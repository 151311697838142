import styled from '@emotion/styled';

const Input = styled.input`
  width: 85%;
  border: 1px;
  margin: 0 auto 10px auto;
  padding: 0.5rem;
  border-radius: 0;
  font-style: normal;
  font-family: Poppins;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1rem;
  letter-spacing: -0.3px;
  color: #000;
  background: rgba(217, 217, 217, 0.4);
  &::placeholder {
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: -0.3px;
    font-weigt: 500;
    line-height: 2rem;
    color: rgba(15, 25, 26, 0.5);
  }
`;
const InputArea = styled.textarea`
  width: 85%;
  border: 1px;
  margin: 0 auto 10px auto;
  padding: 0.3rem;
  border-radius: 0;
  font-style: normal;
  margin-top: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1rem;
  font-family: Poppins;
  letter-spacing: -0.3px;
  color: #000;
  background: rgba(217, 217, 217, 0.4);
  &::placeholder {
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 1.5rem;
    color: rgba(15, 25, 26, 0.5);
  }
`;

const Form = styled.form``;

export { Form, Input, InputArea };
