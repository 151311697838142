/** @format */

import styled from "@emotion/styled";

const HeadingText = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 24.4px;
   line-height: 32px;
   letter-spacing: -0.3px;
   color: #0f6656;
   margin: 1.5rem 0.2rem 0.75rem 0.5rem;
`;

const ItemContainer = styled.div`
   border: 1.5px solid rgba(15, 25, 26, 0.25);
   border-radius: 12px;
   position: relative;
   margin-bottom: 0.75rem;
`;

const ItemName = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 24.4px;
   line-height: 32px;
   letter-spacing: -0.3px;
   color: #0f191a;
`;

const Quantity = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
   margin-top: 0.5rem;
`;

export { HeadingText, ItemContainer, ItemName, Quantity };
