import { useState } from "react";
import Input from "../../component/Input";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Button from "../../component/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import ExclusiveClubModal from "../../component/ExclusiveClubModal/ExclusiveClubModal";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";

export default function ExclusiveClub() {
  const navigate = useNavigate();
  const { mid, qid } = useParams();
  const [showExclusiveClubModal, setExclusiveClubModal] = useState(false);
  const [inputValues, setinputValues] = useState({
    full_name: "",
    emailaddress: "",
    phone_number: "",
    birth_date: "05/19/2001",
    address: "",
    pincode: "",
  });

  const createUserValidationSchema = yup.object({
    full_name: yup.string().required("This field is required"),
    emailaddress: yup
      .string()
      .email("Please enter valid email")
      .required("This field is required"),
    phone_number: yup
      .string()
      .matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid phone number.",
        excludeEmptyString: false,
      })
      .required("This field is required"),
    address: yup.string().required("This field is required"),
    pincode: yup.string().required("This field is required"),
    birth_date: yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: inputValues?.full_name,
      email_address: inputValues?.email,
      phone_number: inputValues?.phone_number,
      birth_date: inputValues?.birth_date,
      address: inputValues?.address,
      pincode: inputValues?.pincode,
    },
    validationSchema: createUserValidationSchema,
    onSubmit: (values) => {},
  });

  const navigateToTandC = () => {
    window.open(
      "https://www.thebeautifulmenu.com/terms-and-conditions",
      "_blank"
    );
  };

  const goToInfo = () => {
    navigate(`/${mid}/${qid}/loyaltyinfo`);
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "#0F6656",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0F6656",
      },
    },
  });

  const classes = useStyles();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Grid
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        container
        spacing={2}
        padding={"2rem 2rem 0"}
      >
        <div className="flex items-center ">
          <div>
            <BackArrow onClick={goBack} />
          </div>
          <div className="loyalty-program-title">Join Exclusive Club</div>
        </div>
        <InfoOutlinedIcon style={{ fontSize: "2rem" }} onClick={goToInfo} />
      </Grid>
      <div className="px-3">
        <h2 className="text-3xl font-bold font-custom my-4">
          Enter your details to unlock exclusive perks!
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="full_name"
            label="Name"
            error={formik.touched.full_name && Boolean(formik.errors.full_name)}
            helperText={formik.touched.full_name && formik.errors.full_name}
            // value={formik.values.full_name}
          />
          <Input
            id="email_address"
            label="Email address"
            error={
              formik.touched.email_address &&
              Boolean(formik.errors.email_address)
            }
            helperText={
              formik.touched.email_address && formik.errors.email_address
            }
            // value={formik.values.full_name}
          />
          <Input
            id="phone_number"
            label="Mobile number (Optional)"
            error={
              formik.touched.phone_number && Boolean(formik.errors.phone_number)
            }
            helperText={
              formik.touched.phone_number && formik.errors.phone_number
            }
            // value={formik.values.full_name}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                className={classes.root}
                views={["day", "month", "year"]}
                format="DD-MM-YYYY"
                label="Data of birth"
                value={dayjs(inputValues?.birth_date)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Input
            id="address"
            label="Address"
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            // value={formik.values.full_name}
          />
          <Input
            id="pincode"
            label="Pincode"
            error={formik.touched.pincode && Boolean(formik.errors.pincode)}
            helperText={formik.touched.pincode && formik.errors.pincode}
            // value={formik.values.full_name}
          />
        </form>
        <div className="text-[#0f191a] font-custom text-xs mt-2 px-2">
          <span>By subscribing, you agree to the</span>
          <span onClick={navigateToTandC} className="text-[#4361ee] ml-1">
            Terms and conditions
          </span>
        </div>
        <div className="my-4">
          <Button
            className="text-xl "
            type="button"
            background="#FF4700"
            // {iseditiing btn color #E7E8E9}
            fontColor="#fff"
            buttonText="Join & Celebrate"
            onClickHandler={() => setExclusiveClubModal(true)}
          />
        </div>
      </div>
      <ExclusiveClubModal
        open={showExclusiveClubModal}
        onClose={() => {
          setExclusiveClubModal(false);
        }}
      />
    </div>
  );
}
