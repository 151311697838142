/** @format */

import { X } from "react-feather";
import React, { useState, useEffect } from "react";

import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "@mui/material";
import createOrder from "../../api/order/createOrder";
import { cartActions } from "../../store/cart/reducer";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import qrSelectors from "../../store/Qr/selectors";
import userSelectors from "../../store/user/selectors";
import ButtonWithArrow from "../Button";
import { Text } from "../Text";
import { useDispatch } from "react-redux";
import { orderSlice } from "../../store/order/index";
import { orderActions } from "../../store/order/orderSlice";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import DiningInIcon from "../../asset/icons/DiningInIcon";
import DeliveryIcon from "../../asset/icons/DeliveryIcon";
import TakeAwayIcon from "../../asset/icons/TakeAwayIcon";
import "./style.scss";
import Tick from "../../asset/icons/Tick";
import showMenuSelector from "../../store/showMenu/selectors";
import Button from "../Button";
import { classNames } from "../../utils/classNames";

const transformDataForCreateOrder = (data: any) => {
	const temp = data.map((item: any) => {
		return {
			item_id: item.item_id,
			count: item.count,
			price: parseFloat(item.price),
			discount: parseFloat(item.discount || 0),
			total_price: parseFloat(item.price) * parseFloat(item.count),
			price_after_discount: parseFloat(item.count) * (parseFloat(item.price) - parseFloat(item.price) * (0.01 * parseFloat(item.discount))),
			is_recommended_item: false,
			out_of_stock: "false",
		};
	});

	return temp;
};

const OrderConfirmationModal = ({ handleModalClose, isOpen, selected, onClose, isAlert }: OrderConfirmationModalProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const dispatchReducer = useDispatch();
	const checkMenu = useAppSelector(showMenuSelector.showMenu);
	const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
	const { mid, qid }: any = useParams();
	const allItems = useAppSelector(cartItemsSelector.getAllCartItems(mid));
	console.log("SHOWMENU ", checkMenu);

	const handleTakeAway = () => {
		// navigate(`/payment/${mid}/${qid}}`);
	};

	const [OrderType, setOrderType] = useState([
		{
			name: "Dine-in",
			icon: <DiningInIcon />,
			onClick: handleTakeAway,
			enabled: checkMenu?.dineIn,
		},
		{
			name: "Delivery",
			icon: <DeliveryIcon />,
			onClick: handleTakeAway,
			enabled: checkMenu?.delivery,
		},
		{
			name: "Take away",
			icon: <TakeAwayIcon />,
			onClick: handleTakeAway,
			enabled: checkMenu?.takeAway,
		},
	]);

	const [isSelect, setSelect] = useState(selected);

	const onSelect = (temp: any) => {};

	return (
		<Modal open={isOpen}>
			<div className="order-confirmation-modal-container">
				<Toaster position="bottom-center" />
				<div className={classNames(isAlert ? "text-[1.3rem]" : "", "order-confirmation-modal-header pt-4 w-[100%] flex items-center justify-center")}>
					<div className="w-[80%]">{isAlert ? "Are you sure you want to confirm this order ? " : "Order collection"}</div>
					{/* <X
            className='order-confirmation-modal-x'
            onClick={() => handleModalClose(isSelect)}
          /> */}
				</div>
				{!isAlert && <div className="order-confirmation-modal-text">Choose how you would like to collect your order ?</div>}
				{!isAlert &&
					OrderType.map((item, index) => {
						const { name, icon, onClick } = item;
						if ((qid === undefined && item.name === "Dine-in") || !item.enabled) {
							return null;
						}
						return (
							<div
								style={{ opacity: item.enabled ? 1 : 0.5 }}
								className="order-confirmation-modal-btn-container"
								key={index}>
								<div
									style={{
										border: isSelect === name ? "2px solid #0F6656" : "1px solid #000000",
									}}
									className="order-confirm-option"
									onClick={() => {
										if (item.enabled) {
											onClick();
											const temp = name;
											setSelect(temp);
										} else {
											toast.error(`This eatery not accepting ${name.toLowerCase()} now`);
										}
										// console.log(isSelect);
									}}>
									{icon}
									<div className="order-option-text">{name}</div>
									{isSelect === name ? <Tick /> : <div></div>}{" "}
								</div>
							</div>
						);
					})}
				<div
					onClick={() => handleModalClose(isSelect)}
					className="order-confirm-modal-button">
					Confirm
				</div>
				<div
					onClick={onClose}
					className="close-button">
					Cancel
				</div>
			</div>
		</Modal>
	);
};

interface OrderConfirmationModalProps {
	handleModalClose: any;
	isOpen: any;
	selected: any;
	onClose: any;
	isAlert?: boolean;
}

export default OrderConfirmationModal;
