/** @format */

import styled from "@emotion/styled";

const Container = styled.div``;

const ItemContainer = styled.div`
   border: 1.5px solid rgba(249, 202, 65, 0.25);
   border-radius: 8px;
   display: flex;
   align-items: center;
   margin: 1rem 0.75rem;
   padding: 0.5rem;
`;

const ImageContainer = styled.div`
   height: 4rem;
   width: 4rem;
   border-radius: 6.07418px;
   border: 1px solid gray;
   margin: 0 0.75rem;
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 0.6rem;
`;

const Name = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: #0f191a;
`;

const Quantity = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
`;

const ModalContainer = styled.div`
   display: flex;
   flex-direction: column;

   justify-content: space-between;
   padding: 1rem;
   height: 85%;
   justify-content: space-between;

   justify-content: space-between;
   padding: 1rem;
`;

const ModalHeaderText = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 15.6px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #0f6656;
`;

const ContentText = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 500;
   font-size: 19.4px;
   line-height: 25px;
   letter-spacing: -0.3px;
   color: #0f191a;
`;

const ContentText2 = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 15.6px;
   line-height: 20px;
   text-align: right;
   letter-spacing: -0.3px;
   color: #4361ee;
`;

const ContentText3 = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 12.5px;
   line-height: 16px;
   letter-spacing: -0.3px;
   color: #0f6656;
   display: flex;
   align-items: center;
`;

const PaymentTypeModalHeader = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 24.4px;
   line-height: 32px;
   display: flex;
   align-items: center;
   text-align: center;
   letter-spacing: -0.3px;
   color: #000000;
   margin: 0 auto;
`;

const PaymentTypeModalContent = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 24.4px;
   line-height: 32px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #000000;
   margin-left: 1rem;
`;

const ConfirmationText = styled.div`
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 24.4px;
   line-height: 32px;
   text-align: center;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
   margin: 1rem;
`;

export {
   Container,
   ItemContainer,
   ImageContainer,
   Name,
   Quantity,
   TextContainer,
   ModalContainer,
   ModalHeaderText,
   ContentText,
   ContentText2,
   ContentText3,
   PaymentTypeModalHeader,
   PaymentTypeModalContent,
   ConfirmationText,
};
