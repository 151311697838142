/** @format */

import React from "react";
import ContentTitle from "../ContentTitle";
import { IS_SMALLER_DEVICE } from "../../../utils/styleDefinitions";

export default function DemoVideo() {
   const { REACT_APP_DEMO_VIDEO_URL } = process.env;

   return (
      <div
         data-aos="fade-up"
         style={{ marginBlock: IS_SMALLER_DEVICE ? "2rem" : "6rem" }}>
         <div className="flex flex-col items-center justify-center">
            <ContentTitle title={"Explore our app in action"} />
            <div
               style={{ marginTop: IS_SMALLER_DEVICE ? "4rem" : "5rem" }}
               className="lg:w-[50%] lg:h-[30rem] w-[90%] h-[15rem]">
               <iframe
                  className="w-full h-full"
                  src={REACT_APP_DEMO_VIDEO_URL}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen={true}></iframe>
            </div>
         </div>
      </div>
   );
}
