import React from "react";
import Lottie from "lottie-react";
import tickAnimationJson from "../../asset/animations/tickAnimation.json";

type Props = {};

function OrderPlaced({}: Props) {
	return (
		<div className="flex flex-col items-center gap-2  w-[100%] min-h-[100vh]">
			<div className="w-[90%] h-[50%] mt-4 flex items-center justify-center">
				<Lottie
					width={"100%"}
					height={"100%"}
					animationData={tickAnimationJson}
					loop={true}
				/>
			</div>
			<div className="flex flex-col gap-2 items-center justify-center">
				<div className="text-center font-bold text-[1.2rem] w-[80%] font-custom">Order placed successfully!</div>
				<div className="text-center w-[100%] font-custom">Kindly wait until our chef confirms your order.</div>
			</div>
		</div>
	);
}

export default OrderPlaced;
