/** @format */

import { Box, Divider, Grid } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { getLoyaltyPointsAPI, loyaltySubscriptionAPI } from "../../api/loyalty/loyaltyAPIs";
import Button from "../../component/Button";
import HurrayModal from "../../component/HurrayModal";
import Input from "../../component/Input";
import Loader from "../../component/Loader";
import LoyaltyBanner from "../../component/LoyaltyBanner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { orderActions } from "../../store/order/orderSlice";
import orderSelectors from "../../store/order/selectors";
import userSelectors from "../../store/user/selectors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import "./index.scss";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as RibbonIcon } from "../../asset/svg/ribbonIcon.svg";
import { ReactComponent as ArrowRight } from "../../asset/svg/arrowRight.svg";
import ExclusiveClubModal from "../../component/ExclusiveClubModal/ExclusiveClubModal";

export default function LoyaltyProgram() {
   const AVAILABLE_COINS = useAppSelector(orderSelectors.getloyaltyPoints);
   const isMember = useAppSelector(orderSelectors.getloyaltyDetails);
   const PHONE_NUMBER = localStorage.getItem("phone_number") !== null ? localStorage.getItem("phone_number") : "";
   const EMAIL = localStorage.getItem("email") !== null ? localStorage.getItem("email") : "";
   const [inputValues, setinputValues] = useState({
      full_name: "",
      email: EMAIL,
      phone_number: PHONE_NUMBER,
      address: "",
      pincode: "",
      birth_date: "05/19/2001",
      wedding_anniversary_date: "",
      Others: "",
   });

   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
   const { mid, qid } = useParams();
   const [isEditing, setisEditing] = useState(false);
   const navigate = useNavigate();
   const [showHurrayModal, setshowHurrayModal] = useState(false);
   const redirect = localStorage.getItem("redirect");
   const [loading, setloading] = useState(false);
   const [isValueChanged, setisValueChanged] = useState(false);
   const [disable, setDisable] = useState(false);

   const dispatch = useAppDispatch();
   const createUserValidationSchema = yup.object({
      full_name: yup.string().required("This field is required"),
      email: yup.string().email("Please enter valid email").required("This field is required"),
      phone_number: yup.string().matches().required("This field is required"),
      address: yup.string().required("This field is required"),
      pincode: yup.string().required("This field is required"),
      birth_date: yup.string().required("This field is required"),
   });

   const formik = useFormik({
      initialValues: {
         full_name: inputValues?.full_name,
         email: inputValues?.email,
         phone_number: inputValues?.phone_number,
         address: inputValues?.address,
         pincode: inputValues?.pincode,
         birth_date: inputValues?.birth_date,
         wedding_anniversary_date: inputValues?.wedding_anniversary_date,
         others: inputValues?.others,
      },
      validationSchema: createUserValidationSchema,
      onSubmit: (values) => {
         const body = { ...values, merchant_id: mid };
         const httpMethod = isEditing ? "PUT" : "POST";
         setloading(true);
         loyaltySubscriptionAPI(accessToken, body, httpMethod)
            .then((res) => {
               setloading(false);
               !isEditing && setshowHurrayModal(true);
               setisEditing(true);
               setisValueChanged(false);
               localStorage.removeItem("redirect");
               setDisable(true);
               console.log(res);
               fetchLoyaltySubscriptionDetails();
               fetchLoyaltyCoins();
            })
            .catch((err) => {
               setloading(false);
               console.log(err);
            });
      },
      enableReinitialize: true,
   });

   const fetchLoyaltyCoins = async () => {
      getLoyaltyPointsAPI(accessToken, mid)
         .then(async (res) => {
            const data = await res.json();
            dispatch(orderActions.setLoyaltyPoints(data.loyalty_points));
         })
         .catch((err) => {
            console.log("loyalty points error", err);
         });
   };
   const navigateToTandC = () => {
      window.open("https://www.thebeautifulmenu.com/terms-and-conditions", "_blank");
   };

   useEffect(() => {
      fetchLoyaltySubscriptionDetails();
      fetchLoyaltyCoins();
   }, []);

   useEffect(() => {
      checkIfTheValuesAreChanged();
   }, [formik.values]);

   useEffect(() => {
      fetchLoyaltySubscriptionDetails();
   }, []);

   const fetchLoyaltySubscriptionDetails = async () => {
      loyaltySubscriptionAPI(accessToken, { merchant_id: mid }, "GET")
         .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
               const data = await res.json();
               console.log(data, "fetchLoyaltySubscriptionDetails");
               dispatch(orderActions.setLoyaltyDetails(data));
               setinputValues(data);
               setisEditing(true);
               setDisable(true);
            }
         })
         .catch((err) => {
            console.log(err, "fetchLoyaltySubscriptionDetails");
         });
   };

   const goBack = () => {
      if (localStorage.getItem("loyaltyRedirect") === "checkout") {
         localStorage.removeItem("loyaltyRedirect");
         if (qid !== undefined) {
            navigate(`/${mid}/${qid}/checkout`);
         } else {
            navigate(`/${mid}/checkout`);
         }
      } else {
         goBackToMenu();
      }
   };

   const goBackToMenu = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}`);
      } else {
         navigate(`/${mid}`);
      }
   };
   const goToInfo = () => {
      navigate(`/${mid}/${qid}/loyaltyinfo`);
   };
   useEffect(() => {
      checkIfTheValuesAreChanged();
   }, [formik.values]);

   const checkIfTheValuesAreChanged = () => {
      const { full_name, email, phone_number, address, pincode, birth_date, wedding_anniversary_date } = inputValues;
      const {
         full_name: formik_full_name,
         email: formik_email,
         phone_number: formik_phone_number,
         address: formik_address,
         pincode: formik_pincode,
         birth_date: formik_birth_date,
         wedding_anniversary_date: formik_wedding_anniversary_date,
      } = formik.values;
      if (
         full_name !== formik_full_name ||
         email !== formik_email ||
         phone_number !== formik_phone_number ||
         address !== formik_address ||
         pincode !== formik_pincode ||
         birth_date !== formik_birth_date ||
         wedding_anniversary_date !== formik_wedding_anniversary_date
      ) {
         setisValueChanged(true);
      } else {
         setisValueChanged(false);
      }
   };

   console.log(isValueChanged, "isValueChanged");
   const useStyles = makeStyles({
      root: {
         "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#0F6656",
         },
         "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0F6656",
         },
      },
   });
   const classes = useStyles();
   return (
      <Box>
         <Grid
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            container
            spacing={2}
            padding={"2rem 2rem 0"}>
            <div className="flex items-center pb-3">
               <div onClick={goBack}>
                  <BackArrow />
               </div>
               <div className="loyalty-program-title font-custom">Exclusive club</div>
            </div>
            {/* <InfoOutlinedIcon style={{ fontSize: "2rem" }} onClick={goToInfo} /> */}
         </Grid>
         {/* <div
        className="bg-[#FFF0EB] text-[#FF4700] my-4 py-3 px-2 relative"
        onClick={() => navigate(`/${mid}/${qid}/joinexclusiveclub`)}
      >
        <p className="text-xl ">
          Explore <span className="font-black font-custom">Exclusive Club</span>
        </p>
        <div className="flex items-center">
          <p className="text-base font-custom">
            Join for free, Earn rewards,Enjoy more!
          </p>
          <span>
            <ArrowRight />
          </span>
        </div>
        <span className="absolute top-0 right-0">
          <RibbonIcon />
        </span>
      </div> */}

         <Loader Load={loading} />
         <HurrayModal
            open={showHurrayModal}
            onClose={() => {
               setshowHurrayModal(false);
               localStorage.getItem("loyaltyRedirect") === "checkout" && goBack();
               fetchLoyaltyCoins();
            }}
            statementPrimary={
               <img
                  className="trophy"
                  src={require("../../asset/gifs/trophy-winner.gif")}
               />
            }
            statementSecondary={"You are now a member!"}
         />
         <Grid
            width={"100%"}
            display={"flex"}
            padding={"2%"}
            justifyContent={"center"}
            flexDirection={"column"}
            gap={"8px"}
            alignItems={"center"}>
            {formik.values.full_name != "" && formik.values.full_name != undefined && (
               <>
                  <Box
                     sx={{
                        width: "19vw",
                        backgroundColor: "#E5F8F4",
                        color: "#0F6656",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "9vh",
                        borderRadius: "50%",
                        letterSpacing: "0.1rem",
                        border: "2px solid #0F6656",
                     }}>
                     {formik.values.full_name.at(0)}
                     {formik.values.full_name?.split(" ")[1]?.charAt(0)}
                  </Box>
                  {/* <p className="text-[#0F6656] font-normal">Add photo</p>
              <div className="flex gap-x-7">
                <p className="text-[#0F6656]">Update</p>
                <p className="text-[#D90429]">Remove</p>
              </div> */}
               </>
            )}
            <LoyaltyBanner
               custom
               isMember={isMember?.subscribed}
               appliedCoins={AVAILABLE_COINS}
            />
         </Grid>
         <Divider
            sx={{
               backgroundColor: "#E4E7E8",
               height: "0.1rem",
               position: "absolute",
               width: "100%",
               left: "0",
               marginTop: "4%",
            }}
         />
         <Grid>
            {/* <div className="loyalty-description">Enter your details to subscribe our loyalty program </div> */}
            <form onSubmit={formik.handleSubmit}>
               <Grid
                  sx={{ marginTop: "1rem" }}
                  container
                  padding={"0 1rem"}>
                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <Input
                        disabled={disable}
                        id="full_name"
                        label="Name*"
                        onChange={formik.handleChange}
                        error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                        helperText={formik.touched.full_name && formik.errors.full_name}
                        value={formik.values.full_name}
                     />
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <Input
                        disabled={EMAIL !== ""}
                        id="email"
                        label="Email address*"
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                     />
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <Input
                        disabled={PHONE_NUMBER !== ""}
                        id="phone_number"
                        label="Phone number*"
                        onChange={formik.handleChange}
                        error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                        helperText={formik.touched.phone_number && formik.errors.phone_number}
                        value={formik.values.phone_number}
                     />
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                           <DatePicker
                              className={classes.root}
                              disabled={disable}
                              views={["day", "month", "year"]}
                              format="DD-MM-YYYY"
                              label="Birth date*"
                              value={dayjs(inputValues?.birth_date)}
                              onChange={(val) =>
                                 formik.setValues({
                                    ...formik.values,
                                    birth_date: dayjs(val).format("MM-DD-YYYY"),
                                 })
                              }
                           />
                        </DemoContainer>
                     </LocalizationProvider>
                  </Grid>

                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <Input
                        disabled={disable}
                        id="address"
                        label="Address*"
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        value={formik.values.address}
                     />
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                           <DatePicker
                              className={classes.root}
                              disabled={disable}
                              label="Wedding anniversary date (Optional)"
                              onChange={(val) =>
                                 formik.setValues({
                                    ...formik.values,
                                    wedding_anniversary_date: dayjs(val).format("MM-DD-YYYY"),
                                 })
                              }
                           />
                        </DemoContainer>
                     </LocalizationProvider>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={6}>
                     <Input
                        disabled={disable}
                        id="pincode"
                        label="Pincode*"
                        onChange={formik.handleChange}
                        error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                        helperText={formik.touched.pincode && formik.errors.pincode}
                        value={formik.values.pincode}
                     />
                  </Grid>
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={6}
                  padding={"0 1rem"}>
                  <Input
                     disabled={false}
                     id="others"
                     placeholder={"e.g. Corporate id, employee id etc"}
                     label="Others (e.g. Corporate id, employee id etc)"
                     onChange={formik.handleChange}
                     error={formik.touched?.others && Boolean(formik.errors?.others)}
                     helperText={formik.touched?.others && formik.errors?.others}
                     value={formik.values?.others}
                  />
               </Grid>

               <div className="by-subscribing-text font-custom pl-4">
                  <span className="font-custom">By subscribing, you agree to the</span>
                  <span
                     onClick={navigateToTandC}
                     className="tandc font-custom">
                     Terms and conditions
                  </span>
               </div>
               <div className="loyalty-submit-button-wrapper">
                  {isEditing && disable ? (
                     <Button
                        type="button"
                        background={isEditing ? "#0F6656" : "#0F6656"}
                        buttonText={isEditing ? "Edit" : "Save"}
                        fontColor="#fff"
                        onClickHandler={() => {
                           setDisable(false);
                        }}
                     />
                  ) : (
                     <Button
                        type="submit"
                        background={isEditing ? "#0F6656" : "#0F6656"}
                        buttonText={isEditing ? "Update profile" : "Save"}
                        fontColor="#fff"
                        disabled={!isValueChanged}
                     />
                  )}
               </div>
            </form>
         </Grid>
      </Box>
   );
}
