import { Modal } from "@mui/material";
import { ReactComponent as VerifiedIcon } from "../../asset/svg/OffersScreen/verified.svg";
import { ReactComponent as CloseIcon } from "../../asset/svg/OffersScreen/Close.svg";

const HurrayModal = ({
  open,
  onClose,
  statementPrimary,
  statementSecondary,
}: any) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="d-flex justify-center align-items-center"
    >
      <div
        className="d-flex flex-column relative justify-content-center align-items-center bg-white mx-3 p-2 px-3 gap-3"
        style={{ borderRadius: "1rem" }}
      >
        <CloseIcon className="absolute right-2 top-1" onClick={onClose} />
        <VerifiedIcon className="mt-2" />
        <div className="text-center text-[#8d8d8d] font-custom mt-2 text-lg">
          "{statementSecondary}"
        </div>
        <div className="text-center fw-bold font-custom text-xl">
          {statementPrimary}
        </div>
        <p className="font-bold text-[#0f6656] font-custom mt-4 mb-4 text-base">
          Whoo! Delicious
        </p>
      </div>
    </Modal>
  );
};

export default HurrayModal;
