/** @format */

import React from "react";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import { Text } from "../Text";
import { Link, Navigate, useNavigate, useNavigationType } from "react-router-dom";
import "./style.scss";

const PageHeader = ({ headerText, background, textColor, backLink }: PageHeaderProps) => {
   const navigate = useNavigate();
   return (
      <div className="page-header-container" /* background={background} */>
         <div
            onClick={() => {
               navigate(-1);
            }}>
            <BackArrowIcon />
         </div>
         <div className="font-bold text-[1.2rem]">{headerText}</div>
         <div></div>
      </div>
   );
};

interface PageHeaderProps {
   headerText: string;
   backLink: string;
   background: string;
   textColor: string;
}

export default PageHeader;
