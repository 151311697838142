/** @format */

import cartIcon from "../svg/cartIcon.svg";

const CartIcon = ({ count }: { count: number }) => {
   return (
      <div
         style={{ position: "relative" }}
         className="h-[fit-content] w-[fit-content] flex items-center justify-center">
         {count > 0 && (
            <div
               style={{
                  position: "absolute",
                  right: -2,
                  top: -8,
                  backgroundColor: "#0F6656",
                  color: "#ffffff",
                  height: "20px",
                  width: "20px",
                  // textAlign: 'center',
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}>
               {count}
            </div>
         )}
         <div className="bg-[#fff] h-[40px] w-[40px] rounded-[50px] flex items-center justify-center ">
            <svg
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                  stroke="#0F191A"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
               <path
                  d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                  stroke="#0F191A"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
               <path
                  d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                  stroke="#0F191A"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
               <path
                  d="M9 8H21"
                  stroke="#0F191A"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
            </svg>
         </div>
      </div>
   );
};

export default CartIcon;
