/** @format */

import { ArrowDropDown } from "@mui/icons-material";
import _ from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithArrow from "../../../component/Button";
import OrderConfirmationModal from "../../../component/OrderConfirmationModal";
import addMoreItemsOnCartSelector from "../../../store/addMoreItemOnOrder/selectors";
import cartItemsSelector from "../../../store/cart/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import updateOrderCartSelectors from "../../../store/updateOrderCart/selectors";
import userSelectors from "../../../store/user/selectors";
import SwipeButton from "../../../component/SwipeButton/";
import "./index.css";
import showMenuSelector from "../../../store/showMenu/selectors";

const ConfirmOrderModal = ({ handleConfirmOrder, handleUpdateOrder, payload, merchant_id, totalDiscount, itemDiscount, couponDiscount, loyaltyDiscount }: ConfirmModalProps) => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [showOffers, setShowOffers] = useState(true);
   const { mid, qid } = useParams();
   const { currency } = useAppSelector(showMenuSelector.showMenu);
   const { jwt } = useAppSelector(userSelectors.getAccessToken);
   const updateOrderCart = useAppSelector(updateOrderCartSelectors.getAllUpdateOrderCartInfo);
   const { refundAmount, previousCartTotal, updateCart, isUpdating, updateCartTotal, payment_status, totalAmountTobePaid, refundAmountLocked } = useAppSelector(
      updateOrderCartSelectors.getAllUpdateOrderCartInfo,
   );
   const { totalValue: currOrderTotalValue, itemCount: currOrderItemCount } = useAppSelector(cartItemsSelector.getAllCartInfo(merchant_id));

   const { totalAmount, addMoreItemCount, addMoreItemFlow, confirmedItems, addedItems } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);

   const navigateToLogin = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/login`);
      } else {
         navigate(`/${mid}/login`);
      }
   };
   const navigateToPayment = () => navigate(`/payment/${mid}`);

   const [cartTotal, setcartTotal] = useState(0);
   const [OpenModal, setOpenModal] = useState(true);

   console.log(cartTotal, " cartTotal");

   const handleUpdate = () => {
      // dispatch(updateOrderCartActions.setIsModalVisible(true));
      handleUpdateOrder();
   };

   const totalAmountToBePaid = isUpdating ? totalAmountTobePaid : currOrderTotalValue;

   const showTotalAmount = () => {
      if (addMoreItemFlow) {
         if (totalAmount > 0) {
            return true;
         }
      } else {
         if (totalAmountToBePaid > 0) {
            return true;
         }
      }
   };

   const getTotalPrice = () => {
      console.log("totalAmountToBePaid getTotalPrice", totalAmountToBePaid);
      console.log("totalDiscount getTotalPrice", totalDiscount);

      const amount = addMoreItemFlow ? totalAmount - totalDiscount : totalAmountToBePaid - totalDiscount;
      //@ts-ignore
      return parseFloat(amount).toFixed(2);
   };

   return (
      <>
         {false ? (
            <OrderConfirmationModal
               selected={""}
               isOpen={OpenModal}
               handleModalClose={() => {
                  setOpenModal(false);
               }}
               onClose={() => setOpenModal(false)}
            />
         ) : (
            <>
               <div className="confirmModalContainer">
                  {/* <div className="confirmOrderRow">
                     <div className="confirmText">Total items</div>
                     <div className="confirmText orderCount">{addMoreItemFlow ? addMoreItemCount : currOrderItemCount}</div>
                  </div> */}
                  {/* <div className='divider'></div> */}
                  {isUpdating && payment_status === "paid" && (
                     <>
                        <div className="confirmOrderRow">
                           <div className="confirmText confirmText2 font-custom"> Previous order paid amount</div>
                           <div className="confirmText orderCount">
                              {currency}
                              {previousCartTotal}
                           </div>
                        </div>{" "}
                        {/* <div className="confirmOrderRow">
                           <div className="confirmText confirmText2">Current order total</div>
                           <div className="confirmText orderCount">{currency}{updateCartTotal}</div>
                        </div> */}
                        {/* {refundAmount >= 0 && payment_status === 'paid' ? ( */}
                        {refundAmount > 0 && (
                           <div className="confirmOrderRow">
                              <div className="confirmText confirmText2 font-custom"> Refund Amount</div>
                              <div className="confirmText orderCount font-custom">
                                 {currency}
                                 {refundAmount}
                              </div>
                           </div>
                        )}
                        {/* ) : ( */}
                        {/* <div className="confirmOrderRow">
                           <div className="confirmText confirmText2"> Refund Amount Locked</div>
                           <div className="confirmText orderCount">{currency}{refundAmountLocked}</div>
                        </div> */}
                        {/* )} */}
                     </>
                  )}

                  {/* <div className="confirmOrderRow">
                     <div className="confirmText confirmText2">Total Amount to be paid</div>
                     <div className="confirmText orderTotalAmount">{currency}{cartTotal > 0 ? cartTotal : currOrderTotalValue}</div>
                  </div> */}

                  {/* <Skeleton wrapper={SingleOrderDetailsSkeleton} /> */}

                  <div className="order-details-container-text">
                     <div className="order-details-text font-custom">Total items</div>

                     <div className="order-details-content font-custom">{addMoreItemFlow ? addMoreItemCount : currOrderItemCount}</div>
                  </div>

                  <div className="order-details-container-text">
                     <div className="order-details-text font-custom">Total amount</div>
                     <div className="order-details-content font-custom">{`${currency}${payload?.total_amount}`}</div>
                  </div>
                  {payload?.cgst_percentage !== 0 && (
                     <div className="order-details-container-text">
                        <div className="order-details-text font-custom">CGST</div>
                        <div className="order-details-content font-custom">{`${payload?.cgst_percentage}%`}</div>
                     </div>
                  )}
                  {payload?.sgst_percentage !== 0 && (
                     <div className="order-details-container-text">
                        <div className="order-details-text font-custom">SGST</div>
                        <div className="order-details-content font-custom">{`${payload?.sgst_percentage}%`}</div>
                     </div>
                  )}
                  <div className="order-details-container-text">
                     <div
                        onClick={() => setShowOffers(!showOffers)}
                        className="order-details-text font-custom">
                        Offers
                        <span>
                           <ArrowDropDown sx={{ color: "#000" }} />
                        </span>
                     </div>

                     <div
                        style={{
                           flexDirection: "row",
                           display: "flex",
                           alignItems: "center",
                        }}>
                        <div
                           style={{ color: "#2FBF71" }}
                           className="order-details-content">
                           -{currency}
                           {totalDiscount}
                        </div>
                     </div>
                  </div>
                  {showOffers && (
                     <div className="Offers-dropdown">
                        <div className="order-details-container-text">
                           <div className="order-details-text font-custom">Item discount</div>
                           <div
                              style={{
                                 flexDirection: "row",
                                 display: "flex",
                                 alignItems: "center",
                              }}>
                              <div className="order-details-content">{`-${currency}${itemDiscount}`}</div>
                           </div>
                        </div>
                        <div className="order-details-container-text">
                           <div className="order-details-text font-custom">Offers</div>
                           <div
                              style={{
                                 flexDirection: "row",
                                 display: "flex",
                                 alignItems: "center",
                              }}>
                              <div className="order-details-content font-custom">{`-${currency}${couponDiscount}`}</div>
                           </div>
                        </div>
                        <div className="order-details-container-text">
                           <div className="order-details-text font-custom">Discount from loyalty coins</div>
                           <div
                              style={{
                                 flexDirection: "row",
                                 display: "flex",
                                 alignItems: "center",
                              }}>
                              <div className="order-details-content font-custom">{`-${currency}${loyaltyDiscount}`}</div>
                           </div>
                        </div>
                     </div>
                  )}

                  <div className="order-details-dotted-divider"></div>
                  <div className="order-details-container-text">
                     <div className="order-details-text-grand-total font-custom">Total price</div>
                     <div className="order-details-content-grand-total text-[#0F191A]">{`${currency}${payload?.amount_after_taxes}`}</div>
                  </div>
               </div>
               <div className="checkout-page-btn-wrapper">
                  {updateOrderCart.isUpdating ? (
                     <ButtonWithArrow
                        disabled={jwt ? false : false}
                        onClickHandler={jwt ? handleUpdate : navigateToLogin}
                        background="#0F6656"
                        fontColor="white"
                        buttonText={jwt ? "Update order" : "Login to confirm"}
                        //  showArrow={true}
                     />
                  ) : jwt ? (
                     <SwipeButton
                        isLoading={false}
                        text="Swipe to confirm"
                        onSuccess={handleConfirmOrder}
                     />
                  ) : (
                     <ButtonWithArrow
                        disabled={addMoreItemFlow ? _.isEqual(confirmedItems, addedItems) : false}
                        onClickHandler={navigateToLogin}
                        background="#0F6656"
                        fontColor="white"
                        buttonText={"Login to confirm"}
                        //  showArrow={true}
                     />
                  )}
               </div>
            </>
         )}
      </>
   );
};

interface ConfirmModalProps {
   // onPage: boolean;
   handleConfirmOrder: () => void;
   handleUpdateOrder: () => void;
   merchant_id: string;
   loyaltyDiscount: number;
   totalDiscount: number;
   itemDiscount: number;
   couponDiscount: number;
   payload: any;
}

export default ConfirmOrderModal;
