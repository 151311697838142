import styled from "@emotion/styled";

const Heading = styled.div`
  font-family: system-ui;
  font-style: normal;
  font-weight: 700;
  font-size: 24.4px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  color: #0f191a;
`;

const ContentHeading = styled.div`
  font-family: system-ui;
  font-style: normal;
  font-weight: 700;
  font-size: 24.4px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #0f191a;
`;

const ContentText = styled.div`
  font-family: system-ui;
  font-style: normal;
  font-weight: 500;
  font-size: 19.4px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #0f191a;
`;

export { Heading, ContentHeading, ContentText };
