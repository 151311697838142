import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { X } from "react-feather";
import { StyledTextField } from "./styles";
import SearchIcon from "../../asset/icons/SearchIcon";
import SearchButton from "../../asset/icons/SearchButton";

const SearchInput = ({ searchValue, setSearchValue, showCrossEndAdornment, handleHideSearchPage, handleShowSearchPage }: SearchInputProps): JSX.Element => {
	return (
		<div>
			<StyledTextField
				showsearch={showCrossEndAdornment ? searchValue.toString() : ""}
				value={searchValue}
				onChange={(ev) => setSearchValue(ev.target.value)}
				variant="outlined"
				className="font-custom"
				sx={{
					backgroundColor: "#fff",
					borderRadius: "8px",
					fontFamily: "TWKEverett,san-serif",
				}}
				placeholder={`Search for "biriyani" `}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment:
						searchValue !== "" ? (
							<InputAdornment position="end">
								<X onClick={() => setSearchValue("")} />
							</InputAdornment>
						) : (
							<></>
						),
				}}
			/>
			{/* {!!searchValue && (
        <span onClick={handleShowSearchPage}>
          <SearchButton />
        </span>
      )} */}
		</div>
	);
};

// types
interface SearchInputProps {
	showCrossEndAdornment?: boolean;
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
	handleShowSearchPage?: (val: any) => void;
	handleHideSearchPage?: () => void;
	// remove optional from these props
}

export default SearchInput;
