import React from 'react';

function NewOrderIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginLeft: '4px' }}
    >
      <path
        d='M13.8531 9.09996V12.3933H2.14648V9.09996C2.14648 5.88662 4.74649 3.28662 7.95982 3.28662H8.03982C11.2531 3.28662 13.8531 5.88662 13.8531 9.09996Z'
        stroke='#F9CA41'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8 1.33325V3.28658'
        stroke='#F9CA41'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.4336 12.3933C10.3469 13.6666 9.28689 14.6666 8.00022 14.6666C6.71356 14.6666 5.65356 13.6666 5.56689 12.3933H10.4336Z'
        stroke='#F9CA41'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default NewOrderIcon;
