/** @format */

import React, { useState } from "react";
import { XCircle } from "react-feather";
import MailBoxIcon from "../../asset/icons/MailBoxIcon";
import ButtonWithArrow from "../Button";
import { Text } from "../Text";
import { useForm } from "react-hook-form";
import "./style.scss";
import { Container, ImageContainer, SubContainer, ButtonContainer, InputContainer, Form } from "./styles";

const SubscrinePopup = ({ handleSubscribeClick, onCloseHandler }: SubscribePopupProps) => {
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm();

   const [isTyping, setIsTyping] = useState(false);

   const onSubmit = () => {
      console.log("error", errors);
      setIsTyping(false);
      if (!errors.email) {
         handleSubscribeClick();
      }
   };

   return (
      <div className="subscribe-popup-container">
         <div className="subscribe-popup-subcontainer">
            <div className="subscribe-popup-image-container">
               <MailBoxIcon />
               <XCircle
                  onClick={onCloseHandler}
                  className="subscribe-popup-x"
               />
            </div>
            <div className="subscribe-popup-text-container">
               <div className="subscribe-popup-text">Subscribe!</div>
               <div className="subscribe-popup-text-content ">Get the best food recommendations of our restaurant in your inbox.</div>
               <Form
                  className="subscribe-popup-form"
                  onSubmit={handleSubmit(onSubmit)}>
                  {errors.email && !isTyping && <div className="subscribe-popup-error-text-content">{errors.email.type === "pattern" ? "Please enter a valid email" : "Email required"}</div>}
                  <div
                     className="subscribe-popup-input-container"
                     placeholder="Email address"
                     onInput={() => setIsTyping(true)}
                     {...register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                     })}
                     onBlur={() => setIsTyping(false)}></div>
                  <div className="subscribe-popup-btn-container">
                     <ButtonWithArrow
                        /*onClickHandler={handleSubscribeClick}*/
                        background="#0F6656"
                        buttonText="Subscribe"
                        fontColor="white"
                        Type={undefined}
                     />
                  </div>
               </Form>
            </div>
         </div>
      </div>
   );
};

interface SubscribePopupProps {
   handleSubscribeClick: () => void;
   onCloseHandler: () => void;
}

export default SubscrinePopup;
