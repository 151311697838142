import React from 'react';

function CancelOrderIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginLeft: '4px' }}
    >
      <path
        d='M2 3.98665C4.22 3.76665 6.45333 3.65332 8.68 3.65332C10 3.65332 11.32 3.71999 12.64 3.85332L14 3.98665'
        stroke='#D90429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.3335 3.31325L10.1868 2.43992C10.0802 1.80659 10.0002 1.33325 8.8735 1.33325H7.12683C6.00016 1.33325 5.9135 1.83325 5.8135 2.44659L5.66683 3.31325'
        stroke='#D90429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.43356 6.09326L3.86689 12.8066C3.94023 13.8533 4.00023 14.6666 5.86023 14.6666H10.1402C12.0002 14.6666 12.0602 13.8533 12.1336 12.8066L12.5669 6.09326'
        stroke='#D90429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.11328 11H6.89328'
        stroke='#D90429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.6665 8.33325H6.33317'
        stroke='#D90429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default CancelOrderIcon;
