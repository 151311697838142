/** @format */

import coupon from "./../../asset/svg/coupon.svg";
import Offer from "./../../asset/svg/OffersScreen/Offer.svg";

const CouponCard = ({
  code,
  description,
  savings,
  isApplied,
  isApplicable,
  applyCoupon,
  removeCoupon,
}: any) => {
  return (
    <div
      className="flex justify-between mt-3 font-custom"
      style={{
        borderBottom: "1px solid #E5E5E5",
      }}
    >
      <div className="flex items-start gap-x-4 mb-3">
        <img src={Offer} alt="coupon" className="mt-2" />
        <div className="flex flex-col">
          <p className="text-lg font-bold tracking-tight font-custom">
            {description}
          </p>
          <p className=" text-base font-base tracking-wider text-[#a7a7a7]  font-custom mt-0.5 uppercase">
            {code}
          </p>
          <p className="text-sm text-[#2FBF71] mt-2 font-custom">{savings}</p>
        </div>
      </div>
      <div className="my-auto text-base font-semibold tracking-wide pr-3 font-custom">
        {isApplied ? (
          <div className="text-[#d90429] cursor-pointer" onClick={removeCoupon}>
            REMOVE
          </div>
        ) : isApplicable ? (
          <div className="text-[#0f6656] cursor-pointer" onClick={applyCoupon}>
            APPLY
          </div>
        ) : (
          <div className="text-[#C3D8D4]">APPLY</div>
        )}
      </div>
    </div>
  );
};

export default CouponCard;
