export const getCustomerDetails = async ( accessToken, mid, qid ) => {
    const response = await fetch( `${process.env.REACT_APP_DEV_URL}/customer/customer_details/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "any",
        },
    } );

    if ( response.status === 200 || response.status === 201 )
    {
        const resData = await response.json();
        return resData;
    } else if ( response.status === 404 )
    {
        return response.status;
    } else if ( response.status === 401 )
    {
        localStorage.clear();
        return response.status;
    }
};
