import React from "react";
import YellowArrowIcon from "../../../asset/icons/YellowArrowIcon";
import { useAppSelector } from "../../../store/hooks";
import HotelCartSelectors from "../../../store/HotelCart/selectors";
import { Container, ItemCountWrapper, PriceWrapper, Button } from "./styles";
import "./style.scss";

function AddToCartFooter() {
  const { cart, itemCount } = useAppSelector(HotelCartSelectors.getCartInfo);
  if (itemCount) {
    return (
      <div className="add-to-cart-footer-container">
        <div>
          <div className="add-to-cart-footer-item-count-wrapper">
            {itemCount} Item added
          </div>
        </div>
        <div>
          <div className="add-to-cart-footer-btn-container">
            <button className="add-to-cart-footer-btn">
              Add to cart <YellowArrowIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default AddToCartFooter;
