import Skeleton from 'react-loading-skeleton'
import "./style.scss";

function SingleOrderSkeleton() {
  return (
    <div
    className='single-order-details-skeleton-container'
  >
    <div
      className="single-order-details-skeleton-subcontainer"
    >
      <Skeleton height={"3.5rem"} width={"3.5rem"} />
      <Skeleton height={"1.3rem"} width={"93%"} count={2} />
    </div>
  </div>
  )
}

export default SingleOrderSkeleton