/** @format */

import React from "react";

function MinusBtnSvg({ handleSubtractItem }: any) {
   return (
      <div onClick={handleSubtractItem}>
         <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
               x="0.5"
               y="0.5"
               width="39"
               height="39"
               rx="7.5"
               fill="white"
               stroke="#0F6656"
            />
            <path
               d="M14 20L26 20"
               stroke="#0F6656"
               stroke-width="2"
               stroke-linecap="round"
               stroke-linejoin="round"
            />
         </svg>
      </div>
   );
}

export default MinusBtnSvg;
