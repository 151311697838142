const { REACT_APP_DEV_URL, REACT_APP_PROD_URL } = process.env;

const getApiUrl = () => {
  const env = process.env.REACT_APP_NODE_ENV;

  if (env === "development") {
    return REACT_APP_DEV_URL as string;
  }
  if (env === "production") {
    return REACT_APP_PROD_URL as string;
  }
  return "http://localhost:3000";
};

const APIURL = getApiUrl();

export default APIURL;
