import React from 'react';

type Props = {};

function ShuffleIBlackIcon({}: Props) {
  return (
    <svg width='23' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1431_1423)'>
        <path
          d='M3.25 7.3125H5.59248C6.62712 7.3125 7.6468 7.55948 8.56677 8.03291C9.48673 8.50634 10.2804 9.19254 10.8818 10.0345L15.1183 15.9655C15.7196 16.8075 16.5133 17.4937 17.4332 17.9671C18.3532 18.4405 19.3729 18.6875 20.4075 18.6875H23.5625'
          stroke='#0F191A'
          stroke-width='1.625'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M21.125 4.875L23.5625 7.3125L21.125 9.75'
          stroke='#0F191A'
          stroke-width='1.625'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M21.125 16.25L23.5625 18.6875L21.125 21.125'
          stroke='#0F191A'
          stroke-width='1.625'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M14.997 10.2042L15.1182 10.0345C15.7196 9.19254 16.5133 8.50634 17.4332 8.03291C18.3532 7.55948 19.3729 7.3125 20.4075 7.3125H23.5625M3.25 18.6875H5.59248C6.62712 18.6875 7.6468 18.4405 8.56677 17.9671C9.48673 17.4937 10.2804 16.8075 10.8818 15.9655L11.003 15.7958'
          stroke='#0F191A'
          stroke-width='1.625'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1431_1423'>
          <rect width='26' height='26' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShuffleIBlackIcon;
