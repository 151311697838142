/** @format */

import { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import getAllOrders from "../../api/order/getAllOrder";
import OrderDetailsCard from "../../component/OrderDetailsCard";
import PageHeader from "../../component/PageHeader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { orderActions } from "../../store/order/orderSlice";
import userSelectors from "../../store/user/selectors";
import OrderDetailsSkeleton from "./OrderDetailsSkeleton";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import "./style.scss";
import OrderPageHeader from "../../component/OrderSummary/OrderPageHeader";
import { reverseArray } from "../../utils/arrayFunctions";
import OrderHistoryCard from "../../component/OrderSummary/OrderHistoryCard";

const OrderDetailsPage = () => {
   const countRef = useRef(0);
   const [filterStatus, setFilterStatus] = useState("All");
   const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
   const [orders, setOrders] = useState<any[]>([]);

   const { mid, qid }: any = useParams();
   const { customer_id } = useAppSelector(userSelectors.getUserDetails);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();

   const totalItemsCount = (order: any) => {
      console.log(order, "order");
      let Count = 0;
      for (let i = 0; i < order.items_ordered?.length; i++) {
         if (order.items_ordered[i]?.suborder_status !== "cancelled") {
            Count = Count + order.items_ordered[i]?.suborder_items_ordered?.length;
         }
      }
      console.log(Count, "Counttt");
      return Count;
   };
   const fetchAllOrders = async () => {
      if (!accessToken) return;

      const response = await getAllOrders(mid, accessToken);

      if (response.statusCode < 300 && response.payload) {
         // @ts-ignore
         const reversedArray = [...response.payload].reverse();
         console.log("reversedArray", reversedArray);
         setOrders(reversedArray);
      } else {
         toast.error("Something went wrong");
      }
   };
   console.log(orders, "orders");

   useEffect(() => {
      if (countRef.current === 0 && accessToken) {
         countRef.current = 1;
         fetchAllOrders();
      }
   });

   const BACKLINK = qid !== undefined ? `/${mid}/${qid}` : `/${mid}`;

   const handlerfilteredorder = (statuses: any) => {
      let filteredData;
      setFilterStatus(statuses);
      if (statuses.includes("All")) {
         filteredData = reverseArray(orders);
      } else if (statuses.includes("new_order")) {
         filteredData = orders.filter((item) => item.order_status !== "order_completed" && item.order_status !== "cancelled");
      } else {
         filteredData = orders.filter((order) => statuses.includes(order.order_status));
      }

      setFilteredOrders(filteredData);
   };

   useEffect(() => {
      handlerfilteredorder("All");
      console.log("useeffect rendered");
   }, [orders]);

   return (
      <div className="order-details-container2">
         <>
            <>
               <div className="sticky top-0 bg-[#ffffff]">
                  <div className="flex justify-between pl-2">
                     <div className="flex items-center gap-x-2 py-3 ">
                        <BackArrow onClick={() => navigate(-1)} />
                        <div>
                           <p className=" font-bold text-xl font-custom">Order history</p>
                        </div>
                     </div>
                  </div>
                  <div className="flex gap-2 p-2 overflow-x-auto scrollbar-hide">
                     <span
                        onClick={() => handlerfilteredorder("All")}
                        className={`border-custom font-custom ${filterStatus === "All" ? " border-[#0F6656] text-[#0F6656] bg-[#EAF1EE]" : ""} rounded-xl px-3 py-1 mb-2`}>
                        All
                     </span>
                     <span
                        onClick={() => handlerfilteredorder("new_order")}
                        className={` border-custom font-custom ${
                           filterStatus === "new_order" || filterStatus === "order_updated" ? " border-[#4361EE] bg-[#E5EAFF] text-[#4361EE]" : ""
                        } rounded-xl px-3 py-1 mb-2`}>
                        Ongoing
                     </span>
                     <span
                        onClick={() => handlerfilteredorder("order_completed")}
                        className={` border-custom font-custom ${filterStatus === "order_completed" ? "border-[#0F6656]  bg-[#EAF1EE] text-[#0F6656]" : ""} rounded-xl px-3 py-1 mb-2`}>
                        Completed
                     </span>
                     <span
                        onClick={() => handlerfilteredorder("cancelled")}
                        className={`border-custom font-custom ${filterStatus === "cancelled" ? " border-[#D90429] bg-[#FBE6E9] text-[#D90429]" : ""} rounded-xl px-3 py-1 mb-2`}>
                        Cancelled
                     </span>
                  </div>
               </div>
               {orders?.length === 0 ? (
                  <Skeleton
                     wrapper={OrderDetailsSkeleton}
                     count={10}
                  />
               ) : (
                  <div>
                     {filteredOrders?.map((order) => (
                        <OrderHistoryCard
                           orderHistory={order}
                           totalItems={order.order_type === "Dine-in" ? totalItemsCount(order) : order.items_ordered.length}
                           onClick={() => {
                              dispatch(orderActions.setOrder(order));
                              navigate(`${BACKLINK}/order_summary/${customer_id}/${order.order_id}`);
                           }}
                        />
                     ))}
                  </div>
               )}
            </>
         </>
      </div>
   );
};

export default OrderDetailsPage;
