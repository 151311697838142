import React from "react";

type Props = {
	color?: string;
};

function CancelIcon({ color }: Props) {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.75732 7.75732L16.2426 16.2426"
				stroke={color ? color : "white"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.75739 16.2426L16.2427 7.75732"
				stroke={color ? color : "white"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default CancelIcon;
