/** @format */

import Fuse from "fuse.js";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoyaltyIcon from "../../asset/icons/LoyaltyIcon";
import OrdersIcon from "../../asset/icons/OrdersIcon";
import ProfileIcon from "../../asset/icons/ProfileIcon";
import ShareIcon from "../../asset/icons/ShareIcon";
import LoginIcon from "../../asset/icons/loginIcon";
import BottomModal from "../../component/BottomModal";
import FilterModal from "../../component/FilterModal";
import MenuCard from "../../component/MenuCards";
import SearchInput from "../../component/SearchInput";
import { Text } from "../../component/Text";
// import OfferCard from "../../component/OfferCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { isSupported } from "firebase/messaging";
import { toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { loyaltySubscriptionAPI } from "../../api/loyalty/loyaltyAPIs";
import getBestSellingItems from "../../api/menu/getBestSellingItems";
import getDailySpecials from "../../api/menu/getDailySpecials";
import getHourlySpecials from "../../api/menu/getHourlySpecials";
import getItemRecommender from "../../api/menu/getItemRecommender";
import getMenu from "../../api/menu/getMenu";
import getNewlyAddedItems from "../../api/menu/getNewlyAddedtems";
import getRecommendedItems from "../../api/menu/getRecommendedItems";
import getMerchantDetails from "../../api/merchantDetails/getMerchantDetails";
import { getAllOffers } from "../../api/offers/getAllOffers";
import getAllOrders from "../../api/order/getAllOrder";
import url_count from "../../api/url_visits";
import CartIcon from "../../asset/icons/CartIcon";
import DrawerIcon from "../../asset/icons/DrawerIcon";
import CustomDrawer from "../../component/Drawer";
import FilledTag from "../../component/FilledTag";
import FoodItemBeforeOrder from "../../component/FoodItemBeforeOrder";
import HourlyDailyCard from "../../component/HourlyDailyCard";
import LoginBanner from "../../component/LoginBanner";
import MostOrdered from "../../component/MostOrdered";
import NoItems from "../../component/NoItems";
import OffersBanner from "../../component/OffersBanner";
import ViewCartButton from "../../component/ViewCartButton";
import WarningModal from "../../component/WariningModal";
import { NAVIGATE_TO_LOGIN } from "../../routes/navigations";
import { QrActions } from "../../store/Qr/reducer";
import { addMoreItemOnCartActions } from "../../store/addMoreItemOnOrder/reducer";
import addMoreItemOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { menuActions } from "../../store/menu/reducer";
import menuSelectors from "../../store/menu/selectors";
import { orderActions } from "../../store/order/orderSlice";
import orderSelectors from "../../store/order/selectors";
import { showMenuActions } from "../../store/showMenu/reducer";
import showMenuSelector from "../../store/showMenu/selectors";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import { userActions } from "../../store/user/reducer";
import userSelectors from "../../store/user/selectors";
import hourlyCardImage from "./../../asset/images/choel bature.png";
import NoItemsSvg from "./NoItemsSvg";
import { firebaseTokenCreator, menuDataTransformer } from "./helper";
import "./style.scss";

const HomePage = () => {
   const dispatch = useAppDispatch();
   const counterRef = useRef(0);
   const { menu } = useAppSelector(menuSelectors.getMenuItems);
   const { mid, qid } = useParams();
   const { itemCount, totalValue } = useAppSelector(cartItemsSelector.getTotalInfo(mid));
   const messagingSupported = isSupported();
   const [showRefer, setshowRefer] = useState(true);
   const location = useLocation();
   const [peopleWhoOrderedItems, setpeopleWhoOrderedItems] = useState([]);
   const [openDrawer, setOpenDrawer] = useState(false);
   // redux calls to get existing state
   const { cart } = useAppSelector(cartItemsSelector.getAllCartInfo(mid));
   const checkMenu = useAppSelector(showMenuSelector.showMenu);
   const { showMenuOnly } = useAppSelector(showMenuSelector.showMenu);
   const accessToken = useAppSelector(userSelectors.getAccessToken);
   const { customer_id } = useAppSelector(userSelectors.getUserDetails);
   const [featuredItems, setfeaturedItems] = useState([]);
   const { isUpdating, updateCart, updateCartTotal } = useAppSelector(updateOrderCartSelectors.getAllUpdateOrderCartInfo);

   const { addMoreItemFlow, addMoreItemCount, addedItems } = useAppSelector(addMoreItemOnCartSelector.getAddMoreItemsOnCart);
   const isLoyaltyEnabled = useAppSelector(showMenuSelector.getLoyaltyStatus);
   const isOrderSummaryVisible = useAppSelector(orderSelectors.getIsOrderSummaryVisible);
   const SingleOrder = useAppSelector(orderSelectors.getOrder);
   const offers = useAppSelector(orderSelectors.getOffers);
   const [loadingItemRecommender, setloadingItemRecommender] = useState(false);
   const [recentlyClickedItem, setrecentlyClickedItem] = useState("");

   // state declarations
   const [openFilter, setOpenFilter] = useState(false);
   const [feauturedTitle, setfeauturedTitle] = useState("Recommended");
   const allCards = menuDataTransformer(menu);
   const [loading, setloading] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const [bestSellingItems, setbestSellingItems] = useState([]);
   const [isNotificationEnabled, setisNotificationEnabled] = useState(false);
   const [newlyAddedItems, setnewlyAddedItems] = useState([]);
   const [showSearchResult, setShowSearchResult] = useState(false);
   const [filteredItems, setFilteredItems] = useState([]);
   const [recommendedItems, setRecommendedItems] = useState(null);
   const [Orders, setOrders] = useState([]);
   const [selectedTag, setSelectedTag] = useState({
      isRecommendedClicked: false,
      isBestSellingClicked: false,
      isNewlyAddedClicked: false,
      isAllItemsClicked: true,
   });
   const [merchantDetails, setMerchantDetails] = useState();
   const [showSpecials, setShowSpecials] = useState({
      hourly: false,
      daily: false,
   });

   // Store QR in redux
   useEffect(() => {
      dispatch(QrActions.addQr({ mid, qid }));
   }, []);

   // API calls
   const fetchMerchantDetails = async () => {
      setloading(true);
      const response = await getMerchantDetails({
         merchantId: mid != undefined ? mid : "",
      });

      if (response.statusCode === 200) {
         // @ts-ignore
         setloading(false);
         dispatch(showMenuActions.setMenu(response.payload));
         setMerchantDetails(response.payload);
      } else {
         setloading(false);
         console.log(response.error, response.message);
      }
   };

   const fetchAllOffers = async () => {
      setloading(true);
      const response = await getAllOffers({
         merchantId: mid != undefined ? mid : "",
         accessToken: accessToken.jwt,
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
         // @ts-ignore
         setloading(false);
         console.log("OFFERSPay", response.payload);
         dispatch(orderActions.setOffers(response.payload.offers));
      } else if (response.statusCode === 404) {
         dispatch(orderActions.resetOffer());
      } else {
         setloading(false);
         console.log(response.error, response.message);
      }
   };

   const fetchMenu = async () => {
      const response = await getMenu({
         merchantId: mid ? mid : "",
      });
      dispatch(menuActions.addMenu({ menu: response.payload }));
   };

   const fetchAllOrders = async () => {
      if (accessToken.jwt !== null) {
         const response = await getAllOrders(mid !== undefined ? mid : "", accessToken !== undefined ? accessToken?.jwt : "");
         const payload = response.payload;
         console.log("PAYLOAD FETCH ALL ORDERS", payload?.status_code);

         if (payload?.status_code === 404) {
            dispatch(userActions.removeAccessToken({}));
         } else {
            response ? setOrders(payload) : setOrders([]);
         }
      }
      //@ts-ignore
   };

   const fetchRecommendedItems = async () => {
      const response = await getRecommendedItems({
         merchantId: mid,
      });

      if (response.statusCode === 200) {
         // @ts-ignore
         const recommendedItemsPayload = response.payload?.recommended_items
            ?.filter((i) => i?.out_of_stock === false)
            .map((i) => {
               return {
                  ...i,
                  item_source: "from_recommended_items",
               };
            });
         setRecommendedItems(recommendedItemsPayload);
      } else {
         console.log(response.error, response.message);
      }
   };

   const fetchBestSellingItems = async () => {
      const response = await getBestSellingItems({
         merchantId: mid,
      });

      if (response.statusCode === 200) {
         // @ts-ignore
         const bestSellingItemsPayload = response.payload?.best_selling_items
            ?.filter((item) => item?.out_of_stock === false)
            .map((i) => {
               return {
                  ...i,
                  item_source: "from_best_selling_items",
               };
            });

         setbestSellingItems(bestSellingItemsPayload);
      } else {
         console.log(response.error, response.message);
      }
   };

   const fetchNewlyAddedItems = async () => {
      const response = await getNewlyAddedItems({
         merchantId: mid,
      });

      if (response.statusCode === 200) {
         // @ts-ignore
         const newlyAddeditemsPayload = response.payload?.newly_added_items
            ?.filter((item) => item?.out_of_stock === false)
            .map((i) => {
               return {
                  ...i,
                  item_source: "from_newly_added_items",
               };
            });
         setnewlyAddedItems(newlyAddeditemsPayload);
      } else {
         console.log(response.error, response.message);
      }
   };

   const fetchSpecials = async () => {
      const response = await getHourlySpecials({
         merchantId: mid,
      });

      const response2 = await getDailySpecials({
         merchantId: mid,
      });

      if (response2.statusCode === 200 && response.statusCode === 200) {
         setShowSpecials({
            hourly: response?.payload?.length > 0,
            daily: response2?.payload?.length > 0,
         });
      }
   };

   const countUrlVisits = async () => {
      const cookies = Object.fromEntries(document.cookie.split("; ").map((v) => v.split(/=(.*)/s).map(decodeURIComponent)));
      const response = await url_count({
         merchant_id: mid ? mid : "",
         cookie_id: cookies.cookie_id ? cookies.cookie_id : null,
      });
      if (response.statusCode === 200) {
         const resData = response.payload;
         console.log("cookie id on visits ", resData.cookie_id);
         const firebasePayload = { mid: mid, cookie_id: resData.cookie_id };
         messagingSupported.then((supported) => {
            if (supported) {
               firebaseTokenCreator({ firebasePayload: firebasePayload, accessToken: accessToken.jwt }, dispatch).then((res) => console.log("firebase res", res));
            }
         });
         document.cookie = "cookie_id" + "=" + resData.cookie_id;
      } else {
         console.log(response.error, response.message);
      }
   };

   const navigate = useNavigate();
   const [toggledId, settoggledId] = useState("");

   useEffect(() => {
      // dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow({}));
      if (counterRef.current === 0) {
         counterRef.current = 1;
         fetchMerchantDetails();
         fetchRecommendedItems();
         countUrlVisits();
         fetchBestSellingItems();
         fetchNewlyAddedItems();
         fetchMenu();
         fetchAllOffers();
         fetchLoyaltySubscriptionDetails();
         fetchSpecials();
         if (accessToken !== null) {
            fetchAllOrders();
         }
      }
      localStorage.getItem("redirect");
   }, []);

   const onClickViewCart = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   // useEffect( () => {
   //   getLocationAccess();
   // }, [] );

   const handleShowSearchPage = (value) => {
      setSearchValue(value);
      const filteredResult = [];
      for (let i = 0; i < menu.length; i++) {
         const menuItems = menu[i].items;
         for (let j = 0; j < menuItems.length; j++) {
            const item = menuItems[j];
            // if (
            //   item.item_name
            //     .toLocaleLowerCase()
            //     .includes(searchValue.toLocaleLowerCase())
            // // ) {
            filteredResult.push(item);
            // }
         }
      }
      const fuse = new Fuse(filteredResult, {
         keys: ["item_name", "category_name"],
      });
      const res = fuse.search(value);
      let lt = [];
      for (let x of res) {
         lt.push(x.item);
      }
      // setFilteredItems(res)
      // @ts-ignore
      setFilteredItems(lt);
   };
   const fetchLoyaltySubscriptionDetails = async () => {
      loyaltySubscriptionAPI(accessToken?.jwt, { merchant_id: mid }, "GET")
         .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
               const data = await res.json();
               console.log(data, "fetchLoyaltySubscriptionDetails");
               dispatch(orderActions.setLoyaltyDetails(data));
            }
         })
         .catch((err) => {
            console.log(err, "fetchLoyaltySubscriptionDetails");
         });
   };
   useEffect(() => {
      if (selectedTag.isRecommendedClicked) {
         setfeaturedItems(recommendedItems);
         setfeauturedTitle("Recommended");
      } else if (selectedTag.isNewlyAddedClicked) {
         setfeaturedItems(newlyAddedItems);
         setfeauturedTitle("Newly added");
      } else if (selectedTag.isBestSellingClicked) {
         setfeaturedItems(bestSellingItems);
         setfeauturedTitle("Best selling");
      }
   }, [selectedTag]);

   useEffect(() => {
      // if ( recommendedItems?.length > 0 )
      // {
      //    setfeaturedItems( recommendedItems );
      //    setSelectedTag( {
      //       isRecommendedClicked: true,
      //       isBestSellingClicked: false,
      //       isNewlyAddedClicked: false,
      //    } );
      // } else if ( bestSellingItems?.length > 0 )
      // {
      //    setfeaturedItems( bestSellingItems );
      //    setSelectedTag( {
      //       isRecommendedClicked: false,
      //       isBestSellingClicked: true,
      //       isNewlyAddedClicked: false,
      //    } );
      // } else if ( newlyAddedItems?.length > 0 )
      // {
      //    setfeaturedItems( newlyAddedItems );
      //    setSelectedTag( {
      //       isRecommendedClicked: false,
      //       isBestSellingClicked: false,
      //       isNewlyAddedClicked: true,
      //    } );
      // }
   }, [recommendedItems, bestSellingItems, newlyAddedItems]);

   const toggleDrawer = (event) => {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
         setOpenDrawer(false);
      } else {
         setOpenDrawer(!openDrawer);
      }
   };

   const ORDER = location.state?.orderDetails;
   console.log(accessToken, "accessToken");
   console.log(featuredItems, "featuredItems ");

   const onClickCart = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/checkout`);
      } else {
         navigate(`/${mid}/checkout`);
      }
   };

   const onClickLogin = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/${NAVIGATE_TO_LOGIN}`);
      } else {
         navigate(`/${mid}/${NAVIGATE_TO_LOGIN}`);
      }
   };
   const getPeopleAlsoOrdered = async (data) => {
      console.log(data, "DATA getPeopleAlsoOrdered");
      setrecentlyClickedItem(data.item_id);
      setloadingItemRecommender(true);
      const request = await getItemRecommender({
         merchantId: data.merchant_id,
         itemId: data.item_id,
         cookieId: data.cookie_id,
         activity: "added_to_cart",
      });
      const payload = request.payload;
      setloadingItemRecommender(false);
      if (request.statusCode === 200) {
         if (payload?.response?.length === 0) {
            toast("No items to recommend");
         }
         setpeopleWhoOrderedItems(payload.response);
         window.localStorage.setItem("peopleAlsoOrdered", JSON.stringify(payload.response));
         window.localStorage.setItem("recentlyClickedItem", data.item_id);
      }
      console.log(payload, "RESPONSE getItemRecommender");
   };

   const openReferral = () => {
      window.open("https://forms.gle/27Z8ofFLJi8mNhVx5");
   };

   const onClickOnViewOrders = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/order_summary/${customer_id}`);
      } else {
         navigate(`/${mid}/order_summary/${customer_id}`);
      }
   };
   const navigateToSurpriseMe = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/surprise-me`);
      } else {
         navigate(`/${mid}/surprise-me`);
      }
   };

   const navigateToHourlySpecials = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/menu/hourly`);
      } else {
         navigate(`/${mid}/menu/hourly`);
      }
   };

   const navigateToDailySpecials = () => {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}/menu/daily`);
      } else {
         navigate(`/${mid}/menu/daily`);
      }
   };

   useEffect(() => {
      messagingSupported.then((supported) => {
         if (supported) {
            if (Notification.permission === "granted") {
               setisNotificationEnabled(true);
            } else {
               setisNotificationEnabled(false);
            }
         }
      });
   }, []);

   const DRAWER_ITEMS = [
      {
         name: "Log in",
         icon: <LoginIcon />,
         showArrow: true,
         navigate: qid !== undefined ? `/${mid}/${qid}/${NAVIGATE_TO_LOGIN}` : `/${mid}/${NAVIGATE_TO_LOGIN}`,
      },
      {
         name: "Exclusive Club",
         icon: (
            <span className="drawer-menu-icon-wrapper">
               <LoyaltyIcon />
            </span>
         ),
         isLoggedIn: true,
         showArrow: true,
         navigate: qid !== undefined ? `/${mid}/${qid}/loyaltyprogram` : `/${mid}/loyaltyprogram `,
      },
      {
         name: "Orders",
         icon: (
            <span className="drawer-menu-icon-wrapper ">
               <OrdersIcon />
            </span>
         ),
         isLoggedIn: true,
         showArrow: true,
         navigate: qid !== undefined ? `/${mid}/${qid}/order_summary/${customer_id}` : `/${mid}/order_summary/${customer_id}`,
      },
      // {
      //   name: "Profile",
      //   icon: <ProfileIcon />,
      //   showArrow: true,
      //   navigate: "",
      // },
      // {
      //   name: "Refer & earn",
      //   icon: <ReferIcon />,
      //   showArrow: true,
      //   navigate: "/login",
      // },
      {
         name: "About us",
         icon: (
            <span className="drawer-menu-icon-wrapper ">
               <ProfileIcon />
            </span>
         ),
         showArrow: true,
         navigate: "/about-us",
      },
      // {
      // 	name: "Call restaurant",
      // 	icon: <CallIcon />,
      // 	showArrow: false,
      // 	href: `tel:+91${merchantDetails?.phone_number}`,
      // 	navigate: "",
      // },

      {
         name: "Share this store",
         icon: (
            <span className="drawer-menu-icon-wrapper ">
               <ShareIcon />
            </span>
         ),
         showArrow: true,
         handleClick: () => {
            navigator.share({
               title: "Share this store",
               text: "Share this store",
               url: qid !== undefined ? `${window.location.origin}/${mid}/${qid}` : `${window.location.origin}/${mid}`,
            });
            setOpenDrawer(false);
         },
      },
   ];

   const isMoreThanOneSpecial = () => Object.values(showSpecials).filter((item) => item).length > 1;

   return (
      <>
         {!loading ? (
            <>
               <CustomDrawer
                  isLoyaltyEnabled={isLoyaltyEnabled}
                  data={accessToken?.jwt ? DRAWER_ITEMS.slice(1) : DRAWER_ITEMS.filter((item) => !item.isLoggedIn)}
                  merchantDetails={merchantDetails}
                  open={openDrawer}
                  isLoggedIn={accessToken.jwt !== null ? true : false}
                  toggleDrawer={toggleDrawer}
               />
               <div className="homepage-container">
                  <WarningModal />
                  <div className="homepage-top-section">
                     <div className="homepage-header">
                        <div>
                           {!addMoreItemFlow ? (
                              <div
                                 onClick={toggleDrawer}
                                 className="drawer-icon-wrapper">
                                 <DrawerIcon />
                              </div>
                           ) : (
                              <div style={{ color: "#ffffff" }}>No text</div>
                           )}
                        </div>
                        <div className="homepage-header-text">
                           <div className="homepage-text1 font-custom">{merchantDetails?.restaurant_name}</div>
                        </div>

                        <div>
                           {!showMenuOnly && checkMenu.acceptOrders && checkMenu.showMenuWithOrder ? (
                              <div
                                 className="ring-1 rounded-[50px] ring-[#C3C5C6] p-1"
                                 onClick={onClickCart}>
                                 <CartIcon count={addMoreItemFlow ? addMoreItemCount : cart.length} />
                              </div>
                           ) : (
                              <div style={{ marginLeft: "3.5rem" }}></div>
                           )}
                        </div>
                     </div>

                     {checkMenu.planActive && checkMenu.showMenuWithOrder && !checkMenu.acceptOrders ? (
                        <div>
                           <Text className="eatery-text">The eatery is not currently accepting orders</Text>
                        </div>
                     ) : null}

                     {addMoreItemFlow && (
                        <Alert
                           sx={{ marginBottom: "1rem" }}
                           action={
                              <Button
                                 onClick={() => dispatch(addMoreItemOnCartActions.resetAddMoreItemFlow({}))}
                                 color="inherit"
                                 size="large">
                                 Cancel
                              </Button>
                           }
                           severity="info">
                           <AlertTitle>Adding items</AlertTitle>
                           You are adding more items to order Id <strong> {ORDER?.order_id}</strong>
                        </Alert>
                     )}
                     <div className="sticky top-0">
                        {menu?.length > 0 && (
                           <SearchInput
                              showCrossEndAdornment={showSearchResult}
                              handleHideSearchPage={() => setShowSearchResult(false)}
                              searchValue={searchValue}
                              setSearchValue={handleShowSearchPage}
                              handleShowSearchPage={handleShowSearchPage}
                           />
                        )}
                     </div>
                  </div>
                  {offers?.length > 0 && searchValue.length === 0 && <OffersBanner offers={offers} />}
                  {merchantDetails !== null && checkMenu.planActive && (
                     <div className="px-[3%]">
                        {searchValue === "" ? (
                           <>
                              {(showSpecials.hourly || showSpecials.daily) && (
                                 <div className="flex flex-col">
                                    <div className="HourlyDailyCard-Wrapper">
                                       <Carousel
                                          showArrows={false}
                                          showStatus={false}
                                          showIndicators={false}
                                          centerMode
                                          swipeable={isMoreThanOneSpecial()}
                                          selectedItem={showSpecials.hourly ? 0 : 1}
                                          centerSlidePercentage={90}
                                          autoPlay={false}>
                                          <div className="HourlyDailyCard-Container">
                                             {showSpecials.hourly && (
                                                <HourlyDailyCard
                                                   title={"Don’t miss out our hourly special"}
                                                   cardTypeText={"Hourly Special"}
                                                   extraText={"Explore now"}
                                                   images={[hourlyCardImage, hourlyCardImage, hourlyCardImage]}
                                                   colorScheme={"purple"}
                                                   onClickAction={navigateToHourlySpecials}
                                                />
                                             )}
                                          </div>
                                          <div className="HourlyDailyCard-Container">
                                             {showSpecials?.daily && (
                                                <HourlyDailyCard
                                                   title={"Grab our today’s special items"}
                                                   cardTypeText={"Today’s Special"}
                                                   extraText={"Explore now"}
                                                   images={[hourlyCardImage, hourlyCardImage, hourlyCardImage]}
                                                   colorScheme={"yellow"}
                                                   onClickAction={navigateToDailySpecials}
                                                />
                                             )}
                                          </div>
                                       </Carousel>
                                    </div>
                                 </div>
                              )}
                              <div className="toggle-chips-row">
                                 <div
                                    className="recommended-chip"
                                    onClick={() =>
                                       setSelectedTag({
                                          isRecommendedClicked: false,
                                          isBestSellingClicked: false,
                                          isNewlyAddedClicked: false,
                                          isAllItemsClicked: true,
                                       })
                                    }>
                                    <FilledTag
                                       underline={selectedTag.isAllItemsClicked}
                                       text="All Items"
                                       background={selectedTag.isAllItemsClicked ? "#0F6656" : "#FBF6F8"}
                                       fontColor={selectedTag.isAllItemsClicked ? "#0F191A" : "#0F191A80"}
                                    />
                                 </div>
                                 {recommendedItems?.length > 0 && (
                                    <div
                                       className="recommended-chip"
                                       onClick={() =>
                                          setSelectedTag({
                                             isRecommendedClicked: true,
                                             isBestSellingClicked: false,
                                             isNewlyAddedClicked: false,
                                             isAllItemsClicked: false,
                                          })
                                       }>
                                       <FilledTag
                                          underline={selectedTag.isRecommendedClicked}
                                          text="Our recommendations"
                                          background={selectedTag.isRecommendedClicked ? "#0F6656" : "#FBF6F8"}
                                          fontColor={selectedTag.isRecommendedClicked ? "#0F191A" : "#0F191A80"}
                                       />
                                    </div>
                                 )}
                                 {bestSellingItems?.length > 0 && (
                                    <div
                                       className="bestSelling-chip"
                                       onClick={() =>
                                          setSelectedTag({
                                             isRecommendedClicked: false,
                                             isBestSellingClicked: true,
                                             isNewlyAddedClicked: false,
                                             isAllItemsClicked: false,
                                          })
                                       }>
                                       <FilledTag
                                          underline={selectedTag.isBestSellingClicked}
                                          text="Best selling"
                                          background={selectedTag.isBestSellingClicked ? "#0F6656" : "#FBF6F8"}
                                          fontColor={selectedTag.isBestSellingClicked ? "#0F191A" : "#0F191A80"}
                                       />
                                    </div>
                                 )}
                                 {newlyAddedItems.length > 0 && (
                                    <div
                                       className="bestSelling-chip"
                                       onClick={() =>
                                          setSelectedTag({
                                             isRecommendedClicked: false,
                                             isBestSellingClicked: false,
                                             isNewlyAddedClicked: true,
                                             isAllItemsClicked: false,
                                          })
                                       }>
                                       <FilledTag
                                          underline={selectedTag.isNewlyAddedClicked}
                                          text="Newly added"
                                          background={selectedTag.isNewlyAddedClicked ? "#0F6656" : "#FBF6F8"}
                                          fontColor={selectedTag.isNewlyAddedClicked ? "#0F191A" : "#0F191A80"}
                                       />
                                    </div>
                                 )}
                                 {/* 
                                 <div onClick={navigateToSurpriseMe}>
                                    <FilledTag
                                       text="Combos"
                                       background={"#FBF6F8"}
                                       fontColor={"#0F191A80"}
                                    />
                                 </div> */}
                              </div>
                              <div>
                                 {(selectedTag.isRecommendedClicked || selectedTag.isBestSellingClicked || selectedTag.isNewlyAddedClicked) && (
                                    // <FeaturedArea
                                    //    title={ feauturedTitle }
                                    //    recommendedItems={ featuredItems["out_of_stock"] !== false ? featuredItems : [] }
                                    // />
                                    <div className="menupage-item-container">
                                       {(selectedTag.isRecommendedClicked
                                          ? recommendedItems
                                          : selectedTag.isBestSellingClicked
                                          ? bestSellingItems
                                          : selectedTag.isNewlyAddedClicked
                                          ? newlyAddedItems
                                          : []
                                       )?.length > 0 ? (
                                          (selectedTag.isRecommendedClicked
                                             ? recommendedItems
                                             : selectedTag.isBestSellingClicked
                                             ? bestSellingItems
                                             : selectedTag.isNewlyAddedClicked
                                             ? newlyAddedItems
                                             : []
                                          )
                                             ?.slice()
                                             .sort((a, b) => (a.out_of_stock === b.out_of_stock ? 0 : a.out_of_stock ? 1 : -1))
                                             .map((item, index) => (
                                                <>
                                                   <FoodItemBeforeOrder
                                                      cardType="details"
                                                      mid={mid ?? ""}
                                                      getPeopleAlsoOrdered={getPeopleAlsoOrdered}
                                                      // orderedInfo={'15'}
                                                      item={item}
                                                      key={item.item_id}
                                                      toggledId={toggledId}
                                                      updateToggle={(id) => {
                                                         toggledId === id ? settoggledId("") : settoggledId(id);
                                                         setrecentlyClickedItem("");
                                                      }}
                                                   />
                                                   {recentlyClickedItem === item?.item_id && loadingItemRecommender ? (
                                                      <MostOrderedSkeleton />
                                                   ) : (
                                                      recentlyClickedItem === item?.item_id && toggledId !== "" && peopleWhoOrderedItems?.length > 0 && <MostOrdered data={peopleWhoOrderedItems} />
                                                   )}
                                                </>
                                             ))
                                       ) : (
                                          <NoItems />
                                       )}
                                    </div>
                                 )}
                                 {/* <div className='homepage-text2'>Today's special</div> */}
                              </div>
                              <div>
                                 {selectedTag.isAllItemsClicked &&
                                    (menu === null ? (
                                       <MenuCard
                                          menu={menu}
                                          cards={allCards}
                                       />
                                    ) : allCards?.length > 0 ? (
                                       <MenuCard
                                          menu={menu}
                                          cards={allCards}
                                       />
                                    ) : (
                                       <div className="homepage-no-items-found">
                                          <NoItemsSvg />
                                          No Items found
                                       </div>
                                    ))}
                              </div>
                              <div className="homepage-powered-by-text my-3 font-custom tracking-normal">
                                 Powered by
                                 <a
                                    className="homepage-footer-text font-custom tracking-wide"
                                    href="https://www.thebeautifulmenu.com">
                                    Thebeautifulmenu.com
                                 </a>
                              </div>
                           </>
                        ) : (
                           <div className="homepage-food-item">
                              {filteredItems.length == 0 && <NoItems />}
                              {filteredItems?.map((item) => (
                                 <>
                                    <FoodItemBeforeOrder
                                       cardType="details"
                                       mid={mid ?? ""}
                                       getPeopleAlsoOrdered={getPeopleAlsoOrdered}
                                       // orderedInfo={'15'}
                                       item={item}
                                       key={item.item_id}
                                       toggledId={toggledId}
                                       updateToggle={(id) => {
                                          toggledId === id ? settoggledId("") : settoggledId(id);
                                          setrecentlyClickedItem("");
                                       }}
                                    />
                                    {recentlyClickedItem === item?.item_id && loadingItemRecommender ? (
                                       <MostOrderedSkeleton />
                                    ) : (
                                       recentlyClickedItem === item?.item_id && toggledId !== "" && peopleWhoOrderedItems.length > 0 && <MostOrdered data={peopleWhoOrderedItems} />
                                    )}
                                 </>
                              ))}
                           </div>
                        )}
                     </div>
                  )}
                  {!checkMenu.planActive && !checkMenu.showMenu && !checkMenu.showMenuWithOrder ? (
                     <img
                        className="homepage-not-found"
                        src={require("../../asset/NotFound.gif")}
                     />
                  ) : null}

                  {openFilter === true && (
                     <BottomModal height="18rem">
                        <FilterModal handleClose={() => setOpenFilter(false)} />
                     </BottomModal>
                  )}
               </div>
            </>
         ) : (
            <HomePageSkeleton />
         )}
      </>
   );
};

const HomePageSkeleton = () => (
   <>
      <Skeleton
         height={"2.5rem"}
         width={"80%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 35,
            marginTop: 20,
         }}
      />
      <Skeleton
         height={"3rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"5rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"2rem"}
         width={"30%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"3rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"3rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"3rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
      <Skeleton
         height={"3rem"}
         width={"90%"}
         style={{
            alignSelf: "center",
            display: "flex",
            marginLeft: 20,
            marginTop: 10,
         }}
      />
   </>
);

const MostOrderedSkeleton = () => (
   <div style={{ marginLeft: "0.5rem" }}>
      <Skeleton
         height={"1.3rem"}
         width={"90%"}
      />
      <div
         style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
         }}>
         <Skeleton
            height={"10rem"}
            width={"10rem"}
         />
         <Skeleton
            height={"10rem"}
            width={"10rem"}
         />
      </div>
   </div>
);

export default HomePage;
