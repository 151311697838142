/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { cartActions } from "../../store/cart/reducer";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import getSingleOrderDetail from "../../api/order/getSingleOrderDetail";
import ActiveOrderCard from "../../component/ActiveOrderCard";
import ButtonWithArrow from "../../component/Button";
import PageHeader from "../../component/PageHeader";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";
import cartItemsSelector from "../../store/cart/selectors";
import OrderDetailsSection from "./OrderDetailsSection";
import { Link } from "react-router-dom";
import "./style.scss";
import qrSelectors from "../../store/Qr/selectors";
import { updateOrderCartActions } from "../../store/updateOrderCart/reducer";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import { enableES5 } from "immer";
import { orderActions } from "../../store/order/orderSlice";
import orderSelectors from "../../store/order/selectors";
import useRazorpay from "react-razorpay";
import updateOrderAPI from "../../api/order/updateOrder";
import Skeleton from "react-loading-skeleton";
import SingleOrderSkeleton from "./SingleOrderSkeleton";
import InfoSvg from "./InfoSvg";
import { Underline } from "react-feather";
import { addMoreItemOnCartActions } from "../../store/addMoreItemOnOrder/reducer";
import { Toaster, toast } from "react-hot-toast";
import InfoIcon from "@mui/icons-material/Info";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import { ToastContainer, toast as toastify } from "react-toastify";
import OrderMessage from "./OrderDetailsSection";
import Tooltip from "@mui/material/Tooltip";
import FilledTag from "../../component/FilledTag";
import OrderStatusPage from "./OrderStatusPage";
import DineInOrderCard from "../../component/DineInOrderItemsCard";
import RightArrowBlack from "../../asset/icons/RightArrowBlack";
const HEIGHT = window.innerHeight;

const SingleOrderDetailPage = (props: any) => {
   const navigate = useNavigate();
   const { orderId } = useParams();
   const dispatch = useAppDispatch();
   const [orderDetails, setOrderDetails] = useState<any | null>(null);
   const REACT_APP_RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
   const [refundModal, setrefundModal] = useState(false);
   const Razorpay = useRazorpay();
   const { mid, qid }: any = useParams();
   const [orderPlacedModal, setorderPlacedModal] = useState(false);
   console.log("QR CODE ID => ", qid);
   const [navigateToOrderStatus, setnavigateToOrderStatus] = useState(true);

   const { cart, totalValue, itemCount } = useAppSelector(cartItemsSelector.getAllCartInfo(mid));
   const [newlyAddedItems, setnewlyAddedItems] = useState(0);
   const { customer_id } = useAppSelector(userSelectors.getUserDetails);
   const { jwt: accessToken }: any = useAppSelector(userSelectors.getAccessToken);
   const { isUpdating } = useAppSelector(updateOrderCartSelectors.getAllUpdateOrderCartInfo);
   const countRef = useRef(0);
   const location: any = useLocation();

   const SingleOrder = useAppSelector(orderSelectors.getOrder);

   console.log(SingleOrder, "SingleOrder");

   const existingInStockOrders = orderDetails?.items_ordered.filter((item: any) => !item.out_of_stock);
   let existingOutOfStockOrders = orderDetails?.items_ordered.filter((item: any) => item.out_of_stock);
   let refund_amount = existingOutOfStockOrders?.reduce((prev: any, curr: any) => prev + curr.price_after_discount * curr.count, 0);

   const previousOrderTotalValue = orderDetails?.items_ordered.reduce((prev: any, curr: any) => prev + parseInt(curr.price_after_discount) * curr.count, 0);

   const [refundAmount, setrefundAmount] = useState(0);

   const fetchOrderData = useCallback(async () => {
      if (!accessToken || !orderId) return;

      const response = await getSingleOrderDetail({
         orderId: orderId,
         accessToken,
      });

      if (response.statusCode == 200 || response.statusCode == 201) {
         setOrderDetails(response.payload as any);

         console.log("response payload single order", response.payload);
      } else {
         toast.error("Error fetching order details");
      }
   }, [accessToken, orderId]);

   useEffect(() => {
      let refund = 0;
      for (const key in orderDetails?.items_ordered) {
         if (orderDetails.items_ordered[key].out_of_stock) {
            refund += orderDetails.items_ordered[key].total_price;
         }
      }
      setrefundAmount(refund);
   }, [orderDetails]);

   useEffect(() => {
      if (countRef.current === 0 && accessToken) {
         countRef.current = 1;
         fetchOrderData();
      }
   }, [accessToken, fetchOrderData]);

   console.log({ orderDetails });

   const payExtraAmount = async () => {
      let updateOrderPayload = {
         items_ordered: orderDetails.items_ordered,
         qr_code_id: qid,
         order_discount: 0.0,
         // total_amount: finalTotal,
         // total_amount_after_discount: finalTotal,
         total_amount: orderDetails.total_amount,
         total_amount_after_discount: orderDetails.total_amount_after_discount,
         merchant_id: mid,
         order_type: orderDetails.order_type,
         payment_status: orderDetails.payment_status,
         order_status: "order_updated",
         refund_amount: orderDetails.refund,
         order_id: orderDetails.order_id,
         order_updated_by: "customer",
         table_number: 12,
         payment_order_id: orderDetails.payment_gateway_details.payment_gateway_order_id,
         extra_payment: true,
         extra_payment_amount: orderDetails.extra_payment_amount,
         amount_paid: orderDetails.amount_paid,
      };

      console.log("updateOrderPayload", updateOrderPayload);

      const response = await updateOrderAPI(updateOrderPayload, accessToken);
      console.log("after", response);
      if (response.statusCode === 200) {
         setOrderDetails(response.payload);
         RetryPayment();
      }
   };
   const NavigateToOrderStatusPage = () => {
      setnavigateToOrderStatus((p) => !p);
   };

   // const addItemsToCart = () => {
   //   if (existingOutOfStockOrders.length > 0 && !isUpdating) {
   //     // dispatch(updateOrderCartActions.setOrderId(orderDetails?.order_id));

   //     // dispatch(updateOrderCartActions.setIsUpdating(true));
   //     // dispatch(
   //     //   updateOrderCartActions.setPaymentStatus(orderDetails.payment_status)
   //     // );
   //   }
   // };

   const handleAddItem = () => {
      dispatch(updateOrderCartActions.initialise(orderDetails));
      navigate(`/${mid}`);
   };

   const proceedWithoutAddItem = () => {
      dispatch(orderActions.setIsOrderSummaryVisible(true));
      dispatch(updateOrderCartActions.initialise(orderDetails));
      navigate(`/checkout/${mid}`);
   };

   let updateOrderPayload = orderDetails;

   const RetryPayment = async () => {
      const amountToPay = orderDetails?.extra_payment_amount > 0 ? orderDetails?.extra_payment_amount : orderDetails?.total_amount_after_discount;

      const amount_paid = amountToPay + orderDetails?.amount_paid;
      const options: any = {
         key: REACT_APP_RAZORPAY_KEY_ID,
         ...orderDetails.payment_gateway_details,
         amount: (amountToPay * 100).toString(),
         handler: async (response: any) => {
            updateOrderPayload = {
               ...orderDetails,
               payment_order_id: response.razorpay_payment_id,
               payment_status: "paid",
               extra_payment_amount: 0,
               extra_payment: false,
               amount_paid: amount_paid,
               payment_made_through_payment_gateway: true,
            };
            const updateOrderResponse: any = await updateOrderAPI(updateOrderPayload, accessToken);

            if (updateOrderResponse.statusCode === 200) {
               window.location.reload();
            }
         },
      };

      const rzp1 = new Razorpay(options);
      rzp1.open();
   };

   const ProceedWithoutAdding = async () => {
      let items_ordered = [...orderDetails.items_ordered];

      let items_modified = [];

      for (const key in items_ordered) {
         if (!items_ordered[key].out_of_stock) {
            items_modified.push(items_ordered[key]);
         }
      }
      const updateOrderPayload = {
         ...orderDetails,
         items_ordered: items_modified,
      };
      const response = await updateOrderAPI(updateOrderPayload, accessToken);
      if (response.statusCode === 200) {
         window.location.reload();
         setrefundModal(false);
      }
   };

   const onPressButton = () => {
      // if (orderDetails?.payment_status === 'paid') {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}`);
      } else {
         navigate(`/${mid}`);
      }
      // }
   };

   console.log(orderDetails, "orderDetails");

   useEffect(() => {
      if (location?.state?.fromDinein || location?.state?.fromPayment) {
         setorderPlacedModal(true);
         setTimeout(() => {
            setorderPlacedModal(false);
         }, 6000);
      }
   }, []);

   const onPressBack = () => {
      const BACKLINK = qid !== undefined ? `/${mid}/${qid}` : `/${mid}`;
      if (location?.state?.fromDinein) {
         navigate(BACKLINK);
      } else {
         navigate(-1);
      }
   };
   console.log(location, "location on single order details");

   const onAddMoreItems = () => {
      dispatch(
         addMoreItemOnCartActions.initialise({
            confirmedItems: orderDetails.items_ordered,
            totalAmount: orderDetails.total_amount_after_discount,
            orderId: orderDetails.order_id,
         }),
      );

      console.log("QID onAddMoreItems", qid);

      navigate(qid !== undefined ? `/${mid}/${qid}` : `/${mid}`, {
         state: { orderDetails: orderDetails },
      });
   };

   const SHOW_ADD_MORE_ITEMS = orderDetails?.order_type === "Dine-in" && orderDetails?.payment_status === "un-paid" && orderDetails?.order_status !== "cancelled";

   const totalItemsCount = (order: any) => {
      console.log(order, "order");
      let Count = 0;
      for (let i = 0; i < order.items_ordered?.length; i++) {
         if (order.items_ordered[i]?.suborder_status !== "cancelled") {
            Count = Count + order.items_ordered[i]?.suborder_items_ordered?.length;
         }
      }
      console.log(Count, "Counttt");
      return Count;
   };

   const onClickViewOrderStatus = () => setnavigateToOrderStatus(true);

   return (
      <>
         {!navigateToOrderStatus ? (
            <div className="single-order-details-container">
               <Toaster
                  position="top-center"
                  containerStyle={{ zIndex: 9999, marginTop: `3rem` }}
               />
               <ToastContainer
                  style={{
                     zIndex: 9999,
                  }}
                  hideProgressBar={true}
                  position="bottom-center"
               />
               <div className="order-summary-header">
                  <div onClick={NavigateToOrderStatusPage}>
                     <BackArrowIcon />
                  </div>
                  <div className="order-status-header-text-container">
                     <div className="order-summary-header-text">{"Order id: " + orderId}</div>
                     <div
                        className="view-order-status-container"
                        onClick={onClickViewOrderStatus}>
                        <div className="view-order-status-text">View order status</div>
                        <RightArrowBlack />
                     </div>
                  </div>
                  <div></div>
               </div>
               <div style={{ paddingTop: "5rem" }}>
                  <OrderDetailsSection
                     onPressChange={() => {}}
                     totalItems={orderDetails?.order_type === "Dine-in" ? totalItemsCount(orderDetails) : orderDetails?.items_ordered?.filter((item: any) => !item.out_of_stock).length}
                     totalAmount={orderDetails?.total_amount}
                     totalDiscount={orderDetails?.order_discount}
                     grandTotal={orderDetails?.amount_after_taxes}
                     refundAmount={orderDetails?.refund}
                     paymentStatus={orderDetails?.payment_status}
                     orderType={orderDetails?.order_type}
                     orderStatus={orderDetails?.order_status}
                     showChange={false}
                     extraPaymentAmount={orderDetails?.extra_payment_amount}
                     cgst={orderDetails?.cgst_percentage}
                     sgst={orderDetails?.sgst_percentage}
                     rounded_off={orderDetails?.rounded_off}
                  />
                  <div className="single-order-details-orders-div">
                     {orderDetails === null && (
                        <div className="single-order-details-skeleton">
                           <Skeleton
                              wrapper={SingleOrderSkeleton}
                              count={4}
                           />
                        </div>
                     )}

                     <div className="single-order-details-border"></div>
                     {existingInStockOrders?.length > 0 ? (
                        <div className="single-order-details-list">
                           {/* {orderDetails?.order_type !== "Dine-in" && (
                    <div className="single-order-details-list-available-text">Items list</div>
                  )} */}
                           <div className="active-cards-single-order-page-container">
                              {orderDetails?.order_type === "Dine-in" ? (
                                 existingInStockOrders?.map((item: any, index: number) => {
                                    return (
                                       <>
                                          <DineInOrderCard
                                             orderDetails={orderDetails}
                                             index={index}
                                             item={item}
                                          />
                                       </>
                                    );
                                 })
                              ) : (
                                 <>
                                    {existingInStockOrders?.map((item: any, index: number) => {
                                       return (
                                          // <ActiveOrderCard
                                          //   key={item?.item_id}
                                          //   itemName={item?.item_name}
                                          //   itemPrice={item?.price_after_discount}
                                          //   count={item?.count}
                                          //   out_of_stock={item?.out_of_stock}
                                          //   order_status={orderDetails?.order_status}
                                          //   merchant_added_quantity={0}
                                          //   extra_payment_amount={0}
                                          // />
                                          <>
                                             <DineInOrderCard
                                                orderDetails={orderDetails}
                                                index={index}
                                                item={item}
                                             />
                                          </>
                                       );
                                    })}
                                 </>
                              )}
                           </div>
                        </div>
                     ) : null}
                  </div>
                  <div className="single-order-page-button-wrapper">
                     <div
                        onClick={onPressButton}
                        className="single-order-details-bottom-container-btn-go-to-menu">
                        Go to menu
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            <>
               <OrderStatusPage
                  orderPlacedModal={orderPlacedModal}
                  orderId={orderId}
                  NavigateToOrderStatusPage={onPressBack}
                  NavigateToOrderDetailsPage={NavigateToOrderStatusPage}
                  orderDetails={orderDetails}
                  setorderPlacedModal={setorderPlacedModal}
                  onAddMoreItems={onAddMoreItems}
                  RetryPayment={RetryPayment}
                  fetchOrderData={fetchOrderData}
               />
            </>
         )}
      </>
   );
};

export default SingleOrderDetailPage;

/*
      <Buttondiv  className="ggrgrg">
      <BottomButtondiv>
        <ButtonWithArrow
          background="#0F6656"
          buttonText="Add Item"
          fontColor="white"
          onClickHandler={handleAddItem}
        />
      </BottomButtondiv>
      <div style={{display:"flex", justifyContent:"center"}}>
      <div 
          style={{
            position : "fixed", letterSpacing : "-0.3px", bottom : "10px",
             textDecorationLine : "underline", 
          }}
          size="1.3rem"
          weight="500"
          color="#4361EE"
          lHeight="1.5rem"
          align="center"
          onClick={proceedWithoutAddItem}
          >
        Proceed without adding Items
          </div>
      </div>
      </Buttondiv>
      */
