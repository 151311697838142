/** @format */

import getSymbolFromCurrency from "currency-symbol-map";
import { createSlice } from "@reduxjs/toolkit";

export interface ShowMenuState {
   showMenu: boolean;
   showMenuWithOrder: boolean;
   acceptOrders: boolean;
   planActive: boolean;
   dineIn: boolean;
   takeAway: boolean;
   delivery: boolean;
   loyalty_enabled: boolean;
   show_available_item_count: boolean;
   include_gst: boolean;
   currency: string;
   within_india: string;
   showMenuOnly: boolean;
}

const initialState: ShowMenuState = {
   showMenu: false,
   showMenuOnly: false,
   showMenuWithOrder: false,
   acceptOrders: false,
   planActive: false,
   dineIn: false,
   takeAway: false,
   delivery: false,
   show_available_item_count: false,
   loyalty_enabled: false,
   include_gst: false,
   currency: "INR",
   within_india: "",
};

export const showMenuSlice = createSlice({
   name: "showMenu",
   initialState: initialState,
   reducers: {
      setMenu: (state: any, action: any) => {
         console.log("PAYLOAD SHOWMENU => ", action.payload);
         const merchantDetails = action.payload;
         state.dineIn = merchantDetails.dine_in;
         state.takeAway = merchantDetails.take_away;
         state.delivery = merchantDetails.delivery;
         state.include_gst = merchantDetails.include_gst;
         state.currency = getSymbolFromCurrency(merchantDetails.currency);
         state.show_available_item_count = merchantDetails.show_available_item_count;
         state.loyalty_enabled = merchantDetails.loyalty_enabled;
         state.within_india = merchantDetails.within_india;
         state.showMenuOnly = merchantDetails.show_menu_only;
         if (merchantDetails.plan_active) {
            state.planActive = true;
            if (merchantDetails.plan_name === "all-in" || merchantDetails.plan_name === "trial") {
               state.showMenu = merchantDetails.show_menu_only;
               console.log("ALL FEAUTRUES ,setMenu");
               state.showMenuWithOrder = true;
               state.acceptOrders = merchantDetails.accept_orders;
            } else {
               console.log("ONLY MENU , setMenu");
               state.showMenu = merchantDetails.show_menu_only;
               state.showMenuWithOrder = false;
               state.acceptOrders = false;
               state.dineIn = false;
               state.takeAway = false;
               state.delivery = false;
            }
         } else {
            state.showMenu = merchantDetails.show_menu_only;
            state.showMenuWithOrder = false;
            state.acceptOrders = false;
            state.planActive = false;
         }
         console.log("setMenu", state);
      },
   },
});

export const showMenuActions = showMenuSlice.actions;
