/** @format */

import React from "react";
import backArrow from "../svg/backArrow.svg";

const BackArrowIcon = () => {
   return (
      <div className="h-[40px] w-[40px] bg-[#fff] flex items-center rounded-[50px] justify-center ">
         <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
               d="M5.97861 1.03619C6.32659 0.688217 6.89201 0.692286 7.23494 1.04523C7.57096 1.39107 7.56699 1.94264 7.22603 2.28361L3.38503 6.1246L17.1114 6.1246C17.5946 6.1246 17.9863 6.51634 17.9863 6.99957C17.9863 7.48281 17.5946 7.87455 17.1114 7.87455L3.38503 7.87454L7.22602 11.7155C7.56699 12.0565 7.57096 12.6081 7.23494 12.9539C6.892 13.3069 6.32658 13.3109 5.97861 12.963L0.722334 7.70668C0.331811 7.31615 0.331811 6.68299 0.722336 6.29247L5.97861 1.03619Z"
               fill="#1C1B1F"
            />
         </svg>
      </div>
   );
};

export default BackArrowIcon;
