/** @format */

import AddIcon from "@mui/icons-material/Add";
import Sucess from "canvas-confetti";
import { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate, useParams } from "react-router-dom";
import { getAddress } from "../../api/address/addessAPI";
import { getLoyaltyPointsAPI, loyaltySubscriptionAPI } from "../../api/loyalty/loyaltyAPIs";
import updateOrderAPI from "../../api/order/updateOrder";
import BackArrowIcon from "../../asset/icons/BackArrowIcon";
import ApplyCoupon from "../../component/ApplyCoupon";
import CartCard from "../../component/CartCard";
import CouponCard from "../../component/CouponCard";
import EmptyCart from "../../component/EmptyCart";
import HurrayModal from "../../component/HurrayModal";
import LoyaltyBanner from "../../component/LoyaltyBanner";
import OfferMessage from "../../component/OfferMessage";
import OrderConfirmationModal from "../../component/OrderConfirmationModal";
import { Text } from "../../component/Text";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import { cartActions } from "../../store/cart/reducer";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { orderActions } from "../../store/order/orderSlice";
import orderSelectors from "../../store/order/selectors";
import showMenuSelector from "../../store/showMenu/selectors";
import { updateOrderCartActions } from "../../store/updateOrderCart/reducer";
import updateOrderCartSelectors from "../../store/updateOrderCart/selectors";
import userSelectors from "../../store/user/selectors";
import { CGST_PERCENTAGE, SGST_PERCENTAGE } from "../../utils/definition";
import ApplyLoyaltyCoinsModal from "../ApplyLoyaltyCoinsModal";
import ConfirmOrderModal from "./ConfirmOrderModal";
import "./style.scss";
import OrderPlaced from "../../component/OrderPlaced";
import NoOffer from "../../asset/svg/OffersScreen/NoOffer.png";
import { ReactComponent as GoBackArrow } from "../../asset/svg/OffersScreen/GoBackArrow.svg";

var hash = require("object-hash");

const MenuPage = () => {
   const [isOrderPlaced, setIsOrderPlaced] = useState(false);
   const REACT_APP_RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
   const { mid, qid } = useParams();
   const Razorpay = useRazorpay();
   const REACT_APP_RAZORPAY_KEY_SECRET = process.env.REACT_APP_RAZORPAY_KEY_SECRET;
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const allItems = useAppSelector(cartItemsSelector.getAllCartItems(mid));
   const [orderConfirmationModal, setorderConfirmationModal] = useState(false);
   const [defaultOrderType, setDefaultOrderType] = useState("Dine-in");
   const [couponView, setCouponView] = useState(false);
   const [hurrayModal, setHurrayModal] = useState(false);
   // const [appliedCoupon, setAppliedCoupon] = useState("");
   const appliedCoupon = useAppSelector(orderSelectors.getAppliedCoupon);
   const { addedItems, addMoreItemFlow, confirmedItems, totalAmount } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);
   const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
   const couponsData = useAppSelector(orderSelectors.getOffers);

   console.log("APPLIED COUPON", appliedCoupon);

   const [openLoyaltyModal, setOpenLoyaltyModal] = useState(false);
   const [hurrayLoyaltyModal, setHurrayLoyaltyModal] = useState(false);
   const [loyaltyCoinsUsed, setLoyaltyCoinsUsed] = useState(0);
   const { jwt: accessToken } = useAppSelector(userSelectors.getAccessToken);
   const { cart, totalValue: currentOrderValue, itemCount } = useAppSelector(cartItemsSelector.getAllCartInfo(mid));
   const { refundAmount, isUpdating, orderId, previousCartTotal, updateCart, updateCartTotal, payment_gateway_details, payment_status, totalAmountTobePaid, amountPaid } = useAppSelector(
      updateOrderCartSelectors.getAllUpdateOrderCartInfo,
   );
   const isLoyaltyEnabled = useAppSelector(showMenuSelector.getLoyaltyStatus);
   const LoyaltyDetails = useAppSelector(orderSelectors.getloyaltyDetails);
   const { currency } = useAppSelector(showMenuSelector.showMenu);
   const { customer_id } = useAppSelector(userSelectors.getUserDetails);
   const checkMenu = useAppSelector(showMenuSelector.showMenu);
   const [newItems, setNewItems] = useState([]);
   const [isMember, setMember] = useState(false);
   const [displayCart, setdisplayCart] = useState(cart);
   const [loyaltyDiscount, setLoyaltyDiscount] = useState(0);

   console.log(confirmedItems);
   console.log("ADDED_ITEMS", addedItems);
   const total_suborder_amount = addMoreItemFlow ? totalAmount : currentOrderValue;

   const fetchLoyaltyCoins = async () => {
      getLoyaltyPointsAPI(accessToken, mid)
         .then(async (res) => {
            const data = await res.json();
            dispatch(orderActions.setLoyaltyPoints(data.loyalty_points));
         })
         .catch((err) => {
            console.log("loyalty points error", err);
         });
   };

   const fetchLoyaltySubscriptionDetails = async () => {
      loyaltySubscriptionAPI(accessToken, { merchant_id: mid }, "GET")
         .then(async (res) => {
            if (res?.status === 200 || res?.status === 201) {
               const data = await res?.json();
               console.log(data, "fetchLoyaltySubscriptionDetails");
               if (data?.subscribed === true) {
                  setMember(true);
                  await dispatch(orderActions.setLoyaltyDetails(data));
               } else {
                  setMember(false);
               }
            }
         })
         .catch((err) => {
            console.log(err, "fetchLoyaltySubscriptionDetails");
         });
   };
   const { include_gst } = useAppSelector(showMenuSelector.showMenu);

   useEffect(() => {
      fetchLoyaltySubscriptionDetails();
      fetchLoyaltyCoins();
   }, []);

   const handleUpdateOrder = async () => {
      if (!accessToken) return;
      const items = transformDataForCreateOrder(addMoreItemFlow ? addedItems : allItems);

      const updatedItems = items.filter((item) => !item.out_of_stock);
      console.log("frf", updatedItems);
      var sum = 0;
      for (let x of items) {
         sum += x.price_after_discount * x.count;
      }
      // sum = sum - prevOrderTotalValue;

      let finalTotal = 0;
      for (const key in updateCart) {
         if (!updateCart[key].out_of_stock) {
            finalTotal += updateCart[key].count * updateCart[key].price_after_discount;
         }
      }

      let updateOrderPayload = {
         items_ordered: updatedItems,
         qr_code_id: "qid",
         order_discount: 0.0,
         total_amount: updateCartTotal,
         total_amount_after_discount: updateCartTotal,
         merchant_id: mid,
         order_type: "dine-in",
         payment_status: payment_status,
         order_status: "order_updated",
         refund_amount: refundAmount,
         order_id: orderId,
         order_updated_by: "customer",
         table_number: 12,
         payment_order_id: payment_gateway_details.payment_gateway_order_id,
         extra_payment: false,
         extra_payment_amount: 0,
         amount_paid: amountPaid,
      };
      let data = {
         ...updateOrderPayload,
      };
      console.log("Update order is calling ....", data);

      if (totalAmountTobePaid > 0) {
         // alert('You have to pay ' + totalAmountTobePaid);
         data = {
            ...data,

            extra_payment: true,
            extra_payment_amount: totalAmountTobePaid,
         };
         console.log("Update order is calling ....", data);
      }
      console.log("Update order is calling ....", data);
      dispatch(updateOrderCartActions.clearCart({}));

      const response = await updateOrderAPI(data, accessToken);
      console.log("after", response);
      if (response.statusCode === 200) {
         if (totalAmountTobePaid > 0) {
            openPaymentGateway(data, response, accessToken);
         } else {
            dispatch(orderActions.setOrder(response?.payload));
            navigate(`/${mid}/order_summary/${customer_id}/${orderId}`);
            dispatch(updateOrderCartActions.clearCart({}));
         }
      }
   };

   const findOfferById = (id) => {
      console.log("COUPEN ID", id);

      return couponsData?.find((coupon) => coupon.offer_id === id);
   };

   const openPaymentGateway = async (updateOrderPayload, createApiResponse, accessToken) => {
      console.log("openPaymentGateway calling ..");
      const amount = isUpdating ? createApiResponse.payload.extra_payment_amount : createApiResponse.payload.total_amount_after_discount;
      const amount_paid = isUpdating
         ? createApiResponse.payload.extra_payment_amount + createApiResponse.payload.amount_paid
         : createApiResponse.payload.extra_payment_amount + createApiResponse.payload.total_amount_after_discount;
      const options = {
         key: REACT_APP_RAZORPAY_KEY_ID,
         amount: (amount * 100).toString(),
         ...createApiResponse.payload.payment_gateway_details,
         handler: async (response) => {
            updateOrderPayload = {
               ...updateOrderPayload,
               payment_order_id: response.razorpay_payment_id,
               payment_hash: response.razorpay_signature,
               payment_status: "paid",
               extra_payment: false,
               extra_payment_amount: 0,
               amount_paid: amount_paid,
               order_status: "new_order",
            };
            const updateOrderResponse = await updateOrderAPI(updateOrderPayload, accessToken);

            if (updateOrderResponse.statusCode === 200) {
               navigate(`/${mid}/order_summary/${customer_id}/${createApiResponse.payload.order_id}`);
               dispatch(updateOrderCartActions.clearCart({}));
               dispatch(cartActions.clearCart(mid));
            }
         },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
         onBackArrowClick();
         dispatch(updateOrderCartActions.clearCart({}));
         dispatch(cartActions.clearCart(mid));
      });

      rzp1.open();
   };

   const checkNewItems = (items) => {};

   function getPrevOrderAmount() {
      let sum = 0;
      for (let x of confirmedItems) {
         if (x?.suborder_status !== "cancelled") {
            sum += x.total_suborder_amount_offers_applied;
         }
      }
      return sum;
   }

   const getDineInPayload = (orderType) => {
      if (!accessToken) return;
      let items = transformDataForCreateOrder(addMoreItemFlow ? addedItems : allItems);
      var sum = getPrevOrderAmount();
      for (let x of items) {
         sum += x.price_after_discount * x.count;
         let addOnsTotal = 0;
         if (x?.addons?.length > 0) {
            x?.addons?.map((item) => {
               addOnsTotal += item?.count * item?.price;
            });
         }
         x.total_price += addOnsTotal;
         sum += addOnsTotal;
      }
      console.log("inhandledining", items);
      const subOrderPayload = {
         suborder_status: "new_order",
         suborder_items_ordered: items,
         total_suborder_amount: total_suborder_amount,
         total_suborder_amount_offers_applied: total_suborder_amount - checkMaximumDiscount(appliedCoupon) - loyaltyDiscount,
         offers_applied: appliedCoupon?.length > 0 ? [appliedCoupon] : [],
         loyalty_points_used: loyaltyCoinsUsed,
         offers_discount_amount: checkMaximumDiscount(appliedCoupon),
         loyalty_discount_amount: loyaltyDiscount,
      };
      const ITEMS_ORDERED = orderType === "Dine-in" ? [...confirmedItems, subOrderPayload] : [subOrderPayload];
      let TOTAL_AMOUNT = 0;
      ITEMS_ORDERED.map((item) => {
         TOTAL_AMOUNT += item.total_suborder_amount;
      });
      TOTAL_AMOUNT = parseFloat(TOTAL_AMOUNT).toFixed(2);
      let OVERALL_OFFER_AMOUNT = 0;
      let OVERALL_LOYALTY_AMOUNT = 0;
      ITEMS_ORDERED.map((item, index) => {
         OVERALL_LOYALTY_AMOUNT += item.loyalty_discount_amount;
         OVERALL_OFFER_AMOUNT += item.offers_discount_amount;
      });
      OVERALL_LOYALTY_AMOUNT = Math.round(OVERALL_LOYALTY_AMOUNT);
      OVERALL_OFFER_AMOUNT = Math.round(OVERALL_OFFER_AMOUNT);
      const TOTAL_AMOUNT_AFTER_DISCOUNT = TOTAL_AMOUNT - (OVERALL_LOYALTY_AMOUNT + OVERALL_OFFER_AMOUNT);
      let AMOUNT_AFER_TAXES = TOTAL_AMOUNT_AFTER_DISCOUNT;
      if (include_gst) {
         AMOUNT_AFER_TAXES = TOTAL_AMOUNT_AFTER_DISCOUNT + (TOTAL_AMOUNT_AFTER_DISCOUNT * (CGST_PERCENTAGE * 0.01) + TOTAL_AMOUNT_AFTER_DISCOUNT * (SGST_PERCENTAGE * 0.01));
      }
      AMOUNT_AFER_TAXES = parseFloat(AMOUNT_AFER_TAXES).toFixed(2);
      const AFTER_ROUNDED_OFF_AMOUNT = Math.round(AMOUNT_AFER_TAXES);
      const ROUNDED_OFF = (AFTER_ROUNDED_OFF_AMOUNT - AMOUNT_AFER_TAXES).toFixed(2);
      return {
         items_ordered: ITEMS_ORDERED,
         qr_code_id: qid !== undefined ? qid : "standalone",
         order_discount: 0.0,
         total_amount: TOTAL_AMOUNT,
         total_amount_after_discount: TOTAL_AMOUNT_AFTER_DISCOUNT,
         merchant_id: mid,
         order_type: orderType,
         payment_status: "un-paid",
         order_status: addMoreItemFlow ? "order_updated" : "new_order",
         // refund_amount: ,
         refund_amount: refundAmount,
         order_created_by: "customer",
         cgst_percentage: include_gst ? CGST_PERCENTAGE : 0,
         sgst_percentage: include_gst ? SGST_PERCENTAGE : 0,
         rounded_off: ROUNDED_OFF,
         amount_after_taxes: AMOUNT_AFER_TAXES,
         overall_offers_amount: OVERALL_OFFER_AMOUNT,
         overall_loyalty_amount: OVERALL_LOYALTY_AMOUNT,
      };
   };

   const handleDiningIn = async (orderType) => {
      const payload = getDineInPayload("Dine-in");

      let updateOrderPayload = {
         ...payload,
         extra_payment: false,
         extra_payment_amount: 0,
      };

      console.log("UPDATE ORDER PAYLOAD ", updateOrderPayload);
      const BASE_URL = qid !== undefined ? `/${mid}/${qid}` : `/${mid}`;
      const getAddressApiResult = await getAddress(accessToken);
      if (updateOrderPayload?.order_type === "Delivery") {
         if (getAddressApiResult === 404) {
            navigate(`${BASE_URL}/address`, {
               state: {
                  orderDetails: {
                     ...updateOrderPayload,
                  },
               },
            });
         } else {
            navigate(`${BASE_URL}/ordersummary`, {
               state: {
                  orderDetails: {
                     ...updateOrderPayload,
                     address: getAddressApiResult,
                     orderType: orderType,
                  },
               },
            });

            setLoyaltyDiscount(0);
            setLoyaltyCoinsUsed(0);
            // dispatch(
            //    orderActions.setLoyaltyUsage({
            //       loyaltyDiscount: 0,
            //       loyaltyCoinsUsed: 0,
            //    }),
            // );
         }
      } else {
         navigate(`${BASE_URL}/ordersummary`, {
            state: {
               orderDetails: {
                  ...updateOrderPayload,
                  address: getAddressApiResult,
               },
            },
         });
      }

      // openPaymentGateway(updateOrderPayload, createApiResponse, accessToken);
   };

   const onBackArrowClick = () => {
      navigate(-1);
   };

   const handleApplyCoupon = (coupon) => {
      let exist = [];
      if (exist.includes(coupon)) {
         exist.splice(exist.indexOf(coupon), 1);
      } else {
         exist.push(coupon);
      }
   };
   const [openConfirmationModal, setOpenConfirmationModal] = useState(true);

   const handleConfirmOrder = () => setOpenConfirmationModal((state) => !state);
   const transformDataForCreateOrder = (data) => {
      const temp = data.map((item) => {
         console.log("BEFORE-TRANSFORM", item);

         const body = {
            category_id: item.category_id,
            item_id: item.item_id,
            count: item.count,
            item_name: item.item_name,
            type: item.type,
            price: item.price,
            discount: item.discount || 0,
            total_price: parseInt(item.price_after_discount) * item.count,
            price_after_discount: parseInt(item.price_after_discount),
            is_recommended_item: false,
            out_of_stock: item.out_of_stock,
            item_source: item?.item_source,
            addons: item?.addons,
            customizations: item?.customizations,
            other_customization: item?.other_customization,
            // newly_added_quantity: item.newly_added_quantity,
         };

         if (item.is_newly_added === true) {
            return {
               ...body,
               is_newly_added: item.is_newly_added,
            };
         } else if (item?.newly_added_quantity > 0) {
            return {
               ...body,
               newly_added_quantity: item.newly_added_quantity,
            };
         } else {
            return body;
         }
      });

      return temp;
   };

   console.log(addedItems, "displayCart");

   const displayEmptyCart = () => {
      if (addMoreItemFlow) {
         if (addMoreItemFlow && addedItems?.length === 0 && cart?.length === 0) {
            return true;
         }
      } else {
         if (cart?.length === 0) {
            return true;
         }
      }
   };

   const handleLoyalty = () => {
      if (!isMember) {
         if (accessToken !== null) {
            if (qid !== undefined) {
               navigate(`/${mid}/${qid}/loyaltyprogram`);
            } else {
               navigate(`/${mid}/loyaltyprogram`);
            }
         } else {
            localStorage.setItem("redirect", "loyaltyprogram");
            localStorage.setItem("loyaltyRedirect", "checkout");
            navigate(qid !== undefined ? `/${mid}/${qid}/login` : `/${mid}/login`);
         }
      } else {
         setOpenLoyaltyModal(true);
      }
   };

   const isOffersApplicable = (coupon) => {
      console.log("ToTAl", total_suborder_amount, coupon);
      if (coupon?.bool_exp === "greater_than") {
         return total_suborder_amount > coupon?.offer_value;
      } else if (coupon?.bool_exp === "greater_than_or_equal_to") {
         return total_suborder_amount >= coupon?.offer_value;
      } else {
         return false;
      }
   };

   console.log("isOffersApplicable", isOffersApplicable());

   const displayConfimedModal = () => {
      if (addMoreItemFlow) {
         if (addedItems.length > 0) {
            return true;
         }
      } else {
         if (cart.length > 0) {
            return true;
         }
      }
   };

   useEffect(() => {
      autoRemoveOffer();
      handleAutoApplyCoupon();
   }, [total_suborder_amount]);

   const autoRemoveOffer = () => {
      if (appliedCoupon != "") {
         if (findOfferById(appliedCoupon)?.offer_value > total_suborder_amount) {
            // setAppliedCoupon("");
            dispatch(orderActions.setAppliedCoupon(""));
         }
      } else {
         return;
      }
   };
   const checkCartFlow = () => {
      if (addMoreItemFlow) {
         return totalAmount;
      } else {
         return currentOrderValue;
      }
   };
   const getNearestCoupon = () => {
      if (couponsData?.length > 0) {
         let offer = couponsData?.reduce((prev, curr) => (Math.abs(curr.offer_value - total_suborder_amount) < Math.abs(prev?.offer_value - total_suborder_amount) ? curr : prev));
         if (offer) {
            return offer;
         } else {
            return false;
         }
      }
   };
   const checkMaximumDiscount = (id) => {
      let total = 0;
      if (findOfferById(id)?.discount_type === "percentage") {
         total = Math.min((findOfferById(id)?.discount_val / 100) * total_suborder_amount, findOfferById(id)?.maximum_allowed_discount);
      } else {
         total = findOfferById(id)?.discount_val;
      }

      if (total === null || total === undefined) {
         return 0;
      } else {
         return total;
      }
   };

   const checkTypePercentage = (offer_id) => {
      if (findOfferById(offer_id)?.discount_type === "percentage") {
         return true;
      } else {
         return false;
      }
   };

   const showConfetti = () => {
      Sucess({
         particleCount: 200,
         spread: 40,
         origin: { y: 0.6 },
      });
   };

   const handleAutoApplyCoupon = () => {
      couponsData?.forEach((coupon) => {
         if (coupon?.offer_value < total_suborder_amount && coupon?.offer_value === getNearestCoupon()?.offer_value) {
            // setAppliedCoupon(coupon?.offer_id);
            dispatch(orderActions.setAppliedCoupon(coupon?.offer_id));

            // setHurrayModal(true);
            // showConfetti();
         }
      });
   };

   console.log("CARTTTT", cart, totalAmountTobePaid);

   const checkEnabledOrderTypes = () => {
      console.log("checkEnabledOrderTypes", checkMenu);
      const allOrderTypes = {
         dineIn: checkMenu.dineIn,
         delivery: checkMenu.delivery,
         takeAway: checkMenu.takeAway,
      };
      const standAloneOrderTypes = {
         delivery: checkMenu.delivery,
         takeAway: checkMenu.takeAway,
      };
      let totalEnabledOrders = [];
      if (qid !== undefined && qid !== "") {
         Object.keys(allOrderTypes).forEach((key) => allOrderTypes[key] === true && totalEnabledOrders.push(key));
      } else {
         Object.keys(standAloneOrderTypes).forEach((key) => {
            if (standAloneOrderTypes[key] === true) {
               totalEnabledOrders.push(key);
            }
         });
      }
      console.log("totalEnabledOrders", totalEnabledOrders);
      if (totalEnabledOrders.length > 1) {
         setorderConfirmationModal(true);
      } else {
         setShowConfirmOrderModal(true);
         switch (totalEnabledOrders[0]) {
            case "dineIn":
               setDefaultOrderType("Dine-in");
               break;
            case "delivery":
               setDefaultOrderType("Delivery");
               break;
            case "takeAway":
               setDefaultOrderType("Take away");
               break;
            default:
               break;
         }
      }
   };

   const onPressButton = () => {
      // if (orderDetails?.payment_status === 'paid') {
      if (qid !== undefined) {
         navigate(`/${mid}/${qid}`);
      } else {
         navigate(`/${mid}`);
      }
      // }
   };

   return (
      <>
         {isOrderPlaced ? (
            <OrderPlaced />
         ) : (
            <div className="checkout-container">
               <HurrayModal
                  open={hurrayModal}
                  onClose={() => setHurrayModal(false)}
                  statementPrimary={`You are saving ${currency}${checkMaximumDiscount(appliedCoupon)} on this order`}
                  statementSecondary={
                     checkTypePercentage(appliedCoupon)
                        ? `${findOfferById(appliedCoupon)?.discount_val?.toString()}%`
                        : `${currency}${findOfferById(appliedCoupon)?.discount_val?.toString()}` + " Off coupon applied!"
                  }
               />

               <div className="checkout-header sticky top-0 p-2 bg-[#fff] gap-[20px]">
                  <div className="w-[100%] py-2 flex items-center">
                     <div
                        className="mr-[10px] ring-1 rounded-[50px] ring-[#C3C5C6]"
                        onClick={couponView ? () => setCouponView(false) : onBackArrowClick}>
                        <BackArrowIcon />
                     </div>

                     <Text
                        className="menupage-header-text font-custom font-semibold flex flex-col gap-1"
                        size="1.3rem"
                        color="#0F191A"
                        lHeight="1"
                        align="left">
                        {couponView ? "Offers" : "Cart details"}
                     </Text>
                  </div>
               </div>

               {!couponView && (
                  <>
                     {getNearestCoupon() && getNearestCoupon()?.offer_value > total_suborder_amount && (
                        <OfferMessage
                           total_suborder_amount={total_suborder_amount}
                           offerValue={getNearestCoupon()?.offer_value}
                           discount={checkTypePercentage(getNearestCoupon().offer_id) ? getNearestCoupon()?.discount_val + "%" : currency + getNearestCoupon()?.discount_val}
                           maximum_allowed_discount={getNearestCoupon()?.maximum_allowed_discount}
                        />
                     )}

                     <OrderConfirmationModal
                        isAlert={showConfirmOrderModal}
                        isOpen={orderConfirmationModal || showConfirmOrderModal}
                        handleModalClose={(value) => {
                           if (value !== "") {
                              handleDiningIn(showConfirmOrderModal ? defaultOrderType : value);
                              setShowConfirmOrderModal(false);
                              setorderConfirmationModal(false);
                           } else {
                              setorderConfirmationModal(false);
                           }
                        }}
                        onClose={() => {
                           setorderConfirmationModal(false);
                           setShowConfirmOrderModal(false);
                        }}
                     />
                     {displayEmptyCart() ? (
                        <EmptyCart />
                     ) : (
                        <div className="checkout-content-container mb-2">
                           <div className="flex items-center justify-between py-3">
                              <div className="text-[18px] font-bold font-custom">Order details</div>
                              <div
                                 onClick={onPressButton}
                                 className="flex items-center gap-1 justify-between font-semibold text-[#0F6656] font-custom">
                                 <AddIcon /> Add items
                              </div>
                           </div>
                           <div className="checkout-items-container">
                              {addMoreItemFlow
                                 ? addedItems?.map((item) => (
                                      <CartCard
                                         item={item}
                                         key={item.item_id}
                                      />
                                   ))
                                 : cart?.map((item) => (
                                      <CartCard
                                         item={item}
                                         key={item.item_id}
                                      />
                                   ))}
                           </div>
                        </div>
                     )}

                     {!displayEmptyCart() && (
                        <>
                           <>
                              <div className="flex items-center justify-between p-3 px-4 w-[100%]">
                                 <div className="text-[18px] font-bold font-custom">Coupons & Benefits</div>
                              </div>
                              {couponsData.length > 0 && (
                                 <ApplyCoupon
                                    appliedOfferDetails={findOfferById(appliedCoupon)}
                                    appliedCoupon={appliedCoupon}
                                    previewDesc={"You saved " + currency + checkMaximumDiscount(appliedCoupon) + " on this order!"}
                                    onClick={() => setCouponView(true)}
                                 />
                              )}
                           </>

                           {isLoyaltyEnabled && (
                              <LoyaltyBanner
                                 isMember={isMember}
                                 appliedCoins={loyaltyCoinsUsed > 0}
                                 onClick={handleLoyalty}
                                 previewDesc={`${currency}${loyaltyDiscount} Off applied using ${loyaltyCoinsUsed} coins`}
                              />
                           )}
                           <ApplyLoyaltyCoinsModal
                              setLoyaltyDiscount={setLoyaltyDiscount}
                              setLoyaltyCoinsUsed={setLoyaltyCoinsUsed}
                              total={checkCartFlow()}
                              setHurrayLoyaltyModal={setHurrayLoyaltyModal}
                              open={openLoyaltyModal}
                              close={() => {
                                 setOpenLoyaltyModal((p) => !p);
                              }}
                           />
                        </>
                     )}
                     {!displayEmptyCart() && (
                        <>
                           <div className="flex items-center justify-between p-3 px-4 w-[100%]">
                              <div className="text-[18px] font-bold font-custom">Bill details</div>
                           </div>

                           {displayConfimedModal() && (
                              <ConfirmOrderModal
                                 payload={getDineInPayload("Dine-in")}
                                 totalDiscount={checkMaximumDiscount(appliedCoupon) + loyaltyDiscount}
                                 itemDiscount={0}
                                 couponDiscount={checkMaximumDiscount(appliedCoupon)}
                                 loyaltyDiscount={loyaltyDiscount}
                                 handleConfirmOrder={() => {
                                    console.log("handleConfirmOrder", addMoreItemFlow);
                                    addMoreItemFlow ? handleDiningIn("Dine-in") : checkEnabledOrderTypes();
                                 }}
                                 handleUpdateOrder={handleUpdateOrder}
                                 merchant_id={mid}
                              />
                           )}
                        </>
                     )}
                  </>
               )}

               {couponView && (
                  <>
                     <div
                        className="d-flex flex-column justify-content-center align-items-stretch gap-2 px-4"
                        style={{
                           width: "100%",
                        }}>
                        {couponsData?.length > 0 ? (
                           <>
                              {couponsData?.map((coupon, index) => {
                                 return (
                                    <CouponCard
                                       key={coupon?.offer_id}
                                       code={coupon?.offer_name}
                                       description={
                                          `${checkTypePercentage(coupon?.offer_id) ? `${coupon?.discount_val}%` : `${currency}${coupon?.discount_val}`}` +
                                          ` Off on orders above ${currency}${coupon?.offer_value}`
                                       }
                                       savings={"You saved " + currency + checkMaximumDiscount(coupon?.offer_id) + " on this order"}
                                       isApplicable={isOffersApplicable(coupon)}
                                       isApplied={appliedCoupon === coupon?.offer_id}
                                       applyCoupon={() => {
                                          showConfetti();
                                          // setAppliedCoupon(coupon.offer_id);
                                          dispatch(orderActions.setAppliedCoupon(coupon.offer_id));
                                          setCouponView(false);
                                          setHurrayModal(true);
                                       }}
                                       removeCoupon={() => {
                                          // setAppliedCoupon("");
                                          dispatch(orderActions.setAppliedCoupon(""));
                                       }}
                                    />
                                 );
                              })}
                           </>
                        ) : (
                           <div className="flex flex-col items-center ">
                              <img
                                 src={NoOffer}
                                 alt="NoOffer"
                              />
                              <p className="text-2xl font-semibold text-center mb-2">No coupons right now!</p>
                              <p className="text-base text-center mb-5">There is no coupons available at this moment. Come back later for more offers</p>
                              <button
                                 onClick={onBackArrowClick}
                                 className="flex items-center gap-x-2 mx-auto bg-[#F3F7F6] font-semibold text-[#0F6656] px-6 py-2 rounded-full">
                                 <span className="w-5">
                                    <GoBackArrow />
                                 </span>{" "}
                                 Go back
                              </button>
                           </div>
                        )}
                     </div>
                  </>
               )}
            </div>
         )}
      </>
   );
};

export default MenuPage;
