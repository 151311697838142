/** @format */

import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

interface StyledTextFieldProps {
   showsearch: string;
}

const StyledTextField = styled(TextField)<StyledTextFieldProps>`
   width: 100%;
   .MuiOutlinedInput-root {
      border-radius: 0.5rem;
      padding: 0 1rem;
      border-color: #fbf6f8;
      height: 3rem;
   }

   transition: all 0.2s;
`;
// background: #fbf6f8;
// color: #0F6656;
// & .Mui-focused .MuiOutlinedInput-notchedOutline {
//   border-color: pink;
// }
export { StyledTextField };
