import { combineReducers } from '@reduxjs/toolkit';
import { cartSlice } from './cart';
import { menuSlice } from './menu';
import { showMenuSlice } from './showMenu';
import { userSlice } from './user';
import { QrSlice } from './Qr';
import { orderSlice } from './order';
import { updateOrderCartSlice } from './updateOrderCart';
import { HotelCartSlice } from './HotelCart';
import { surpriseMeSlice } from './surprise_me';
import { addMoreItemOnCartSlice } from './addMoreItemOnOrder';
import { addonsSlice } from './Addons';

export const rootReducer = combineReducers({
  cartItems: cartSlice.reducer,
  menuItems: menuSlice.reducer,
  user: userSlice.reducer,
  showMenu: showMenuSlice.reducer,
  qr: QrSlice.reducer,
  order: orderSlice.reducer,
  hotelCartItems: HotelCartSlice.reducer,
  updateOrderCart: updateOrderCartSlice.reducer,
  surpriseMe: surpriseMeSlice.reducer,
  addMoreItemOnCart: addMoreItemOnCartSlice.reducer,
  addonsItems: addonsSlice.reducer,
  // [getAllOrdersApi.reducerPath]: getAllOrdersApi.reducer,
});
