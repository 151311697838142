/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";

const Card = ({ id, activeCard, totalCards, children }) => {
   const [visible, setVisible] = useState(false);

   useEffect(() => {
      if (id === activeCard) {
         setVisible(true);
      } else if (id === activeCard - 1 || (activeCard === 0 && id === totalCards - 1)) {
         setVisible(false);
      }
   }, [activeCard, id, totalCards]);

   return (
      <div className={`card ${visible ? "fade-in" : "fade-out"}`}>
         {/* Card Content */}
         {children}
      </div>
   );
};

export default Card;
