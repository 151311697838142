/** @format */

import React from "react";
import "./index.scss";

const MobilePreview = () => {
   return (
      <div className={`marvel-device iphone-x`}>
         <div className="notch">
            <div className="camera" />
            <div className="speaker" />
         </div>
         <div className="top-bar" />
         <div className="sleep" />
         <div className="bottom-bar" />
         <div className="volume" />
         <div className="overflow">
            <div className="shadow shadow--tr" />
            <div className="shadow shadow--tl" />
            <div className="shadow shadow--br" />
            <div className="shadow shadow--bl" />
         </div>
         <div className="inner-shadow" />
         <div className="screen">
            <div className="h-full pt-5">
               <iframe
                  src={"https://the-beautiful-menu-webapp-development.vercel.app/f483d00e-646b-443c-b5fa-db50a580ab20/79113da7-92fe-4474-90b4-550139312a67"}
                  height={"100%"}
                  width={"100%"}
               />
            </div>
         </div>
      </div>
   );
};

export default MobilePreview;
