/** @format */

import { Add, Remove } from "@mui/icons-material";
import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import AnalyticsIcon from "../../asset/icons/AnalyticsIcon";
import DiscountRect from "../../asset/icons/DiscountRect";
import RightArrow from "../../asset/icons/RightArrow";
import { addMoreItemOnCartActions } from "../../store/addMoreItemOnOrder/reducer";
import addMoreItemsOnCartSelector from "../../store/addMoreItemOnOrder/selectors";
import { cartActions } from "../../store/cart/reducer";
import cartItemsSelector from "../../store/cart/selectors";
import { useAppSelector } from "../../store/hooks";
import showMenuSelector from "../../store/showMenu/selectors";
import { classNames } from "../../utils/classNames";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";
import NonVegTag from "../NonVegTag";
import AddonsModal from "./AddonsModal";
import { toast } from "react-hot-toast";
import "./style.scss";
import DetailsModal from "../DetailsModal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const FoodItemBeforeOrder = ({ cardType, mid, item, toggledId, orderedInfo, updateToggle, getPeopleAlsoOrdered, index, handleShowDetails }) => {
   const {
      image_url: imageSrc,
      item_name: dishName,
      description,
      price,
      price_after_discount,
      discount,
      type,
      item_id,
      out_of_stock,
      recommended,
      best_selling: bestSelling,
      merchant_id,
      remaining_quantity,
      manage_inventory,
   } = item;
   const outOfStock = out_of_stock;
   const dispatch = useAppDispatch();
   const { showMenu, showMenuOnly, showMenuWithOrder, acceptOrders, show_available_item_count, dineIn, currency } = useAppSelector(showMenuSelector.showMenu);
   const [showInfo, setShowInfo] = useState(false);
   const [showItemDetails, setshowItemDetails] = useState(false);

   const [showAddons, setShowAddons] = useState(false);
   const cartItem = useAppSelector(cartItemsSelector.getMenuItem(item_id, merchant_id));
   const { addMoreItemFlow } = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItemsOnCart);

   const addedMoreItem = useAppSelector(addMoreItemsOnCartSelector.getAddMoreItem(item_id));

   const canReduceItem = useAppSelector(addMoreItemsOnCartSelector.canReduceItem(item_id));

   console.log("TOGGLE IDDD ", toggledId);

   const toggleShowInfo = () => setShowInfo((value) => !value);
   const toggleShowButton = () => {
      if (showMenuWithOrder && acceptOrders) {
         updateToggle(item_id);
      }
   };

   const isItemAlreadyAdded = () => {
      if (addMoreItemFlow && addedMoreItem?.count !== undefined) {
         return true;
      } else if (cartItem?.count !== undefined) {
         return true;
      }
      return false;
   };

   console.log("FOOD ITEM BEFORE ORDER ===>", item);

   const handleAddToCart = () => {
      const cookies = Object.fromEntries(document.cookie.split("; ").map((v) => v.split(/=(.*)/s).map(decodeURIComponent)));
      const isQuantityReached = item?.manage_inventory ? (addMoreItemFlow ? addedMoreItem?.count === remaining_quantity : cartItem?.count === remaining_quantity) : false;

      if (isQuantityReached) {
         toast.error("No more quantity available");
      } else if (cartItem?.count > 0) {
         addMoreItemFlow
            ? dispatch(
                 addMoreItemOnCartActions.updateAddMoreItemCart({
                    item: item,
                    type: "add",
                 }),
              )
            : dispatch(cartActions.addFoodItem(item));
      } else {
         addMoreItemFlow
            ? dispatch(
                 addMoreItemOnCartActions.updateAddMoreItemCart({
                    item: item,
                    type: "add",
                 }),
              )
            : dispatch(cartActions.addFoodItem(item));
         const data = {
            item_id: item_id,
            merchant_id: merchant_id,
            cookie_id: cookies["cookie_id"],
         };
         getPeopleAlsoOrdered(data);
      }
      if ((item?.add_ons?.length > 0 || item?.customizations?.length > 0) && !isItemAlreadyAdded()) {
         setShowAddons(true);
      }
   };

   const showDetailsButton = () => {
      if (item?.description !== "") return true;
      if (item?.ingredients !== undefined && item?.ingredients !== "") return true;
      return false;
   };

   const Price = () => {
      return (
         <div className={classNames(cardType === "details" ? "justify-end" : "justify-end", "food-item-before-order-price-wrapper font-custom items-center w-[100%] ")}>
            {!outOfStock ? (
               <>
                  <div className="food-item-before-order-price-content1 font-custom">{`${currency}${price_after_discount}`}</div>

                  {discount === 0 ? "" : <div className="food-item-before-order-price-content2 font-custom">{`${currency}${price}`}</div>}
               </>
            ) : (
               <div className="food-item-before-order-out-of-stock-text font-custom">Out of stock</div>
            )}
         </div>
      );
   };

   const handleRemoveFromCart = (count) => {
      if (count === 1) {
         toggleShowButton();
      }
      addMoreItemFlow
         ? dispatch(
              addMoreItemOnCartActions.updateAddMoreItemCart({
                 item,
                 type: "remove",
              }),
           )
         : dispatch(cartActions.removeFoodItem(item));
   };

   const style1 = {
      opacity: outOfStock ? 0.5 : 1,
   };

   console.log(item?.description, item?.ingredients, "item to check");

   return (
      <div
         style={style1}
         className="w-[100%] px-3">
         <AddonsModal
            toggleId={toggledId}
            mid={mid}
            visible={showAddons}
            onClose={() => setShowAddons(false)}
            item={item}
            updateToggle={updateToggle}
            clearOnClose={true}
         />
         <DetailsModal
            item={item}
            visible={showItemDetails}
            onClose={() => setshowItemDetails(false)}
         />
         <div className={classNames(index !== 0 ? "border-t-[1px]" : "", "food-item-before-order-container pt-1")}>
            <div className="food-item-before-order-left-section !relative">
               {discount > 0 && (
                  <div className="food-item-before-order-btns-discount-container">
                     <DiscountRect />
                     <div
                        className="font-custom"
                        style={{
                           position: "absolute",
                           top: 3,
                           color: "white",
                           fontSize: "0.9rem",
                           left: 2,
                        }}>
                        {discount}% off
                     </div>
                  </div>
               )}
               {imageSrc && imageSrc !== "null" ? (
                  <img
                     className="food-item-before-order-image-wrapper h-[5.3rem]"
                     src={imageSrc}
                  />
               ) : (
                  <div className="food-item-before-order-dish-name-container font-custom">{dishName[0]}</div>
               )}
            </div>
            <div className="food-item-before-order-right-section">
               <div className="food-item-before-order-right-middle-section mt-3">
                  <NonVegTag veg={type === "veg"} />
               </div>
               <div className="flex justify-between ">
                  <div className="w-[100%] gap-2 flex flex-col mt-2">
                     <div className="food-item-before-order-title-wrapper">
                        <div className="font-custom food-item-before-order-dish-name-content   mb-1">{capitalizeFirstLetter(dishName)}</div>
                     </div>

                     {cardType === "details" && (
                        <div
                           style={{
                              display: showDetailsButton() ? "flex" : "none",
                              width: "60px",
                              alignItems: "center",
                           }}
                           onClick={() => setshowItemDetails(true)}
                           className=" text-[0.8rem] rounded-[0.8rem] bottom-[-1.9rem] text-center ">
                           <span>Details</span>
                           <ChevronRightIcon style={{ fontSize: "0.8rem" }} />
                        </div>
                     )}
                  </div>
                  <div className={`flex flex-col gap-1.5 mt-[7px] {relative}`}>
                     <span className="mr-2 mb-1">
                        <Price />
                     </span>
                     {!showMenuOnly && (
                        <div className={classNames(cardType === "details" ? "h-[50px] flex justify-end items-end" : "", "mt-1 ")}>
                           {addMoreItemFlow ? (
                              addedMoreItem?.count ? (
                                 <div className="food-item-before-order-count-container">
                                    <Remove
                                       fontSize="small"
                                       onClick={() => {
                                          canReduceItem && handleRemoveFromCart(addedMoreItem?.count);
                                       }}
                                    />
                                    <div> {addedMoreItem.count} </div>
                                    <Add
                                       fontSize="small"
                                       onClick={handleAddToCart}
                                    />
                                 </div>
                              ) : (
                                 <div
                                    className="font-custom food-item-before-order-add-button"
                                    onClick={() => {
                                       handleAddToCart();
                                       toggleShowButton();
                                    }}>
                                    Add
                                 </div>
                              )
                           ) : cartItem && showMenuWithOrder && acceptOrders && cartItem.count ? (
                              <div className="food-item-before-order-count-container">
                                 <Remove
                                    sx={{ color: "#0f6656" }}
                                    fontSize="small"
                                    onClick={() => {
                                       canReduceItem && handleRemoveFromCart(cartItem?.count);
                                    }}
                                 />
                                 {cartItem.count}
                                 <Add
                                    sx={{ color: "#0f6656" }}
                                    fontSize="small"
                                    onClick={handleAddToCart}
                                 />
                              </div>
                           ) : acceptOrders && !outOfStock && showMenuWithOrder ? (
                              <div
                                 className="food-item-before-order-add-button"
                                 onClick={handleAddToCart}>
                                 Add
                              </div>
                           ) : null}
                        </div>
                     )}
                  </div>
               </div>

               <div>
                  <div className="food-item-before-order-info-container ">
                     {orderedInfo && (
                        <div className="food-item-people-ordered-info">
                           <AnalyticsIcon /> {orderedInfo} people ordered in last 24 hrs
                        </div>
                     )}
                     {show_available_item_count && manage_inventory && <div className="available-items">Available items : {remaining_quantity}</div>}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default FoodItemBeforeOrder;
