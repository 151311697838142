import React from "react";

function AboutIcon() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M26.7711 26.8483C26.3664 27.249 22.8942 26.8921 22.4005 27.1765C21.9055 27.4582 20.4614 30.6363 19.9117 30.7827C19.3605 30.929 16.5339 28.8818 15.9636 28.8804C15.3934 28.8804 12.5544 30.9085 12.0046 30.7594C11.4549 30.6117 10.0286 27.424 9.53627 27.1382C9.04396 26.851 5.57046 27.1902 5.16704 26.7868C4.76636 26.3833 5.12465 22.9098 4.84157 22.4162C4.55713 21.9225 1.37901 20.4784 1.23405 19.9273C1.08772 19.3762 3.1349 16.5495 3.13627 15.9792C3.13764 15.409 1.10687 12.57 1.25593 12.0203C1.40636 11.4705 4.59268 10.0456 4.87849 9.55189C5.16567 9.05959 4.82653 5.58608 5.22995 5.18403C5.63473 4.78335 9.1055 5.14027 9.60054 4.85719C10.0942 4.57412 11.5383 1.396 12.0894 1.24967C12.6392 1.10335 15.4672 3.15053 16.0375 3.15189C16.6064 3.15326 19.4467 1.12386 19.9964 1.27292C20.5462 1.42061 21.9711 4.6083 22.4648 4.89412C22.9571 5.1813 26.4306 4.84215 26.8327 5.24557C27.2347 5.65036 26.8764 9.12249 27.1595 9.61617C27.4426 10.1112 30.6207 11.5539 30.767 12.1051C30.912 12.6562 28.8662 15.4828 28.8648 16.0531C28.8621 16.6233 30.8929 19.4623 30.7438 20.0121C30.5947 20.5618 27.4084 21.9881 27.1226 22.4804C26.8354 22.9727 27.1746 26.4463 26.7711 26.8483Z"
				fill="white"
			/>
			<path
				d="M25.959 26.0157C25.5856 26.3849 22.3761 26.0554 21.9207 26.3165C21.4639 26.5777 20.1292 29.5165 19.6205 29.6506C19.1118 29.7846 16.4985 27.8933 15.9733 27.8919C15.4468 27.8919 12.8226 29.7668 12.3139 29.6287C11.8065 29.4919 10.4896 26.5477 10.0342 26.2824C9.57881 26.0184 6.36924 26.3316 5.99728 25.9583C5.62531 25.5849 5.95625 22.3754 5.69642 21.92C5.43386 21.4632 2.49642 20.1285 2.36241 19.6198C2.22702 19.1111 4.1183 16.4977 4.11967 15.9713C4.12104 15.4448 2.24617 12.8219 2.38292 12.3131C2.51967 11.8044 5.46531 10.4875 5.72924 10.0321C5.99454 9.57809 5.68138 6.36715 6.05471 5.99519C6.42804 5.62596 9.63762 5.95553 10.093 5.69433C10.5498 5.43314 11.8845 2.49433 12.3932 2.36032C12.9019 2.2263 15.5152 4.11758 16.0417 4.11895C16.5668 4.11895 19.1911 2.24544 19.6998 2.3822C20.2072 2.51895 21.5255 5.46459 21.9795 5.72852C22.4349 5.99245 25.6444 5.68066 26.0164 6.05262C26.3884 6.42732 26.0574 9.63553 26.3173 10.0923C26.5798 10.549 29.5173 11.8837 29.6513 12.3911C29.7867 12.8998 27.8954 15.5131 27.894 16.0396C27.8927 16.5661 29.7675 19.1904 29.6308 19.6977C29.494 20.2065 26.5484 21.5234 26.2844 21.9788C26.0191 22.4342 26.3323 25.6437 25.959 26.0157Z"
				fill="#13B53E"
			/>
			<path
				d="M24.4657 24.519C24.1498 24.8335 21.4298 24.5518 21.0414 24.7733C20.6544 24.9949 19.5235 27.4865 19.0927 27.6C18.6606 27.7149 16.4452 26.1108 15.9994 26.1094C15.5522 26.1094 13.3286 27.6985 12.8979 27.5822C12.4657 27.4646 11.3498 24.9703 10.9642 24.7446C10.5785 24.5204 7.8558 24.7857 7.54127 24.4698C7.22674 24.1525 7.50708 21.4339 7.28554 21.0455C7.06401 20.6585 4.57375 19.5275 4.45888 19.0954C4.34537 18.6646 5.94947 16.4492 5.94947 16.0021C5.95084 15.5563 4.36042 13.3313 4.47665 12.9005C4.59426 12.4698 7.08999 11.3539 7.31426 10.9669C7.53854 10.5812 7.27324 7.85984 7.5905 7.54531C7.9064 7.22941 10.6264 7.51112 11.0134 7.28958C11.4018 7.06805 12.5314 4.57779 12.9635 4.46292C13.3956 4.34805 15.611 5.95215 16.0568 5.95352C16.5026 5.95352 18.7276 4.36446 19.1584 4.4807C19.5891 4.5983 20.7064 7.09266 21.092 7.3183C21.4777 7.54258 24.2004 7.27728 24.5149 7.59317C24.8295 7.91044 24.5491 10.6304 24.7707 11.0174C24.9922 11.4045 27.4825 12.5354 27.5973 12.9675C27.7108 13.3983 26.1067 15.6137 26.1067 16.0609C26.1054 16.5067 27.6958 18.7316 27.5796 19.1624C27.462 19.5932 24.9662 20.7104 24.742 21.0961C24.5177 21.4817 24.783 24.2045 24.4657 24.519Z"
				fill="#18A12B"
			/>
			<path
				d="M7.30038 11.775L24.1551 12.1128C25.9014 12.1484 27.2553 12.069 27.3113 12.1771L28.3889 14.2502L26.8382 17.2424L28.4464 20.3466C28.5024 20.4533 25.8823 20.2919 24.1359 20.2619L7.28261 19.9213C5.53491 19.8858 4.125 19.9801 4.125 19.8571L4.15235 11.7121C4.15235 11.5918 9.04808 11.8119 7.30175 11.7764L7.30038 11.775Z"
				fill="#13B53E"
			/>
			<path
				d="M13.3164 14.5379C13.3164 14.1865 13.4442 13.8876 13.6998 13.6411C13.9508 13.3947 14.2588 13.2715 14.6239 13.2715C14.9799 13.2715 15.2856 13.3947 15.5412 13.6411C15.7967 13.8876 15.9245 14.1865 15.9245 14.5379C15.9245 14.8802 15.7967 15.1745 15.5412 15.421C15.2856 15.6628 14.9799 15.7838 14.6239 15.7838C14.2634 15.7838 13.9553 15.6628 13.6998 15.421C13.4442 15.1745 13.3164 14.8802 13.3164 14.5379ZM14.8019 18.4193H13.864L17.5469 13.3605H18.4916L14.8019 18.4193ZM14.6239 15.1129C14.7791 15.1129 14.9137 15.0559 15.0278 14.9418C15.1464 14.8277 15.2058 14.6908 15.2058 14.531C15.2058 14.3713 15.1464 14.2344 15.0278 14.1203C14.9137 14.0062 14.7791 13.9492 14.6239 13.9492C14.4596 13.9492 14.3204 14.0062 14.2063 14.1203C14.0922 14.2298 14.0352 14.3668 14.0352 14.531C14.0352 14.6953 14.0922 14.8345 14.2063 14.9486C14.3204 15.0582 14.4596 15.1129 14.6239 15.1129ZM16.4653 17.2555C16.4653 16.9087 16.5908 16.6121 16.8418 16.3656C17.0974 16.1192 17.4054 15.996 17.766 15.996C18.1265 15.996 18.4345 16.1192 18.6901 16.3656C18.9457 16.6121 19.0734 16.9087 19.0734 17.2555C19.0734 17.5978 18.9457 17.8922 18.6901 18.1386C18.4345 18.3805 18.1265 18.5014 17.766 18.5014C17.4054 18.5014 17.0974 18.3805 16.8418 18.1386C16.5908 17.8922 16.4653 17.5978 16.4653 17.2555ZM18.1835 17.6663C18.2976 17.5522 18.3547 17.413 18.3547 17.2487C18.3547 17.0844 18.2976 16.9475 18.1835 16.838C18.0694 16.7239 17.9302 16.6668 17.766 16.6668C17.6062 16.6668 17.4693 16.7239 17.3552 16.838C17.2411 16.9475 17.1841 17.0844 17.1841 17.2487C17.1841 17.413 17.2389 17.5522 17.3484 17.6663C17.4625 17.7758 17.6017 17.8306 17.766 17.8306C17.9302 17.8306 18.0694 17.7758 18.1835 17.6663Z"
				fill="white"
			/>
		</svg>
	);
}

export default AboutIcon;
