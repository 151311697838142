/** @format */

import React from "react";
import "./index.scss";
import DicountIcon from "../../asset/icons/DiscountIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function ApplyCoupon({ appliedOfferDetails, appliedCoupon, previewDesc, onClick }) {
	console.log(appliedOfferDetails);
	return (
		<div
			className="apply-coupon-container"
			onClick={onClick}>
			<div className="apply-coupon-wrapper">
				<DicountIcon />
				{appliedCoupon ? (
					<div className="ms-2 ">
						<div className="fw-bold font-custom">"{appliedOfferDetails?.offer_name?.toUpperCase()}" applied</div>
						<div
							className="mt-1 fw-bold font-custom"
							style={{ fontSize: "0.8rem", color: "#2FBF71" }}>
							{previewDesc}
						</div>
					</div>
				) : (
					<div className="apply-coupon-text font-semibold font-custom">Apply coupons</div>
				)}
			</div>
			{appliedCoupon ? (
				<div
					onClick={() => {}}
					className="font-semibold text-[#D90429] text-[16px] font-custom">
					Remove
				</div>
			) : (
				<ArrowForwardIosIcon sx={{ color: "#1C1B1F" }} />
			)}
		</div>
	);
}
