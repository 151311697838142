import React from 'react';
import InfoIcon from '../../asset/icons/InfoIcon';

type Props = {
  isClicked?: boolean;
  setClick?: any;
};

function InfoSection({ isClicked, setClick }: Props) {
  return (
    <>
      <div
        className='surprise-me-info-container'
        onClick={() => {
          setClick((p: boolean) => !p);
        }}
      >
        <div className='surprise-me-info'>
          Surprise me allows you to choose items in random
          <span className='surpise-me-info-btn'>
            <InfoIcon />
          </span>
        </div>
      </div>

      {isClicked && (
        <div className='suprise-me-info-expanded-wrapper'>
          <div className='surprise-me-content'>
            <div className='surprise-me-content-title'>Completely random</div>
            <div className='surprise-me-content-description'>
              This option gives you a completely random items
            </div>
          </div>
          <div className='surprise-me-content'>
            <div className='surprise-me-content-title'>Not so random</div>
            <div className='surprise-me-content-description'>
              This option gives you a partial random items from choosing your favorited category
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InfoSection;
