import { request } from '../axios';
import { SuccessResponse, ErrorResponse, ResponsePayload } from '../index.type';
import { PayloadProps, Props } from './contact.type';
import { ErrorResponseHandler } from '../index';

const contactApi = async ({
  name,
  email,
  pincode,
  phone,
  state,
  country,
  query,
}: Props): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  try {
    const response = await request<ResponsePayload<PayloadProps>>({
      api: `/enquiry/`,
      method: 'POST',
      data: {
        name,
        email,
        pincode,
        phone,
        state,
        country,
        query,
      },
    });

    const result = response.data;

    if (response.status > 300) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: 'Success',
      // @ts-ignore
      payload: result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default contactApi;
