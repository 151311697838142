import React from "react";
import MostOrderedCard from "./MostOrderedCard";
import "./style.scss";
import notFound from "../../asset/svg/not_found.svg";
import { Add } from "@mui/icons-material";
import CancelIcon from "../../asset/icons/CancelIcon";

type Props = {
	data: Array<any>;
	settoggledId: any;
};

function MostOrdered({ data, settoggledId }: Props) {
	return (
		<div className="MostOrdered-MainContainer pl-3">
			<div className="MostOrdered-Header">
				<div className="font-custom">Popular pairings with your choice</div>
				<div onClick={() => settoggledId("")}>
					<CancelIcon color="#000" />
				</div>
			</div>
			{data?.length > 0 ? (
				<div className="MostOrdered-CardContainer">
					{data.map((item: any) => (
						<>
							<MostOrderedCard item={item} />
						</>
					))}
				</div>
			) : (
				<div className="MostOrdered-NotFoundContainer">
					<img
						src={notFound}
						alt="not found"
					/>
					<div className="MostOrdered-NotFound font-custom">Sorry, no recommendations found at this moment</div>
				</div>
			)}
		</div>
	);
}

export default MostOrdered;
