/** @format */

export const loyaltySubscriptionAPI = async (accessToken, data, requestMethod) => {
   if (requestMethod === "GET") {
      try {
         const response = await fetch(`${process.env.REACT_APP_DEV_URL}/customer/loyalty/subscribe/${data?.merchant_id}`, {
            method: requestMethod,
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${accessToken}`,
               "ngrok-skip-browser-warning": "any",
            },
         });
         return response;
      } catch (error) {
         console.log(error);
      }
   } else {
      try {
         const response = await fetch(`${process.env.REACT_APP_DEV_URL}/customer/loyalty/subscribe`, {
            method: requestMethod,
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
         });
         return response;
      } catch (error) {
         console.log(error);
      }
   }
};

export const getLoyaltyPointsAPI = async (accessToken, mid) => {
   console.log(accessToken, mid, "getLoyaltyPointsAPI");
   try {
      const response = await fetch(`${process.env.REACT_APP_DEV_URL}/customer/loyalty/points/${mid}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      return response;
   } catch (error) {
      console.log(error);
   }
};

 



 