import { createSlice } from '@reduxjs/toolkit';

export interface surpriseMeState {
  filterConf: {
    random: string;
    categories: Array<any>;
    item_preference: string;
    amount: number;
    number_of_items: number;
  };
  notSoRandomItems: Array<any>;
  completelyRandomItems: Array<any>;
}
const initialState: surpriseMeState = {
  filterConf: {
    random: 'Not so random',
    categories: [],
    item_preference: '',
    amount: 0,
    number_of_items: 0,
  },
  notSoRandomItems: [],
  completelyRandomItems: [],
};

export const surpriseMeSlice = createSlice({
  name: 'surpriseme',
  initialState,
  reducers: {
    setFilterConf: (state: any, action: any) => {
      console.log('PAYLOAD', action.payload);

      state.filterConf = action.payload;
    },
    resetFilter: (state: any) => {
      state.filterConf = {};
      state.notSoRandomItems = [];
      state.completelyRandomItems = [];
    },
    setNotSoRandomItems: (state, { payload }) => {
      console.log('PAYLOAD', payload);
      if (payload?.items?.length > 0) {
        state.notSoRandomItems = payload?.items;
        console.log('Items', state.notSoRandomItems);
      } else {
        console.log('Error', payload);
      }
    },
    setCompletelyRandomItems: (state, { payload }) => {
      console.log('PAYLOAD', payload);

      state.completelyRandomItems = payload?.items;
      console.log('Items', state?.completelyRandomItems);
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(notSoRandom.fulfilled, (state, { payload }) => {});
  //   builder.addCase(completelyRandom.fulfilled, (state, { payload }) => {});
  // },
});

export const surpriseMeActions = surpriseMeSlice.actions;
