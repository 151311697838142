import React from "react";

function AboutIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0276 15.0586L17.0859 10.0003L12.0276 4.94193" stroke="#0F191A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.91641 10L16.9414 10" stroke="#0F191A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
}

export default AboutIcon;
