import React from "react";

const MailBoxIcon = () => (
  <svg
    width="373"
    height="249"
    viewBox="0 0 373 249"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_714_11156)">
      <path
        d="M278.88 138.462C280.15 161.88 234.366 209.498 177.934 191.873C151.688 183.675 153.911 170.068 133.954 152.135C122.058 141.448 104.966 133.366 100.212 117.83C96.106 104.42 98.6241 90.0065 105.744 80.6314C121.756 59.5478 154.081 45.1102 185.931 78.3296C217.782 111.549 275.595 78.0647 278.88 138.462V138.462Z"
        fill="url(#paint0_linear_714_11156)"
      />
      <path
        d="M259.012 78.4744C254.817 85.9621 245.628 86.9908 230.907 85.568C219.836 84.4958 209.74 83.6661 198.668 77.6297C190.918 73.4073 184.784 67.6951 180.306 62.1465C175.455 56.1362 168.683 49.2763 172.003 42.4616C176.565 33.0997 202.948 25.2153 228.567 38.1091C256.709 52.2769 263.116 71.1561 259.012 78.4744V78.4744Z"
        fill="url(#paint1_linear_714_11156)"
      />
      <path
        d="M133.774 198.457C123.483 184.06 112.944 168.759 99.1464 161.223C84.9148 153.445 82.2902 158.239 81.305 165.859C80.3199 173.479 84.7109 191.535 104.133 201.808C123.561 212.084 143.403 211.93 133.774 198.457V198.457Z"
        fill="url(#paint2_linear_714_11156)"
      />
      <path
        d="M289.661 98.2696C282.378 99.9949 274.676 93.8184 274.676 93.8184C274.676 93.8184 278.786 84.8486 286.071 83.1287C293.354 81.4033 301.054 87.574 301.054 87.574C301.054 87.574 296.943 96.5442 289.661 98.2696V98.2696Z"
        fill="url(#paint3_linear_714_11156)"
      />
      <path
        d="M293.386 169.196C299.988 173.744 310.075 170.565 310.075 170.565C310.075 170.565 309.45 160.014 302.843 155.47C296.241 150.923 286.158 154.097 286.158 154.097C286.158 154.097 286.783 164.649 293.386 169.196V169.196Z"
        fill="url(#paint4_linear_714_11156)"
      />
      <path
        d="M70.6666 121.66C77.8245 128.945 91.2094 127.409 91.2094 127.409C91.2094 127.409 92.9807 114.061 85.8162 106.781C78.6584 99.495 65.2801 101.027 65.2801 101.027C65.2801 101.027 63.5088 114.375 70.6666 121.66Z"
        fill="url(#paint5_linear_714_11156)"
      />
      <path
        d="M288.069 125.688C286.047 125.688 284.408 127.327 284.408 129.349C284.408 131.371 286.047 133.01 288.069 133.01C290.091 133.01 291.73 131.371 291.73 129.349C291.73 127.327 290.091 125.688 288.069 125.688Z"
        fill="#4361EE"
      />
      <path
        d="M117.382 172.82C115.107 172.82 113.263 174.664 113.263 176.938C113.263 179.213 115.107 181.057 117.382 181.057C119.656 181.057 121.5 179.213 121.5 176.938C121.5 174.664 119.656 172.82 117.382 172.82Z"
        fill="#4361EE"
      />
      <path
        d="M93.8146 75.3504C91.9191 75.3504 90.3826 73.8138 90.3826 71.9183C90.3826 70.0229 91.9191 68.4863 93.8146 68.4863C95.71 68.4863 97.2466 70.0229 97.2466 71.9183C97.2466 73.8138 95.71 75.3504 93.8146 75.3504Z"
        fill="#4361EE"
      />
      <path
        d="M227.894 91.3697C227.009 91.3697 226.292 90.6525 226.292 89.7678C226.292 88.8832 227.009 88.166 227.894 88.166C228.778 88.166 229.496 88.8832 229.496 89.7678C229.496 90.6525 228.778 91.3697 227.894 91.3697Z"
        fill="#4361EE"
      />
      <path
        d="M276.858 177.396C275.72 177.396 274.798 176.474 274.798 175.337C274.798 174.199 275.72 173.277 276.858 173.277C277.995 173.277 278.917 174.199 278.917 175.337C278.917 176.474 277.995 177.396 276.858 177.396Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M85.1203 139.418C83.4775 139.418 82.1458 138.086 82.1458 136.443C82.1458 134.8 83.4775 133.469 85.1203 133.469C86.763 133.469 88.0947 134.8 88.0947 136.443C88.0947 138.086 86.763 139.418 85.1203 139.418Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M153.966 196.568C152.716 196.568 151.703 195.555 151.703 194.305C151.703 193.055 152.716 192.041 153.966 192.041C155.216 192.041 156.23 193.055 156.23 194.305C156.23 195.555 155.216 196.568 153.966 196.568Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M249.401 55.6725C248.011 55.6725 246.884 54.5457 246.884 53.1556C246.884 51.7655 248.011 50.6387 249.401 50.6387C250.791 50.6387 251.918 51.7655 251.918 53.1556C251.918 54.5457 250.791 55.6725 249.401 55.6725Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M111.093 113.369C111.416 114.574 110.701 115.813 109.495 116.136C108.29 116.46 107.051 115.744 106.728 114.538C106.404 113.333 107.12 112.094 108.326 111.771C109.531 111.448 110.77 112.163 111.093 113.369Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M148.72 61.6874C147.059 61.6874 145.713 60.3412 145.713 58.6806C145.713 57.02 147.059 55.6738 148.72 55.6738C150.38 55.6738 151.727 57.02 151.727 58.6806C151.727 60.3412 150.38 61.6874 148.72 61.6874Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M253.291 123.561C252.013 123.561 250.977 122.733 250.977 121.711C250.977 120.69 252.013 119.861 253.291 119.861C254.568 119.861 255.604 120.69 255.604 121.711C255.604 122.733 254.568 123.561 253.291 123.561Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M205.38 52.4953C206.052 55.0044 204.563 57.5835 202.054 58.2559C199.544 58.9282 196.965 57.4392 196.293 54.93C195.621 52.4208 197.11 49.8417 199.619 49.1694C202.128 48.497 204.707 49.9861 205.38 52.4953Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M294.032 107.384H294.092C294.45 112.46 298.226 112.539 298.226 112.539C298.226 112.539 294.063 112.62 294.063 118.485C294.063 112.62 289.9 112.539 289.9 112.539C289.9 112.539 293.673 112.46 294.032 107.384V107.384ZM132.874 32.9863H132.978C133.595 41.8699 140.098 42.0067 140.098 42.0067C140.098 42.0067 132.928 42.1489 132.928 52.4138C132.928 42.1489 125.758 42.0067 125.758 42.0067C125.758 42.0067 132.257 41.8699 132.874 32.9863V32.9863Z"
        fill="#0F191A"
        fillOpacity="0.04"
      />
      <path
        d="M187.625 229.618C256.123 229.618 311.652 226.309 311.652 222.226C311.652 218.143 256.123 214.834 187.625 214.834C119.126 214.834 63.5977 218.143 63.5977 222.226C63.5977 226.309 119.126 229.618 187.625 229.618Z"
        fill="url(#paint6_linear_714_11156)"
      />
      <path
        d="M183.003 216.96H190.764V147.115H183.003V216.96V216.96Z"
        fill="#4361EE"
        stroke="#0F191A"
        strokeWidth="1.7245"
      />
      <path
        d="M167.718 212.828V218.777H206.157V212.828H167.718ZM257.21 98.4875L262.798 118.022C262.889 118.339 262.917 118.672 262.88 119C262.842 119.328 262.741 119.646 262.581 119.934C262.421 120.223 262.205 120.478 261.947 120.683C261.688 120.889 261.392 121.041 261.074 121.132L227.149 130.837C226.65 130.98 226.118 130.964 225.628 130.792C225.138 130.621 224.713 130.301 224.411 129.878C224.245 129.645 224.119 129.386 224.041 129.11L218.455 109.584C218.364 109.267 218.336 108.935 218.373 108.607C218.41 108.279 218.512 107.962 218.672 107.674C218.831 107.385 219.047 107.131 219.305 106.925C219.563 106.72 219.859 106.567 220.176 106.476L254.102 96.7688C254.742 96.5858 255.428 96.6639 256.011 96.9861C256.593 97.3082 257.024 97.8481 257.21 98.4875Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M262.889 118.881C262.854 119.397 262.661 119.889 262.336 120.292C262.011 120.694 261.57 120.986 261.073 121.129L227.15 130.836C226.65 130.979 226.119 130.963 225.629 130.792C225.139 130.62 224.714 130.301 224.412 129.877L241.69 114.135L262.889 118.881Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M257.21 98.4856L242.022 117.344L218.742 110.582L218.456 109.582C218.364 109.265 218.337 108.933 218.374 108.605C218.411 108.278 218.512 107.96 218.672 107.672C218.832 107.383 219.047 107.129 219.306 106.923C219.564 106.718 219.86 106.565 220.177 106.474L254.102 96.767C254.742 96.5839 255.429 96.6621 256.011 96.9842C256.594 97.3064 257.025 97.8463 257.21 98.4856V98.4856ZM142.986 91.6415L150.067 116.392C150.182 116.794 150.217 117.215 150.17 117.631C150.123 118.047 149.994 118.449 149.791 118.815C149.588 119.181 149.315 119.504 148.988 119.764C148.66 120.025 148.285 120.218 147.882 120.333L104.897 132.63C104.265 132.81 103.591 132.79 102.97 132.573C102.349 132.355 101.811 131.95 101.429 131.414C101.218 131.119 101.059 130.79 100.96 130.442L93.8819 105.702C93.7663 105.3 93.731 104.879 93.778 104.464C93.8251 104.048 93.9536 103.646 94.1562 103.28C94.3588 102.915 94.6314 102.592 94.9587 102.332C95.2859 102.072 95.6612 101.879 96.0631 101.763L139.048 89.4636C140.736 88.9808 142.492 89.9531 142.986 91.6415V91.6415Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M150.18 117.482C150.136 118.136 149.891 118.76 149.48 119.27C149.068 119.78 148.509 120.15 147.879 120.331L104.898 132.631C104.265 132.811 103.591 132.791 102.97 132.574C102.349 132.356 101.81 131.951 101.429 131.415L123.32 111.469L150.18 117.482V117.482Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M142.986 91.6415L123.741 115.536L94.2445 106.968L93.8819 105.702C93.7663 105.3 93.731 104.879 93.778 104.464C93.8251 104.048 93.9536 103.646 94.1562 103.28C94.3588 102.915 94.6314 102.592 94.9587 102.332C95.2859 102.072 95.6612 101.879 96.0631 101.763L139.048 89.4636C140.736 88.9808 142.492 89.9531 142.986 91.6415V91.6415ZM268.913 142.554L273.456 158.433C273.53 158.691 273.552 158.962 273.522 159.228C273.492 159.495 273.409 159.753 273.279 159.988C273.149 160.223 272.973 160.43 272.763 160.597C272.553 160.764 272.312 160.888 272.054 160.962L244.475 168.852C243.954 169 243.395 168.936 242.921 168.673C242.447 168.409 242.098 167.969 241.949 167.448L237.407 151.574C237.333 151.317 237.311 151.047 237.341 150.78C237.371 150.514 237.453 150.256 237.583 150.021C237.713 149.786 237.888 149.58 238.098 149.413C238.308 149.246 238.549 149.122 238.807 149.048L266.386 141.156C266.906 141.007 267.464 141.071 267.938 141.333C268.411 141.595 268.762 142.034 268.913 142.554Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M273.528 159.135C273.5 159.555 273.343 159.955 273.079 160.282C272.815 160.61 272.456 160.847 272.052 160.963L244.475 168.855C244.069 168.971 243.637 168.958 243.238 168.818C242.84 168.679 242.494 168.419 242.25 168.075L256.295 155.277L273.528 159.135Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M268.913 142.556L256.566 157.887L237.64 152.39L237.407 151.577C237.333 151.319 237.311 151.049 237.341 150.783C237.371 150.516 237.454 150.258 237.584 150.024C237.714 149.789 237.888 149.582 238.098 149.415C238.308 149.248 238.549 149.124 238.807 149.051L266.386 141.159C266.906 141.01 267.464 141.074 267.938 141.336C268.411 141.598 268.762 142.036 268.913 142.556V142.556ZM108.651 146.833L113.088 162.341C113.16 162.593 113.182 162.857 113.152 163.118C113.123 163.378 113.042 163.631 112.915 163.86C112.788 164.089 112.617 164.291 112.412 164.454C112.206 164.618 111.971 164.739 111.719 164.811L84.7854 172.516C84.3888 172.629 83.9669 172.616 83.5777 172.48C83.1885 172.344 82.8511 172.09 82.612 171.754C82.4797 171.569 82.3801 171.363 82.3178 171.145L77.8828 155.643C77.8104 155.391 77.7884 155.127 77.8179 154.867C77.8474 154.607 77.928 154.355 78.0549 154.126C78.1818 153.897 78.3527 153.695 78.5577 153.532C78.7627 153.369 78.9978 153.248 79.2496 153.176L106.183 145.469C106.691 145.323 107.236 145.385 107.699 145.641C108.161 145.897 108.504 146.325 108.651 146.833V146.833Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M113.158 163.023C113.131 163.433 112.978 163.824 112.72 164.144C112.462 164.463 112.111 164.695 111.717 164.809L84.7852 172.516C84.3886 172.629 83.9667 172.616 83.5776 172.48C83.1884 172.343 82.851 172.09 82.6118 171.754L96.3283 159.256L113.158 163.023V163.023Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M108.651 146.831L96.5925 161.803L78.1103 156.435L77.8828 155.641C77.8104 155.389 77.7884 155.126 77.8179 154.865C77.8474 154.605 77.928 154.353 78.0549 154.124C78.1818 153.895 78.3527 153.693 78.5577 153.53C78.7627 153.367 78.9978 153.246 79.2496 153.174L106.183 145.467C106.691 145.321 107.236 145.383 107.699 145.639C108.161 145.895 108.504 146.323 108.651 146.831V146.831ZM143.76 138.535L146.55 148.285C146.596 148.443 146.609 148.609 146.591 148.773C146.572 148.937 146.521 149.095 146.441 149.24C146.361 149.384 146.254 149.511 146.125 149.613C145.996 149.716 145.848 149.792 145.689 149.837L128.757 154.681C128.437 154.773 128.093 154.733 127.803 154.572C127.512 154.41 127.297 154.139 127.205 153.819L124.417 144.074C124.372 143.915 124.358 143.75 124.376 143.586C124.395 143.422 124.446 143.264 124.525 143.12C124.605 142.976 124.713 142.849 124.841 142.746C124.97 142.644 125.118 142.568 125.277 142.522L142.209 137.677C142.529 137.586 142.871 137.625 143.162 137.786C143.453 137.947 143.668 138.216 143.76 138.535V138.535Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M146.594 148.715C146.577 148.972 146.481 149.218 146.318 149.419C146.156 149.62 145.936 149.766 145.688 149.837L128.757 154.682C128.507 154.753 128.242 154.745 127.997 154.66C127.753 154.574 127.541 154.414 127.39 154.203L136.013 146.346L146.594 148.715Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M143.76 138.537L136.179 147.95L124.56 144.575L124.417 144.076C124.372 143.918 124.358 143.752 124.376 143.588C124.395 143.425 124.445 143.266 124.525 143.122C124.605 142.978 124.712 142.851 124.841 142.749C124.97 142.646 125.118 142.57 125.276 142.525L142.209 137.679C142.528 137.588 142.871 137.627 143.162 137.788C143.452 137.949 143.667 138.218 143.76 138.537V138.537ZM271.36 61.3089L275.797 76.8169C275.943 77.326 275.88 77.8721 275.624 78.3352C275.367 78.7983 274.937 79.1405 274.428 79.2866L247.494 86.9916C246.985 87.1366 246.439 87.0737 245.977 86.8166C245.514 86.5594 245.172 86.1291 245.027 85.6202L240.592 70.1183C240.519 69.8665 240.497 69.603 240.527 69.3427C240.556 69.0824 240.637 68.8305 240.764 68.6014C240.891 68.3723 241.062 68.1704 241.267 68.0074C241.472 67.8443 241.707 67.7232 241.959 67.6511L268.892 59.9441C269.4 59.7989 269.945 59.8609 270.408 60.1167C270.87 60.3725 271.213 60.8012 271.36 61.3089V61.3089Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M275.868 77.4982C275.84 77.9079 275.687 78.2992 275.429 78.6186C275.171 78.938 274.821 79.1701 274.426 79.2832L247.495 86.9902C247.098 87.1034 246.676 87.0909 246.287 86.9545C245.898 86.8181 245.56 86.5644 245.321 86.2285L259.038 73.7305L275.868 77.4982Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M271.36 61.3089L259.301 76.2807L240.819 70.9124L240.592 70.1183C240.519 69.8665 240.497 69.603 240.527 69.3427C240.556 69.0824 240.637 68.8305 240.764 68.6014C240.891 68.3723 241.061 68.1704 241.266 68.0074C241.471 67.8443 241.707 67.7232 241.958 67.6511L268.892 59.9441C269.4 59.7989 269.945 59.8609 270.408 60.1167C270.87 60.3725 271.212 60.8012 271.36 61.3089V61.3089ZM201.777 73.1081L206.214 88.6162C206.36 89.1252 206.298 89.6714 206.041 90.1345C205.785 90.5976 205.354 90.9398 204.845 91.0859L177.912 98.7908C177.403 98.9361 176.857 98.8733 176.394 98.6162C175.931 98.3592 175.59 97.9288 175.444 97.4198L171.009 81.9179C170.937 81.6662 170.915 81.4026 170.944 81.1423C170.974 80.882 171.054 80.6301 171.181 80.4009C171.308 80.1717 171.479 79.9698 171.684 79.8067C171.889 79.6436 172.124 79.5225 172.376 79.4503L199.31 71.7433C199.818 71.5983 200.363 71.6605 200.825 71.9163C201.288 72.172 201.63 72.6006 201.777 73.1081V73.1081Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M206.285 89.299C206.258 89.7087 206.105 90.0999 205.847 90.4194C205.589 90.7388 205.238 90.9709 204.844 91.084L177.912 98.791C177.516 98.9042 177.094 98.8918 176.704 98.7554C176.315 98.6189 175.978 98.3653 175.739 98.0292L189.455 85.5312L206.285 89.299Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M201.777 73.1041L189.719 88.076L171.237 82.7077L171.009 81.914C170.937 81.6622 170.915 81.3986 170.944 81.1383C170.974 80.878 171.054 80.6261 171.181 80.397C171.308 80.1678 171.479 79.9659 171.684 79.8028C171.889 79.6397 172.124 79.5186 172.376 79.4464L199.31 71.7394C199.818 71.5944 200.363 71.6566 200.825 71.9123C201.288 72.1681 201.63 72.5966 201.777 73.1041ZM161.681 54.9962L166.118 70.5043C166.264 71.0134 166.202 71.5595 165.945 72.0226C165.688 72.4857 165.258 72.8279 164.749 72.974L137.815 80.6789C137.306 80.8242 136.76 80.7613 136.298 80.5042C135.835 80.2471 135.494 79.8169 135.348 79.308L130.913 63.8061C130.841 63.5543 130.819 63.2907 130.848 63.0304C130.878 62.7701 130.958 62.5182 131.085 62.289C131.212 62.0599 131.383 61.858 131.588 61.6949C131.793 61.5318 132.028 61.4107 132.28 61.3385L159.213 53.6315C159.722 53.4864 160.266 53.5485 160.729 53.8043C161.191 54.0601 161.533 54.4887 161.681 54.9962V54.9962Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M166.189 71.1896C166.161 71.5993 166.008 71.9906 165.75 72.3101C165.492 72.6295 165.142 72.8616 164.747 72.9746L137.815 80.6816C137.419 80.7948 136.997 80.7824 136.608 80.646C136.219 80.5096 135.881 80.2559 135.642 79.9199L149.359 67.4219L166.189 71.1896V71.1896Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        d="M161.681 54.9962L149.623 69.968L131.141 64.5997L130.913 63.8061C130.841 63.5543 130.819 63.2907 130.848 63.0304C130.878 62.7701 130.958 62.5182 131.085 62.289C131.212 62.0599 131.383 61.858 131.588 61.6949C131.793 61.5318 132.028 61.4107 132.28 61.3385L159.213 53.6315C159.722 53.4864 160.266 53.5485 160.729 53.8043C161.191 54.0601 161.533 54.4887 161.681 54.9962V54.9962Z"
        fill="#D4E9F6"
        fillOpacity="0.5"
        stroke="#4361EE"
        strokeWidth="1.7245"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.586 130.643H204.212C209.946 130.643 214.593 135.291 214.593 141.024V157.337C214.593 158.124 214.28 158.878 213.724 159.434C213.168 159.99 212.414 160.303 211.627 160.303H164.17C163.384 160.303 162.629 159.99 162.073 159.434C161.517 158.878 161.204 158.124 161.204 157.337V141.024C161.204 135.291 165.852 130.643 171.586 130.643Z"
        fill="#4361EE"
        stroke="#0F191A"
        strokeWidth="1.7245"
      />
      <path
        d="M181.966 121.746V142.509"
        stroke="#0F191A"
        strokeWidth="1.7245"
      />
      <path
        d="M181.969 141.395C182.264 141.396 182.546 141.514 182.754 141.723C182.962 141.932 183.079 142.215 183.079 142.51C183.079 142.656 183.05 142.801 182.994 142.935C182.938 143.07 182.856 143.193 182.753 143.296C182.65 143.399 182.527 143.48 182.392 143.536C182.257 143.591 182.112 143.62 181.966 143.619C181.818 143.622 181.672 143.595 181.534 143.54C181.397 143.485 181.272 143.404 181.166 143.3C181.061 143.196 180.977 143.073 180.92 142.936C180.863 142.8 180.834 142.653 180.834 142.505C180.834 142.358 180.864 142.211 180.921 142.075C180.979 141.939 181.063 141.815 181.168 141.712C181.274 141.608 181.399 141.527 181.537 141.473C181.674 141.418 181.821 141.392 181.969 141.395"
        stroke="#0F191A"
        strokeWidth="3.50288"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.212 130.643C198.478 130.643 193.831 135.291 193.831 141.024V160.303"
        fill="#4361EE"
      />
      <path
        d="M204.212 130.643C198.478 130.643 193.831 135.291 193.831 141.024V160.303"
        stroke="#0F191A"
        strokeWidth="1.7245"
      />
      <path
        d="M161.309 123.478C160.967 124.06 161.387 124.793 162.061 124.793H182.375C182.606 124.793 182.828 124.701 182.992 124.538C183.156 124.374 183.248 124.152 183.248 123.921V113.683C183.248 112.858 182.581 112.191 181.756 112.191H162.048C161.372 112.191 160.953 112.926 161.296 113.508L163.978 118.05C164.14 118.323 164.14 118.662 163.979 118.935L161.309 123.478Z"
        fill="#4361EE"
      />
      <rect
        x="166.679"
        y="212.179"
        width="40.5435"
        height="7.20773"
        fill="#4361EE"
        stroke="#0F191A"
        strokeWidth="0.900966"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_714_11156"
        x1="224.158"
        y1="278.834"
        x2="138.516"
        y2="-107.553"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_714_11156"
        x1="230.748"
        y1="127.002"
        x2="193.352"
        y2="-41.039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_714_11156"
        x1="122.283"
        y1="245.717"
        x2="88.5205"
        y2="89.5813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_714_11156"
        x1="262.236"
        y1="102.559"
        x2="325.989"
        y2="72.4406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_714_11156"
        x1="319.105"
        y1="184.118"
        x2="267.131"
        y2="129.314"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_714_11156"
        x1="99.2252"
        y1="146.545"
        x2="47.4781"
        y2="65.426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_714_11156"
        x1="192.914"
        y1="237.908"
        x2="192.886"
        y2="198.745"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EEEEEE" />
      </linearGradient>
      <clipPath id="clip0_714_11156">
        <rect width="373" height="248.667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MailBoxIcon;
