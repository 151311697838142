import React from 'react';

type Props = {
  title: string;
  isSelect?: boolean;
  onClickHandler?: any;
};

export default function RandomSelector({ onClickHandler, title, isSelect }: Props) {
  return (
    <>
      <div
        onClick={onClickHandler}
        className={
          isSelect
            ? 'surprise-me-random-selector random-active col-6'
            : 'surprise-me-random-selector col-6'
        }
      >
        {title}
      </div>
    </>
  );
}
