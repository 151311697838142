import React from "react";
import { Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../asset/svg/OffersScreen/Close.svg";

const AcknownledgementModal = ({
  open,
  onClose,
  statementPrimary,
  buttonName,
  icon,
  orderModal,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="d-flex justify-center align-items-center"
    >
      <div
        className="d-flex flex-column relative justify-content-center align-items-center bg-white mx-3 p-3 gap-3"
        style={{ borderRadius: "1rem" }}
      >
        <CloseIcon className="absolute right-2 top-4" onClick={onClose} />
        <img src={icon} alt="Order Icon" className="mt-2" />
        <div
          className={`text-center fw-bold font-custom  ${
            orderModal ? "text-2xl mb-3" : "text-lg mb-5"
          }`}
        >
          {statementPrimary}
        </div>
        <button
          className="font-bold text-base text-[#0f6656] font-custom mb-3"
          onClick={onClose}
        >
          {buttonName}
        </button>
      </div>
    </Modal>
  );
};

export default AcknownledgementModal;
