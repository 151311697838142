import React from "react";
import nonVegIcon from "../svg/nonVegIcon.svg";

const NonVegIcon = () => {
	return (
		<img
			src={nonVegIcon}
			style={{ width: 18, height: 18 }}
		/>
	);
};

export default NonVegIcon;
