import React from "react";

type Props = { color?: string };

const SmallCrossIcon = (props: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75781 7.75781L16.2431 16.2431"
        stroke={props.color ? props.color : "#0F191A"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.75691 16.2431L16.2422 7.75781"
        stroke={props.color ? props.color : "#0F191A"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SmallCrossIcon;
