import React from "react";

const TwitterIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 26 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.8878 0.5H23.6315L15.4544 9.90852L25.1092 22.6667H17.5233L11.6122 14.933L4.81442 22.6667H1.07072L9.83887 12.6178L0.578125 0.5H8.36109L13.7303 7.59333L19.8878 0.5ZM18.5578 20.4007H20.6266L7.22813 2.61815H4.9622L18.5578 20.4007Z"
				fill="black"
			/>
		</svg>
	);
};

export default TwitterIcon;
