/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import HomePage from "../pages/HomePage";
import Pay from "../pages/Pay";
import MenuPage from "../pages/MenuPage";
import CheckoutPage from "../pages/CheckoutPage";
import LoginPage from "../pages/LoginPage";
import OTPVerification from "../pages/OTPVerification";
import ChooseAddressPage from "../pages/ChooseAddressPage";
import history from "../lib/history";
import OrderDetailsPage from "../pages/OrderDetailsPage";
import SingleOrderDetailPage from "../pages/SingleOrderDetailPage";
import getMenu from "../api/menu/getMenu";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import ConfirmationPage from "../pages/ConfirmationPage";
import SubscribePopup from "../component/SubscribePopup";
import SubscribeDonePopup from "../component/SubscribeDonePopup";
import PrivateRoute from "../component/PrivateRoute";
import qrSelectors from "../store/Qr/selectors";
import PreHomePage from "../pages/PreHomePage";
import { userActions } from "../store/user/reducer";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy";
import Razorpay from "../pages/razorpay";
import HotelHomePage from "../component/Hotel/Home";
import Confirmed from "../component/Hotel/Confirmation/Confirmed";
import EmptyCart from "../component/Hotel/EmptyCart/EmptyCart";
import CartScreen from "../component/Hotel/CartScreen";
import Amenities from "../component/Hotel/Amenities";
import DiningInConfirmation from "../component/DiningInConfirmation";
import Promotions from "../component/Promotions";
import AlsoOrdered from "../component/AlsoOrdered";
import Address from "../pages/Address";
import OrderSummary from "../pages/OrderSummary";
import SurpriseMe from "../pages/SurpriseMe";
import LoyaltyProgram from "../pages/LoyaltyProgram";
import { getCustomerDetails } from "../api/customer/customerAPI";
import userSelectors from "../store/user/selectors";
import MenuConversion from "../pages/MenuConversion";
import ExclusiveClub from "../pages/ExclusiveClub/ExclusiveClub";
import LoyaltyInfo from "../pages/LoyaltyInfo/LoyaltyInfo";
import OrderDetails from "../pages/OrderDetailsPage/OrderDetails";

const BASE_URL = "/:mid";
const BASE_URL_WITH_QR_ID = "/:mid/:qid";

const AllRoutes = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(userSelectors.getAccessToken);
  const { mid, qid } = useAppSelector(qrSelectors.getqr);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [openSubscribeConfirmationModal, setOpenSubscribeConfirmationModal] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setOpenSubscribeModal(true), 600000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleSubscribeClick = () => {
    setOpenSubscribeConfirmationModal(true);
  };
  const onCloseHandler = () => setOpenSubscribeModal(false);

  let path = window.location.pathname;
  console.log(path, "route path");

  useEffect(() => {
    if (
      path != "/" &&
      path != `${BASE_URL}/login` &&
      path != `${BASE_URL}/otp` &&
      path != `${BASE_URL_WITH_QR_ID}/login` &&
      path != `${BASE_URL_WITH_QR_ID}/otp` &&
      path != "/terms-and-conditions" &&
      path != "/privacy-policy" &&
      path != "/refund-policy" &&
      path != "/contact-us" &&
      path != "/about-us" &&
      path != "/menu-conversion"
    ) {
      checkIfTheCustomerTokenExpired();
    }
  }, [path]);

  const checkIfTheCustomerTokenExpired = async () => {
    const status = await getCustomerDetails(accessToken.jwt, mid, qid);
    if (status === 401) {
      dispatch(userActions.removeAccessToken({}));
    }
  };

  return (
    <HistoryRouter history={history}>
      {/* open={openSubscribeModal}  */}
      <Modal open={false} style={{ zIndex: 1, marginTop: "8rem" }}>
        {openSubscribeConfirmationModal ? (
          <div>
            <SubscribeDonePopup onCloseHandler={onCloseHandler} />
          </div>
        ) : (
          <div>
            <SubscribePopup
              onCloseHandler={onCloseHandler}
              handleSubscribeClick={handleSubscribeClick}
            />
          </div>
        )}
      </Modal>
      <Routes>
        <Route path="/" element={<PreHomePage />}></Route>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/alsoordered" element={<AlsoOrdered />} />

        <Route path="/hotel/cartscreen" element={<CartScreen />} />
        <Route path="/hotel/amenities" element={<Amenities />} />
        <Route path="/hotel/confirmed" element={<Confirmed />} />
        <Route path="/hotel/empty" element={<EmptyCart />} />
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/menu-conversion" element={<MenuConversion />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path={BASE_URL} element={<HomePage />}></Route>
        <Route path={BASE_URL_WITH_QR_ID} element={<HomePage />}></Route>
        <Route path={`${BASE_URL}/address`} element={<Address />}></Route>
        <Route path={`${BASE_URL}/checkout`} element={<CheckoutPage />} />
        <Route path={`${BASE_URL}/ordersummary`} element={<OrderSummary />} />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/address`}
          element={<Address />}
        ></Route>
        <Route
          path={`${BASE_URL_WITH_QR_ID}/checkout`}
          element={<CheckoutPage />}
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/ordersummary`}
          element={<OrderSummary />}
        />
        <Route
          path={`${BASE_URL}/dininginconfirmation`}
          // path='/dininginconfirmation'
          element={<DiningInConfirmation />}
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/dininginconfirmation`}
          element={<DiningInConfirmation />}
        />
        <Route
          path={`${BASE_URL}/loyaltyprogram`}
          element={<LoyaltyProgram />}
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/loyaltyprogram`}
          element={<LoyaltyProgram />}
        />
        <Route
          path={`${BASE_URL}/joinexclusiveclub`}
          element={<ExclusiveClub />}
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/joinexclusiveclub`}
          element={<ExclusiveClub />}
        />
        <Route path={`${BASE_URL}/loyaltyinfo`} element={<LoyaltyInfo />} />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/loyaltyinfo`}
          element={<LoyaltyInfo />}
        />
        <Route
          path={`${BASE_URL}/orderdetails/:customer_id/:orderId/:suborderId`}
          element={
            <PrivateRoute>
              <OrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/orderdetails/:customer_id/:orderId/:suborderId`}
          element={
            <PrivateRoute>
              <OrderDetails />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/upi"
          element={
            <PrivateRoute>
              <UPIPayment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/debit-card"
          element={
            <PrivateRoute>
              <DebitCardPayment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/credit-card"
          element={
            <PrivateRoute>
              <CreditCardPayment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/result"
          element={
           
              <PaymentResult />
            </PrivateRoute>
          }
        />{" "} */}
        <Route
          path={`/payment/:mid`}
          element={
            <PrivateRoute>
              <Pay />
            </PrivateRoute>
          }
        />
        <Route path={`${BASE_URL}/login`} element={<LoginPage />} />
        <Route path={`${BASE_URL_WITH_QR_ID}/login`} element={<LoginPage />} />
        <Route
          path={`${BASE_URL}/otp`}
          element={
            <OTPVerification
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          }
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/otp`}
          element={
            <OTPVerification
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          }
        />
        <Route path={`${BASE_URL}/menu/:item`} element={<MenuPage />} />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/menu/:item`}
          element={<MenuPage />}
        />
        <Route path={`${BASE_URL}/surprise-me`} element={<SurpriseMe />} />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/surprise-me`}
          element={<SurpriseMe />}
        />
        <Route
          path={`${BASE_URL}/order_summary/:customer_id`}
          element={
            <PrivateRoute>
              <OrderDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/order_summary/:customer_id`}
          element={
            <PrivateRoute>
              <OrderDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/confirmation"
          element={
            <PrivateRoute>
              <ConfirmationPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${BASE_URL}/order_summary/:customer_id/:orderId`}
          element={
            <PrivateRoute>
              <SingleOrderDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${BASE_URL_WITH_QR_ID}/order_summary/:customer_id/:orderId`}
          element={
            <PrivateRoute>
              <SingleOrderDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/choose-address"
          element={
            <PrivateRoute>
              <ChooseAddressPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/razorpay"
          element={
            <PrivateRoute>
              <Razorpay />
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
};

export default AllRoutes;
