/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="78"
         height="28"
         fill="none"
         viewBox="0 0 78 28">
         <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.223"
            d="M1.8 15.822c26.65 5.213 34.687 3.808 74.91-5.302m0 0c-3.334 0-13.632-1.704-28.152-8.52m28.152 8.52c-6.174 4.568-18.225 14.224-17.04 16.298"></path>
      </svg>
   );
}

export default Icon;
