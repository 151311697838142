import React from "react";

const ShareIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.6">
				<path
					d="M17.3984 14.4004C19.3904 14.4004 20.9984 16.0084 20.9984 18.0004C20.9984 19.9924 19.3904 21.6004 17.3984 21.6004C15.4064 21.6004 13.7984 19.9924 13.7984 18.0004C13.7984 17.7124 13.8344 17.4484 13.9064 17.1724L8.65044 14.4124C7.99044 15.1444 7.05444 15.6004 5.99844 15.6004C4.00644 15.6004 2.39844 13.9924 2.39844 12.0004C2.39844 10.0084 4.00644 8.40039 5.99844 8.40039C7.05444 8.40039 7.99044 8.86839 8.65044 9.58839L13.9064 6.82839C13.8344 6.55239 13.7984 6.28839 13.7984 6.00039C13.7984 4.00839 15.4064 2.40039 17.3984 2.40039C19.3904 2.40039 20.9984 4.00839 20.9984 6.00039C20.9984 7.99239 19.3904 9.60039 17.3984 9.60039C16.3424 9.60039 15.4064 9.13239 14.7464 8.41239L9.49044 11.1724C9.63578 11.7148 9.63578 12.286 9.49044 12.8284L14.7464 15.5884C15.4064 14.8564 16.3424 14.4004 17.3984 14.4004Z"
					fill="#0F191A"
				/>
			</g>
		</svg>
	);
};

export default ShareIcon;
