/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
interface PrevOrderItemsProps {
   prevOrderItemId: string;
   prevOrderItemCount: number;
}

// export interface updateOrderCart {
//   cart: Array<any>;
//   isUpdating: boolean;
//   totalValue: number;
//   itemCount: number;
//   prevOrderTotalValue: number;
//   prevOrderValues: Array<PrevOrderItemsProps>;
//   refund: number;
//   isModalVisible: boolean;
//   totalAmountToBePaid: number;
//   newAddedItemsAmount: number;
//   orderId: string;
//   payment_gateway_details: any;
//   payment_status: string;
//   flags: {
//     showCountModifierInCart: boolean;
//   };
//   previousCartTotal: number;
//   currentCartTotal: number;
//   refundAmount: number;
// }

export interface updateOrderCart {
   previousCartTotal: number;
   payment_status: string;
   updateCart: Array<any>;
   refundAmount: number;
   updateCartTotal: number;
   totalAmountTobePaid: number;
   isUpdating: boolean;
   payment_gateway_details: any;
   orderId: string;
   refundAmountLocked: number;
   amountPaid: number;
}

const initialState: updateOrderCart = {
   previousCartTotal: 0,
   payment_status: "",
   updateCart: [],
   refundAmount: 0,
   updateCartTotal: 0,
   totalAmountTobePaid: 0,
   isUpdating: false,
   payment_gateway_details: {},
   orderId: "",
   refundAmountLocked: 0,
   amountPaid: 0,
};

export const updateOrderCartSlice = createSlice({
   name: "updateOrdercart",
   initialState: initialState,
   reducers: {
      initialise: (state: any, action: any) => {
         const { total_amount_after_discount, payment_status, payment_gateway_details, order_id, items_ordered, amount_paid } = action.payload;

         state.payment_status = payment_status;
         state.payment_gateway_details = payment_gateway_details;
         state.orderId = order_id;
         state.isUpdating = true;
         state.amountPaid = amount_paid;
         state.updateCart = items_ordered.map((item: { count: any }) => {
            return { ...item, lockedCount: item.count };
         });
         for (const key in items_ordered) {
            if (items_ordered[key].out_of_stock) {
               if (payment_status === "paid") {
                  state.refundAmount += items_ordered[key].total_price;
               }
               state.refundAmountLocked += items_ordered[key].total_price;
            }
            state.updateCartTotal += items_ordered[key].total_price;
            state.previousCartTotal += items_ordered[key].total_price;
         }
      },
      addItem: (state: any, action: any) => {
         //Assign existing cart to a variable
         let existingCart = [...state.updateCart];

         //Finding the index of the incoming item from exisiting cart
         let itemIndex = existingCart.findIndex((item) => item.item_id === action.payload.item_id);

         //Check if the item is aldready exists or not by getting the index.
         //If index not equal to -1 means, the item already exisiting
         if (itemIndex !== -1) {
            //If the item exists means, just adding 1 to the count of the item
            state.updateCart[itemIndex]["count"] = existingCart[itemIndex].count + 1;
            state.updateCart[itemIndex]["total_price"] = existingCart[itemIndex].count * action.payload.price_after_discount;
         } else {
            //If the item not exists, just push the payload item into the updateCart with count value of 1
            let newItem = { ...action.payload };
            newItem.count = 1;
            newItem.total_price = newItem.count * newItem.price_after_discount;
            state.updateCart.push(newItem);
         }

         let total = 0;
         for (const key in state.updateCart) {
            if (!state.updateCart[key].out_of_stock) {
               total += state.updateCart[key].total_price;
            }
         }

         state.updateCartTotal = total;
         const totalDifference = state.previousCartTotal - state.updateCartTotal;
         console.log("TOTAL DIFFERENCE", totalDifference);

         if (state.payment_status === "paid") {
            if (totalDifference > 0) {
               state.refundAmount = totalDifference;
            } else {
               state.totalAmountTobePaid = totalDifference * -1;
               state.refundAmount = 0;
            }
         } else {
            state.totalAmountTobePaid = total;
         }
         // if (state.refundAmount > 0) {
         //   state.refundAmount =
         //     state.refundAmount - action.payload.price_after_discount;
         // } else {
         //   state.totalAmountTobePaid =
         //     state.updateCartTotal - state.refundAmountLocked;
         // }
      },
      removeItem: (state: any, action: any) => {
         let existingCart = [...state.updateCart];

         //Finding the index of the incoming item from exisiting cart
         let itemIndex = existingCart.findIndex((item) => item.item_id === action.payload.item_id);

         let existingItem = { ...existingCart[itemIndex] };

         console.log(existingItem?.count, existingItem?.lockedCount, existingItem?.count > existingItem?.lockedCount, "count");

         // if (
         //   existingItem?.count > existingItem?.lockedCount ||
         //   existingItem?.lockedCount === undefined
         // ) {
         existingCart.splice(itemIndex, 1);
         if (existingItem.count > 1) {
            existingItem["count"] = existingItem.count - 1;
            existingItem["total_price"] = existingItem.count * existingItem.price_after_discount;
            existingCart.splice(itemIndex, 0, existingItem);
         }

         state.updateCart = existingCart;

         let total = 0;
         for (const key in state.updateCart) {
            if (!state.updateCart[key].out_of_stock) {
               console.log(state.updateCart[key].total_price, "state.updateCart[key].total_price");

               total += state.updateCart[key].total_price;
            }
         }

         state.updateCartTotal = total;
         const totalDifference = state.previousCartTotal - state.updateCartTotal;
         if (state.payment_status === "paid") {
            if (totalDifference > 0) {
               state.refundAmount = totalDifference;
            } else {
               state.totalAmountTobePaid = totalDifference * -1;
               state.refundAmount = 0;
            }
         } else {
            state.totalAmountTobePaid = total;
         }
      },
      clearCart: (state: any, action: any) => {
         state.previousCartTotal = 0;
         state.payment_status = "";
         state.updateCart = [];
         state.refundAmount = 0;
         state.updateCartTotal = 0;
         state.totalAmountTobePaid = 0;
         state.isUpdating = false;
         state.payment_gateway_details = {};
         state.orderId = "";
         state.refundAmountLocked = 0;
      },
   },
});

export const updateOrderCartActions = updateOrderCartSlice.actions;
