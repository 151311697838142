/** @format */

import React from "react";
import RightArrow from "../../asset/icons/RightArrow";
import FilledTag from "../FilledTag";
import "./index.scss";
import CustomAccordion from "../CustomAccordion";
import { useAppSelector } from "../../store/hooks";
import showMenuSelector from "../../store/showMenu/selectors";

type Props = {
   index: number;
   item: any;
   orderDetails: any;
};

function DineInOrderCard({ index, item, orderDetails }: Props) {
   const [open, setOpen] = React.useState(item?.suborder_status === "new_order");
   const { currency } = useAppSelector(showMenuSelector.showMenu);

   const getCustomizationsCount = (cartItem: any) => {
      let total = cartItem?.customizations?.length || 0;
      if (cartItem?.other_customization !== undefined && cartItem?.other_customization !== "") {
         total = total + 1;
      }
      return total;
   };

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
         }}>
         <div
            style={{
               borderRadius: !open ? "8px 8px 8px 8px" : "",
            }}
            className="single-order-items-container"
            onClick={() => {
               setOpen(!open);
            }}>
            <div className="order-no">
               Order {index + 1}
               {orderDetails?.order_time && (
                  <span className="order-time">
                     - {orderDetails?.order_time}
                     {/* {new Date(item?.order_time).toUTCString().slice(17, 25)} */}
                  </span>
               )}
            </div>

            <FilledTag order_status={item?.suborder_status} />
            <div
               style={{
                  marginRight: "10px",
               }}>
               <RightArrow
                  transform={"rotate(90deg)"}
                  Totransform={"rotate(180deg)"}
                  open={open}
               />
            </div>
         </div>
         {open && (
            <>
               <div className="dine-in-order-card-main-container">
                  {item?.suborder_items_ordered?.map((i: any) => {
                     return (
                        <>
                           <div className="dine-in-card-container">
                              <div className="dine-in-card-item-price">
                                 <span className="dine-in-card-item-name">{i?.item_name}</span>
                                 <span className="dine-in-card-price">
                                    {currency}
                                    {i?.price_after_discount * i?.count}
                                 </span>
                              </div>
                              <div className="dine-in-card-qty">
                                 <span>Qty :</span> <span>{i?.count}</span>
                              </div>
                           </div>
                           <div style={{ padding: "0.5rem", marginTop: "-1rem" }}>
                              {i.addons?.length > 0 && (
                                 <CustomAccordion
                                    header={
                                       <div
                                          style={{ fontWeight: "normal" }}
                                          className="order-no">
                                          Add ons ({i.addons?.length})
                                       </div>
                                    }
                                    details={
                                       <>
                                          {i.addons.map((item: any) => {
                                             return (
                                                <div className="dine-in-card-container">
                                                   <div className="dine-in-card-item-price">
                                                      <span className="dine-in-card-item-name">{item?.item_name}</span>
                                                      <span className="dine-in-card-price">
                                                         {currency}
                                                         {item?.count * item.price}
                                                      </span>
                                                   </div>
                                                   <div className="dine-in-card-qty">
                                                      <span>Qty :</span> <span>{item?.count}</span>
                                                   </div>
                                                </div>
                                             );
                                          })}
                                       </>
                                    }
                                 />
                              )}
                              {getCustomizationsCount(i) > 0 && (
                                 <CustomAccordion
                                    header={
                                       <div
                                          style={{ fontWeight: "normal" }}
                                          className="order-no">
                                          Customizations ({getCustomizationsCount(i)})
                                       </div>
                                    }
                                    details={
                                       <>
                                          {i.customizations.map((item: any) => {
                                             return (
                                                <div className="dine-in-card-container">
                                                   <div className="dine-in-card-item-price">
                                                      <span className="dine-in-card-item-name">
                                                         {item?.customisation_title}
                                                         <span className="dine-in-card-price">
                                                            <div>
                                                               {item?.selected_options?.map((item: any) => (
                                                                  <div>{item}</div>
                                                               ))}
                                                            </div>
                                                         </span>
                                                      </span>
                                                   </div>
                                                </div>
                                             );
                                          })}
                                          {i?.other_customization !== "" && (
                                             <div className="dine-in-card-container">
                                                <div className="dine-in-card-item-price">
                                                   <span
                                                      style={{ fontWeight: "normal" }}
                                                      className="dine-in-card-item-name">
                                                      {i?.other_customization}
                                                   </span>
                                                </div>
                                             </div>
                                          )}
                                       </>
                                    }
                                 />
                              )}
                           </div>
                        </>
                     );
                  })}
               </div>
            </>
         )}
      </div>
   );
}

export default DineInOrderCard;
