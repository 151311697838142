import { createSlice } from "@reduxjs/toolkit";
export interface MenuState {
  addon: any;
  customization: any;
}

const initialState: MenuState = {
    addon: null,
    customization: null,
};

export const addonsSlice = createSlice({
  name: "addons",
  initialState: initialState,
  reducers: {
    setAddon: (state: any, action: any) => {
      state.addon = action.payload.addon;
    },
    setCustomization: (state: any, action: any) => {
      state.customization = action.payload.customization;
    },
  },
});

export const addonsActions = addonsSlice.actions;
