import React from "react";
import { ReactComponent as BackArrow } from "../../asset/svg/backArrow.svg";
import { useNavigate } from "react-router-dom";

export default function OrderPageHeader({
  title,
  date,
  addItemsButton,
  orderId,
  HandlerBackArrow,
}) {
  return (
    <div className="flex justify-between pl-2 sticky top-0 bg-[#ffffff]">
      <div className="flex items-center gap-x-2 py-3 ">
        <BackArrow onClick={HandlerBackArrow} />
        <div>
          <p
            className={` font-bold font-custom ${
              addItemsButton ? "text-xl" : "text-base"
            }`}
          >
            {title}
          </p>
          <p className="text-sm font-custom text-neutral-400 tracking-tight">
            {date}
          </p>
          {addItemsButton && (
            <p className="text-sm font-semibold text-neutral-900 tracking-tight">
              <span className="font-custom">Order ID: #{orderId}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
