import React from "react";

function OrdersIcon() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.6">
				<path
					d="M21.9688 18.5V19.5C21.9688 21.15 21.9688 22.5 18.9688 22.5H4.96875C1.96875 22.5 1.96875 21.15 1.96875 19.5V18.5C1.96875 17.95 2.41875 17.5 2.96875 17.5H20.9688C21.5187 17.5 21.9688 17.95 21.9688 18.5Z"
					fill="#0F191A"
				/>
				<path
					d="M14.4095 5.67977C14.4595 5.47977 14.4895 5.28977 14.4995 5.07977C14.5295 3.91977 13.8195 2.89977 12.6995 2.59977C11.0195 2.13977 9.49953 3.39977 9.49953 4.99977C9.49953 5.23977 9.52953 5.45977 9.58953 5.67977C5.97953 6.44977 3.26953 9.65977 3.26953 13.4998V14.9998C3.26953 15.5498 3.71953 15.9998 4.26953 15.9998H19.7195C20.2695 15.9998 20.7195 15.5498 20.7195 14.9998V13.4998C20.7195 9.65977 18.0195 6.45977 14.4095 5.67977ZM14.9995 12.2498H8.99953C8.58953 12.2498 8.24953 11.9098 8.24953 11.4998C8.24953 11.0898 8.58953 10.7498 8.99953 10.7498H14.9995C15.4095 10.7498 15.7495 11.0898 15.7495 11.4998C15.7495 11.9098 15.4095 12.2498 14.9995 12.2498Z"
					fill="#0F191A"
				/>
			</g>
		</svg>
	);
}

export default OrdersIcon;
