import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./index.scss";

export default function PlusAndMinusWithQuantity({ quantity, disableRemove, onPressRemove, onPressAdd }) {
	return (
		<div className="plus-and-minus-container">
			<RemoveIcon
				onClick={() => !disableRemove && onPressRemove()}
				sx={{ color: "#0F6656", opacity: disableRemove ? 0.5 : 1 }}
			/>
			<div className="plus-and-minus-qty-text">{quantity}</div>
			<AddIcon
				onClick={onPressAdd}
				sx={{ color: "#0F6656" }}
			/>
		</div>
	);
}
