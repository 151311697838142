/** @format */

import React from "react";
import PlusIconWhite from "../../asset/icons/PlusIconWhite";
import ButtonWithArrow from "../../component/Button";
import PageHeader from "../../component/PageHeader";
import SelectAddressCard from "../../component/SelectAddressCard";
import { Text } from "../../component/Text";
import { useAppSelector } from "../../store/hooks";
import qrSelectors from "../../store/Qr/selectors";
import { Container, TextContainer, AddNewDetailButton, Line, AddNewAddressContainer } from "./styles";
import "./style.scss";
import { useParams } from "react-router-dom";

const ChooseOrderPage = () => {
   const { mid } = useParams();
   return (
      <>
         <PageHeader
            backLink={`/checkout/${mid}`}
            headerText=""
            background="#0F6656"
            textColor=""
         />
         <div className="choose-address-page-container">
            <div>
               <div className="choose-address-page-text1">You are in final step</div>
               <div className="choose-address-page-text-container">
                  <Text
                     size="1.2rem"
                     weight="500"
                     color="white"
                     lHeight="1.33"
                     style={{
                        whiteSpace: "nowrap",
                     }}>
                     Saved details
                  </Text>
                  <Line />
               </div>

               <SelectAddressCard />

               <div className="choose-address-page-add-new-address-container">
                  <div className="choose-address-page-add-new-detaile-btn">
                     Add new details <PlusIconWhite />
                  </div>
               </div>
            </div>
            <div>
               <div className="choose-address-page-text2">Important note</div>
               <div className="choose-address-page-text3">You will not be able to modify the order once it is placed. Contact hotel staff to modify your orders</div>
               <ButtonWithArrow
                  background="white"
                  buttonText="Place order"
                  showArrow
                  fontColor="#0F6656"
                  border=""
               />
            </div>
         </div>
      </>
   );
};

export default ChooseOrderPage;
