import { request } from "../axios";
import { SuccessResponse, ErrorResponse, ResponsePayload } from "../index.type";
import { PayloadProps, Props } from "./getRecommendedItems.type";
import { ErrorResponseHandler } from "../index";

const getRecommendedItems = async ({merchantId,}: Props): Promise<SuccessResponse<PayloadProps> | ErrorResponse> => {
  try {
    const response = await request<ResponsePayload<PayloadProps>>({
      api: `/customer/recommended_items/${merchantId}`,
      method: "GET",
    });

    const result = response.data;

    if (response.status !== 200) {
      throw new Error(result.error.message);
    }

    return {
      statusCode: 200,
      message: "Success",
      payload: result,
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getRecommendedItems;
