/** @format */

import { createSlice } from "@reduxjs/toolkit";
export interface MenuState {
   menu: any;
}

const initialState: MenuState = {
   menu: null,
};

export const menuSlice = createSlice({
   name: "menu",
   initialState: initialState,
   reducers: {
      addMenu: (state: any, action: any) => {
         state.menu = action.payload.menu;
      },
   },
});

export const menuActions = menuSlice.actions;
